import React from 'react'
import { Routes,Route} from 'react-router-dom'
import Signup from '../screen/Signup'
import Login from '../screen/Login'
import Recovery from '../screen/Recovery'
import Proceed from '../screen/Proceed'
import Company from '../screen/Company'
import Plan from '../screen/Plan'
import Payment from '../screen/Payment'
import Blocked from '../screen/Blocked'
import NotFound from '../screen/NotFound'
import Waiting from '../screen/Waiting'
import MainLoader from '../components/MainLoader'
import Expired from '../screen/Expired'
import Landing from '../screen/Landing'
import Pricing from '../screen/Pricing'

function AuthNavigation({screename}) {
  return (
    <Routes>
        <Route path="/"  element={ 
          screename === "setup" ? <Company /> :
          screename === "blocked" ? <Blocked /> :
          screename === "expired" ? <Expired /> :
          screename === "waiting" ? <Waiting /> :
          screename === "landing" ? <Landing /> :
          <Login  />} />
        <Route path="/Signup"  element={<Signup />}  />
        <Route path="/Login"  element={<Login  />}   />
        <Route path="/Recover"  element={<Recovery />} />
        <Route path="/Proceed"  element={<Proceed />} />
        <Route path="/Company"  element={<Company />} />
        <Route path="/Subscription"  element={<Plan />} />
        <Route path="/Payment"  element={<Payment />} />
        <Route path="/Blocked"  element={<Blocked />} />
        <Route path="/Waiting"  element={<Waiting />} />
        <Route path="/Expired"  element={<Expired />} />
        <Route path="/Landing"  element={<Landing />} />
        <Route path="/Pricing"  element={<Pricing />} />
        <Route path="/home/:id"  element={<Landing />} />
        <Route path="*"  element={<MainLoader />} />
   </Routes>
           
  )
}

export default AuthNavigation