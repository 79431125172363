import React,{useContext,useState} from 'react'
import TopNav from '../components/TopNav'
import {ThemeContext} from '../App'
import SiderContent from '../components/SiderContent'
import QRCode from 'react-qr-code';
import Printer, { print } from 'react-pdf-print'
import {useLocation} from 'react-router-dom'
import format from 'date-fns/format'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import { useSnackbar } from 'react-simple-snackbar'

const ids = ['1']

function Receipt() {
    const { body} = useContext(ThemeContext)
    const {state} = useLocation()
    const [openSnackbar] = useSnackbar()
    const {currentUser} = useGlobalContextHook()
    const {settings,companyId,username} = currentUser
    const {currency} = settings
    const {company,branch,amountPaid,fullName,loanId,title,totalLoan,loanOfficerName,createdAt} = state

    const print = async() =>{
      getCompanyInfo()
    }

    const getCompanyInfo = async()=>{
      try{
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_USER_BY_COMPANY_ID&id=${companyId}`)
        const json = await response.json()
        if(response.ok){
          downloadFormLoan(json[0])
        }
        if(!response.ok){
          openSnackbar(json.message)
        }
      }catch(error){openSnackbar("Error getting company")}
    }
    
    const downloadFormLoan = async(companyOwner)=>{
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=PRINT_RECEIPT&logo=${companyOwner.photoURL}&currency=${currency}&branch=${branch}&company=${companyOwner.company}&date=${format(new Date(createdAt),'dd/MMMM/yyyy')}&email=${companyOwner.email}&phone=${companyOwner.phoneNumber}&username=${username}`,{
          method: 'POST',
          body: JSON.stringify(state),
          headers: {'Content-Type': 'application/json'}
        })
        if(response.ok){
          const file = await response.blob()
          const createdFile =  await window.URL.createObjectURL(new Blob([file]), { type: 'application/pdf'});
          const link = document.createElement('a');
          link.href = createdFile;
          link.download = `Receipt ${fullName}.pdf`;
          link.click();
      
        }
      
        if(!response.ok){
          openSnackbar("Error creating pdf")
        }
      } catch (error) {
          console.log(error)
        }
      }
  return (
    <div className="main-wapper" >
    <TopNav /> 
<div className="content-wrapper">
<div className="sider" ><SiderContent /></div>
            <div className="section" style={{backgroundColor:body}}> <br />
            <div className="receipt-wrapper">
                    <h2>{company}</h2>
                     <h3>Branch: {branch}</h3>
                     <div className="stars">CASH RECEIPT</div>
                     
                     <div className="des-box">
                     <h4>Decription</h4>

                     <div className="des-bar">
                        <div className="des-left">Date</div>
                        <div className="des-left">{format(new Date(createdAt),'dd/MMMM/yyyy')}</div>
                     </div>

                     <div className="des-bar">
                        <div className="des-left">Received from</div>
                        <div className="des-left">{fullName}</div>
                     </div>

                     <div className="des-bar">
                        <div className="des-left">Amount</div>
                        <div className="des-left">{amountPaid.toLocaleString()}<span className="currency">{currency}</span></div>
                     </div>

                     
                     <div className="des-bar">
                        <div className="des-left">For</div>
                        <div className="des-left">{title} repayment</div>
                     </div>

                     <div className="des-bar">
                        <div className="des-left">Received by</div>
                        <div className="des-left">{loanOfficerName}</div>
                     </div>

                     <div className="des-bar">
                        <div className="des-left" ><strong>Total loan</strong></div>
                        <div className="des-left">{totalLoan.toLocaleString()}<span className="currency">{currency}</span></div>
                     </div>

                    </div>

                    <div className="des-qr-code">
                    <QRCode
                        title="loan details"
                        value={"Name "+ fullName +', Amount paid: '+amountPaid.toLocaleString()+ ' ,Date: '+format(new Date(createdAt),'dd/MMMM/yyyy')+', Collected by'+ loanOfficerName}
                        bgColor="#fff"
                        fgColor="#000"
                        size={70}
                        /> <br />
                    </div>
                    <div className="des-qr-code">
                        THANK YOU
                    </div>
                    
                </div>

                <input type='button' className="print-pdf" 
                    onClick={() => print()} value='Download Receipt' />
            </div>
</div>
        </div> 
  )
}

export default Receipt