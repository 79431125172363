import React from 'react'

function AccessDenied() {
  return (
    <div className="access-wapper">
        <h1>403</h1>
        <h2>Access Denied/Forbidden</h2>
         <p>You are not allowed to access this page. contact the administrator to grant you the access</p>
    </div>
  )
}

export default AccessDenied