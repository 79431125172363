import React,{useContext} from 'react'
import {ThemeContext} from '../App'
import { Icon} from '@blueprintjs/core'

function PersonalTab({loan}) {
  const {  primary,lightgrey,card,grey} = useContext(ThemeContext)
  const {fullName,sex,residence,phoneNumber,age,occupation,idNumber2,idNumber1} = loan
 
  return (
    <div className="d-wrapper"  >
        <div className="info-box" style={{ backgroundColor:card}}>
             <div className="info-rail">
                <Icon icon="user" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title" >Full name</div>
                     <div className="info-title" style={{color:lightgrey}}>{fullName}</div>
             </div>

             <div className="info-rail">
                <Icon icon="exchange" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title" >Sex</div>
                     <div className="info-title" style={{color:lightgrey}}>{sex}</div>
             </div>

             <div className="info-rail">
                <Icon icon="home" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title" >Residence</div>
                     <div className="info-title" style={{color:lightgrey}}>{residence}</div>
             </div>

             
             <div className="info-rail">
                <Icon icon="phone" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title" >Phone</div>
                     <div className="info-title" style={{color:lightgrey}}>{phoneNumber}</div>
             </div>

                         
             <div className="info-rail">
                <Icon icon="database" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title" >Age</div>
                     <div className="info-title" style={{color:lightgrey}}>{age}</div>
             </div>

             <div className="info-rail">
                <Icon icon="build" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title" >Occupation</div>
                     <div className="info-title" style={{color:lightgrey}}>{occupation}</div>
             </div>

             <div className="info-rail">
                <Icon icon="id-number" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title" >Id number</div>
                     <div className="info-title" style={{color:lightgrey}}>{idNumber1}</div>
             </div>

             
             <div className="info-rail">
                <Icon icon="id-number" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title" > Extra Id number</div>
                     <div className="info-title" style={{color:lightgrey}}>{idNumber2}</div>
             </div>

            
            
          </div>

    </div>
  )
}

export default PersonalTab