import React,{useContext,useState} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {ThemeContext,UserContext} from '../App'
import FinancesTab from '../tabs/FinancesTab'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import SelectBranch from '../components/SelectBranch'
import AccessDenied from '../components/AccessDenied'


function Expenses() {
    const {body} = useContext(ThemeContext)
    const {currentUser,activeBranch} = useGlobalContextHook()
    const {role,branch,branchId} = currentUser
    const {permissions} = currentUser
    const {expenses:expensesPermissions} = permissions
  
  return (
    <div className="main-wapper" >
         <TopNav />
    <div className="content-wrapper">
    <div className="sider" >
            <SiderContent />
        </div>
        <div className="section" style={{backgroundColor:body}}> 
          {!expensesPermissions[1].isGranted ? <AccessDenied /> : 
            <div>
               {activeBranch === null && role === "company_admin" ? <SelectBranch /> : 
         activeBranch !== null && role === "worker"  ?   <FinancesTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> :
           <FinancesTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> }
            </div> }
        </div>
    </div>
    </div>
  )
}

export default Expenses