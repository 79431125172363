import React,{useContext,useState,useEffect} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {ThemeContext} from '../App'
import Notibar from '../components/Notibar'
import {Icon} from '@blueprintjs/core'
import Empty from '../components/Empty'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import { useSnackbar } from 'react-simple-snackbar'


function Notifications() {
    const {currentUser,dispatch,notifications} = useGlobalContextHook()
    const { nusery,body,lightgrey,lighttext} = useContext(ThemeContext)
    const [limit,setLimit] = useState(40)
    const [unread,setUnread] = useState(0)
    const [openSnackbar] = useSnackbar()


    useEffect(() => {
          
      const unSubscribe=async() => {
            try {
              const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_NOTIFICATIONS&id=${currentUser.id}&limit=${limit}`)
              const json = await response.json()
              if(response.ok){
                dispatch({type:'SET_NOTIFICATIONS',payload:json})
              }
              
              if(!response.ok){
                console.log('error on fetching notifications')
              }
            } catch (error) {
              console.log(error)
            }
      }
      unSubscribe();
      return () =>unSubscribe();
    },[])

    //list unread notifications
useEffect(() => {
  const unSubscribe=async() => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_UNREAD_NOTIFICATIONS&id=${currentUser.id}&limit=${limit}`)
          const json = await response.json()
          if(response.ok){
           setUnread(json.length)
          }
          
         if(!response.ok){
            openSnackbar(json.message)
          }
        } catch (error) {
          console.log(error)
        }
  }
  unSubscribe();
  return () =>unSubscribe();
},[notifications])
 
  return (
    <div className="main-wapper" >
         <TopNav />
        <div className="content-wrapper">
        <div className="sider" >
            <SiderContent />
        </div>
        <div className="section" style={{backgroundColor:body}}> 
        <div class="noti-header" style={{width:'100%'}}>
           <div className="h1">Notifications</div>
           <div className="bell">
           <button type="button" class="icon-button">
                <span class="material-icons">notifications</span>
              {unread <= 0 ? <div /> :  <span class="icon-button__badge">{unread}</span>}
           </button></div>
        </div>
               {notifications.length <= 0 ? <div class="noti-main-wrapper">
                   <Empty  icon="notifications" color={lightgrey} name="There are currently no new notifications"/>
               </div> : 
                  <div class="noti-main-wrapper">
                    {notifications.map((noti,i)=>(
                                   <Notibar  info={noti} key={noti._id}/>
                    ))}
                  </div>
                  }
              
           </div>
        </div>
    </div>
  )
}

export default Notifications