import React,{useState,useEffect,useContext} from 'react'
import logo from '../img/logo.png'
import {Link,useLocation,useNavigate} from 'react-router-dom'
import {Icon,Collapse} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import { useSnackbar } from 'react-simple-snackbar'
const uniqueId = require('react-unique-nums')
var moment = require('moment'); // require
moment().format();



function Login() {


    const {state}  = useLocation()
    const navigate = useNavigate()
    const {name,brandColor,companyId,_id} = state
    const [open, close] = useState(false)
    const [toggle,setToggle] = useState(false)
    const [errorMessage,showErrorMessage] = useState(null)
    const [allBranches,setbranches] = useState([])
    const [openSnackbar, closeSnackbar] = useSnackbar()
    const {dispatch,currentUser} = useGlobalContextHook()

    
    const workerProceed = () => {
        close(prev=>!prev)
          
    }

    useEffect(() => {
       const unSubscribe = async() => {
             try {
                //fetching branches 
                try {
                    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_BRANCHES&id=${_id}&limit=${50}&criteria=active`)
                    const json = await response.json()
                     if(response.ok){
                        setbranches(json)
                     }
                  } catch (error) {
                     console.log(error,'error on fetching branches')
                  }
    
             } catch (error) {
                  console.log(error,'here is the error on fetching branches')
             }         
              
       }

       unSubscribe()
       return ()=>unSubscribe();
    },[])

    const searchBranch = async(keyword)=>{
        if(keyword.length > 0) {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SEARCH_BRANCH&keyword=${keyword}&id=${_id}`)
                const json = await response.json()
                if(response.ok){
                    setbranches(json)
                }
                if(!response.ok){
                    console.log('error on fetching branches')
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
          const unSubscribe = async () => {
              // check if my company has verified me            
          }
          unSubscribe()
          return ()=>unSubscribe();
    },[])
    const companyAdminProceed = () => {
       //check if own the compnay and subscribe to the plan if not take me to the payment
    }

    const selectBranch = async(branch)=>{
      //obtain latest user information from db
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_USER&id=${currentUser.id}`)
        const json = await response.json()
        if(response.statusText === "OK"){
            dispatch({type:'SET_CURRENT_USER',payload:json})
              navigate('/Waiting') 
                checkProfile(branch)
        }
        if(response.statusText ==="Not Found"){
           console.log(json.message)
        }
     } catch (error) {
        console.log(error)
     }


     }

     const checkProfile =async(branch)=>{
        if(currentUser.worker_status === "active")  {
            sendWorkerHome(branch)
       }  else {
          if(currentUser.worker_status === "blocked"){
              navigate("/Blocked")
          } else {
            if(currentUser.worker_status === "waiting approval"){
                 openSnackbar('Your account has not been approved yet')
                 navigate('/Waiting')
            } else {
               if(currentUser.role === "company_admin"){
                    companyAdminProceed()
               } else{
                  if(currentUser.worker_status === 'unset'){
                    sendWorkerRequest(branch)
                  }
               }
            }
          }
       }
     }

   const sendWorkerRequest =async(branch)=>{
    try {
            
        const userObj = {worker_status:"waiting approval",branch:branch.name,branchId:branch._id}
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_USER_PROFILE&id=${currentUser.id}`,{
            method: 'PATCH',
            body: JSON.stringify(userObj),
            headers: {'Content-Type': 'application/json'}
        })
        const json = await response.json()
        if(response.ok) {
             //update user context
             dispatch({type:'SET_CURRENT_USER',payload:json})
             openSnackbar('Request to continue as worker for '+branch.name +' sent successfully')
        }
        if(!response.ok){
            openSnackbar('!Ooops! failed to update user profile')
        }

      } catch (error) {
          console.log(error);
      }
   } 

   const sendWorkerHome =async()=>{
                navigate("/");
   }



  return (
    <div>
        <div className="auth-nav">
            <img src={logo} />
                <h2 style={{color:"#fff"}}>Kopaleo</h2>
        </div>

        <div className="auth-container">
            <div className="comp-bar" style={{marginLeft:10}}>
                        <div className="round-comp" style={{ backgroundColor:brandColor}}>{name.charAt(0)+name.charAt(1)}</div> 
                         <div className="round-name"><h2 style={{ marginTop:-3}}>{name}</h2></div> 
                        
                </div>
                <h2>Continue as</h2>
           {currentUser.role === "company_admin" ? <div/> : 
           
           <div className="input-bar" onClick={()=>workerProceed()} >
           <Icon icon="user" color={"#5F6B7C"}  size={20} className='icon-auth'/>
            <div className="input-title">Worker/Agent</div>
         
       </div>
             }
             
        <Collapse isOpen={open} canOutsideClickClose={true} onClose={()=>close(prev=>!prev)}>
        <div className="companies">
               { /* <div>Request to continue as a worker has been sent to the company admin.
                     Please contact admin to approve your request.</div>
                    <div>E-mail: <b>John@doe.mail.com</b></div>  */ }     

                     <h4>Select  branch</h4>
                 <input type="text" placeholder="search branch"  onChange={(e)=>searchBranch(e.target.value)}className="search-company"/> 
                 {allBranches.length <= 0 ? <div style={{textAlign:'center'}}>No branches found</div> :
                   <div>
                       {allBranches.map((branch, index)=>(
                               <div className="comp-bar" key={index} style={{marginTop:10}} onClick={()=>selectBranch(branch)}>
                                    <div className="round-comp" style={{ backgroundColor:branch.color}}>{branch.name.charAt(0)+branch.name.charAt(1)}</div> 
                                        <div className="round-name">{branch.name}</div> 
                            </div>
                       ))}
                   </div>
                 }

             </div>
        </Collapse>
            
            <div className="input-bar" onClick={()=>companyAdminProceed()}>
                <Icon icon="crown" color={"#5F6B7C"}  size={20} className='icon-auth'/>
                <div className="input-title">Company admin</div>
            </div> 

            <Collapse isOpen={toggle} canOutsideClickClose={true} onClick={()=>setToggle(prev=>!prev)}>
            <div className="companies">
                 
             </div>
            </Collapse>

            {errorMessage === null ? <div/> : 
			  <div className="input-bar" style={{borderWidth:0}}>
			  <Icon icon="error" color={"#b41313"}  size={20} className='icon-auth'/>
			  <p   style={{color:"#b41313"}}>{errorMessage.toString()}</p>
				 </div> 
			}

            

        </div>
    </div>
  )
}

export default Login