import React,{useContext,useState} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {ThemeContext,UserContext} from '../App'
import {Icon,Dialog,Spinner,SpinnerSize} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'


function Expenses() {
    const {body,primary} = useContext(ThemeContext)
    const [show,setShow] = useState(false)
    const {currentUser} = useGlobalContextHook()
    const [selectedAttachment,setSelectedAttachment] = useState(null)
    const {role} = currentUser
    const [spin,stop] = useState(false)

    const uploadAttachment = async() =>{

    }
 
  return (
    <div className="main-wapper" >
         <TopNav />
    <div className="content-wrapper">
    <div className="sider" >
            <SiderContent />
        </div>
        <div className="section" style={{backgroundColor:body}}> 
        <div className="learn-bar">
            <h2>Videos</h2>
            <input type="text" placeholder="Search lesson" />
            <div class="add-user" onClick={()=>setShow(prev=>!prev)}>Upload video</div>
        </div>
        <div className='dash-wrapper'>
        <div className='dash-section'>
                <video controls={true}  className="play-div"
                 src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" />
                <h2>Namna ya kuongeza fomu ya mteja mpya</h2>
                <p>Description ya kuprint ripoti ya mteja lorem na can it be found in some dash and underscore can be verified in some dash and underscore can be verified in symbolSize
                </p>
        </div>
        <div className='dash-sider'>
           <div className='videos-wrapper'>
           <div className='video-bar'>
                <img src='https://picsum.photos/id/200/300'  alt="thumbnail" />
                <div className='video-title'>
                    <h2>Namna ya kuprint ripoti ya mwezi na kuihamisha</h2>
                    <p>02.45 Min&emsp;<Icon icon="video" color={primary}  /></p>
                </div>
            <div className='video-far'></div>
            </div>
           </div>
        </div>
        </div>
           </div>
           <Dialog isOpen={show} canClickOutsideToClose={true} onClose={()=>setShow(prev=>!prev)}>
          <h1 style={{marginLeft:20,marginBottom:0}}>Select video {role === "worker" ? "": <span id="choose-file">  <input type="file" placeholder="" onChange={(e)=>setSelectedAttachment(e.target.files[0])} />
          <button className='upload-profile-photo' disabled={selectedAttachment === null ? true : false} onClick={()=>uploadAttachment()}>Upload</button></span>}</h1>
          {!show ? <div /> : <Spinner size={SpinnerSize.SMALL} intent="primary"/>}
          </Dialog>
    </div>
    </div>
  )
}

export default Expenses