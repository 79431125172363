import React,{useContext,useState,useEffect} from 'react'
import TopNav from '../components/TopNav'
import {ThemeContext} from '../App'
import SiderContent from '../components/SiderContent'
import {useLocation} from 'react-router-dom'
import {Tab,Tabs,Switch,Spinner,SpinnerSize,Icon,Dialog,ProgressBar} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {useNavigate} from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import AccessDenied from '../components/AccessDenied'
import Userbar from '../components/userbar/Userbar'

function Settings() {
    const { body} = useContext(ThemeContext)
    const {state} = useLocation()
    const [disabled,setDisabled] = useState(false)
    const [disabled2,setDisabled2] = useState(false)
    const navigate = useNavigate()
    const [spin,stop] = useState(false)
    const {currentUser} = useGlobalContextHook()
    const {settings,id,role,username,phoneNumber,email,photoURL,companyId,permissions} = currentUser
    const {loan,repayment,mature} = settings.notifications
    const {loans:loansPermissions,borrowers:borrowersPermissions} = permissions
    const {language,currency} = settings
    const [userName,setUserName] = useState(username)
    const [phone,setPhone] = useState(phoneNumber)
    const [userEmail,setUserEmail] = useState(email)
    const [userPwd,setUserPwd] = useState("")
    const [shortPwd,setShortPwd] = useState(false)
    const [userPwd2,setUserPwd2] = useState("")
    const [photo,setPhoto] = useState(photoURL)
    const [languageSetting,setLanguage] = useState(language)
    const [currencySetting,setCurrency] = useState(currency)
    const [loanSms,setLoanSms] = useState(loan)
    const [repaySms,setRepaySms] = useState(repayment)
    const [matureSms,setMatureSms] = useState(mature)
    const [basis,setBasis] = useState('days')
    const [duration,setDuration] = useState(7)
    const [penaltyAmount,setPenaltyAmount] = useState(0)
    const [selectedTabId,setSelectedTab] = useState("1")
    const [isPenaltyBtnDisabled,setIsPenaltyBtnDisabled] = useState(true)
    const [openSnackbar] = useSnackbar()
    const [show, hide] = useState(false)
    const [open,setOpen] = useState(false)
    const [selectedAttachment,setSelectedAttachment] = useState(null)
    const [ten,setTen] = useState(['1','2','3','4','5','6','7','8','9','10','11','12','1','2','3','4','5','6','7','8','9','10','11','12','1','2','3','4','5','6','7','8','9','10','11','12','1','2','3','4','5','6','7','8','9','10','11','12'])
    const [tabs,setTabs] = useState([ 
      {id:"1",title:"General"},
      {id:"2",title:"Account"},
      {id:"3",title:"SMS"},{id:"4",title:"Loans"}])
    const handleTabChange =(id)=>{
    setSelectedTab(id)} 
    
   const changeNotifications= async()=>{
    try{
      stop(true)
      const settingsObject = {
        notifications:{
          loan:loanSms,
          repayment:repaySms,
          mature:matureSms
        },
        language:languageSetting,
        currency:currencySetting,
      }
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_USER_SETTINGS&id=${id}`,{
        method: 'PATCH',
        body: JSON.stringify(settingsObject),
        headers: {'Content-Type': 'application/json'}
      })
      const json = await response.json()
      if(response.ok){
        openSnackbar(json.message); stop(false)
        navigate('/');   window.location.reload()
        window.location.reload()
      }
      if(!response.ok){
        openSnackbar(json.message); stop(false)
       
      }
    }catch(e){
     openSnackbar(e); stop(false)
    }
   } 

   const savePwd = async() =>{
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_PWD&id=${currentUser.id}`,{
        method: 'PATCH',
        body: JSON.stringify({pwd:userPwd}),
        headers: {'Content-Type': 'application/json'}
      })
      const json = await response.json()
      if(response.ok){openSnackbar('Password changed successfully')}
      if(!response.ok){openSnackbar('Failed to change password')}
    } catch(e){console.log(e)}
   }
   const verifyPwd =(pwd)=>{
    if(pwd.length < 6) {setShortPwd(true); setUserPwd(pwd);} else {
      setUserPwd(pwd); setShortPwd(false)
    }
  }
 
 const profileUpdate= async() =>{
  try{
    stop(true)
    const userObj = {
      username:userName,
      phoneNumber:phone,
      email:userEmail,
      photoURL:photo
    }
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_PROFILE_USER_SETTING&id=${currentUser.id}`,{
      method: 'PATCH',
      body: JSON.stringify(userObj),
      headers: {'Content-Type': 'application/json'}
    })
    if(response.ok){
      openSnackbar('Profile updated successfully'); stop(true);
      navigate('/');   window.location.reload()
      window.location.reload()
    }
    if(!response.ok){openSnackbar('Profile updated failed')}
  }catch(e){openSnackbar(JSON.stringify(e))}
 } 

  const selectImage = (img) => {
    setPhoto(img); setOpen(false);
    openSnackbar("Photo selected")
}
const uploadAttachment = async() =>{
  if(selectedAttachment === null){
      openSnackbar("Select first attachment to upload")
  } else {
           //upload it
           hide(true)
           const formData = new FormData();
           formData.append(`myFile`,selectedAttachment)
           const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=UPLOAD_LOGO`,{
            method: 'POST',
            body: formData
           })
           const json = await response.json()
           if(response.ok){
            openSnackbar('upload success')
            setPhoto(`${process.env.REACT_APP_SITE_URL}/${json.message}`)
            hide(false); setOpen(false)
          } else {openSnackbar("Failed to upload")}

  }
}

const validateDuration= (input)=>{
  var numbers = /^[0-9]+$\b/;
  if(input.match(numbers)){
     setDuration(input)
  } else {
    openSnackbar("Please input numeric characters only")
    setDuration("")
  } 
}

const validateAmount= (input)=>{
  var numbers = /^[0-9]+$\b/;
  if(input.match(numbers)){
     setPenaltyAmount(input)
  } else {
    openSnackbar("Please input numeric characters only")
    setPenaltyAmount("")
  } 
}

const savePenalty = async()=>{
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=ADD_PENALTY&id=${companyId}`,{
        method: "POST",
        body: JSON.stringify({
          companyId:companyId,
          amount:Number(penaltyAmount),
          basics:basis,
          duration:Number(duration)
        }),
        headers: {'Content-Type': 'application/json'}
      })
      const json = await response.json()
      if(response.ok) {
        openSnackbar('Penalty changes saved successfully')
      }
      if(!response.ok){
        openSnackbar('!Ooops! failed to update penalty changes')
      }
    } catch(e) {
      openSnackbar("Error during saving penalty cahanges" )
    }
}

//validate penalty Inputs
useEffect(() => {
  if(penaltyAmount === ""  || basis === "" || duration === ""){
    setIsPenaltyBtnDisabled(true)
  } else { setIsPenaltyBtnDisabled(false)}
},[penaltyAmount,basis,duration])
console.log(isPenaltyBtnDisabled)
  return (
    <div className="main-wapper" >
    <TopNav /> 
<div className="content-wrapper">
<div className="sider" ><SiderContent /></div>
  <div className="section" style={{backgroundColor:body}}>
  <Userbar title="Settings" />
        <div className="Settings-wrapper">
     <Tabs className="Tabs" onChange={(id)=>handleTabChange(id)}  animate={true} selectedTabId={selectedTabId}  > 
    {tabs.map((tab, i)=>(
         <Tab key={i} id={tab.id} title={tab.title}   
         panel={<div> {

            tab.id === "1" ? <div>
          <div style={{height:'60vh'}}>
          <div className="modi-bar"><div className="modi-name">Language</div>
              <div className="modi-si">
                  <select defaultValue={languageSetting} onChange={(e)=>setLanguage(e.target.value)}>
                      <option value="English">English</option>
                    </select>
                     </div>
                    </div>

              <div className="modi-bar"><div className="modi-name">Currency</div>
              <div className="modi-si">
                  <select defaultValue={currencySetting} onChange={(e)=>setCurrency(e.target.value)}>
                  <option value="AFN" label="Afghan afghani">AFN</option>
  <option value="ALL" label="Albanian lek">ALL</option>
  <option value="DZD" label="Algerian dinar">DZD</option>
  <option value="AOA" label="Angolan kwanza">AOA</option>
  <option value="ARS" label="Argentine peso">ARS</option>
  <option value="AMD" label="Armenian dram">AMD</option>
  <option value="AWG" label="Aruban florin">AWG</option>
  <option value="AUD" label="Australian dollar">AUD</option>
  <option value="AZN" label="Azerbaijani manat">AZN</option>
  <option value="BHD" label="Bahraini dinar">BHD</option>
  <option value="BSD" label="Bahamian dollar">BSD</option>
  <option value="BDT" label="Bangladeshi taka">BDT</option>
  <option value="BBD" label="Barbadian dollar">BBD</option>
  <option value="BYN" label="Belarusian ruble">BYN</option>
  <option value="BZD" label="Belize dollar">BZD</option>
  <option value="BMD" label="Bermudian dollar">BMD</option>
  <option value="BTN" label="Bhutanese ngultrum">BTN</option>
  <option value="BOB" label="Bolivian boliviano">BOB</option>
  <option value="BAM" label="Bosnia and Herzegovina convertible mark">BAM</option>
  <option value="BWP" label="Botswana pula">BWP</option>
  <option value="BRL" label="Brazilian real">BRL</option>
  <option value="GBP" label="British pound">GBP</option>
  <option value="BND" label="Brunei dollar">BND</option>
  <option value="MMK" label="Burmese kyat">MMK</option>
  <option value="BIF" label="Burundian franc">BIF</option>
  <option value="KHR" label="Cambodian riel">KHR</option>
  <option value="CAD" label="Canadian dollar">CAD</option>
  <option value="CVE" label="Cape Verdean escudo">CVE</option>
  <option value="KYD" label="Cayman Islands dollar">KYD</option>
  <option value="XAF" label="Central African CFA franc">XAF</option>
  <option value="XPF" label="CFP franc">XPF</option>
  <option value="CLP" label="Chilean peso">CLP</option>
  <option value="CNY" label="Chinese yuan">CNY</option>
  <option value="COP" label="Colombian peso">COP</option>
  <option value="KMF" label="Comorian franc">KMF</option>
  <option value="CDF" label="Congolese franc">CDF</option>
  <option value="CRC" label="Costa Rican colón">CRC</option>
  <option value="HRK" label="Croatian kuna">HRK</option>
  <option value="CUC" label="Cuban convertible peso">CUC</option>
  <option value="CUP" label="Cuban peso">CUP</option>
  <option value="CZK" label="Czech koruna">CZK</option>
  <option value="DKK" label="Danish krone">DKK</option>
  <option value="DOP" label="Dominican peso">DOP</option>
  <option value="DJF" label="Djiboutian franc">DJF</option>
  <option value="XCD" label="Eastern Caribbean dollar">XCD</option>
  <option value="EGP" label="Egyptian pound">EGP</option>
  <option value="ERN" label="Eritrean nakfa">ERN</option>
  <option value="ETB" label="Ethiopian birr">ETB</option>
  <option value="EUR" label="Euro">EUR</option>
  <option value="FKP" label="Falkland Islands pound">FKP</option>
  <option value="FJD" label="Fijian dollar">FJD</option>
  <option value="GMD" label="Gambian dalasi">GMD</option>
  <option value="GEL" label="Georgian lari">GEL</option>
  <option value="GHS" label="Ghanaian cedi">GHS</option>
  <option value="GIP" label="Gibraltar pound">GIP</option>
  <option value="GTQ" label="Guatemalan quetzal">GTQ</option>
  <option value="GGP" label="Guernsey pound">GGP</option>
  <option value="GNF" label="Guinean franc">GNF</option>
  <option value="GYD" label="Guyanese dollar">GYD</option>
  <option value="HTG" label="Haitian gourde">HTG</option>
  <option value="HNL" label="Honduran lempira">HNL</option>
  <option value="HKD" label="Hong Kong dollar">HKD</option>
  <option value="HUF" label="Hungarian forint">HUF</option>
  <option value="ISK" label="Icelandic króna">ISK</option>
  <option value="INR" label="Indian rupee">INR</option>
  <option value="IDR" label="Indonesian rupiah">IDR</option>
  <option value="IRR" label="Iranian rial">IRR</option>
  <option value="IQD" label="Iraqi dinar">IQD</option>
  <option value="ILS" label="Israeli new shekel">ILS</option>
  <option value="JMD" label="Jamaican dollar">JMD</option>
  <option value="JPY" label="Japanese yen">JPY</option>
  <option value="JEP" label="Jersey pound">JEP</option>
  <option value="JOD" label="Jordanian dinar">JOD</option>
  <option value="KZT" label="Kazakhstani tenge">KZT</option>
  <option value="KES" label="Kenyan shilling">KES</option>
  <option value="KID" label="Kiribati dollar">KID</option>
  <option value="KGS" label="Kyrgyzstani som">KGS</option>
  <option value="KWD" label="Kuwaiti dinar">KWD</option>
  <option value="LAK" label="Lao kip">LAK</option>
  <option value="LBP" label="Lebanese pound">LBP</option>
  <option value="LSL" label="Lesotho loti">LSL</option>
  <option value="LRD" label="Liberian dollar">LRD</option>
  <option value="LYD" label="Libyan dinar">LYD</option>
  <option value="MOP" label="Macanese pataca">MOP</option>
  <option value="MKD" label="Macedonian denar">MKD</option>
  <option value="MGA" label="Malagasy ariary">MGA</option>
  <option value="MWK" label="Malawian kwacha">MWK</option>
  <option value="MYR" label="Malaysian ringgit">MYR</option>
  <option value="MVR" label="Maldivian rufiyaa">MVR</option>
  <option value="IMP" label="Manx pound">IMP</option>
  <option value="MRU" label="Mauritanian ouguiya">MRU</option>
  <option value="MUR" label="Mauritian rupee">MUR</option>
  <option value="MXN" label="Mexican peso">MXN</option>
  <option value="MDL" label="Moldovan leu">MDL</option>
  <option value="MNT" label="Mongolian tögrög">MNT</option>
  <option value="MAD" label="Moroccan dirham">MAD</option>
  <option value="MZN" label="Mozambican metical">MZN</option>
  <option value="NAD" label="Namibian dollar">NAD</option>
  <option value="NPR" label="Nepalese rupee">NPR</option>
  <option value="ANG" label="Netherlands Antillean guilder">ANG</option>
  <option value="TWD" label="New Taiwan dollar">TWD</option>
  <option value="NZD" label="New Zealand dollar">NZD</option>
  <option value="NIO" label="Nicaraguan córdoba">NIO</option>
  <option value="NGN" label="Nigerian naira">NGN</option>
  <option value="KPW" label="North Korean won">KPW</option>
  <option value="NOK" label="Norwegian krone">NOK</option>
  <option value="OMR" label="Omani rial">OMR</option>
  <option value="PKR" label="Pakistani rupee">PKR</option>
  <option value="PAB" label="Panamanian balboa">PAB</option>
  <option value="PGK" label="Papua New Guinean kina">PGK</option>
  <option value="PYG" label="Paraguayan guaraní">PYG</option>
  <option value="PEN" label="Peruvian sol">PEN</option>
  <option value="PHP" label="Philippine peso">PHP</option>
  <option value="PLN" label="Polish złoty">PLN</option>
  <option value="QAR" label="Qatari riyal">QAR</option>
  <option value="RON" label="Romanian leu">RON</option>
  <option value="RUB" label="Russian ruble">RUB</option>
  <option value="RWF" label="Rwandan franc">RWF</option>
  <option value="SHP" label="Saint Helena pound">SHP</option>
  <option value="WST" label="Samoan tālā">WST</option>
  <option value="STN" label="São Tomé and Príncipe dobra">STN</option>
  <option value="SAR" label="Saudi riyal">SAR</option>
  <option value="RSD" label="Serbian dinar">RSD</option>
  <option value="SLL" label="Sierra Leonean leone">SLL</option>
  <option value="SGD" label="Singapore dollar">SGD</option>
  <option value="SOS" label="Somali shilling">SOS</option>
  <option value="SLS" label="Somaliland shilling">SLS</option>
  <option value="ZAR" label="South African rand">ZAR</option>
  <option value="KRW" label="South Korean won">KRW</option>
  <option value="SSP" label="South Sudanese pound">SSP</option>
  <option value="SRD" label="Surinamese dollar">SRD</option>
  <option value="SEK" label="Swedish krona">SEK</option>
  <option value="CHF" label="Swiss franc">CHF</option>
  <option value="LKR" label="Sri Lankan rupee">LKR</option>
  <option value="SZL" label="Swazi lilangeni">SZL</option>
  <option value="SYP" label="Syrian pound">SYP</option>
  <option value="TJS" label="Tajikistani somoni">TJS</option>
  <option value="TZS" label="Tanzanian shilling">TZS</option>
  <option value="THB" label="Thai baht">THB</option>
  <option value="TOP" label="Tongan paʻanga">TOP</option>
  <option value="PRB" label="Transnistrian ruble">PRB</option>
  <option value="TTD" label="Trinidad and Tobago dollar">TTD</option>
  <option value="TND" label="Tunisian dinar">TND</option>
  <option value="TRY" label="Turkish lira">TRY</option>
  <option value="TMT" label="Turkmenistan manat">TMT</option>
  <option value="TVD" label="Tuvaluan dollar">TVD</option>
  <option value="UGX" label="Ugandan shilling">UGX</option>
  <option value="UAH" label="Ukrainian hryvnia">UAH</option>
  <option value="AED" label="United Arab Emirates dirham">AED</option>
  <option value="USD" label="United States dollar">USD</option>
  <option value="UYU" label="Uruguayan peso">UYU</option>
  <option value="UZS" label="Uzbekistani soʻm">UZS</option>
  <option value="VUV" label="Vanuatu vatu">VUV</option>
  <option value="VES" label="Venezuelan bolívar soberano">VES</option>
  <option value="VND" label="Vietnamese đồng">VND</option>
  <option value="XOF" label="West African CFA franc">XOF</option>
  <option value="ZMW" label="Zambian kwacha">ZMW</option>
  <option value="ZWB" label="Zimbabwean bonds">ZWB</option>
                    </select>
                     </div>
                    </div>
            </div>              
     <div className="station-action">
      <button disabled={disabled} onClick={() =>changeNotifications()}
        style={{backgroundColor:disabled ? "#ddd": "#07608B",color:disabled ? "#444": "#fff" }}>
         {spin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />} Save changes</button>
        <button className='b' onClick={() =>navigate('/')}>Cancel</button>
             </div>

            </div> :
            tab.id === "2" ? <div>
            <div className="container-input">
              <div className="station-input">
                 <h4 style={{margin:"0px 4px 8px 4px"}}>Username</h4>
                  <input type="text" value={userName} onChange={(e)=>setUserName(e.target.value)}/>
              </div>
           <div className="station-input">
               <h4 style={{margin:"0px 4px 8px 4px"}}>Phone number</h4>
             <input type="text"  value={phone}  onChange={(e)=>setPhone(e.target.value)}/>
             </div>
          </div>
 
          <div className="station-input">
                 <h4 style={{margin:"15px 4px 8px 4px"}}>E-mail</h4>
                  <input type="text" className="id" onChange={(e)=>setUserEmail(e.target.value)}   value={email} />
              </div>
 
         <div className="container-input" style={{marginTop:15}}>
              <div className="station-input">
                 <h4 style={{margin:"0px 4px 8px 4px"}}>Change password</h4>
                  <input type="password"  onChange={(e)=>verifyPwd(e.target.value)}/>
                  {shortPwd ? <p>Password is too short!</p>: <p />}
              </div>
 
           <div className="station-input" >
               <h4 style={{margin:"0px 4px 8px 4px"}}>Re-type password</h4>
             <input type="password"   onChange={(e)=>setUserPwd2(e.target.value)}/>
             </div>
          </div>
          <button disabled={userPwd.length <= 5 || userPwd !== userPwd2 ? true : false} className="btn-generate" onClick={()=>savePwd()} style={{marginLeft:'5%'}}>
                  <Icon icon="lock" color={userPwd.length <=1  || userPwd !== userPwd2 ? "#666": "#107E39"} />&emsp;
                    Save password</button>
 
          <div className="station-input">
               <h4 style={{margin:"0px 4px 8px 4px"}}>Change profile picture</h4>
                   <div className="logo" onClick={()=>setOpen(prev=>!prev)} style={{marginTop:0}}>
                           <img src={photo} alt="logo" />
                     </div>
             </div>
 
          <div className="station-action" style={{marginLeft:'5%'}}>
                <button disabled={disabled2}  onClick={() =>profileUpdate()}
                 style={{backgroundColor:disabled2 ? "#ddd": "#07608B",color:disabled ? "#444": "#fff" }}>
                   {spin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
                   Save</button>
                <button className='b' onClick={() =>navigate('/')}>Cancel</button>
              </div>
 
             </div> : 
            tab.id === "3" ? <div>
            <div style={{height:'60vh'}}> 
           {borrowersPermissions[2].isGranted ?              <div>
                          <div className="modi-bar"><div className="modi-name"> New loan released</div>
            <div className="modi-si"> <Switch checked={loanSms} label={loanSms ? "Disable" :"Enable"} onChange={()=>setLoanSms(prev=>!prev)} /></div>
            </div>

            <div className="modi-bar"><div className="modi-name">Repayment added</div>
            <div className="modi-si"> <Switch checked={repaySms} label={repaySms ? "Disable" :"Enable"} onChange={()=>setRepaySms(prev=>!prev)} /></div>
            </div>
              </div> : <AccessDenied />}
            </div>
            {role === "worker" ? <h3 style={{marginLeft:'1%'}}></h3>: 
              <div className="station-action">
               <button disabled={disabled} onClick={() =>changeNotifications()}
                style={{backgroundColor:disabled ? "#ddd": "#07608B",color:disabled ? "#444": "#fff" }}>
                  {spin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
                  Save changes</button>
               <button className='b' onClick={() =>navigate('/')}>Cancel</button>
             </div>}
           
            </div> : 
            tab.id === "4" ? <div>
             {loansPermissions[2].isGranted ? 
              <div>
              <div className="modi-bar"><div className="modi-name">Add penalty after</div>
              <div className="modi-si">
              <input type="text" style={{width:'10%'}} value={duration}  onChange={(e)=>validateDuration(e.target.value)}/>  
              <select onChange={(e)=>setBasis(e.target.value)}>
                  <option value="days">days</option>
                  <option value="weeks">weeks</option>
                  <option value="months">months</option>
                  <option value="years">years </option>
                        </select>
                     </div>
                  </div>
              <div className="modi-bar"><div className="modi-name">Penalty amount</div>
              <div className="modi-si">
                 <input type="text" style={{width:'40%'}} value={penaltyAmount} onChange={(e)=>validateAmount(e.target.value)}  />
                 <span className="currency">{currency}</span>
                     </div>
                    </div>

              {/**<div className="modi-bar" id='touchable' onClick={()=>navigate('/loan-products')}><div className="modi-name">Loan products</div>
              <div className="modi-si"><Icon icon='eye-open'  color='#666' size={25}/></div>
                  </div> */}

                    <div className="station-action">
                      <button disabled={isPenaltyBtnDisabled}
                       style={{backgroundColor:isPenaltyBtnDisabled  ? "#ddd": "#07608B",color:isPenaltyBtnDisabled  ? "#444": "#fff" }}
                      onClick={() =>savePenalty()}>Save changes</button>
                    </div>
              </div>: <AccessDenied />}
            </div> :
            <div className="weekely"></div>
                 }
            </div>
        } style={{outline: "none",fontWeight: "bold",color:"#555",fontSize:16,marginLeft:20}} />
        ))} </Tabs>

        </div>
            </div>
</div>
<Dialog isOpen={open} canClickOutsideToClose={true} onClose={()=>setOpen(prev=>!prev)}>
          <h1 style={{marginLeft:20,marginBottom:0}}>Select photo{role === "worker" ? "": <span id="choose-file">  <input type="file" placeholder="" onChange={(e)=>setSelectedAttachment(e.target.files[0])} />
          <button className='upload-profile-photo' disabled={selectedAttachment === null ? true : false} onClick={()=>uploadAttachment()}>Upload</button></span>}</h1>
          {!show ? <div /> : <Spinner size={SpinnerSize.SMALL} intent="primary"/>}
          </Dialog>
        </div> 
  )
}

export default Settings