import React,{useContext,useState,useEffect} from 'react'
import {ThemeContext} from '../App'
import {Icon,Popover,Menu,MenuItem,Drawer,Position,DrawerSize} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {useSnackbar} from 'react-simple-snackbar'
import Empty  from '../components/Empty'
import {useNavigate} from 'react-router-dom'

function BorrowersList({branch,branchId}) {
    const {borrowers,dispatch,currentUser} = useGlobalContextHook()
    const {permissions} = currentUser
    const {borrowers: borrowersPermissions} = permissions
    const {lightgrey} = useContext(ThemeContext)
    const [openSnackbar] = useSnackbar()
    const [openedBorrower,setOpenedBorrower] = useState(null)
    const [isDrawerOpen,setIsDrawerOpen] = useState(false)
    const navigate = useNavigate()

    const listBorrowers = async()=>{
      try{
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_BORROWERS&id=${branchId}`)
        const json = await response.json()
        if(response.ok) {
          dispatch({type:'SET_BORROWERS',payload:json})
        }
        if(!response.ok) {
          openSnackbar('Failed to list borrowers!')
          console.log(json.message)
        }
      } catch(err){
        openSnackbar('Error during listing borrowers')
      }
    }

    const searchBorower =async(keyword)=>{
      try{
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SEARCH_BORROWERS&keyword=${keyword}&id=${branchId}`)
        const json = await response.json()
        if(response.ok) {
          dispatch({type:'SET_BORROWERS',payload:json})
        }
        if(!response.ok) {
          openSnackbar('Failed to search borrowers!')
        }
      } catch(err){
        openSnackbar('Error during searching borrowers')
        console.log(err)
      }
    }

    const openBorrower = (borrower) => {
     setOpenedBorrower(borrower)
     setIsDrawerOpen(true)
    }

    const editBorrower = (borrower)=>{
      navigate("/edit-borrower",{state:borrower})
    }

    const removeBorrower = async(borrower) => {
      try {
        const {_id} = borrower
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/remove?action=DELETE_BORROWER&id=${_id}`,{
          method: 'DELETE'
        })
        if(response.ok) {
          listBorrowers()
        }
        if(!response.ok){
          openSnackbar('Failed to  remove borrower!')
        }
      } catch(err){
        openSnackbar('Error during removing borrower')
      }
    }
    //list borrowers
    useEffect(() => {
      listBorrowers()
    },[branchId])

  return (
     <div>
      <h3>Branch: {branch}</h3>
    <div className="borrowers-wapper">
              <div className='search-bar' style={{marginLeft:'1%',marginTop:'0px'}}>
      <div className="search-input" style={{display:'inline-flex',width:'100%'}}>
        <input type="text"  placeholder="Search borrower's name"  style={{width:'50%',marginRight:15}}  onChange={(e)=>searchBorower(e.target.value)}/>
        {borrowersPermissions[0].isGranted ? <div className="add-new-group"  style={{width:260}} onClick={()=>navigate('/add-borrower')}> + Add borrower</div> : <div /> }
        </div>     
        </div> 
        <div className="table1">
        <div className="table1-header">
          <div className="header-title">Name</div>
          <div className="header-title">Address</div>
          <div className="header-title">Phone</div>
          <div className="header-title">Occupation</div>
          <div className="header-title">Branch</div>
          <div className="header-title">Menu</div>
        </div>

        <div className="table1-row-wrapper">
        {borrowers.length <= 0 ? <Empty icon="people" name="Currently,No any borrower" color="#666" /> : 
          borrowers.map(borrower =>(
          <div className="table1-cell-holder">
          <div className="table1-cell" onClick={()=>openBorrower(borrower)}>{borrower.fullName}</div>
          <div className="table1-cell">{borrower.residence}</div>   
          <div className="table1-cell">{borrower.phoneNumber}</div>   
          <div className="table1-cell">{borrower.occupation}</div>    
          <div className="table1-cell">{borrower.branch}</div>
           <div className="table1-cell">
           <Popover content={
               <Menu>
                   <p className='container-p'>Options </p>
                  <MenuItem    icon='eye-open' text="View" onClick={()=>openBorrower(borrower)} />
                 {borrowersPermissions[2].isGranted ?  <MenuItem    icon='edit' text="Edit" onClick={()=>editBorrower(borrower)} />: <div />}
                 {borrowersPermissions[3].isGranted ?  <MenuItem    icon='trash' text="Remove" onClick={()=>removeBorrower(borrower)} /> : <div />}
               </Menu>
               
              } position={Position.BOTTOM_BOTTOM}>
                <div className="table-menu" id="touchable">
               <Icon icon="more"  color="#666"  size={22}/></div> 
        </Popover>
              </div>
          </div>))
        }
        </div>

        <div className="table-footer">
             <div className="footer-title">Showing <strong> 1-{borrowers.length}</strong>  </div>
              <div className="footer-items">
                rows per page 
                <select>
                <option value="20">20</option>
                <option value="20">50</option>
                <option value="20">100</option>
                <option value="20">200</option>
                </select>
              </div>
            <div className="footer-far-right">
            <div className="footer-next">
                   <div onClick={()=>{}} id="touchable">Prev</div>
               </div>  
               <div className="footer-next">
                   <div onClick={()=>{}} id="touchable">Next</div>
            </div></div>                       
             </div>
        </div>
    </div> 

    <Drawer className="borrower-sider-wrapper"
        isOpen={isDrawerOpen} 
        title="Borrower details"
        titleTextColor="#fff"
        position={Position.RIGHT}
        size={window.innerWidth > 1000 ? DrawerSize.STANDARD :  DrawerSize.LARGE} 
        canClickOutsideToClose={true} onClose={()=>setIsDrawerOpen(prev=>!prev)}>
      {openedBorrower === null ? <div/> :
        <div>
          <div className="new-user-bar">
          <img src={openedBorrower.passportImage} alt="borrower_img" />
              <div classNamee="new-titles">
                <h3>{openedBorrower.fullName}</h3>
                 <p>{openedBorrower.phoneNumber}</p>
                 {borrowersPermissions[2].isGranted ? <h4 onClick={()=>editBorrower(openedBorrower)} style={{borderWidth:0,padding:0,textAlign: 'left',width:'97%'}}>
                    <Icon icon="edit" />&emsp;
                    Edit details</h4> : <div />}
                </div>
              </div>

              <div className="info-rail"  style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Occupation</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.occupation}</div>
             </div>

             <div className="info-rail"  style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Sex</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.sex}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Residence</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.residence}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">ID number</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.idNumber1}</div>
             </div>
             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">ID type</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.idType || "-"}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Extra Id</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.idNumber2}</div>
             </div>
             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Extra Id type</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.idType2 || '-'}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Branch</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.branch}</div>
             </div>
             {/*<div>
                   <h2 style={{marginLeft:20}}>Bank account details</h2>
             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="office" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Bank name</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.bankName}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="credit" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Account number</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.accountNumber}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="credit" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Bank account status</div>
             </div>
             <div className="valid-bar" id="unique-valid-bar" style={{borderColor:openedBorrower.accountStatus ? "#3A8E5C":"#B71C1C"}}>
                      <Icon icon={openedBorrower.accountStatus ? "tick-circle":"cross"} color={openedBorrower.accountStatus ? "#3A8E5C":"#B71C1C"}  size={22} className="valid-icon" />
                      <h3 style={{color:openedBorrower.accountStatus ? "#3A8E5C":"#B71C1C"}}>{openedBorrower.accountStatus ? "Account is verified": "Account is not verified"}</h3>
                    </div>
              </div>*/}
          </div>}
      </Drawer>
     </div>
  )
}

export default BorrowersList