import React,{useState,useEffect} from 'react'
import {Icon,Popover,Position,Menu} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import { useSnackbar } from 'react-simple-snackbar'
import Empty  from '../components/Empty'
import format from 'date-fns/format'
var moment = require('moment'); // require
moment().format();

function Ledger({ branchId, branch }) {
  const { currentUser,transactions,dispatch,expenses} = useGlobalContextHook()
  const { settings, companyId } = currentUser
  const { currency } = settings
  const [ openSnackbar] = useSnackbar()
  const date = moment.utc();
  const [totalExpenses,setTotalExpense] = useState(0)
  const [fromDate,setFromDate] = useState(date.clone().add(-10,"days").format("YYYY-MM-DD"))
  const [toDate,setToDate] = useState(date.clone().add(1,"days").format("YYYY-MM-DD"))

  const setToDateFn =(date)=>{
    let newDate = moment.utc(date)
    setToDate(newDate.clone().add(1,"days").format("YYYY-MM-DD"))     
}

const listTransactionsFn =async()=>{
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_TRANSACTIONS&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
    const json = await response.json()
      if(response.ok) {
        dispatch({type:'SET_TRANSACTIONS',payload:json})
      }
      if(!response.ok) {
        openSnackbar('Failed to list transactions')
      }

  } catch (e) {
    openSnackbar('Error while getting transaction list')
  }
}

const listExpenses = async () => {
  try{
    if(branchId === null){
    } else {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_APPROVED_EXPENSES&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
      const json = await response.json()
        if(response.ok){
           dispatch({type:'SET_EXPENSES',payload:json})
        } else {
          openSnackbar(`Could not fetch expense`)
        }
    }
  } catch(error){
    openSnackbar('Failed to fetch expenses')
  }
  }

  const sumExpenses =()=>{
     if(expenses.length > 0) { 
       const summedTotalExpenses = expenses.reduce((accumulator,expense)=>{
         return accumulator + Number(expense.expenseAmount)
       },0)
       setTotalExpense(summedTotalExpenses)
     } else { setTotalExpense(0)}
  }

  const printledger =async(companyInfo)=>{
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=PRINT_LEDGER&id=${branchId}&branch=${branch}&fromDate=${fromDate}&toDate=${toDate}`,{
        method: 'POST',
        body: JSON.stringify({ 
          transactions: transactions,
          expenses: expenses,
          companyInfo:companyInfo
        }),
        headers: {'Content-Type': 'application/json'}
      })
      if(response.ok){
        const file = await response.blob()
        const createdFile =  await window.URL.createObjectURL(new Blob([file]), { type: 'application/pdf'})
        const link = document.createElement('a');
        link.href = createdFile;
        link.download =`General ledger from${fromDate}=>${toDate}.pdf`
        link.click();
      }
    
      if(!response.ok){
        openSnackbar("Error creating pdf")
      }
    } catch(e){
      console.log(e)
       openSnackbar('Error during printing ledger')
    }
  }

  const getCompanyInfo = async()=>{
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_USER_BY_COMPANY_ID&id=${companyId}`)
      const json = await response.json()
      if(response.ok){
      printledger(json[0])
      }
      if(!response.ok){
        openSnackbar(json.message)
      }
    }catch(error){openSnackbar("Error getting company")}
  }

useEffect(() => {
  listTransactionsFn()
  listExpenses()
  sumExpenses()
},[branchId,fromDate,toDate])

  return (
    <div className="ledger-wrapper">
      <div className="ledger-bar">
        <h2>General Ledger</h2>
        <div className="ledger-far">
        <Popover content=
         {
            <Menu>
                <p className='container-p'>Filter options </p>
                <div className="date-picker">
                  <div className="from" style={{marginLeft:15}}>
                    <p>From</p>
                    <input type="date" value={fromDate} placeholder="Date" onChange={(e)=>setFromDate(e.target.value)} />
                  </div> <br />
                  <div className="from" style={{marginLeft:15}}>
                    <p>To</p> 
                    <input type="date" value={toDate} placeholder="Date" onChange={(e)=>setToDateFn(e.target.value)}  />
                  </div>
                  <button className="just-button" onClick={()=>getCompanyInfo()} style={{margin:15}} id="touchable">Export PDF</button>
              </div>
            </Menu>
            
           } position={Position.BOTTOM_BOTTOM}>
          <div className="ledger-far" id="touchable">
          <Icon icon="filter-list" color="#666" size={24} />
        </div> 
        </Popover>   
        </div>
      </div>
      <div className="ledger-info">
          <h3>{format(new Date(fromDate),'dd/MMMM/yyyy')} - {format(new Date(toDate),'dd/MMMM/yyyy')}</h3>
        </div>
        {expenses.length <= 0 ? <div /> : <div className="account-box">
                  <div className="accountbox-bar"><h3>Branch expenses</h3> <p>asset</p></div>
                  <div className="transaction-table">
                   <div className="transaction-table-row">
                      <div className="transaction-table-row-title">Transaction </div>
                      <div className="transaction-table-row-title">Date </div>
                      <div className="transaction-table-row-title"> Debit</div>
                      <div className="transaction-table-row-title">Credit</div>
                   </div>
       
       
                   <div className="transaction-table-wrapper">
                    {expenses <= 0 ? <div /> : expenses.map(expense=>(
                        <div className="transaction-table-cell-row" key={expense._id}>
                          <div className="transaction-table-row-cell">{expense.expenseName}&emsp;</div>
                          <div className="transaction-table-row-cell">{format(new Date(expense.createdAt),'dd/MMMM/yyyy')}</div>
                          <div className="transaction-table-row-cell">{expense.expenseAmount.toLocaleString()}<span className='currency'>{currency}</span></div>
                          <div className="transaction-table-row-cell"></div>
                        </div>
                    ))}
                   </div>
                  </div>
                  <div className='net-movement'>
                    <div className="net-name">Net Movement</div>
                    <div className="net-amount">{totalExpenses.toLocaleString()}<span className="currency">{currency}</span></div>
                  </div> <br /> <br /> <br />
               </div>}

               {expenses.length <= 0 ? <div /> : <div className="account-box">
                  <div className="accountbox-bar"><h3>Branch Petty cash</h3> <p>asset</p></div>
                  <div className="transaction-table">
                   <div className="transaction-table-row">
                      <div className="transaction-table-row-title">Transaction </div>
                      <div className="transaction-table-row-title">Date </div>
                      <div className="transaction-table-row-title"> Debit</div>
                      <div className="transaction-table-row-title">Credit</div>
                   </div>
       
       
                   <div className="transaction-table-wrapper">
                    {expenses <= 0 ? <div /> : expenses.map(expense=>(
                        <div className="transaction-table-cell-row" key={expense._id}>
                          <div className="transaction-table-row-cell">Expense: {expense.expenseName}&emsp;</div>
                          <div className="transaction-table-row-cell">{format(new Date(expense.createdAt),'dd/MMMM/yyyy')}</div>
                          <div className="transaction-table-row-cell"></div>
                          <div className="transaction-table-row-cell">{expense.expenseAmount.toLocaleString()}<span className='currency'>{currency}</span></div>
                        </div>
                    ))}
                   </div>
                  </div>
                  <div className='net-movement'>
                    <div className="net-name">Net Movement</div>
                    <div className="net-amount">{totalExpenses.toLocaleString()}<span className="currency">{currency}</span></div>
                  </div> <br /> <br /> <br />
               </div>}
        {transactions.length <= 0 ? <div /> : transactions.map(transaction => (
                  <div className="account-box" key={transaction._id}>
                  <div className="accountbox-bar"><h3>{transaction._accountName}</h3> <p>{transaction._accountType}</p></div>
                  <div className="transaction-table">
                   <div className="transaction-table-row" key={transaction._id}>
                      <div className="transaction-table-row-title">Transaction </div>
                      <div className="transaction-table-row-title">Date </div>
                      <div className="transaction-table-row-title">Debit </div>
                      <div className="transaction-table-row-title">Credit </div>
                   </div>
       
       
                   <div className="transaction-table-wrapper">
                    {transaction.transactions.length <= 0 ? <div /> : transaction.transactions.map(trans=>(
                        <div className="transaction-table-cell-row" key={trans._id}>
                          <div className="transaction-table-row-cell">{trans.name}&emsp;</div>
                          <div className="transaction-table-row-cell">{format(new Date(trans.date),'dd/MMMM/yyyy')}</div>
                          <div className="transaction-table-row-cell">{trans.debit.toLocaleString()}<span className='currency'>{currency}</span></div>
                          <div className="transaction-table-row-cell">{trans.credit.toLocaleString()} <span className='currency'>{currency}</span></div>
                        </div>
                    ))}
                   </div>
                  </div>
                  <div className='net-movement'>
                    <div className="net-name">Net Movement</div>
                    <div className="net-amount">{transaction._netMovement.toLocaleString()}<span className="currency">{currency}</span></div>
                  </div> <br /> <br /> <br />
               </div>
        ))}
    </div>
  )
}

export default Ledger