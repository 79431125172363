import React,{useState,useEffect} from 'react'
import {Icon,Popover,Position,Menu} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import { useSnackbar } from 'react-simple-snackbar'
import Empty  from '../components/Empty'
import format from 'date-fns/format'
var moment = require('moment'); // require
moment().format();

function BalanceSheet({branchId,branch}) {
  const {currentUser} = useGlobalContextHook()
  const {settings, companyId} = currentUser
  const {currency} = settings
  const [openSnackbar] = useSnackbar()
  const date = moment.utc()
  const [totalExpenseAccounts,setTotalExpenseAccounts] = useState(0)
  const [totalRepayments,setTotalRepayments] = useState(0)
  const [totalExpenses,setTotalExpenses] = useState(0)
  const [accounts,setAccounts] = useState([])
  const [totalAssets,setTotalAssets] =useState(0)
  const [totalLiabilities,setTotalLiabilities] = useState(0)
  const [fromDate,setFromDate] = useState(date.clone().add(-10,"days").format("YYYY-MM-DD"))
  const [toDate,setToDate] = useState(date.clone().add(1,"days").format("YYYY-MM-DD"))

  const setToDateFn =(date)=>{
    let newDate = moment.utc(date)
    setToDate(newDate.clone().add(1,"days").format("YYYY-MM-DD"))     
}

const listTotalExpenseAccounts = async () => {
  try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_TOTAL_EXPENSE_ACCOUNTS&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
      const json = await response.json()
      if(response.ok) {
       if(json.length > 0) {  
         setTotalExpenseAccounts(json[0].amount)
      } else {
          setTotalExpenseAccounts(0)
         }
      }
      if(!response.ok){
        openSnackbar(json.message)
      }
    } catch (e) {
      openSnackbar(e)
  }
}  

const createBalanceSheet= async()=>{
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=CREATE_BALANCE_SHEET&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
    const json = await response.json()
    if(response.ok) {
       setAccounts(json)
    }
    if(!response.ok){
      openSnackbar(json.message)
    }
  } catch (e) {}
 
}

const repayments = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=REPAYMENTS_REPORT&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
    const json = await response.json()
    if(response.ok) {
        if(json.length > 0) {setTotalRepayments(json[0].repayments)} else {
          setTotalRepayments(0)
        }
  
    }
    if(!response.ok){
      openSnackbar(json.message)
    }
  } catch (e) {
    openSnackbar(e)
  }
}

const getTotalExpenses = async () => {
  try{
    const  response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=EXPENSE_REPORT&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
    const json = await response.json()
    if(response.ok) {
      if(json.length > 0) { setTotalExpenses(json[0].expense)} else {setTotalExpenses(0)}
    }
    if(!response.ok) {
      openSnackbar(json.message)
    }
  }catch(error){openSnackbar(error)}
}


const getCompanyInfo = async()=>{
  try{
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_USER_BY_COMPANY_ID&id=${companyId}`)
    const json = await response.json()
    if(response.ok){
      printBalanceSheet(json[0])
    }
    if(!response.ok){
      openSnackbar(json.message)
    }
  }catch(error){openSnackbar("Error getting company")}
}

const printBalanceSheet =async(companyInfo)=>{
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=PRINT_BALANCE_SHEET&id=${branchId}&branch=${branch}&fromDate=${fromDate}&toDate=${toDate}`,{
      method: 'POST',
      body: JSON.stringify({ 
        expenses:totalExpenses,
        accounts:accounts,
        totalAssets:totalAssets,
        totalRepayments:totalRepayments,
        totalLiabilities:totalLiabilities,
        companyInfo:companyInfo
      }),
      headers: {'Content-Type': 'application/json'}
    })

    if(response.ok){
      const file = await response.blob()
      const createdFile =  await window.URL.createObjectURL(new Blob([file]), { type: 'application/pdf'})
      const link = document.createElement('a');
      link.href = createdFile;
      link.download =`Balance sheet from${fromDate}=>${toDate}.pdf`
      link.click();
    }
  
    if(!response.ok){
      openSnackbar("Error creating pdf")
    }
  } catch(e){
    console.log(e)
     openSnackbar('Error during printing ledger')
  }
}

useEffect(() => {
   repayments()
   getTotalExpenses()
   createBalanceSheet()
   listTotalExpenseAccounts()
},[branchId,fromDate,toDate])

//find total assets and total liabilites and equity
useEffect(() => {
  var totalAssetsAccount = []
  var totalLiabilitesAccount = []
  accounts.map(account => {
      if(account._accountType === 'asset' || account._accountType === 'expense'){
         return totalAssetsAccount.push(account)
      }
       return totalLiabilitesAccount.push(account)
  })
  findTotalAsset(totalAssetsAccount)
  findTotalLiabilitesAccount(totalLiabilitesAccount)
},[accounts,totalRepayments,totalExpenses])


const findTotalAsset=(totalAssetsAccount)=>{
  const SummedAssetAccount = totalAssetsAccount.reduce((accumulator,account)=>{
    if(account._totalDebit > 0){
       return accumulator + ((Number(account._totalDebit )- Number(account._totalCredit)))
    } else {
      return accumulator + (Number( account._totalCredit) + Number(account._totalDebit))
    }
  },0)
  setTotalAssets(Number(SummedAssetAccount)+ Number(totalRepayments) - Number(totalExpenses))
}

const  findTotalLiabilitesAccount=(totalLiabilitesAccount)=>{
  const SummeLiabilitytAccount = totalLiabilitesAccount.reduce((accumulator,account)=>{
    if(account._totalDebit > 0){
       return accumulator + ((Number(account._totalDebit )- Number(account._totalCredit)))
    } else {
      return accumulator + (Number( account._totalCredit) + Number(account._totalDebit))
    }
  },0)
  setTotalLiabilities(Number(SummeLiabilitytAccount) + (Number(totalRepayments) - Number(totalExpenses)) )
}

  return (
    <div className="ledger-wrapper">
      <div className="ledger-bar">
        <h2>Balance Sheet</h2>
        <div className="ledger-far">
        <Popover content=
         {
            <Menu>
                <p className='container-p'>Filter options </p>
                <div className="date-picker">
                  <div className="from" style={{marginLeft:15}}>
                    <p>From</p>
                    <input type="date" value={fromDate} placeholder="Date" onChange={(e)=>setFromDate(e.target.value)} />
                  </div> <br />
                  <div className="from" style={{marginLeft:15}}>
                    <p>To</p> 
                    <input type="date" value={toDate} placeholder="Date" onChange={(e)=>setToDateFn(e.target.value)}  />
                  </div>
                  <button className="just-button" onClick={()=>getCompanyInfo()} style={{margin:15}} id="touchable">Export PDF</button>
              </div>
            </Menu>
            
           } position={Position.BOTTOM_BOTTOM}>
          <div className="ledger-far" id="touchable">
          <Icon icon="filter-list" color="#666" size={24} />
        </div> 
        </Popover>   
        </div>
      </div>
      <div className="ledger-info">
          <h3>{format(new Date(fromDate),'dd/MMMM/yyyy')} - {format(new Date(toDate),'dd/MMMM/yyyy')}</h3>
        </div>
        <div className="account-box">
            <div className="accountbox-bar"><h3>Assets</h3> <p></p></div>
            <div className="transaction-table">        
          <div className="transaction-table-wrapper">
           {accounts.length <= 0 ? <div /> : accounts.map(account =>(
            account._accountType === 'asset' || account._accountType === 'expense' ? 
            <div className="transaction-table-cell-row" key={account._id}>
            <div className="transaction-table-row-cell">{account._accountName}&emsp;</div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell">{account._totalDebit > 0 ?  (account._totalDebit - account._totalCredit).toLocaleString(): (account._totalCredit + account._totalDebit).toLocaleString()}<span className='currency'>{currency}</span></div>
         </div> : <div/>
           ))}

          {totalRepayments > 0 ? <div className="transaction-table-cell-row" >
            <div className="transaction-table-row-cell">Total loans repayments&emsp;</div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell">{totalRepayments.toLocaleString()}<span className='currency'>{currency}</span></div>
         </div>: <div/>}
         
         {totalRepayments > 0 ? <div className="transaction-table-cell-row" >
            <div className="transaction-table-row-cell">Less branch expenses&emsp;</div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell">- {totalExpenses.toLocaleString()}<span className='currency'>{currency}</span></div>
         </div>: <div/>}

           <div className="transaction-table-cell-row" >
            <div className="transaction-table-row-cell">Total Assets&emsp;</div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell">{totalAssets.toLocaleString()}<span className='currency'>{currency}</span></div>
         </div> 

             </div>
             <div className="accountbox-bar"><h3>Liabilities  and Equity</h3> <p></p></div>
            <div className="transaction-table-wrapper">
           {accounts.length <= 0 ? <div /> : accounts.map(account =>(
            account._accountType === 'equity' || account._accountType === 'liability' || account._accountType === 'income'? 
            <div className="transaction-table-cell-row" key={account._id}>
            <div className="transaction-table-row-cell">{account._accountName}&emsp;</div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell">{account._totalDebit > 0 ?  (account._totalDebit - account._totalCredit).toLocaleString(): (account._totalCredit + account._totalDebit).toLocaleString()}<span className='currency'>{currency}</span></div>
         </div> : <div/>
           ))}  

        <div className="transaction-table-cell-row" >
            <div className="transaction-table-row-cell">Retained Earnings&emsp;</div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell">{
              (Number(totalRepayments) - Number(totalExpenses)).toLocaleString()
            }<span className='currency'>{currency}</span></div>
         </div>  

          <div className="transaction-table-cell-row" >
            <div className="transaction-table-row-cell">Total Liabilities and Equity&emsp;</div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell"></div>
            <div className="transaction-table-row-cell">{totalLiabilities.toLocaleString()}<span className='currency'>{currency}</span></div>
         </div>   
             </div>
            </div>    
            </div>
    </div>
  )
}

export default BalanceSheet