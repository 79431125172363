import React, { useContext, useState } from 'react'
import { ThemeContext } from '../App'
import {Button,Icon } from '@blueprintjs/core'

function Newloan(props) {
    const {title,type,url,progress} = props.info
    const removeAttachment = props.removeAttachment
    const  {name,icon} = props
    const {  nusery } = useContext(ThemeContext)

    return (
    <div class="attach-bar">
        <div class="">{progress <= 0.9 ? <div /> : <Icon icon="tick-circle"  className='loan-icon-sp'color={nusery} />}</div>
        <div class="attach-box">{title}</div>
        <div class="attach-box-btn">{progress <= 0.9 ? <div>{progress * 100}%</div> :<Button icon={icon} onClick={()=>removeAttachment(url)}>{name}</Button> }</div>
    </div>
    )
}

export default Newloan
