import React,{useContext,useState,useEffect} from 'react'
import {ThemeContext} from '../App'
import {Menu,MenuItem,MenuDivider,Drawer,Position,DrawerSize, Collapse} from '@blueprintjs/core'
import {Icon} from '@blueprintjs/core'
import logo from '../img/logo.png'
import {Link,useNavigate} from 'react-router-dom'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import Empty from './Empty'

function SiderContent() {

    const { grey,card,primary,lighttext,nusery} = useContext(ThemeContext)
    const {branches,currentUser,dispatch,activeBranch} = useGlobalContextHook()
    const {permissions} = currentUser
    const {borrowers,loans,repayments, expenses,workers,branches:branchesPermissions,reports,accounting} = permissions
    const [limit,setLimit] = useState(40)
    const [criteria, setCriteria] = useState("active")
    const [open,close] = useState(false)
    const [open2,setOpen2] = useState(false)
    const [isLoanOpened,setIsLoanOpened] = useState(false)
    const navigate = useNavigate()
    const {role,company} = currentUser

    useEffect(() => {
      const unSubscribe = async () => {
           try {
             const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_BRANCHES&id=${currentUser.companyId}&limit=${limit}&criteria=${criteria}`)
             const json = await response.json()
              if(response.ok){
                 dispatch({type:'SET_BRANCHES',payload:json})
                 //dispatch({type:'SET_ACTIVE_BRANCH',payload:json[0]});
              }
           } catch (error) {
              console.log(error,'error on fetching branches')
           }
      }
      unSubscribe();
      return () =>unSubscribe();
  },[])

  const setActiveBranch = (branch) =>{
     dispatch({type:'SET_ACTIVE_BRANCH',payload:branch}); close(false);
  }

  const setCriteriaFn = async(criteria)=>{setCriteria(criteria);setOpen2(false)}
  return (
    <div className="sider-wrapper" >
      <div className="sider-site-name-new">
       <img src={logo} className="logo-img"/>
        <h1 class="new-site-name">Kopaleo</h1>
      </div>
      
        <Menu style={{overflow:'auto'}} className="sider-wrapper">
          <MenuItem icon="grid-view" text="Dashboard" className="menu-li" onClick={()=>navigate('/Dashboard')} />

            <MenuDivider />
            {branchesPermissions ===undefined ?  <div />: branchesPermissions[1].isGranted  ?  <MenuItem icon="git-branch" onClick={()=>navigate('/Branches')} text="Branches" className="menu-li" /> : <div />}
            {borrowers ===undefined ? <div />: borrowers[1].isGranted ?  <MenuItem onClick={()=>navigate('/borrowers/list')} icon="people" text="Borrowers" className="menu-li" /> : <div />}
            {accounting ===undefined  ? <div />: accounting[1].isGranted ? <MenuItem onClick={()=>navigate('/accounting')} icon="bank-account" text="Accounting" className="menu-li" />: <div />}  
            <MenuDivider />

            {loans ===undefined ? <div />: loans[1].isGranted ?  <MenuItem icon="dollar" text="Loans" className="menu-li" onClick={()=>setIsLoanOpened(prev=>!prev)}  labelElement={<Icon icon={isLoanOpened ? "chevron-up" : "chevron-down"} color="#fff" />}/> : <div />}
              <Collapse isOpen={isLoanOpened} canClickOutsideToClose={true} onClose={() => setIsLoanOpened(prev=>!prev)}>
                  <Menu className="sub-menu-wrapper">
                    <MenuItem onClick={() =>navigate('/Loans')}icon="dot" text="Individual loans"  className="sub-menu-li" />
                    <MenuItem onClick={() =>navigate('/group-loans')}icon="dot" text="Group loans" className="sub-menu-li" />
                  </Menu>
             </Collapse>

           {repayments ===undefined ? <div />: repayments[1].isGranted ?   <MenuItem onClick={()=>navigate('/Repayments')} icon="import" text="Repayments" className="menu-li" /> : <div />}
            {/*<MenuItem onClick={()=>navigate('/Collection')} icon="clipboard" text="Collection sheet" className="menu-li" />*/}
           {expenses ===undefined ? <div />: expenses[1].isGranted ?  <MenuItem  onClick={()=>navigate('/Expenses')}icon="export" text="Expenses" className="menu-li" /> : <div />}
           {workers ===undefined ? <div />:  workers[1].isGranted ? <MenuItem  onClick={()=>navigate('/Workers')}icon="inherited-group" text="Workers" className="menu-li" />: <div />}
           <MenuDivider />
           {reports ===undefined ? <div />: reports[1].isGranted ?  <MenuItem onClick={()=>navigate('/Report')} icon="projects" text="Reports" className="menu-li" />  : <div />}
      
            <MenuItem onClick={()=>navigate('/Chat')} icon="chat" text="Chat" className="menu-li" /> 
            <MenuItem onClick={()=>navigate('/Settings')} icon="cog" text="Settings" className="menu-li" />     
            <MenuDivider />
        </Menu>

    {role === "company_admin" ?<div className="list-branch">
               <div className="l-bar" onClick={()=>close(prev=>!prev)}>
                <h3>&emsp;{activeBranch === null ? "Select branch" : activeBranch.name}</h3><Icon icon="chevron-right" className="icon-d-d" color="#fff"/>
               </div>
             </div> :   <div className="l-bar" style={{marginLeft:15}}><h3>{currentUser.branch}</h3></div>}
        <Drawer 
                isOpen={open}  title="Select branch"
                position={Position.RIGHT}
                size={window.innerWidth > 1000 ? DrawerSize.STANDARD :  DrawerSize.LARGE} 
                canClickOutsideToClose={true} onClose={()=>close(prev=>!prev)}>

      <div className="filter-div" style={{marginTop:20}}>
              <button className='filter' style={{backgroundColor:card}} onClick={()=>setOpen2(prev=>!prev)}> Sort by &emsp;{criteria}<Icon icon={open2 ? 'chevron-up':'chevron-down'}  color={primary}/>
                  </button>   
                  <Collapse isOpen={open2} canOutsideClickClose={true} onClose={()=>setOpen2(prev=>!prev)}>
                    <div className='b-col'>
                      <div className="branch-li"  onClick={()=>setCriteriaFn('active')}>Active</div>
                      <div className="branch-li" onClick={()=>setCriteriaFn('inactive')}>Inactive</div>
                      </div>
                   </Collapse>   
                </div>

                <div className="">
                  {branches === undefined ? <div>Refresh your browser</div> : branches.length <= 0 ? <Empty  name="No braches found" icon="git-branch" color="#666"/> : branches.map(branch=>(
                        <div className="branch-bar" key={branch._id} onClick={()=>setActiveBranch(branch)}>
                        <div className="branch-name" style={{ backgroundColor:branch.color,color:lighttext }}>{branch.name.charAt(0)+branch.name.charAt(1)}</div>
                            <div className="branch-title">{branch.name} </div>
                                <div className="branch-title-status" style={{color:branch.status === "active" ? nusery:"#333"}}> {branch.status} </div>
                    </div>
                  ))}
                </div>
            </Drawer>

    </div>
  )
}

export default SiderContent