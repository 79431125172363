import React,{useContext,useState,useEffect} from 'react'
import {ThemeContext} from '../App'
import {Spinner,SpinnerSize} from '@blueprintjs/core'
import { UserContext } from '../App'
import { useSnackbar } from 'react-simple-snackbar'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
var moment = require('moment'); // require
moment().format();




function Newbranch({closeFn}) {
  const {currentUser,dispatch} = useGlobalContextHook()
  const {  primary,} = useContext(ThemeContext)
  const [openSnackbar, closeSnackbar] = useSnackbar()
  const [branch_name,setName] = useState("")
  const [location,setLocation] = useState("")
  const [disabled,setDisabled] = useState(true)
  const [spin,stop] = useState(false)
  
  

  const newbranch = async()=>{
        try {

            stop(true)
            function getRandomColor() {
              // alert()
              var letters = '0123456789ABCDEF';
              var color = '#';
              for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
              }
              return color;
              
            }
            const  branchColor = getRandomColor()
            const branchObj = {
                  color:branchColor,
                  company:currentUser.company,
                  companyId:currentUser.companyId,
                  expenses:0,
                  loans:0,
                  fullypaid:0,
                  overdue:0,
                  location:location,
                  name:branch_name,
                  owner:currentUser.id,
                  repayments:0,
                  status:"active",
                  workers:0
          }
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=CREATE_BRANCH`,{
                  method: 'POST',
                  body: JSON.stringify(branchObj),
                  headers: {
                    'Content-Type': 'application/json'
                  }
            })
            const json = await response.json()
            if(response.statusText === "OK"){
              dispatch({type:'ADD_BRANCH',payload:json})
              openSnackbar(`New branch created successfully${branch_name}`)
              closeFn()
              stop(false)
            } 
            if(response.statusText === "Not Found"){
               openSnackbar(`New branch failed`)
            }

        } catch (error) {
          console.error(error,'Error in creating new branch')
      }
  }

  useEffect(() => {
      if(branch_name === "" || location === ""){
         setDisabled(true)
      } else { setDisabled(false)}
  },[branch_name,location])
 
  return (
    <div className='new-branch-wrapper'>
        <h2>Add new branch</h2>
      
         <input type="text" placeholder="Branch name"  onChange={(e)=>setName(e.target.value)}/>
         <input type="text" placeholder="Location" onChange={(e)=>setLocation(e.target.value)} />

         <button style={{ backgroundColor:disabled ? "#ccc" :primary }} onClick={()=>newbranch()}>Save</button>
         {spin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
    </div>
  )
}

export default Newbranch