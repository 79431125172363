import React,{useContext} from 'react'
import {ThemeContext} from '../App'
import {Icon,Portal} from '@blueprintjs/core'

function Fab({icon,text}) {
    const { primary,card} = useContext(ThemeContext)

  return (
        <Portal className='portal'>
              <div className="fab" style={{backgroundColor:primary}} onClick={()=>console.log("hello")}>
                    <div className="fab-rail">
                         <Icon icon={icon} color={card} size={20} className="fab-icon" />
                         <div className='text-fab' style={{color:card}}>{text}</div>
                    </div>
                 </div>
        </Portal>
  )
}

export default Fab