import React,{useState,useEffect} from 'react'
import logo from '../img/logo.png'
import {useLocation,useNavigate} from 'react-router-dom'
import {Icon,Collapse,Spinner,SpinnerSize} from '@blueprintjs/core'
import { useSnackbar } from 'react-simple-snackbar'
import {useGlobalContextHook} from '../hook/useGlobalContextHook'
var moment = require('moment'); 


function Payment() {

    const {state} = useLocation();
    const {dispatch,currentUser} = useGlobalContextHook()
    const {code,title,price,color,days} = state;
    const [open, close] = useState(true)
    const [toggle,setToggle] = useState(false)
    const [errorMessage,showErrorMessage] = useState(null)
    const [openSnackbar, closeSnackbar] = useSnackbar()
    const [userInput,setInput] = useState("")
    const [disabled,setDisabled] = useState(true)
    const [spin,stop] = useState(false)
    const [promocode,setPromoCode] = useState("")
    const navigate = useNavigate()

const activatePlan  = async() =>{ 
      stop(true)   
       try {
         const  response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_ACTIVATION_CODE&code=${userInput}&promocode=${promocode}&price=${Number(price)}`)
         const json = await response.json()
         if(!response.ok){
              showErrorMessage(json.message);
              stop(false)
         }
         if(response.ok){
            modifyPlan(json);
            stop(false)
         }
       } catch (error) {
        
       }
 
}

const modifyPlan = async(activatedcode) => {
              try {
                const planChanges= {
                  used:true,
                  usedBy:currentUser.email,
                  company:currentUser.company,
                  usedDate:new Date().toDateString(),
                  days:activatedcode.days,
                }
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_ACTIVATION_CODE&code=${userInput}`,{
                      method: 'PATCH',
                      body: JSON.stringify(planChanges),
                      headers: {
                        'Content-Type': 'application/json'
                      }
                })
                const json = await response.json()
                if(response.ok){
                    updateUserProfile(json);
                    openSnackbar("Code activation successfully")
                }
                if(!response.ok){
                    openSnackbar(json.message);
                    showErrorMessage(json.message)
                 }

              } catch (error) {
                showErrorMessage("Code validation failed!")
                openSnackbar("Code activation failed")
                console.log(error)
              }     
  }

const updateUserProfile = async ()=>{
     try {
       const  realtime = moment.utc()
       const  ending =  realtime.clone().add(days,'days');
       const userChanges= {
                role:"company_admin",
                subscription:title,
                started:realtime._d,
                ending:ending._d,
                daysLeft:days,
                completed:"completed"
       }
       const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_USER_PROFILE&id=${currentUser.id}`,{
        method: 'PATCH',
        body: JSON.stringify(userChanges),
        headers:{
            'Content-Type': 'application/json'
        }
       })
       const json = await response.json()
       if(response.ok){
        dispatch({type:'SET_CURRENT_USER',payload:json})
        stop(false); console.log("take user home")
        dispatch({type:'ACCOUNT_STATUS',payload:true});
        navigate('/')   
       }
       if(!response.ok){
        console.log(json.error)
       }
     } catch (error) {
         showErrorMessage("user profile update failed!")
        
     }
}

  useEffect(() => {
     if(userInput=== ""){
          setDisabled(true);
          updateUserProfile()
     } else { setDisabled(false)}
  },[userInput])

  return (
    <div>
        <div className="auth-nav">
            <img src={logo} />
                <h2 style={{color:"#fff"}}>Kopaleo</h2>
        </div>

        <div className="auth-container">

                <h2>Payment</h2>
               
                  <div className="input-bar" style={{flexDirection: 'column'}} onClick={()=>close(prev=>!prev)} >
                    <div className="comp-bar" style={{margin:10}}>
                         <div className="round-comp" style={{ backgroundColor:color,marginTop:30}}>{code}</div> 
                          <div className="round-name" style={{width:"70%"}}>
                              <h3>{title}</h3>
                              <p style={{marginTop:-10}}>{price}</p>
                              </div> 
                          <div className="round-tick"><Icon icon="tick-circle"  className='loan-icon' color={"#249E61"} size={20} style={{marginTop:30}}/></div>
                 </div>
                 </div>
             

<Collapse isOpen={open} canOutsideClickClose={true} onClose={()=>close(prev=>!prev)}>
        <div className="companies">
                <h3>How to pay</h3>
                <div>1.Dial mobile payment *150*00# </div>
                <div>2.Number 0766298542 Joseph Owigo </div>
                <div>3. You will recieve code</div>
             </div>
        </Collapse>
            
        <div className="input-bar">
                <Icon icon="barcode" color={"#5F6B7C"}  size={20} className='icon-auth'/>
                <input type="text" placeholder="Activation code" onChange={(e)=>setInput(e.target.value)} />
            </div>

           <div className="companies">
                <h3>Do you have Promo code ?</h3>
                <div>Use promo code to get free 14 days </div>
                </div>
                <div className="input-bar">
                <Icon icon="array-numeric" color={"#5F6B7C"}  size={20} className='icon-auth'/>
                <input type="text" placeholder="promo code" onChange={(e)=>setPromoCode(e.target.value)} />
            </div>   

            {errorMessage === null ? <div/> : 
			          <div className="input-bar" style={{borderWidth:0}}>
			          <Icon icon="error" color={"#b41313"}  size={20} className='icon-auth'/>
			          <p   style={{color:"#b41313"}}>{errorMessage}</p>
				 </div> 
			}
<button  onClick={()=>activatePlan()} style={{ backgroundColor:disabled ? "#ccc" :"#07608B" }}>
{spin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
  Verify</button>
     

        </div>
    </div>
  )
}

export default Payment