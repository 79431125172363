import React,{useContext,useState} from 'react'
import {Icon,Collapse,Button} from '@blueprintjs/core';
import {ThemeContext} from '../App'
import { useSnackbar } from 'react-simple-snackbar'
import db  from '../firebase/config'
import {doc,limit,updateDoc,deleteDoc} from 'firebase/firestore'
import {useNavigate} from 'react-router-dom'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'


function Notibar(props) {
    const {currentUser,dispatch} = useGlobalContextHook()
    const { nusery,lighttext,card,lightgrey} = useContext(ThemeContext)
    const { docId,createdAt,icon,title,read,headline,_id} = props.info
    const [more,setMore] = useState(false); 
    const [openSnackbar, closeSnackbar] = useSnackbar()
    const navigate = useNavigate()

    const marked_as_read = async()=>{
            try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_NOTIFICATION&id=${_id}`,{
                  method: 'PATCH',
                  body: JSON.stringify({read:true}),
                  headers: {'Content-Type': 'application/json'}
            })
            if(response.ok){
                getAllNotifications()
                openSnackbar("Marked as already read") 
            }
            if(!response.ok){
                console.log("error")
            }
                   
          } catch (error) {
                console.log(error)
                let err = error.toString()
                openSnackbar("Couldn't update notification as read",err)
            }
    }

    const delete_noti = async()=>{
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/remove?action=DELETE_NOTIFICATION&id=${_id}`,{
                  method: 'DELETE'
            })
            if(response.ok){
                getAllNotifications()
                openSnackbar("Notification deleted!") 
            }
            if(!response.ok){
                console.log("error",response)
            }
             
          } catch (error) {
                console.log(error)
                let err = error.toString()
                openSnackbar("Couldn't update notification as read",err)
            }
      }

    const getAllNotifications = async()=>{
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_NOTIFICATIONS&id=${currentUser.id}&limit=40`)
            const json = await response.json()
            if(response.ok){
              dispatch({type:'SET_NOTIFICATIONS',payload:json})
            }
            
            if(!response.ok){
              console.log('error on fetching notifications')
            }
          } catch (error) {
            console.log(error)
          }
    }
  return (
    <div className="agent-main" style={{ backgroundColor:read ? card:"#f8f8f8"}}>
        <div className="agent-bar" style={{width: '100%',backgroundColor:read ? card:"#f8f8f8"}}>
            <Icon icon={icon}  className="noti-icon" size={20} color={read ? lightgrey: '#07608B'}/>
                <div className="mid-noti-bar">
                    <h4>{headline}</h4>
                    <p>{title}</p>
                </div>
                <button type="button" onClick={()=>setMore(prev=>!prev)} className='more' style={{backgroundColor:read ? card:"#f8f8f8",outline:'none'}}>
                    <Icon  icon={more ? "chevron-up" : "chevron-down"} /></button>
            </div>
            
        <Collapse isOpen={more} onClose={()=>setMore(prev=>!prev)} canOutsideClickCancel={true}>
            <div className='more-opt' style={{backgroundColor:read ? card:"#f8f8f8"}}>
               <Button  icon="tick-circle" onClick={()=>marked_as_read()}>Mark as read</Button>
               <Button  icon="trash" onClick={()=>delete_noti()}>Clear</Button>
        </div>
        </Collapse> 

    </div>
  )
}

export default Notibar