import React,{useState,useEffect} from 'react'
import { useSnackbar } from 'react-simple-snackbar'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {useNavigate} from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import {Icon,Menu,MenuItem,Popover,Position,Spinner,SpinnerSize,Tag} from '@blueprintjs/core'

function JournalEntry({branchId,branch}) {
  const {currentUser,accounts,dispatch} = useGlobalContextHook()
  const {id,company,companyId,settings} = currentUser
  const {currency} = settings
  const [entryName,setEntryName] = useState("")
  const [description,setDescription] = useState("")
  const [date,setDate] = useState(new Date().toJSON().slice(0,10))
  const [keyword,setKeyword] = useState("")
  const [openSnackbar] = useSnackbar()
  const [disabled,setIsDisabled] = useState(false)
  const [isSpinning,setIsSpinning] = useState(false)
  const [isEntryBalanced,setIsEntryBalanced] = useState(null)
  const navigate = useNavigate()
  const [entrybars,setEntryBars] = useState([
   {id:"1",accountName:"", accountId:"",accountType:"",debit:0,credit:0},
   {id:"2",accountName:"", accountId:"",accountType:"",debit:0,credit:0},
  ])


  //list accounts
  useEffect(() => {
    listAccounts()
  },[branchId,keyword])

  const listAccounts = async()=>{
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_ACCOUNTS&keyword=${keyword}&id=${branchId}`)
      const json = await response.json()
      if(response.ok){
        dispatch({type:'SET_ACCOUNTS',payload:json})
      }
      if(!response.ok){
        openSnackbar(`Failed to list branches`)
      }
    } catch(e){
      openSnackbar(`Error during listing accounts, Check your internet connection`)
    }
  }

  const addEntry =()=>{
    const randomId = Math.floor(Math.random() * 1000000000000000000);
    setEntryBars([...entrybars,{id:randomId,accountName:"", accountId:"",accountType:"",debit:0,credit:0}])
  }

  const removeEntryBar=(entrybar)=>{
    const newEntryBars = entrybars.filter(bar=>bar.id !== entrybar.id)
    setEntryBars(newEntryBars)
  }

  const updateEntryDebit =(entrybar,debit)=>{
    const  newEntryBars = entrybars.map(entry=>{
          if(entry.id === entrybar.id){
            return {...entry,
                    id: entrybar.id,
                    accountName:entrybar.accountName,
                    accountId:entrybar.accountId,
                    debit:debit,
                    credit:entry.credit
                }   
          }
          return {
            ...entry,
            id:entry.id,
            accountName:entry.accountName,
            accountId:entry.accountId,
            debit:entry.debit,
            credit:entry.credit
         } 
     })
     setEntryBars(newEntryBars)
  }

  const updateEntryCredit =(entrybar,credit)=>{
    const  newEntryBars = entrybars.map(entry=>{
          if(entry.id === entrybar.id){
            return {...entry,
                    id: entrybar.id,
                    accountName:entrybar.accountName,
                    accountId:entrybar.accountId,
                    debit:entry.debit,
                    credit:credit
                }   
          }
          return {
            ...entry,
            id:entry.id,
            accountName:entry.accountName,
            accountId:entry.accountId,
            debit:entry.debit,
            credit:entry.credit
         } 
     })
     setEntryBars(newEntryBars)
  }

  const selectAccount =(entrybar,account)=>{
    const  newEntryBars = entrybars.map(entry=>{
      if(entry.id === entrybar.id){
        return {...entry,
                id: entrybar.id,
                accountName:account.name,
                accountId:account._id,
                accountType:account.accountType,
                debit:entry.debit,
                credit:entry.credit
            }   
      }
      return {
        ...entry,
        id:entry.id,
        accountName:entry.accountName,
        accountId:entry.accountId,
        accountType:entry.accountType,
        debit:entry.debit,
        credit:entry.credit
     } 
   })
   setEntryBars(newEntryBars)
  }

  const saveEntry = async() => {
    const journalId =await uuidv4();
    //check if debit and credit Balances
    const sumOfCredit = entrybars.reduce((accumulator,entry)=>{
        return accumulator + Number(entry.credit)
      },0)

    const sumOfDebit = entrybars.reduce((accumulator,entry)=>{
        return accumulator + Number(entry.debit)
      },0)

     if(sumOfCredit === sumOfDebit) {
          entrybars.forEach(entry => {
            saveEntryToDb(entry,journalId)
          })
       } else {
        setIsEntryBalanced(true)
      }
    }

  const saveEntryToDb =async(entry,journalId)=>{
    setIsSpinning(true)
    try {
      const {accountName,accountId,debit,credit,accountType} = entry
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=ADD_ENTRY`,{
        method: 'POST',
        body: JSON.stringify({
          entryId:journalId,
          entryName:entryName,
          description:description,
          date:date,
          accountName:accountName,
          accountId:accountId,
          accountType:accountType,
          debit:debit,
          credit:credit,
          company:company,
          companyId:companyId,
          branch:branch,
          branchId:branchId,
          createdBy:id
        }),
        headers: {'Content-Type': 'application/json'}
      })
      const json = await response.json()
      if(response.ok){
        openSnackbar(`${entryName} added`)
        navigate('/accounting');
        setIsSpinning(false);
        setEntryName(""); setDescription("");
        dispatch({type:'SET_SELECTED_TAB',payload:"3"})
        setEntryBars([
          {id:"1",accountName:"", accountId:"",accountType:"",debit:0,credit:0},
          {id:"2",accountName:"", accountId:"",accountType:"",debit:0,credit:0},
         ])
      }
      if(!response.ok){
        openSnackbar(`Failed to add new entry`)
      }
    } catch(e) {
      openSnackbar(`Error during saving entry`)
      console.log(e)
    }
  }

  const updateAccountBalance =async(json)=>{
    try {
      const {accounId,credit,debit} = json
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_ACCOUNT_BALANCE&id=${accounId}`,{
        method: 'PATCH',
        body: JSON.stringify({})
      })
    } catch(e){
      openSnackbar(`Error during updating account balance`)
    }
  }
//validate journal entry 
useEffect(() => {
  if(entryName === "" || description === ""){
    setIsDisabled(true)
  } else setIsDisabled(false)
},[entryName,description,entrybars])

  return (
    <div className="journal-wrapper">
        <div className="location-bar">
        <h1 className="clinic-h1" style={{padding:'12px 12px 12px 6%'}}>New Journal Entry</h1>
        </div>

        <div className="tab-2" id="tab-2">
            <div className="ln-box">
                <p>Entry Name</p>
                 <input type="text" placeholder="Enter a name" onChange={(e)=>setEntryName(e.target.value)} />
              </div>
              <div className="ln-box">
                <p>Date</p>
                <input type="date" onChange={(e)=>setDate(e.target.value)} />
              </div>

              <div className="ln-box" id="description-box">
                <p style={{paddingLeft:0}}>Description</p>
                <textarea id="description" onChange={(e)=>setDescription(e.target.value)}/>
              </div>
        </div>

        <div className="account-entry-wrapper">
         {entrybars.length <= 0 ? <div /> : entrybars.map(entrybar=>(
                  <div className="entry-bar" key={entrybar.id}>
                  <div className="entry-box-main">
                    <h3>Accounts</h3>
                   <Popover  className="entry-sub-box" content={
                      <div className="search-account-list">
                      <input type="text"  placeholder="Search account" onChange={(e)=>setKeyword(e.target.value)}/>
                      <Menu id="menu-second">
                      {accounts.length <= 0 ? <div /> : accounts.map(account=>(
                          <MenuItem text={account.name} className="link3" id="link3" 
                            onClick={()=>selectAccount(entrybar,account)}
                            key={account.id}
                          />
                      ))}
                      </Menu>
                    </div>
                   } position={Position.TOP}>
                   <div className="entry-sub-box" style={{border:0,width:'60vh'}}>
                   <input type="text"  value={entrybar.accountName}
                    placeholder="" style={{width:'70%'}}/>
                   <div className="location-options" id="touchable">
                      <Icon icon="chevron-down"  color="#666"  size={22}/></div>                   
                   </div>
                   </Popover>
                  </div>

                  <div className="entry-box">
                    <h3>Debit</h3>
                    <input type="text"  onChange={(e)=>updateEntryDebit(entrybar,e.target.value)}  placeholder="" />
                  </div>

                  <div className="entry-box">
                    <h3>Credit</h3>
                    <input type="text"  onChange={(e)=>updateEntryCredit(entrybar,e.target.value)}  placeholder="" />
                  </div>
                  {entrybars.length <= 2 ? <div /> :  
                  <div  onClick={()=>removeEntryBar(entrybar)} className="location-options" id="touchable" style={{marginTop:60}}>
                      <Icon icon="trash"  color="#666"  size={22}/></div>}
                </div>
         ))}
         <div style={{margin:'20px 10px 15px 0px'}}>  {isEntryBalanced === null  ? <div/> : 
        <Tag intent="danger" large={true} interactive={true} onRemove={()=>setIsEntryBalanced(null)}>Debits and credits don not balance</Tag>} </div>
         <div className="just-button" id="special-disabled-button" style={{marginLeft:0}} onClick={() =>addEntry()}>+ Add Entry box</div>
         <div className="just-button" id={disabled ? "disabled-button" : "not-disabled"} onClick={()=>saveEntry()}>
            {isSpinning ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
            {isSpinning ? "Saving entries":"Save Journal Entry"}
         </div>
        </div>
    </div>
  )
}

export default JournalEntry