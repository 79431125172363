import React,{useContext,useState,useEffect} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {ThemeContext} from '../App'
import { Icon,Dialog} from '@blueprintjs/core'
import Branch from '../components/Branch'
import Newbranch from '../components/Newbranch'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import AccessDenied from '../components/AccessDenied'

function Branches() {
    const {branches,currentUser,dispatch} = useGlobalContextHook()
    const {company,permissions} = currentUser
    const {branches:branchesPermissions} = permissions
    const {  primary,card,lighttext,body} = useContext(ThemeContext)
    const [open, close] = useState(false)
    const [limit,setLimit] = useState(40)
    const [criteria, setCriteria] = useState("active")

    useEffect(() => {
        const unSubscribe = async () => {
             try {
               const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_BRANCHES&id=${currentUser.companyId}&limit=${limit}&criteria=${criteria}`)
               const json = await response.json()
                if(response.ok){
                   dispatch({type:'SET_BRANCHES',payload:json})
                }
             } catch (error) {
                console.log(error,'error on fetching branches')
             }
        }
        unSubscribe();
        return () =>unSubscribe();
    },[limit,criteria])
    
    const closeFn=()=>{
       close(prev=>!prev);
    }

  return (
    <div className="main-wapper" >
         <TopNav />
   <div className="content-wrapper">
   <div className="sider" >
            <SiderContent />
        </div>
      {branchesPermissions[1].isGranted ?        <div className="section" style={{backgroundColor:body}}>
        <div className="stat-box" style={{width:'95%',marginLeft:10}}>
          <div className="stat-h3" style={{ backgroundColor:primary}}><Icon icon="git-branch" style={{marginTop:-8}} color={lighttext} size={30} className="exp-icon-icon"/></div>
              <div className="stat-para"><h3 className="stat-h4">BRANCHES</h3><p>{company}</p></div>
                 {branchesPermissions[0].isGranted ?  <div class="add-user" onClick={()=>close(prev=>!prev)}>+ Add new branch</div> : <div />}
                    </div> 
<div className='branch-wrapper-sp' style={{backgroundColor:card}}>{
 branches.length <= 0 ? <Newbranch />: <div />}
   <div className="branches-divs" style={{backgroundColor:card}}>  
    {branches.length <= 0 ? <div/ > : <div>
        {branches.map((branch,key)=>(
            <Branch  key={key}  info={branch}  />
            ))}
          </div> }
    </div> 
      </div>
        </div> : <AccessDenied />}
        <Dialog isOpen={open} canOutsideClickClose={true} onClose={()=>close(prev=>!prev)}>
            <Newbranch  closeFn={closeFn} />
        </Dialog>
   </div>
    </div>
  )
}

export default Branches