import React,{useContext,useState,useEffect} from 'react'
import {ThemeContext} from '../App'
import {Icon,Popover,Menu,MenuItem,Position,Checkbox,Drawer,DrawerSize,Dialog,Tag,Spinner,SpinnerSize} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {useSnackbar} from 'react-simple-snackbar'
import Empty  from '../components/Empty'
import {useNavigate} from 'react-router-dom'
import '../css/phone.css'

function BulkySms({branch,branchId}) {
  const {borrowers,dispatch,currentUser} = useGlobalContextHook()
  const {permissions} = currentUser
  const {borrowers: borrowersPermissions} = permissions
  const [isDialogOpen,setIsDialogOpen] = useState(false)
  const {lightgrey} = useContext(ThemeContext)
  const [openSnackbar] = useSnackbar()
  const [openedBorrower,setOpenedBorrower] = useState(null)
  const [isDrawerOpen,setIsDrawerOpen] = useState(false)
  const [isDisabled,setIsDisabled] = useState(false)
  const [textArea,setTextArea] = useState("")
  const [sending,setSending] = useState(false)
  const [queNumber,setQueNumber] = useState(0)
  const [photo,setPhoto] = useState("https://kopaleo.com/api/img/logos/siba.png")
  const [selectedMembers,setSelectedMembers] = useState([])
  const navigate = useNavigate()

  const listBorrowers = async()=>{
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_BORROWERS&id=${branchId}`)
      const json = await response.json()
      if(response.ok) {
       let newBorrowers = json.map(item=>{
           if(item!== null){
            return {
              ...item,
              isChecked:false
            }
           }
       })
        dispatch({type:'SET_BORROWERS',payload:newBorrowers})
      }
      if(!response.ok) {
        openSnackbar('Failed to list borrowers!')
        console.log(json.message)
      }
    } catch(err){
      openSnackbar('Error during listing borrowers')
    }
  }

  const searchBorower =async(keyword)=>{
       if(keyword.length <= 0){
         listBorrowers()
       } else {
          let likePattern = `%%${keyword}%%%%%a`;
          let myregexp = new RegExp(likePattern.replaceAll('%','.*'),"i")
          let newArry = borrowers.filter(borrower =>borrower.fullName.match(myregexp))
          dispatch({type:'SET_BORROWERS',payload:newArry})
       }
  }

  const openBorrower = (borrower) => {
   setOpenedBorrower(borrower)
   setIsDrawerOpen(true)
  }

  const editBorrower = (borrower)=>{
    navigate("/edit-borrower",{state:borrower})
  }

  const removeBorrower = async(borrower) => {
    try {
      const {_id} = borrower
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/remove?action=DELETE_BORROWER&id=${_id}`,{
        method: 'DELETE'
      })
      if(response.ok) {
        listBorrowers()
      }
      if(!response.ok){
        openSnackbar('Failed to  remove borrower!')
      }
    } catch(err){
      openSnackbar('Error during removing borrower')
    }
  }
  //list borrowers
  useEffect(() => {
    listBorrowers()
  },[branchId])

  const selectBorrower = (borrower)=>{
    dispatch({type:'SELECT_BORROWER',payload:borrower})
    let isMemberExist = selectedMembers.map(member=>member._id).indexOf(borrower._id)
    if(isMemberExist !== -1) {
       //remove member from the list of selected
       let filteredMembers = selectedMembers.filter(member=>member._id !== borrower._id)
       setSelectedMembers(filteredMembers)
    }
    if(isMemberExist === -1) {
       setSelectedMembers([borrower,...selectedMembers])
    }
  }

  const selectOrDeselectBorrower = (borrower)=>{
    dispatch({type:'SELECT_DE_BORROWER',payload:borrower})
    let isMemberExist = selectedMembers.map(member=>member._id).indexOf(borrower._id)
    if(isMemberExist !== -1) {
       //remove member from the list of selected
       let filteredMembers = selectedMembers.filter(member=>member._id !== borrower._id)
       setSelectedMembers(filteredMembers)
    }
    if(isMemberExist === -1) {
       setSelectedMembers([borrower,...selectedMembers])
    }
  }

  const sendSms= async()=>{
      if(selectedMembers.length >= 1){
          setIsDialogOpen(true)
      } else openSnackbar(`Select at least one borrower`)
  }

  const sendSmsFn =()=>{
      selectedMembers.forEach((member,i)=>{
         sendSmsWorker(i,member.fullName,member.phoneNumber)
      })
  }

  const sendSmsWorker = async(i,name,phoneNumber)=>{
    try {
       setQueNumber(i+1)
       setSending(true)
       const message = `${name} ${textArea}`
       const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=SEND_SMS&phone=${phoneNumber}`,{
           method:'POST',
           body: JSON.stringify({message:message}),
           headers:{'Content-Type': 'application/json'}
       })
       if(response.ok) {   
           setIsDialogOpen(false)
           setSending(false)
           setSelectedMembers([])
           setTextArea("")
           setIsDisabled(true)
           openSnackbar('All SMS sent successfully!')
           openSnackbar('All SMS sent successfully!')
         }
       if(!response.ok){console.log(response)}
    }
    catch(e){
      openSnackbar(`Error during sending sms`)
    } 
  }
  useEffect(() => {
    if(textArea === ""){
      setIsDisabled(true)
    } else setIsDisabled(false)
  },[textArea])
  return (
    <div>
            <h3>Branch: {branch}</h3>
    <div className="borrowers-wapper">
        <div className='search-bar' style={{marginLeft:'1%',marginTop:'0px'}}>
      <div className="search-input" style={{display:'inline-flex',width:'100%'}}>
        <input type="text"  placeholder="Search borrower's name"  style={{width:'50%',marginRight:15}}  onChange={(e)=>searchBorower(e.target.value)}/>
        {borrowersPermissions[0].isGranted ? <div className="add-new-group"  style={{width:260}} onClick={()=>sendSms()}> <Icon icon="chat" color="#07608b" /> &emsp; Send SMS</div>: <div />}
        </div>     
      </div>
      <p className="p-selected">Selected borrowers &emsp;[{selectedMembers.length}]</p> 
      <div class="selected-wrapper">
       {selectedMembers.length <= 0 ? <div/> : selectedMembers.map(member=>(
           <Tag large={true} multiline={true}  key={member._id} style={{margin:5}}
           onClick={() =>selectOrDeselectBorrower(member)} round={true}
           rightIcon="cross"
           >{member.fullName}</Tag>
         ))}
      </div>
        <div className="table1">
        <div className="table1-header">
          <div className="header-title" id="check-box">#</div>
          <div className="header-title">Name</div>
          <div className="header-title">Address</div>
          <div className="header-title">Phone</div>
          <div className="header-title">Occupation</div>
          <div className="header-title">Branch</div>
          <div className="header-title">Menu</div>
        </div>

        <div className="table1-row-wrapper">
        {borrowers.length <= 0 ? <Empty icon="people" name="Currently,No any borrower" color="#666" /> : 
          borrowers.map(borrower =>(
          <div className="table1-cell-holder">
          <div className="table1-cell" id="check-box">    <Checkbox checked={borrower.isChecked} onChange={()=>selectBorrower(borrower)} /></div>
          <div className="table1-cell" onClick={()=>openBorrower(borrower)}>{borrower.fullName}</div>
          <div className="table1-cell">{borrower.residence}</div>   
          <div className="table1-cell">{borrower.phoneNumber}</div>   
          <div className="table1-cell">{borrower.occupation}</div>    
          <div className="table1-cell">{borrower.branch}</div>
           <div className="table1-cell">
           <Popover content={
               <Menu>
                   <p className='container-p'>Options </p>
                  <MenuItem    icon='eye-open' text="View" onClick={()=>openBorrower(borrower)} />
                  {borrowersPermissions[2].isGranted ?  <MenuItem    icon='edit' text="Edit" onClick={()=>editBorrower(borrower)} />: <div />}
                 {borrowersPermissions[3].isGranted ?  <MenuItem    icon='trash' text="Remove" onClick={()=>removeBorrower(borrower)} /> : <div />}
               </Menu>
               
              } position={Position.BOTTOM_BOTTOM}>
                <div className="table-menu" id="touchable">
               <Icon icon="more"  color="#666"  size={22}/></div> 
        </Popover>
              </div>
          </div>))
        }
        </div>

        <div className="table-footer">
             <div className="footer-title">Showing <strong> 1-{borrowers.length}</strong>  </div>
              <div className="footer-items">
                rows per page 
                <select>
                <option value="20">20</option>
                <option value="20">50</option>
                <option value="20">100</option>
                <option value="20">200</option>
                </select>
              </div>
            <div className="footer-far-right">
            <div className="footer-next">
                   <div onClick={()=>{}} id="touchable">Prev</div>
               </div>  
               <div className="footer-next">
                   <div onClick={()=>{}} id="touchable">Next</div>
            </div></div>                       
             </div>
        </div>
    </div> 
    <Drawer className="borrower-sider-wrapper"
        isOpen={isDrawerOpen} 
        title="Borrower details"
        titleTextColor="#fff"
        position={Position.RIGHT}
        size={window.innerWidth > 1000 ? DrawerSize.STANDARD :  DrawerSize.LARGE} 
        canClickOutsideToClose={true} onClose={()=>setIsDrawerOpen(prev=>!prev)}>
      {openedBorrower === null ? <div/> :
        <div>
          <div className="new-user-bar">
          <img src={openedBorrower.passportImage} alt="borrower_img" />
              <div classNamee="new-titles">
                <h3>{openedBorrower.fullName}</h3>
                 <p>{openedBorrower.phoneNumber}</p>
                 {borrowersPermissions[2].isGranted ?  <h4 onClick={()=>editBorrower(openedBorrower)} style={{borderWidth:0,padding:0,textAlign: 'left',width:'97%'}}>
                    <Icon icon="edit" />&emsp;
                    Edit details</h4> : <div />}
                </div>
              </div>

              <div className="info-rail"  style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Occupation</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.occupation}</div>
             </div>

             <div className="info-rail"  style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Sex</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.sex}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Residence</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.residence}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">ID number</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.idNumber1}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Extra Id</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.idNumber2}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Branch</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.branch}</div>
             </div>
             {/*<div>
                   <h2 style={{marginLeft:20}}>Bank account details</h2>
             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="office" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Bank name</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.bankName}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="credit" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Account number</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedBorrower.accountNumber}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="credit" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Bank account status</div>
             </div>
             <div className="valid-bar" id="unique-valid-bar" style={{borderColor:openedBorrower.accountStatus ? "#3A8E5C":"#B71C1C"}}>
                      <Icon icon={openedBorrower.accountStatus ? "tick-circle":"cross"} color={openedBorrower.accountStatus ? "#3A8E5C":"#B71C1C"}  size={22} className="valid-icon" />
                      <h3 style={{color:openedBorrower.accountStatus ? "#3A8E5C":"#B71C1C"}}>{openedBorrower.accountStatus ? "Account is verified": "Account is not verified"}</h3>
                    </div>
              </div>*/}
          </div>}
      </Drawer>

      <Dialog isOpen={isDialogOpen}
       title={"Write your message"}
       style={{width:window.innerWidth > 1000 ? "80%": '100%',overflow:'auto'}}
       canOutsideClickClose={true} onClose={()=>setIsDialogOpen(prev=>!prev)}>
        <div className="text-wrapper">
          <div className="text">
            <textarea placeholder="Start to type..." onChange={(e)=>setTextArea(e.target.value)}  value={textArea} />
            <div className="just-button" onClick={()=>sendSmsFn()} id={`${isDisabled ? "disabled-button": "not-disabled" }`}>
             {sending ? `Sending ${queNumber}/${selectedMembers.length}` : 'Send Sms'}
             {sending ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <Icon icon="send-message" color="#666" /> }
        </div>
          </div>
        <div class="iphone" id="iphoneLeft">

<div class="silence-switch outer-button"></div>
<div class="volume-rocker-top outer-button"></div>
<div class="volume-rocker-bottom outer-button"></div>
<div class="power-button outer-button-reversed"></div>
<div class="top-section">
  <i class="arrow left"></i>
  <div class="top-section-time">12:00</div>
  <div class="top-section-symbols">
    <i class="fas fa-signal"></i>
    <i class="fas fa-wifi"></i>
    <i class="fas fa-battery-three-quarters"></i>
  </div>
  <div class="top-section-middle">
    <div class="speaker"><div class="front-camera"></div></div>
    <div class="top-section-user-pic selectDisable"><i class="fas fa-user-circle"></i></div>
    <div class="top-section-user-name">ONSMS</div>
  </div>
<div class="messages-section" id="messages-left"><br />
    <div class="message from">{selectedMembers.length <= 0 ? <div/> :selectedMembers[0].fullName}&emsp;{textArea}</div>
</div>
</div>
        </div>
        </div>
      </Dialog>
    </div>
  )
}

export default BulkySms