import React,{useContext,useState} from 'react'
import { Icon,Popover,Position,Menu,MenuItem,Dialog} from '@blueprintjs/core'
import {ThemeContext} from '../App'
import {useNavigate} from 'react-router-dom'
import EditBranch from './EditBranch'
import { useSnackbar } from 'react-simple-snackbar'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'


function Branch(props) {

    const {  lighttext,nusery} = useContext(ThemeContext)
    const {dispatch,currentUser} = useGlobalContextHook()
    const {permissions} = currentUser
    const {branches:branchesPermissions} = permissions
    const [open, close] = useState(false)
    const [openSnackbar] = useSnackbar()
    const {name,status,color,_id} = props.info
    const navigate = useNavigate()
    
    const removeBranch = async()=>{
         try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/remove?action=DELETE_BRANCH&id=${_id}`,{
              method: 'DELETE'
            })
            const json = await response.json()
            if(response.ok){
                dispatch({type:'REMOVE_BRANCH',payload:json})
                openSnackbar(`${name} removed successfully`)
            }
            if(!response.ok){
              console.log(json,'on removing branch')
            }
            
         } catch (error) {
           let err = error.toString()
           openSnackbar(`Failed to remove branch ${err}`)
         }
    }

    const closeFn=()=>{
      close(false);
   }
   const openBranch = ()=>{
    dispatch({type:'SET_ACTIVE_BRANCH',payload:props.info}); close(false);
    navigate('/Branches/View')
   }

  return (
    <div className="branch-bar"  >
        <div className="branch-name" style={{ backgroundColor:color,color:lighttext }}>{name.charAt(0)+name.charAt(1)}</div>
            <div className="branch-title" onClick={()=>openBranch()}>{name} </div>
                <div className="branch-title-status" style={{color:status === "active" ? nusery:"#333"}}> {status} </div>
                <Popover content=
              {
                 <Menu>
                     <p className='container-p'>Branch options</p>
                    
                    <MenuItem    icon='eye-open'  text="View branch" onClick={()=>openBranch()}/>
                    {branchesPermissions[2].isGranted ?   <MenuItem     icon='manually-entered-data' text="Edit details"  onClick={()=>close(prev=>!prev)}/>: <div />}
                    {branchesPermissions[3].isGranted ? <MenuItem    onClick={()=>removeBranch()}  icon='trash' text="Remove branch" />: <div />}
                 </Menu>
                 
            
                } position={Position.BOTTOM_BOTTOM}>
                       <button className="branch-icon"><Icon icon="more" /></button>
                      </Popover>

              <Dialog isOpen={open} canOutsideClickClose={true} onClose={()=>close(prev=>!prev)}>
                  <div>
                        <EditBranch info={props.info} closeFn={closeFn} />
                  </div>
           </Dialog>
    </div>
  )
}

export default Branch