import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'
import { GlobalContextProvider } from './context/GlobalContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <GlobalContextProvider>
            <App  className="bp4-dark"/>
      </GlobalContextProvider>
  </React.StrictMode>
);

