import React from 'react'
import './userbar.css'
import { Icon, Popover } from '@blueprintjs/core'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import { useGlobalContextHook } from '../../hook/useGlobalContextHook'

function Userbar({title}) {
  const { currentUser, dispatch } = useGlobalContextHook()
  const { email, phoneNumber, username, photoURL, role} = currentUser
  const navigate = useNavigate()
  const [openSnackbar] = useSnackbar()

  const logout =async()=>{
     try {
        await localStorage.clear() 
        dispatch({type:'SET_CURRENT_USER', payload:null})
        openSnackbar('Logged out successfully')
        setTimeout(()=>{
          window.location.reload()
        },1000)
        navigate('/')
     } catch(err){
      openSnackbar('Failed to logout')
     }
  }

  return (
    <div className="top-bar-wrapper">
    <h1>{title}</h1>
    <div className="top-bar-far">
        <div className="rounded">
        <Popover
            placement="bottom"
            content={
              <div className="popover-profile">
                <div className="menu-bottom" style={{color:"#fff"}} onClick={() =>navigate('/profile')}>
                  <div className="logo"><img src={photoURL || "https://picsum.photos/id/250/300/300"} alt="logo" />
                  <h3>{ username }</h3><p>{email}</p> </div></div>

             <div className="span-bar">
                 <div className="spanned-a"> <Icon icon="phone" color="#07608B" />&emsp;Phone</div>
                  &emsp; <div  className="spanned" >{ phoneNumber }</div></div>

            <div className="span-bar">
             <div className="spanned-a"> <Icon icon="user" color="#07608B" />&emsp;Role</div>
                  &emsp; <div  className="spanned" >{role }</div></div>
                    </div>
                 }>
                <div className="bell-pr" style={{marginRight:10}}>
                <img src={photoURL} alt="placeholder"  id="touchable" />
                </div>
                </Popover>
           
            <h3 id="touchable">{username}</h3>
            {/*<div className="log-out-btn"  id="touchable"><Icon icon="notifications" size={24} /></div> */}
            <div className="log-out-btn"  id="touchable" onClick={()=>logout()}><Icon icon="power" size={24} /></div>
        </div>
    </div>
</div>
  )
}

export default Userbar