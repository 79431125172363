import React,{useContext,useState,useEffect} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {ThemeContext} from '../App'
import {Icon,Spinner,SpinnerSize,Collapse} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {useSnackbar} from 'react-simple-snackbar'
import SelectBranch from '../components/SelectBranch'
import { useNavigate,useLocation } from 'react-router-dom'

function EditGroup() {
    const navigate = useNavigate()
    const {state} = useLocation()
    const {body} = useContext(ThemeContext)
    const {currentUser,activeBranch,dispatch,borrowers} = useGlobalContextHook()
    const {role,company,companyId,id,username} = currentUser
    const branchId = activeBranch === null ? null : currentUser.role === "worker" ? currentUser.branchId :  activeBranch._id
    const branch = activeBranch === null ? currentUser.branch : currentUser.role === "worker" ? currentUser.branch :  activeBranch.name
    const [name,setName] = useState(state === null ? "": state.name)
    const [residence,setResidence] = useState(state === null ? "": state.residence)
    const [description, setDescription] = useState(state === null ? "": state.description)
    const [isDisabled,setIsDisabled] = useState(true)
    const [isSpinning,setIsSpinning] = useState(false)
    const [isCollapsed,setIsCollapsed] = useState(false)
    const [openSnackbar] = useSnackbar()
    const [selectedMembers,setSelectedMembers] = useState(state === null ? [] : state.members)

    const searchBorower =async(keyword)=>{
        try{
          setIsCollapsed(true)  
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SEARCH_BORROWERS&keyword=${keyword}&id=${branchId}`)
          const json = await response.json()
          if(response.ok) {
            dispatch({type:'SET_BORROWERS',payload:json})
          }
          if(!response.ok) {
            openSnackbar('Failed to search borrowers!')
          }
        } catch(err){
          openSnackbar('Error during searching borrowers')
          console.log(err)
        }
      }  

    const saveGroupBorrower =async()=>{
          setIsSpinning(true)
          try {
            const {_id} = state || ""
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_BORROWER_GROUP&id=${_id}`,{
                method: 'PATCH',
                body: JSON.stringify({
                    name:name,
                    residence:residence,
                    members:selectedMembers,
                    description:description,
                    createdBy:id,
                    createdByName:username,
                    company:company,
                    companyId:companyId,
                    branch:branch,
                    branchId:branchId,
                    status:"active"
                }),
                headers: {'Content-Type': 'application/json'}
            })
            if(response.ok){
                openSnackbar(`${name} updated successfully!`)
                navigate('/borrowers/list')
            }
            if(!response.ok){
                openSnackbar(`Failed to update group details`)
            }

          } catch(err){
            openSnackbar('Error during adding new group')
          }
    }

    const selectMember =(clickedMember)=>{
     let isMemberExist = selectedMembers.map(member=>member._id).indexOf(clickedMember._id)
     if(isMemberExist !== -1) {
        openSnackbar(`Member named ${clickedMember.fullName} already added`)
     }
     if(isMemberExist === -1) {
        setSelectedMembers([clickedMember,...selectedMembers])
     }
    }

    const removeMember = (clickedMember)=>{
        let newSortedMembers = selectedMembers.filter(member => member._id !== clickedMember._id)
        setSelectedMembers(newSortedMembers)
    }

    //validate group members
    useEffect(() => {
    if(name === "" || residence === "" || selectedMembers.length <= 0 || description === ""){
        setIsDisabled(true)
        } else setIsDisabled(false) 
    },[name,residence,selectedMembers,description])

  return (
    <div className="main-wapper" >
    <TopNav />
    <div className="content-wrapper">
        <div className="sider" >
         <SiderContent />
     </div>
     <div className="section" style={{backgroundColor:body}}>
     {branchId === null && role === "company_admin" ? <SelectBranch /> :<div className="new-user-wrapper">
             <h1 style={{padding:10}}>Edit Groups details</h1>
             <h3 style={{padding:10}}> Selected branch: {branch}</h3>
             <div className="tab-2">
            <div className="ln-box">
                <p>Name</p> 
                <input type="text" placeholder="Group name" value={name}
                    onChange={(e)=>setName(e.target.value)}/>
                </div>

                <div className="ln-box">
                <p>Residence</p> 
                <input type="text" placeholder="Residence" value={residence}
                    onChange={(e)=>setResidence(e.target.value)}/>
                </div>

                <div className="ln-box">
                <p>Group description</p>  
                <input type="text" placeholder="Write group description" value={description}
                    onChange={(e)=>setDescription(e.target.value)}/>
                </div>

                <div className="ln-box">
                <p style={{width:'95%',display: 'inline-flex'}}>Add Members<div className="borrower-far" style={{marginTop:-20}} id="touchable" onClick={()=>setIsCollapsed(prev=>!prev)}>
                            <Icon icon ={isCollapsed ? "chevron-down" : "chevron-up"} color="#666" size={18} />
                        </div></p> 
                <input type="text" placeholder="Search borrower"  style={{marginTop:0}}
                    onChange={(e)=>searchBorower(e.target.value)}/>
                <Collapse isOpen={isCollapsed} canOutsideClickClose={true} onClose={()=>setIsCollapsed(prev=>!prev)}>
                {borrowers.length <= 0 ? <div /> : borrowers.map(borrower=>(
                    <div className="borrower-bar" id="touchable" onClick={()=> selectMember(borrower)} key={borrower._id}>
                        <img src={borrower.passportImage}  alt="img" className=""/>
                            <div className="borrower-titles">
                            <h5>{borrower.fullName}</h5>
                            <h6>{borrower.phoneNumber}</h6>
                            </div>
                    </div>
                ))}
            </Collapse>
                </div>
                
                <div className="ln-box">
                <p>Current Members&emsp; [ {selectedMembers.length} ]</p>  
                 {selectedMembers.length <= 0 ? <div /> : selectedMembers.map(member=>(
                    <div className="borrower-bar" id="touchable" onClick={()=>(member)} key={member._id}>
                    <img src={member.passportImage}  alt="img" className=""/>
                        <div className="borrower-titles">
                        <h5>{member.fullName}</h5>
                        <h6>{member.phoneNumber}</h6>
                        </div>
                        <div className="borrower-far" id="touchable" onClick={()=>removeMember(member)}>
                            <Icon icon ="trash" color="#666" size={22} />
                        </div>
                    </div>
                    ))}
                </div>
            </div>
            <div className="just-button" onClick={()=>saveGroupBorrower()} id={isDisabled ? "disabled-button": "not-disabled"}>
                      {isSpinning ? 'Saving....': 'Save changes'}
                      {isSpinning ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
                  </div>
            </div> }
         </div>
   </div>
</div> 
  )
}

export default EditGroup