import React,{useContext,useState} from 'react'
import {Icon } from '@blueprintjs/core'
import {ThemeContext} from '../App'
import {Drawer,Position,DrawerSize} from '@blueprintjs/core'
import SiderContent from './SiderContent'

function TopNav() {
    const {  primary,lighttext} = useContext(ThemeContext)
    const [open,close] = useState(false)

  return (
    <div className='nav-wrapper' style={{backgroundColor:primary}}>
         <div className='nav-title' style={{color:lighttext}}>Kopaleo</div>
            <div className='nav-icon'>
               <button className='btn-hidden' onClick={()=>close(prev=>!prev)}>
                    <Icon icon="menu" color={lighttext} size={20} />
               </button>
            </div>
        <Drawer 
                isOpen={open} 
                position={Position.LEFT}
                size={DrawerSize.LARGE} 
                canClickOutsideToClose={true} onClose={()=>close(prev=>!prev)}>
            <div style={{backgroundColor:primary,height:'100%'}}>
            <SiderContent />
            </div>
            </Drawer>    
    </div>
  )
}

export default TopNav