import {createContext,useReducer} from 'react'

export const GlobalContexts = createContext()

export const updateContextReducer = (state,action)=>{
        switch(action.type){
             case 'SET_CURRENT_USER':
                  return {
                     ...state,
                     currentUser:action.payload
                  }
             case 'ADD_BRANCH':
                  return {
                     ...state,
                     branches:[action.payload,...state.branches]
                  }

            case 'SET_BRANCHES':{
                return {
                  ...state,
                  branches:action.payload
                }
            }
            
            case 'SET_BORROWERS':{
               return {
                 ...state,
                 borrowers:action.payload
               }
           }
            
            case 'REMOVE_BRANCH':{
               return{
                  ...state,
                  branches:state.branches.filter(b=>b._id !== action.payload._id)
               }
            }

            case 'UPDATE_BRANCH':{
               let newbranches = state.branches.map(bk=>{
                  if(bk._id === action.payload._id){
                   return {...bk,
                            name:action.payload.name,
                            location:action.payload.location,
                            color:action.payload.color
                           }
                  }
                  return bk
            })
               return {
                   ...state,
                   branches:newbranches
               }
            }

            case 'SET_WORKERS': 
               return {
                  ...state,
                  workers:action.payload
              }

            case 'SET_NOTIFICATIONS':
                  return { 
                     ...state,
                     notifications:action.payload
                  }
            
            case 'SET_BRANCH_LOANS': 
                  return {
                     ...state,
                     loans:action.payload
                  }
                        
            case 'SET_MEMBERS_LOANS': 
            return {
               ...state,
               membersLoans:action.payload
            }      

            case 'SET_GROUP_LOANS': 
            return {
               ...state,
               groupLoans:action.payload
            }      

            case 'REMOVE_LOAN':{
               return{
                   ...state,
                   loans:state.loans.filter(b=>b._id !== action.payload._id)
               }
            }

            case 'REMOVE_MEMBER_LOAN':{
               return{
                   ...state,
                   membersLoans:state.membersLoans.filter(b=>b._id !== action.payload._id)
               }
            }
            
            case 'SET_OPENED_LOAN':
               return {
                  ...state,
                  openedLoan:action.payload
               }

            case 'SET_REPAYMETS':
               return {
                  ...state,
                  repayments: action.payload
               }
            case 'REMOVE_REPAYMENT':
               return{
                  ...state,
                  repayments:state.repayments.filter(repayment => repayment._id !== action.payload._id)
               }      
            
            case 'SELECT_LOAN':{
               let newloans = state.loans.map(loan=>{
                  if(loan._id === action.payload._id){
                   return {...loan,
                           checked:loan.checked ? false : true
                           }
                  }
                  return loan
            })
               return {
                   ...state,
                   loans:newloans
               }
            }

            case 'SELECT_BORROWER':{
               let newBorrowers = state.borrowers.map(borrower=>{
                  if(borrower._id === action.payload._id){
                   return {...borrower,
                           isChecked:borrower.isChecked ? false : true
                           }
                  }
                  return borrower
            })
               return {
                   ...state,
                   borrowers:newBorrowers
               }
            }
         
            case 'SELECT_DE_BORROWER':{
               let newBorrowers = state.borrowers.map(borrower=>{
                  if(borrower._id === action.payload._id){
                   return {...borrower,
                           isChecked:false
                           }
                  }
                  return borrower
            })
               return {
                   ...state,
                   borrowers:newBorrowers
               }
            }

            case 'SET_EXPENSES':
               return {
                   ...state,
                   expenses:action.payload
               }
            case 'SET_ACTIVE_BRANCH':
               return {
                  ...state,
                  activeBranch:action.payload
               }
            case 'SET_REPORT':
               return {
                  ...state,
                  report:action.payload
               }
            case 'SET_LOAN_REPORT':
               return {
                  ...state,
                  loanReport:action.payload
               } 
            case 'SET_FINANCE_REPORT':
               return {
                  ...state,
                  financeReport:action.payload
               }  
            case 'BRANCH_LOAN_GRAPH':
               return {
                  ...state,
                  branchLoanGraph:action.payload
               }

            case 'BRANCH_REPAYMENT_GRAPH':
               return {
                  ...state,
                  branchRepaymentGraph:action.payload
                  } 
                  
            case 'BRANCH_EXPENSE_GRAPH':
               return {
               ...state,
               branchExpenseGraph:action.payload
               }
            case 'SET_LAST_TEN_DAYS':
               return {
                  ...state,
                  lastTenDaysGraph:action.payload
               } 
           case 'COMPANY_LOAN_GRAPH':
               return {
                     ...state,
                     companyLoanGraph:action.payload
                  } 
            case 'COMPANY_REPAYMENT_GRAPH':
               return {
                  ...state,
                  companyRepaymentGraph:action.payload
              }  
          case 'COMPANY_EXPENSE_GRAPH':
               return {
               ...state,
               companyExpenseGraph:action.payload
               }
         case 'ACCOUNT_STATUS':
            return{
               ...state,
               accountSetupStatus:action.payload
            }                        
         case 'BLOCK_ACCOUNT':
            return {
               ...state,
               blocked:action.payload
            }
         case 'SET_SUBSCRIPTION':
               return {
                  ...state,
                  subscription:action.payload
               }
          
         case 'SET_ACCOUNTS':
            return {
               ...state,
               accounts:action.payload
            }
            
         case 'SET_TRANSACTIONS':
               return {
                  ...state,
                  transactions:action.payload
               }
         
         case 'SET_TRIAL_BALANCE':
            return {
               ...state,
               trialBalance:action.payload
            }      

         case 'SET_BORROWERS_GROUPS':
            return {
               ...state,
               borrowersGroups:action.payload
               }
               
         case 'SET_CHAT_SMS':
               return {
               ...state,
               borrowersGroups:action.payload
               }       
         
         case 'SET_SELECTED_TAB':
            return {
               ...state,
               selectedTabId:action.payload
            }      

          case 'SET_PENDING_LOANS':
            return {
               ...state,
               pendingLoans:action.payload
            }   
              default: return state    
        }
      
}


export const GlobalContextProvider = ({children})=>{
         const[state,dispatch] = useReducer(updateContextReducer,{
                     loans:[],
                     groupLoans:[],
                     membersLoans:[],
                     accounts:[],
                     borrowers:[],
                     borrowersGroups:[],
                     repayments:[],
                     expenses:[],
                     workers:[],
                     branches:[],
                     notifications:[],
                     currentUser:null,
                     openedLoan:[],
                     activeBranch:null,
                     report:[],
                     loanReport:[],
                     pendingLoans:[],
                     branchLoanGraph:[],
                     branchRepaymentGraph:[],
                     branchExpenseGraph:[],
                     lastTenDaysGraph:[],
                     companyLoanGraph:[],
                     companyRepaymentGraph:[],
                     companyExpenseGraph:[],
                     accountSetupStatus:null,
                     blocked:null,
                     subscription:null,
                     selectedTabId:"1",
                     transactions:[],
                     trialBalance:[],
                     chatSms:[]
                    
         })
         return (
            <GlobalContexts.Provider value={{...state,dispatch}}>
                {children}
            </GlobalContexts.Provider>
         )
}
