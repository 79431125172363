import React,{useState,useEffect,useContext} from 'react'
import logo from '../img/logo.png'
import {useNavigate} from 'react-router-dom'
import {Icon,Collapse,Spinner,SpinnerSize} from '@blueprintjs/core'
import db  from '../firebase/config'
import { useAuthState } from 'react-firebase-hooks/auth';
//import {setDoc,doc,collection,query,onSnapshot,where,updateDoc,getDocs} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'
import { useSnackbar } from 'react-simple-snackbar'
import Combar from '../components/Combar'
import { UserContext } from '../App'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
const auth  = getAuth()

function Company() {
    const [user] =useAuthState(auth)
    const {currentUser,dispatch} = useGlobalContextHook()
    const [openSnackbar, closeSnackbar] = useSnackbar()
    const [open, close] = useState(true)
    const [spin,stop] = useState(false)
    const [name,setName] = useState("")
    const [location,setLocation] = useState("")
    const [disabled,setDisabled] = useState(true)
    const [errorMessage,showErrorMessage] = useState(null)
    const navigate = useNavigate()

    const addNewMicrocredit = async() =>{
         try {
              stop(true)
              function getRandomColor() {
                    var letters = '0123456789ABCDEF';
                     var color = '#';
                         for (var i = 0; i < 6; i++) {
                             color += letters[Math.floor(Math.random() * 16)];
                         }
                         return color;           
              }
              const  brandColor = getRandomColor()
              const companyObj = {name:name, brandColor:brandColor,location:location,owner:currentUser.id}
              const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=CREATE_NEW_COMPANY`,{
                    method: 'POST',
                    body: JSON.stringify(companyObj),
                    headers: {'Content-Type': 'application/json'}
              })
              const json = await response.json()
                if(response.ok) {
                     //updateUserProfile
                     await editUserProfile(json)
                     await openSnackbar(`New micro-credit added ${name}`)
                     await setName(""); await setLocation(""); await stop(false) 
                     await navigate('/Subscription')
                }

                if(!response.ok){
                     openSnackbar("Error on creating new micro-credit")
                }

                     
         } catch (error) {
            console.log(error,'Creating new Microfinance');
             }
    }

    const editUserProfile = async (newcompany) => {
          try {
            const userObj = {
                companyId:newcompany._id,
                company:newcompany.name
            }
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_USER_PROFILE&id=${newcompany.owner}`,{
                method: 'PATCH',
                body: JSON.stringify(userObj),
                headers: {'Content-Type': 'application/json'}
            })
            const json = await response.json()
            if(response.ok) {
                 //update user context
                 dispatch({type:'SET_CURRENT_USER',payload:json})
            }
            if(!response.ok){
                openSnackbar('!Ooops! failed to update user profile')
            }

          } catch (error) {
              console.log(error);
          }
    }

    useEffect(()=>{
         if(name === "" || location === ""){ 
             setDisabled(true)   }  else setDisabled(false)
    },[name,location])
  
  return (
    <div>
        <div className="auth-nav">
            <img src={logo} />
            <h2 style={{color:"#fff"}}>Kopaleo</h2>
        </div>

        <div className="auth-container">
            <h2>Add your company</h2>
            <Collapse isOpen={open} canOutsideClickClose={true} onClose={()=>close(prev=>!prev)}>
            <div className="input-bar">
                <Icon icon="office" color={"#5F6B7C"}  size={20} className='icon-auth'/>
                <input type="text" onChange={(e)=>setName(e.target.value)} placeholder="Name" />  
            </div>
            
            <div className="input-bar">
                <Icon icon="area-of-interest" color={"#5F6B7C"}  size={20} className='icon-auth'/>
                <input type="text" onChange={(e)=>setLocation(e.target.value)} placeholder="Location" />
    
            </div> 
            </Collapse>
            {errorMessage === null ? <div/> : 
			  <div className="input-bar" style={{borderWidth:0}}>
			  <Icon icon="error" color={"#b41313"}  size={20} className='icon-auth'/>
			  <p   style={{color:"#b41313"}}>{errorMessage.toString()}</p>
				 </div> }
            <button  onClick={() =>addNewMicrocredit()}style={{ backgroundColor:disabled ? "#ccc" :"#07608B",color:'#fff' }}>Add new company
              {spin ? <Spinner size={SpinnerSize.SMALL}  intent={"warning"}/> : <div />}
              </button>
        </div>
    </div>
  )
}

export default Company