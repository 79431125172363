import React,{ useContext,useState,useEffect} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {ThemeContext} from '../App'
import {Icon,Card,Popover,Position,Menu,MenuItem} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import { useSnackbar } from 'react-simple-snackbar'
import Empty from '../components/Empty'
import addDays from 'date-fns/addDays'
import { linearGradientDef } from '@nivo/core'
import { ResponsiveLine } from '@nivo/line'
import {useNavigate} from 'react-router-dom'
var moment = require('moment'); // require
moment().format();

function Dashboard() {
    const {currentUser,branches, dispatch,workers,companyLoanGraph, companyRepaymentGraph, companyExpenseGraph} = useGlobalContextHook()
    const {body } = useContext(ThemeContext)
    const {username,companyId,photoURL,company,settings} = currentUser
    const {currency} = settings
    const [open, setOpen] = useState(false)
    const [unread,setUnread] = useState(0)
    const [limit,setLimit] = useState(40)
    const [openSnackbar] = useSnackbar()
    const [data,setData] = useState([])
    const [criteria, setCriteria] = useState("active")
    const [fromDate,setFromDate] = useState(addDays(new Date(),-30).toJSON().slice(0,10))
    const [number,setNumber] = useState(30)
    const navigate = useNavigate()
   
    //branches
    useEffect(() => {
      const unSubscribe = async () => {
           try {
             const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_BRANCHES&id=${companyId}&limit=${limit}&criteria=${criteria}`)
             const json = await response.json()
              if(response.ok){
                 dispatch({type:'SET_BRANCHES',payload:json})
              }
           } catch (error) {
            openSnackbar(error)
           }
      }
      unSubscribe();
      return () =>unSubscribe();
  },[limit,criteria])

  //workers
  useEffect(() => {
    const unSubscribe = async () => {
         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_WORKERS&worker_criteria=all&limit=${limit}&id=${companyId}`)
         const json = await response.json()
         if(response.ok){
           dispatch({type:'SET_WORKERS',payload:json})
           
         }
         if(!response.ok){
          openSnackbar(json.message)
         }
    }   
    unSubscribe()
     return()=>unSubscribe();
   },[criteria,limit]) 
   
 //loan graph
useEffect(()=>{
  const unsubscribe = async () => {
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=COMPANY_LOAN_GRAPH&id=${companyId}&fromDate=${fromDate}`)
      const json = await response.json()
      if(response.ok){
         dispatch({type:'COMPANY_LOAN_GRAPH',payload:json})
      } 
      if(!response.ok){
        openSnackbar(json.message)
      }
    } catch(error){openSnackbar(error)}
  }
  unsubscribe()
  },[fromDate])  

 //repayment graph
useEffect(()=>{
  const unsubscribe = async () => {
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=COMPANY_REPAYMENT_GRAPH&id=${companyId}&fromDate=${fromDate}`)
      const json = await response.json()
      if(response.ok){
         dispatch({type:'COMPANY_REPAYMENT_GRAPH',payload:json})
      } 
      if(!response.ok){
        openSnackbar(json.message)
      }
    } catch(error){openSnackbar(error)}
  }
  unsubscribe()
  },[fromDate]) 

//expense graph  
useEffect(()=>{
  const unsubscribe = async () => {
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=COMPANY_EXPENSE_GRAPH&id=${companyId}&fromDate=${fromDate}}`)
      const json = await response.json()
      if(response.ok){
         dispatch({type:'COMPANY_EXPENSE_GRAPH',payload:json})
      } 
      if(!response.ok){
        openSnackbar(json.message)
      }
    } catch(error){openSnackbar(error)}
  }
  unsubscribe()
  },[fromDate])

//aggregate loan graphs  
useEffect(() => {
  setData([
    companyLoanGraph[0]|| {id:"Loans",data:[{x:"0",y:"0"}]},
    companyRepaymentGraph [0] || {id:"Repayments",data:[{x:"0",y:"0"}]},
    companyExpenseGraph[0] || {id:"Expenses",data:[{x:"0",y:"0"}]}
  ])
},[companyLoanGraph,companyRepaymentGraph,companyExpenseGraph])

//list unread notifications
useEffect(() => {
  const unSubscribe=async() => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_UNREAD_NOTIFICATIONS&id=${currentUser.id}&limit=${limit}`)
          const json = await response.json()
          if(response.ok){
           setUnread(json.length)
          }
          
         if(!response.ok){
            openSnackbar(json.message)
          }
        } catch (error) {
          console.log(error)
        }
  }
  unSubscribe();
  return () =>unSubscribe();
},[])

const setNewDate= (numbervar)=>{
  const date = moment.utc();
  setFromDate( date.clone().add(-numbervar,"days").format("YYYY-MM-DD"))
  setNumber(numbervar)
}

  return (
    <div className="main-wapper" >
        <TopNav /> 
      <div className="content-wrapper">
      <div className="sider" >
            <SiderContent />
        </div>
        <div className="section" style={{backgroundColor:body}}>
          <div className="hello-bar">
            <div className="hello-heading"><h1>{company}</h1><p>Hello, {username}!👋 </p></div>
            <div className="hello-far">
              <div className="bell" ><button type="button" onClick={() =>navigate('/Notifications')}style={{backgroundColor:body,border:"1px solid #dddddd"}} class="icon-button">
                <span class="material-icons">notifications</span>
              {unread <= 0 ? <div /> :  <span class="icon-button__badge">{unread}</span>}
           </button></div> 
           <div className="bell" onClick={() =>navigate('/Profile')}><div type="button" class="icon-button-img"><img src={photoURL} alt="IMG" /></div></div>
           </div>
          </div>
         <div className="overview-bar"><h2>Overview</h2>        
          <Popover isOpen={open} canClickOutsideToClose={true} onClose={()=>setOpen(false)} style={{marginLeft:'auto'}} 
           content={
              <Menu>
                  <MenuItem text="Last 3 months " onClick={()=>setNewDate(30)} />
                  <MenuItem text="Last 6 months"  onClick={()=>setNewDate(60)}/>
                  <MenuItem text="Last 9 months"  onClick={()=>setNewDate(90)}/>
                 </Menu>
                } position={Position.BOTTOM_BOTTOM}>
                  <div onClick={()=>setOpen(prev=>!prev)} className="pick-duration">Last {number} days&emsp; <Icon icon="chevron-down" color="#666" /></div> 
                  </Popover></div>
          <div className='dash-wrapper'>
            <div className='dash-section'>
              <div className="dash-cards-wrapper">
              <Card interactive={true} elevation={0} className="dash-card" onClick={()=>navigate('/Loans')}>
                <div className='dash-section-bar'><div className='dash-section-div-icon' style={{backgroundColor:"#1B9E77"}}><Icon icon="dollar" color="#fff" className="dash-b-b" /></div>
                <div className="dash-little-graph">
                  {companyLoanGraph.length <= 0 ? <div /> :
                  <ResponsiveLine
                  data={companyLoanGraph}
                  margin={{ top: 2, right: 2, bottom: 5, left: 2 }}
                  defs={[
                    linearGradientDef('gradientA', [
                      { offset: 0, color: 'inherit' },
                  { offset: 200, color: 'inherit', opacity: 0.4 },
                  ]),
                  ]}
                  fill={[{ match: '*', id: 'gradientA' }]}
                  curve="monotoneX"
                  xScale={{ type:'point'}}
                  yScale={{
                      type: 'linear',
                      min: 0,
                      max: 'auto',
                      stacked: true,
                      reverse: false
                  }}
                  
                  axisTop={null}
                  axisRight={null}
                  axisBottom={null}
                  axisLeft={null}
                  enableGridX={false}
                  enableGridY={false}
                  colors={{ scheme: 'dark2' }}
                  pointSize={0}
                  pointColor={{ theme: 'background' }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: 'serieColor' }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  areaOpacity={0.05}
                  enableArea = {true}
                  legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                  ]}
                  
                  /> }
                  </div></div>
                <h3>Loans</h3>
                <h2>{companyLoanGraph.length <= 0 ? 0 : companyLoanGraph[0].total.toLocaleString()}<span className="currency">{currency}</span></h2>
               </Card>

               <Card interactive={true} elevation={0} className="dash-card" onClick={()=>navigate('/Repayments')}>
                <div className='dash-section-bar'><div className='dash-section-div-icon' style={{backgroundColor:"#D95F02"}}><Icon icon="import" color="#fff" className="dash-b-b" /></div>
                <div className="dash-little-graph">
                  {companyRepaymentGraph.length <= 0 ? <div /> :
                                    <ResponsiveLine
                                    data={companyRepaymentGraph}
                                    margin={{ top: 2, right: 2, bottom: 5, left: 2 }}
                                    defs={[
                                      linearGradientDef('gradientA', [
                                        { offset: 0, color: 'inherit' },
                                    { offset: 200, color: 'inherit', opacity: 0.6 },
                                    ]),
                                    ]}
                                    fill={[{ match: '*', id: 'gradientA' }]}
                                    curve="monotoneX"
                                    xScale={{ type:'point'}}
                                    yScale={{
                                        type: 'linear',
                                        min: 0,
                                        max: 'auto',
                                        stacked: true,
                                        reverse: false
                                    }}
                                    
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={null}
                                    axisLeft={null}
                                    enableGridX={false}
                                    enableGridY={false}
                                    colors="#D95F02"
                                    pointSize={0}
                                    pointColor={{ theme: 'background' }}
                                    pointBorderWidth={2}
                                    pointBorderColor={{ from: 'serieColor' }}
                                    pointLabelYOffset={-12}
                                    useMesh={true}
                                    areaOpacity={0.05}
                                    enableArea = {true}
                                    legends={[
                                      {
                                          anchor: 'bottom-right',
                                          direction: 'column',
                                          justify: false,
                                          translateX: 100,
                                          translateY: 0,
                                          itemsSpacing: 0,
                                          itemDirection: 'left-to-right',
                                          itemWidth: 80,
                                          itemHeight: 20,
                                          itemOpacity: 0.75,
                                          symbolSize: 12,
                                          symbolShape: 'circle',
                                          symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                          effects: [
                                              {
                                                  on: 'hover',
                                                  style: {
                                                      itemBackground: 'rgba(0, 0, 0, .03)',
                                                      itemOpacity: 1
                                                  }
                                              }
                                          ]
                                      }
                                    ]}
                                    /> }
                  </div></div>
                <h3>Repayments</h3>
                <h2>{companyRepaymentGraph.length <= 0 ? 0 : companyRepaymentGraph[0].total.toLocaleString()}<span className="currency">{currency}</span></h2>
               </Card>
               
               <Card interactive={true} elevation={0} className="dash-card" onClick={()=>navigate('/Expenses/')}>
                <div className='dash-section-bar'><div className='dash-section-div-icon' style={{backgroundColor:"#7570B3"}}><Icon icon="export" color="#fff" className="dash-b-b" /></div>
                <div className="dash-little-graph">
                  {companyExpenseGraph.length <= 0 ? <div /> :
                                                      <ResponsiveLine
                                                      data={companyExpenseGraph}
                                                      margin={{ top: 2, right: 2, bottom: 5, left: 2 }}
                                                      defs={[
                                                        linearGradientDef('gradientA', [
                                                          { offset: 0, color: 'inherit' },
                                                      { offset: 200, color: 'inherit', opacity: 0.6 },
                                                      ]),
                                                      ]}
                                                      fill={[{ match: '*', id: 'gradientA' }]}
                                                      curve="monotoneX"
                                                      xScale={{ type:'point'}}
                                                      yScale={{
                                                          type: 'linear',
                                                          min: 0,
                                                          max: 'auto',
                                                          stacked: true,
                                                          reverse: false
                                                      }}
                                                      
                                                      axisTop={null}
                                                      axisRight={null}
                                                      axisBottom={null}
                                                      axisLeft={null}
                                                      enableGridX={false}
                                                      enableGridY={false}
                                                      colors="#7570B3"
                                                      pointSize={0}
                                                      pointColor={{ theme: 'background' }}
                                                      pointBorderWidth={2}
                                                      pointBorderColor={{ from: 'serieColor' }}
                                                      pointLabelYOffset={-12}
                                                      useMesh={true}
                                                      areaOpacity={0.05}
                                                      enableArea = {true}
                                                      legends={[
                                                        {
                                                            anchor: 'bottom-right',
                                                            direction: 'column',
                                                            justify: false,
                                                            translateX: 100,
                                                            translateY: 0,
                                                            itemsSpacing: 0,
                                                            itemDirection: 'left-to-right',
                                                            itemWidth: 80,
                                                            itemHeight: 20,
                                                            itemOpacity: 0.75,
                                                            symbolSize: 12,
                                                            symbolShape: 'circle',
                                                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                                            effects: [
                                                                {
                                                                    on: 'hover',
                                                                    style: {
                                                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                                                        itemOpacity: 1
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                      ]}
                                                      />
                  }
                  </div></div>
                <h3>Expenses</h3>
                <h2>{companyExpenseGraph.length <= 0 ? 0: companyExpenseGraph[0].total.toLocaleString()}<span className="currency">{currency}</span></h2>
               </Card>
              </div>
              <div className="dash-graph">
              <ResponsiveLine
                 data={data}
margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
defs={[
  linearGradientDef('gradientA', [
    { offset: 0, color: 'inherit' },
{ offset: 70, color: 'inherit', opacity: 0.1 },
]),
]}
fill={[{ match: '*', id: 'gradientA' }]}
curve="monotoneX"
xScale={{ type:'point'}}
yScale={{
    type: 'linear',
    min: 0,
    max: 'auto',
    stacked: true,
    reverse: false
}}

axisTop={null}
axisRight={null}

colors={{ scheme: 'dark2' }}
pointSize={0}
pointColor={{ theme: 'background' }}
pointBorderWidth={2}
pointBorderColor={{ from: 'serieColor' }}
pointLabelYOffset={-12}
useMesh={true}
areaOpacity={0.05}
enableArea = {true}
legends={[
  {
      anchor: 'bottom-right',
      direction: 'column',
      justify: false,
      translateX: 100,
      translateY: 0,
      itemsSpacing: 0,
      itemDirection: 'left-to-right',
      itemWidth: 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: 'circle',
      symbolBorderColor: 'rgba(0, 0, 0, .5)',
      effects: [
          {
              on: 'hover',
              style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
              }
          }
      ]
  }
                  ]}
                />
              </div>
            </div>
            <div className='dash-sider'>
              <div className='dash-branches-container'>
              <div className='dash-section-bar'><h2>Branches</h2><div className='dash-far-h2'>{branches.length || 0}</div></div>
                {branches.length <= 0 ?<Empty icon={"git-branch"} color="#666"
                       name="There are currently no any Branch" />: branches.map(branch=>(
                    <div className="branch-bar" style={{boxShadow:"0px 1px 0px 0px #eee"}} key={branch._id}>
                    <div className="branch-name" style={{ backgroundColor:"#07608B",color:"#fff" }}>{branch.name.charAt(0)+branch.name.charAt(1)}</div>
                      <div className="branch-title" >{branch.name} </div>
                      </div>
                ))}
              </div>
              <div className='dash-branches-container'>
              <div className='dash-section-bar'><h2>Workers</h2><div className='dash-far-h2'>{workers.length || 0}</div></div>
             {workers.length <= 0 ? <Empty icon="inherited-group" color="#666"
                       name="There are currently no any other users" /> : workers.map(worker=>(
                <div className="branch-bar" style={{boxShadow:"0px 1px 0px 0px #eee"}} >
                  <div className="branch-name" style={{ backgroundColor:"#f8f8f8",color:"#fff" }}>
                    <img src={worker.photoURL} alt="img" className="sp-img-sp" />
                    </div>
                     <div className="branch-title" >{worker.username} </div>
                    </div>
                       ))
             }
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Dashboard