import React,{useState,useEffect} from 'react'
import LandingNavbar from '../components/LandingNavbar'
import {Icon,Spinner,SpinnerSize} from '@blueprintjs/core'
import sms_img from '../img/sms.png'
import cloud_img from '../img/cloud.png'
import branch_img from '../img/branches.png'
import report_img from '../img/report.png'
import macowigo from '../img/macowigo.png'
import overview_img from '../img/overview.png'
import {useNavigate,Link as reactLink,useParams} from 'react-router-dom'
import {getAuth,signInWithEmailAndPassword} from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth';
import {useSnackbar} from 'react-simple-snackbar'
import {Link} from 'react-scroll'

const auth = getAuth()

function Landing() {
  const [user]= useAuthState(auth)
  const [isSpin,setIsSpin] = useState(false)
  const [openSnackbar] = useSnackbar()
  const navigate = useNavigate()
  const params = useParams()
  const affiliateId = params.id
  
  const getIpAddress = async() =>{
   const response = await fetch(`https://api.ipdata.co?api-key=${process.env.REACT_APP_IP_API_KEY}`)
   const json = await response.json()
   const ipAddres = json.ip
   if(response.ok){
      addNewVisitor(ipAddres)
   }
   if(!response.ok){console.log(response)}
  }

  const addNewVisitor = async(ipAddres) =>{
 /**    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=ADD_NEW_VISITOR&ip=${ipAddres}`,{
        method: 'POST',
        body: JSON.stringify({
            ip:ipAddres,
            refferalCode:affiliateId
        }),
        headers: {'Content-Type': 'application/json'} 
      })
      const json = await response.json()
      if(response.ok){console.log('welcome new visitor')}
      if(!response.ok){}
    }catch(e){
      console.log(e)
    } */
  }
  useEffect(() => {
    getIpAddress()
  },[params])

  const LoginFn = async()=>{
    try {
        setIsSpin(true);
        await signInWithEmailAndPassword(auth,'demo@mail.com', '1234567')    
         await setIsSpin(false);
         navigate('/') 
    } catch (error) {
       setIsSpin(false)
    }
}

  return (
    <div className="main-wapper" >
        <LandingNavbar /> 
      <div className="landing-page-content-wrapper">
        <div className="landing-first-container">
          <div className="landing-box-left">
            <h1> Modern Loans management software</h1>
            <p>Your micro-finance friend</p>
            <div className='landing-box-left-call-to-action-box'>
              <button type="" onClick={()=>navigate('/Signup')}>Sign up now</button>
             <Link  to="explore" spy={true} smooth={true}>
             <div className="watch-demo-video" >Explore features</div></Link> 
            </div>
          </div>
          <div className="landing-box-right">
            <img src={overview_img} alt="overview" className="play-div" />
         {/* <video controls={true}  className="play-div"
                 src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" />*/}
          </div>
        </div>

        <div className="landing-second-container" id="explore">
          <div className="landing-second-box-left">
            <h2 id="features"><span>Stay connected</span>  with your customers with our personalised and automated SMS</h2>
            <p className="landing-second-box-left-p">Receive short message to your mobile from the system when different actions are performed on the system.</p>
            <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>When new loan is released to the client.</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>When  loan repayment is made.</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Custom SMS reminder to the customer.</p>
           </div>
          </div>
          <div className="landing-second-box-right">
            <img src={sms_img} alt="sms_img" />
          </div>
        </div>

        <div className="landing-third-container">
          <div className="landing-third-box-left">
            <h2 id="features"><span>Cloud data storage</span>  All your data is safely stored online.</h2>
            <p className="landing-third-box-left-p">This makes it easy to access your data from anywhere.</p>
            <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>All loan records.</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>List of company worker</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Repayments made by your clients</p>
           </div>
          </div>

          <div className="landing-third-box-right" >
            <img src={cloud_img} alt="sms_img" />
          </div>
        </div>
    
        <div className="landing-second-container">
          <div className="landing-second-box-left">
            <h2 id="features"><span>Multiple branches support</span> Keep track of all of your company branches</h2>
            <p className="landing-second-box-left-p">Kopaleo allow you manage different branches located in different places</p>
            <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Analyze each branch report.</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Customize branch details</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Assign worker to a specific branch.</p>
           </div>
          </div>
          <div className="landing-second-box-right">
            <img src={branch_img} alt="branch_img" />
          </div>
        </div>

        <div className="landing-third-container">
          <div className="landing-third-box-left">
            <h2 id="features"><span>Generate </span> your branch report.</h2>
            <p className="landing-third-box-left-p">TKopaleo makes it easier to generate branch report on total loan released, repayments made and expensed incurred on specific branch.</p>
            <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Daily report on a specific day.</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Monthly report</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>All of these reports ca be exported as a PDF</p>
           </div>
          </div>

          <div className="landing-third-box-right" >
            <img src={report_img} alt="sms_img" />
          </div>
        </div>

        <div className="landing-more-center">
          <h1>But wait, there's more</h1>
          <div className="landing-center-wrapper">
           <div className="more-card">
            <Icon icon ="edit" className="more-card-icon" color="#666" size={20} />
             <div className="more-card-title">
              <h2>Custom company logo</h2>
               <p>Add your company logo that will appear on all PDF printed</p>
              </div> 
           </div>

           <div className="more-card">
            <Icon icon ="export" className="more-card-icon" color="#666" size={20} />
             <div className="more-card-title">
              <h2>Export loan form as PDF</h2>
               <p>All loan forms can be exported as a PDF and printed or shared</p>
              </div> 
           </div>

           <div className="more-card">
            <Icon icon ="people" className="more-card-icon" color="#666" size={20} />
             <div className="more-card-title">
              <h2>Add company workers</h2>
               <p>Once added workers can login to the system </p>
              </div> 
           </div>

           <div className="more-card">
            <Icon icon ="calendar" className="more-card-icon" color="#666" size={20} />
             <div className="more-card-title">
              <h2>Generate collection sheet</h2>
               <p>Print all matured loans as PDF for follow up</p>
              </div> 
           </div>

          </div>
        </div>
      </div>
      <div className="landing-page-footer">
          <div className="landing-page-footer-box">
            <ul>
            <li onClick={()=>navigate('/Landing')}>Features</li>
              <li onClick={()=>navigate('/Pricing')}>Pricing</li>
                <div onClick={()=>LoginFn()}><li>{isSpin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : "Demo"}</li></div>
                <a href="https://affiliate-kopaleo.web.app/"><li>Affiliate</li></a>
                <a href="mailto:support@macowigotech.com"><li>Contacts</li></a>
            </ul>
          </div>

          <div className="landing-page-footer-box-second">
          <ul>
                <a href="mailto:support@macowigotech.com"><li>Support</li></a>
                <a href="mailto:support@macowigotech.com"><li>Request feature</li></a>
                <a href="https://macowigotech.com/"><li>About us</li></a>
                <li onClick={()=>navigate('/Signup')}>Sign up</li>
            </ul>
          </div>

          <div className="landing-page-footer-box-third">
          <div className="more-card" >
            <img src={macowigo} alt="macowigo_logo" className="more-card-icon"  />
             <div className="more-card-title">
              <h2 style={{color:"#666"}}>MACOWIGO</h2>
               <p style={{color:"#666"}}>We are the technology.</p>
              </div> 
           </div>
          </div>
        </div>
    </div>  
  )
}

export default Landing