import React,{useState,useEffect,useContext} from 'react'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {ThemeContext} from '../App'
import { Collapse,Icon} from '@blueprintjs/core'
import Empty from './Empty'

function SelectBranch() {
    const { grey,card,primary,lighttext,nusery} = useContext(ThemeContext)
    const {branches,currentUser,dispatch} = useGlobalContextHook()
    const [limit,setLimit] = useState(40)
    const [criteria, setCriteria] = useState("active")
    const [open,close] = useState(false)
    const [open2,setOpen2] = useState(false)

    useEffect(() => {
        const unSubscribe = async () => {
             try {
               const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_BRANCHES&id=${currentUser.companyId}&limit=${limit}&criteria=${criteria}`)
               const json = await response.json()
                if(response.ok){
                   dispatch({type:'SET_BRANCHES',payload:json})
                   //dispatch({type:'SET_ACTIVE_BRANCH',payload:json[0]});
                }
             } catch (error) {
                console.log(error,'error on fetching branches')
             }
        }
        unSubscribe();
        return () =>unSubscribe();
    },[criteria])

    const setActiveBranch = (branch) =>{
        dispatch({type:'SET_ACTIVE_BRANCH',payload:branch}); close(false);
     }
   
     const setCriteriaFn = async(criteria)=>{setCriteria(criteria);setOpen2(false)}
  return (
    <div className="select-b-r">
    <div className="select-BAR">
    <h2 style={{marginLeft:12}}>Select Branch</h2>
        <div className="filter-div" style={{marginTop:20}}>
              <button className='filter' style={{backgroundColor:card}} onClick={()=>setOpen2(prev=>!prev)}> Sort by &emsp;{criteria}<Icon icon={open2 ? 'chevron-up':'chevron-down'}  color={primary}/>
                  </button>   
                  <Collapse isOpen={open2} canOutsideClickClose={true} onClose={()=>setOpen2(prev=>!prev)}>
                    <div className='b-col'>
                      <div className="branch-li"  onClick={()=>setCriteriaFn('active')}>Active</div>
                      <div className="branch-li" onClick={()=>setCriteriaFn('inactive')}>Inactive</div>
                      </div>
                   </Collapse>   
                </div>
    </div>
                <div className="">
                  {branches === undefined ? <div>Refresh your browser</div> : branches.length <= 0 ? <Empty  name="No braches found" icon="git-branch" color="#666"/> : branches.map(branch=>(
                        <div className="branch-bar" key={branch._id} onClick={()=>setActiveBranch(branch)}>
                        <div className="branch-name" style={{ backgroundColor:branch.color,color:lighttext }}>{branch.name.charAt(0)+branch.name.charAt(1)}</div>
                            <div className="branch-title">{branch.name} </div>
                                <div className="branch-title-status" style={{color:branch.status === "active" ? nusery:"#333"}}> {branch.status} </div>
                    </div>
                  ))}
                </div>
    </div>
  )
}

export default SelectBranch