import React from 'react'
import {BrowserRouter as Router, Routes,Route} from 'react-router-dom'
import Dashboard from '../screen/Dashboard'
import Branches from '../screen/Branches'
import Workers from '../screen/Workers'
import ViewBranch from '../screen/ViewBranch'
import ViewLoan from '../screen/ViewLoan'
import LoanScreen from '../screen/LoanScreen'
import Repayments from '../screen/Repayments'
import Expenses from '../screen/Expenses'
import Report from '../screen/Report'
import Profile from '../screen/Profile'
import Notifications from '../screen/Notifications'
import Newloan from '../screen/Newloan'
import Signup from '../screen/Signup'
import Login from '../screen/Login'
import Recovery from '../screen/Recovery'
import Proceed from '../screen/Proceed'
import Company from '../screen/Company'
import Plan from '../screen/Plan'
import Payment from '../screen/Payment'
import NotFound from '../screen/NotFound'
import CollectionSheet from '../screen/CollectionSheet'
import Editloan from '../screen/Editloan'
import Forbidden from '../screen/Forbidden'
import Receipt from '../screen/Receipt'
import Settings from '../screen/Settings'
import Learn from '../screen/Learn'
import Borrowers from '../screen/Borrowers'
import Addborrower from '../screen/Addborrower'
import EditBorrower from '../screen/EditBorrower'
import Addgroup from '../screen/Addgroup'
import EditGroup from '../screen/EditGroup'
import Accounting from '../screen/Accounting'
import GroupLoans from '../screen/GroupLoans'
import ViewGroupLoan from '../screen/ViewGroupLoan'
import EditUser from '../screen/EditUser'
import MainLoader from '../components/MainLoader'
import Chat from '../screen/chat/Chat'
import LoanProducts from '../screen/loanproduct/LoanProducts'

function MainNavigation() {
  return (
    <Routes>
        <Route path="/"  element={<Dashboard />} />
        <Route path="*"  element={<MainLoader />} />
        <Route path="/Dashboard"  element={<Dashboard />} />
        <Route path="/Branches"  element={<Branches />} />
        <Route path="/Workers"  element={<Workers />} />
        <Route path="/Branches/View"  element={<ViewBranch />} />
        <Route path="/Loans/View"  element={<ViewLoan />} />
        <Route path="/Loans"  element={<LoanScreen />} />
        <Route path="/Repayments"  element={<Repayments />} />
        <Route path="/Report"  element={<Report />} />
        <Route path="/Profile"  element={<Profile />} />
        <Route path="/Notifications"  element={<Notifications />} />
        <Route path="/Newloan"  element={<Newloan />} />
        <Route path="/Signup"  element={<Signup />} />
        <Route path="/Login"  element={<Login />} />
        <Route path="/Recover"  element={<Recovery />} />
        <Route path="/Proceed"  element={<Proceed />} />
        <Route path="/Company"  element={<Company />} />
        <Route path="/Subscription"  element={<Plan />} />
        <Route path="/Payment"  element={<Payment />} />
        <Route path="/Collection"  element={<CollectionSheet />} />
        <Route path="/EditLoan"  element={<Editloan />} />
        <Route path="/Forbidden"  element={<Forbidden />} />
        <Route path="/Receipt"  element={<Receipt />} />
        <Route path="/Expenses/"  element={<Expenses />} />
        <Route path="/Settings"  element={<Settings />} />
        <Route path="/borrowers/list"  element={<Borrowers />} />
        <Route path="/Learn"  element={<Learn />} />
        <Route path="/add-borrower"  element={<Addborrower />} />
        <Route path="/edit-borrower"  element={<EditBorrower />} />
        <Route path="/add-group"  element={<Addgroup />} />
        <Route path="/edit-group"  element={<EditGroup />} />
        <Route path="/accounting"  element={<Accounting />} />
        <Route path="/group-loans"  element={<GroupLoans />} />
        <Route path="/view-group-loan"  element={<ViewGroupLoan />} />
        <Route path="/edit-user"  element={<EditUser />} />
        <Route path="/Chat"  element={<Chat />} />
        <Route path="/loan-products"  element={<LoanProducts />} />

   </Routes>
           
  )
}

export default MainNavigation