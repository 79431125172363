import React,{useState,useEffect,useContext} from 'react'
import logo from '../img/logo.png'
import {Link,useNavigate} from 'react-router-dom'
import {Icon,Spinner,SpinnerSize} from '@blueprintjs/core'
import {getAuth,signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup} from 'firebase/auth'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import { useAuthState } from 'react-firebase-hooks/auth';

const auth = getAuth()
const provider = new GoogleAuthProvider()

function Login({fetchLoggedInUser}) {
    const [user]= useAuthState(auth)
    const {dispatch} = useGlobalContextHook()
    const navigate = useNavigate()
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [validPwd,show_pwd] = useState(null)
    const [validEmail,show_email] = useState(null)
    const [disabled,setDisabled] = useState(true)
	const [spin,stop] = useState(false)
	const [errorMessage,showErrorMessage] = useState(null)

    const LoginFn = async()=>{
        try {
            stop(true);
            const userCredential = await signInWithEmailAndPassword(auth,email, password)    
             await stop(false);
             navigate('/') 
        } catch (error) {
           showErrorMessage(error.code)
           stop(false)
        }
   }

   const signWithGoogle =async()=>{
    try {
       const userCredential = await signInWithPopup(auth, provider)
       stop(true)
       const defaultPhotoURL ='https://picsum.photos/id/237/200/300';
       const credential = await userCredential.user	
       console.log(credential.reloadUserInfo.localId,'local id')					   
       const userObj = {
          id:credential.reloadUserInfo.localId,
          createdAt: credential.reloadUserInfo.createdAt,
          email:credential.reloadUserInfo.email,
          username:credential.reloadUserInfo.displayName,
          photoURL:credential.reloadUserInfo.photoUrl,
          completed:"incomplete",
          role:"unset",
          company:"unset",
          companyId:"unset",
          branchId:"unset",
          branch:"unset",
          phoneNumber:0,
          subscription:"unset",
          started:"unset",
          ending:"unset",
          accountStatus:"active",
          worker_status:"unset",
          lastTimeLoggedIn:credential.reloadUserInfo.createdAt,
       } 

       const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?model=user&action=CREATE_NEW_USER&id=${credential.reloadUserInfo.localId}`,{
              method:'POST',
              body: JSON.stringify(userObj),
              headers: {
                   'Content-Type':'application/json'
              }

       })

       const json = await response.json()
           if(response.ok){
              dispatch({type:'SET_CURRENT_USER',payload:json})
              console.log(json)
              stop(false)
              navigate('/Company')
          } 
       if(!response.ok){
           //console.log(response)
           navigate('/')
       } 
       

    } catch (error) {
        console.log(error)
    }      
}

    useEffect(() => {
        if(
            validPwd === null || validPwd ||
            validEmail === null || validEmail ){
            setDisabled(true)
            //console.log("setDisabled is true")
        } else {
            if(!validPwd && !validEmail){
                setDisabled(false)
            }
            
        }
    },[validEmail,validPwd]);
  
    const validatePassword = (password) =>{
        if(password.length < 4){
            show_pwd(true)
            setPassword(password)
        } else {
            show_pwd(false)
            setPassword(password)
        }
    }

    const validateEmail = (email) =>{
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(!email.match(validRegex)){
            show_email(true);
            setEmail(email)
        } else {
            show_email(false)
            setEmail(email)
        }
  
    }

  return (
    <div>
        <div className="auth-container">
            <h2>Login</h2>

            <div className="input-bar">
                <Icon icon="envelope" color={"#5F6B7C"}  size={20} className='icon-auth'/>
                <input type="text" placeholder="E-mail" onChange={(e)=>validateEmail(e.target.value)} />
                {validEmail === null || validEmail === " " || email.length <= 0 ? <di/> :
               <Icon icon={validEmail ? "cross" :"tick-circle"} color={validEmail ? "#b41313": "#249E61" }  size={20} className='icon-auth'/>}
            </div>
            
                      
            <div className="input-bar">
                <Icon icon="lock" color={"#5F6B7C"}  size={20} className='icon-auth'/>
                <input type="password" placeholder="Password" onChange={(e)=>validatePassword(e.target.value)}/>
                {validPwd === null || validPwd === " " || password.length <= 0 ? <div/> :
               <Icon icon={validPwd ? "cross" :"tick-circle"} color={validPwd ? "#b41313": "#249E61" }  size={20} className='icon-auth'/>}
            </div>  

            {errorMessage === null ? <div/> : 
			  <div className="input-bar" style={{borderWidth:0}}>
			  <Icon icon="error" color={"#b41313"}  size={20} className='icon-auth'/>
			  <p   style={{color:"#b41313"}}>{errorMessage.toString()}</p>
				 </div> 
			  }

            <button  onClick={() =>LoginFn()} style={{ backgroundColor:disabled ? "#ccc" :"#07608B" }}>
			{spin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
				 Login</button>
             <Link to="/Recover" className="forgot" style={{marginLeft:'30%'}}>Forgot Password</Link> <br /><br />
             <Link to="/Signup" style={{marginLeft:20}}>Don't have an account? Signup here</Link> <br /><br />


        </div>
    </div>
  )
}

export default Login