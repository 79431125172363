import React,{ useContext, useEffect, useState } from 'react'
import TopNav from '../../components/TopNav'
import SiderContent from '../../components/SiderContent'
import { ThemeContext } from '../../App'
import { useGlobalContextHook } from '../../hook/useGlobalContextHook'
import SelectBranch from '../../components/SelectBranch'
import AccessDenied from '../../components/AccessDenied'
import { useSnackbar } from 'react-simple-snackbar'
import Empty from '../../components/Empty'
import './loanproduct.css'
import Userbar from '../../components/userbar/Userbar'

import { Icon, Position, Popover, Menu, MenuItem, Drawer, DrawerSize } from '@blueprintjs/core'

function LoanProducts() {
    const { body } = useContext(ThemeContext)
    const [openSnackbar] = useSnackbar()
    const { currentUser, activeBranch } = useGlobalContextHook()
    const { role, branch, branchId, company, companyId, _id, username } = currentUser
    const { permissions } = currentUser
    const { loans:loansPermissions } = permissions 
    const [ isDrawerOpen, setIsDrawerOpen ] = useState(false)
    const [ isEditDrawerOpen, setIsEditDrawerOpen ] = useState(false)
    const [name, setName] = useState("")
    const [minAmount, setMinAmount] = useState(0)
    const [maxAmount, setMaxAmount] = useState(0)
    const [interest, setInterest] = useState(0)
    const [loanProducts, setLoanProducts] = useState([])
    const [editedLoanProduct, setEditedLoanProduct] = useState(null)
  
    const addNewLoanProduct = async()=>{
      try { 
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=ADD_LOAN_PRODUCT`,{
          method: "POST",
          body:JSON.stringify({
            name:name,
            maxAmount:maxAmount,
            minAmount:minAmount,
            interest:interest,
            company: company,
            companyId: companyId,
            branch:branch,
            branchId: branchId,
            createdBy: _id,
            createByName: username
          }),
          headers: {'Content-Type': 'application/json'}
        })

        const json = await response.json()
        if(response.ok) {
          openSnackbar('New loan product added successfull')
          clearInputs()
        }
        if(!response.ok) {
          openSnackbar('Failed to add loan product')
        }
      } catch (error) {
        openSnackbar('Error during adding new loan product')
      }
    }

    const listLoanProducts = async() => {
      try { 
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_LOAN_PRODUCTS&id=${companyId}`)
        const json = await response.json()
        if(response.ok) {
          setLoanProducts(json)
        }
        if(!response.ok) {
          openSnackbar('Failed to list loan product')
        }
      } catch (error) {
        openSnackbar('Error during lising  loan products')
      }
    }

    const searchLoanProducts = async(keyword) => {
      try { 
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SEARCH_LOAN_PRODUCTS&id=${companyId}&keyword=${keyword}`)
        const json = await response.json()
        if(response.ok) {
          setLoanProducts(json)
        }
        if(!response.ok) {
          openSnackbar('Failed to list loan product')
        }
      } catch (error) {
        openSnackbar('Error during lising  loan products')
      }
    }

    const saveLoanProductChanges = async() => {
      try { 
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=SAVE_LOAN_PRODUCT&id=${companyId}`,{
          method: "PATCH",
          body:JSON.stringify({
            name:name,
            maxAmount:maxAmount,
            minAmount:minAmount,
            interest:interest,
            company: company,
            companyId: companyId,
            branch:branch,
            branchId: branchId,
            createdBy: _id,
            createByName: username
          }),
          headers: {'Content-Type': 'application/json'}
        })

        const json = await response.json()
        if(response.ok) {
          openSnackbar('Loan product saved successfull')
          clearInputs()
        }
        if(!response.ok) {
          openSnackbar('Failed to save loan product')
        }
      } catch (error) {
        openSnackbar('Error during saving loan product')
      }
    }

    const clearInputs = () => {
      setInterest(0); setName(""); setMaxAmount(0); setMinAmount(0);
      setIsDrawerOpen(false);  setIsEditDrawerOpen(false); listLoanProducts()
    }

    useEffect(() => {
      listLoanProducts()
    },[])

    const editLoanProduct = (loanProduct) => {
         setIsEditDrawerOpen(true)
          setEditedLoanProduct(loanProduct)
          setInterest(loanProduct.interest)
          setName(loanProduct.name) 
          setMaxAmount(loanProduct.maxAmount)
          setMinAmount(loanProduct.minAmount)
    }

    const removeLoanProduct = async(loanProduct) => {
      try { 
        const { _id: loanProductId } = loanProduct
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/remove?action=REMOVE_LOAN_PRODUCT&id=${loanProductId}`,{
          method: 'DELETE'        
        })
        const json = await response.json()
        if(response.ok) {
          openSnackbar('Loan product removed successfully')
          listLoanProducts()
        }
        if(!response.ok) {
          openSnackbar('Failed to remove loan product')
        }
      } catch (error) {
        openSnackbar('Error during removing  loan products')
      }
    }

  return (
    <div className="main-wapper" >
         <TopNav />
    <div className="content-wrapper">
    <div className="sider" >
            <SiderContent />
        </div>
        <div className="section" style={{backgroundColor:body}}> 
          {!loansPermissions[0].isGranted? <AccessDenied /> : 
            <div>
               { activeBranch === null && role === "company_admin" ? <SelectBranch /> : 
                 activeBranch !== null ? 
                 <div>
                  <Userbar title="Loan products" />
                  <div className='loan-product-wrapper'> 
                  <div className="searchbar">
                    <input type="text" placeholder="Search loan product"  onChange={(e)=> searchLoanProducts(e.target.value)}/>
                    <div className="sortbar">
                       <button type="button" id="touchable" className="button-primary" onClick={(e)=>setIsDrawerOpen(prev=>!prev)}>New loan product</button>
                    </div>
                  </div>

        <div className="table1" id="table-1">
        <div className="table1-header">
          <div className="header-title">Name</div>
          <div className="header-title">Min Amount</div>
          <div className="header-title">Max Amount</div>
          <div className="header-title">Interest rate</div>
          <div className="header-title">Menu</div>
        </div>

        <div className="table1-row-wrapper">
              { loanProducts.length <= 0 ? <Empty /> : loanProducts.map(product => (
                <div className="table1-cell-holder">
                    <div className="table1-cell" >{ product.name }</div>  
                        <div className="table1-cell">{ product.minAmount.toLocaleString() }</div>   
                            <div className="table1-cell">{ product.maxAmount.toLocaleString() }</div>   
                              <div className="table1-cell">{ product.interest }%</div>   
                                <div className="table1-cell">
                                <Popover content={
                                    <Menu>
                                      <MenuItem icon="edit" text="Edit" onClick={() => editLoanProduct(product)} />
                                      <MenuItem icon="trash" text="Remove" onClick={() => removeLoanProduct(product)} />
                                    </Menu>              
                                  } position={Position.BOTTOM_BOTTOM}>
                                    <div className="table-menu" id="touchable">
                                    <Icon icon="more"  color="#666"  size={22}/></div> 
                              </Popover>
                        </div>
                   </div>               
              ))}
          
           </div>
        </div>
                  </div>
                </div>
                  : <div></div> }
            <Drawer 
              isOpen={isDrawerOpen} 
              title="New loan product"
              titleTextColor="#333"
              position={Position.RIGHT}
              size={window.innerWidth > 1000 ? DrawerSize.STANDARD :  DrawerSize.LARGE} 
              canClickOutsideToClose={true} onClose={()=>setIsDrawerOpen(prev=>!prev)}>
                <div className="new-loan-product">
                <div class="input-box">
                    <p>Name  <span>*</span></p>
                    <input type="text" onChange={(e)=> setName(e.target.value)} />
                </div>

                <div class="input-box">
                    <p>Minimum amount  </p>
                    <input type="text"  value={minAmount} onChange={(e)=> setMinAmount(e.target.value)} />
                </div>

                <div class="input-box">
                    <p>Maximum amount  </p>
                    <input type="text"  value={maxAmount} onChange={(e)=> setMaxAmount(e.target.value)}/>
                </div>

                
                <div class="input-box">
                    <p>Interest rate </p>
                    <input type="text" value={interest} onChange={(e)=> setInterest(e.target.value)} />
                </div>

                <div className="input-box">
                    <button id="touchable" onClick={()=>setIsDrawerOpen(false)}>Cancel</button>   
                      <button id="touchable" className="button-primary" onClick={()=>addNewLoanProduct(true)}>Save</button>
                    </div>
                </div>
              </Drawer>

              <Drawer 
              isOpen={isEditDrawerOpen} 
              title="Edit loan product"
              titleTextColor="#333"
              position={Position.RIGHT}
              size={window.innerWidth > 1000 ? DrawerSize.STANDARD :  DrawerSize.LARGE} 
              canClickOutsideToClose={true} onClose={()=>setIsEditDrawerOpen(prev=>!prev)}>
                <div className="new-loan-product">
                <div class="input-box">
                    <p>Name  <span>*</span></p>
                    <input type="text" onChange={(e)=> setName(e.target.value)} />
                </div>

                <div class="input-box">
                    <p>Minimum amount  </p>
                    <input type="text"  value={minAmount} onChange={(e)=> setMinAmount(e.target.value)} />
                </div>

                <div class="input-box">
                    <p>Maximum amount  </p>
                    <input type="text"  value={maxAmount} onChange={(e)=> setMaxAmount(e.target.value)}/>
                </div>

                
                <div class="input-box">
                    <p>Interest rate </p>
                    <input type="text" value={interest} onChange={(e)=> setInterest(e.target.value)} />
                </div>

                <div className="input-box">
                    <button id="touchable" onClick={()=>setIsEditDrawerOpen(false)}>Cancel</button>   
                      <button id="touchable" className="button-primary" onClick={()=>saveLoanProductChanges(true)}>Save changes</button>
                    </div>
                </div>
              </Drawer>
            </div> }
        </div>
    </div>
    </div>
  )
}

export default LoanProducts