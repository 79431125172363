import React,{useState,useEffect} from 'react'

function Falling() {
  const [show,hide] = useState(true)
 
useEffect(()=>{
     setTimeout(()=>{
       hide(false)
     },5000)
  },[])
  return (
    <div class="col-3">
           {show ?  <div class="dot-falling"></div> : <div>0</div>}
      </div>
  )
}

export default Falling