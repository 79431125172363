import React,{useState,useEffect} from 'react'
import {Icon,Popover,Position,Menu} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import { useSnackbar } from 'react-simple-snackbar'
import Empty  from '../components/Empty'
import format from 'date-fns/format'
var moment = require('moment'); // require
moment().format();

function ProfitAndLoss({branchId, branch}) {
  const {currentUser,dispatch} = useGlobalContextHook()
  const {settings, companyId} = currentUser
  const {currency} = settings
  const [totalLoansReleased,setLoansReleased] = useState(0)
  const [totalRepayments2,setTotalRepayments2] = useState(0)
  const [totalIncomeAccounts2,setTotalIncomeAccounts2] = useState(0)
  const [totalVariableCost2,setTotalVariableCost2] = useState(0)
  const [totalFixedCost2,setTotalFixedCost2] = useState(0)
  const [totalExpenseAccounts2,setTotalExpenseAccounts2] = useState(0)

  const [totalRepayments,setTotalRepayments] = useState(0)
  const [totalVariableCost,setTotalVariableCost] = useState(0)
  const [totalFixedCost,setTotalFixedCost] = useState(0)
  const [totalIncomeAccounts,setTotalIncomeAccounts] = useState(0)
  const [totalExpenseAccounts,setTotalExpenseAccounts] = useState(0)
  const [openSnackbar] = useSnackbar()
  const [comparativePeriod,setComparativePeriod] = useState('range')
  const date = moment.utc();
  const [fromDate,setFromDate] = useState(date.clone().add(-10,"days").format("YYYY-MM-DD"))
  const [toDate,setToDate] = useState(date.clone().add(1,"days").format("YYYY-MM-DD"))

  const [fromDate2,setFromDate2] = useState(date.clone().add(-10,"days").format("YYYY-MM-DD"))
  const [toDate2,setToDate2] = useState(date.clone().add(1,"days").format("YYYY-MM-DD"))

  const[ DifferenceGrossProfit,setDifferenceGrossProfit] = useState(0)
  const [firstPeriodGrossProfitState,setFirstPeriodGrossProfitState] = useState(0)
  const [secondPeriodGrossProfitState,setSecondPeriodGrossProfitState] = useState(0)


  const setToDateFn =(date)=>{
    let newDate = moment.utc(date)
    setToDate(newDate.clone().add(1,"days").format("YYYY-MM-DD"))     
}

const setToDateFn2 =(date)=>{
  let newDate = moment.utc(date)
  setToDate2(newDate.clone().add(1,"days").format("YYYY-MM-DD"))     
}

const listTransactionsFn =async(transactions)=>{
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_TRANSACTIONS&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
    const json = await response.json()
      if(response.ok) {
        dispatch({type:'SET_TRANSACTIONS',payload:json})
      }
      if(!response.ok) {
        openSnackbar('Failed to list transactions')
      }

  } catch (e) {
    openSnackbar('Error while getting transaction list')
  }
  }

  const loansReleased = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=REPORT&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
      const json = await response.json()
      if(response.ok) {
       if(json.length > 0) {  setLoansReleased(json[0].loans)} else {}
      }
      if(!response.ok){
        openSnackbar(json.message)
      }
    } catch (e) {
      openSnackbar(e)
    }
  }

  const repayments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=REPAYMENTS_REPORT&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
      const json = await response.json()
      if(response.ok) {
          if(json.length > 0) {setTotalRepayments(json[0].repayments)} else {
            setTotalRepayments(0)
          }
    
      }
      if(!response.ok){
        openSnackbar(json.message)
      }
    } catch (e) {
      openSnackbar(e)
    }
  }
 
  const listTotalVariableCost = async () => {
    try{
      if(branchId === null){
      } else {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_VARIABLE_COST&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
        const json = await response.json()
          if(response.ok){
              if(json.length > 0){ setTotalVariableCost(json[0].totalVariableCoast)} else {
                 setTotalVariableCost(0)
              }
          } 
          if(!response.ok){
            openSnackbar('Failed to fetch cost of sales')
          }
      }
    } catch(error){
      openSnackbar('Failed to fetch expenses')
     }
    }
   
  const listTotalFixedCost = async () => {
      try{
        if(branchId === null){
        } else {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_FIXED_COST&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
          const json = await response.json()
            if(response.ok){
                if(json.length > 0){ setTotalFixedCost(json[0].totalFixedCoast)} else {
                  setTotalFixedCost(0)
                }
            } 
            if(!response.ok){
              openSnackbar('Failed to fetch cost of sales')
            }
        }
      } catch(error){
        openSnackbar('Failed to fetch expenses')
       }
     } 
      
  const listTotalIncomeAccounts = async () => {
      try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=TOTAL_INCOME_ACCOUNTS&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
          const json = await response.json()
          if(response.ok) {
           if(json.length > 0) {  
             setTotalIncomeAccounts(json[0].amount)
          } else {
              setTotalIncomeAccounts(0)
            }
          }
          if(!response.ok){
            openSnackbar(json.message)
          }
        } catch (e) {
          openSnackbar(e)
      }
   }  

   const listTotalExpenseAccounts = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_TOTAL_EXPENSE_ACCOUNTS&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
        const json = await response.json()
        if(response.ok) {
         if(json.length > 0) {  
           setTotalExpenseAccounts(json[0].amount)
        } else {
            setTotalExpenseAccounts(0)
           }
        }
        if(!response.ok){
          openSnackbar(json.message)
        }
      } catch (e) {
        openSnackbar(e)
    }
 }   

 //second period
 const secondPeriodRepayments = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SECOND_PERIOD_REPAYMENTS_REPORT&id=${branchId}&fromDate2=${fromDate2}&toDate2=${toDate2}`)
    const json = await response.json()
    if(response.ok) {
        if(json.length > 0) {setTotalRepayments2(json[0].repayments)} else {
          setTotalRepayments2(0)
        }
  
    }
    if(!response.ok){
      openSnackbar(json.message)
    }
  } catch (e) {
    openSnackbar(e)
  }
}

const listTotalIncomeAccounts2 = async () => {
  try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SECOND_PERIOD_TOTAL_INCOME_ACCOUNTS&id=${branchId}&fromDate2=${fromDate2}&toDate2=${toDate2}`)
      const json = await response.json()
      if(response.ok) {
       if(json.length > 0) {  
         setTotalIncomeAccounts2(json[0].amount)
      } else {
          setTotalIncomeAccounts2(0)
        }
      }
      if(!response.ok){
        openSnackbar(json.message)
      }
    } catch (e) {
      openSnackbar(e)
  }
  }  

const listTotalVariableCost2 = async () => {
  try{
    if(branchId === null){
    } else {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SECOND_PERIOD_LIST_VARIABLE_COST&id=${branchId}&fromDate2=${fromDate2}&toDate2=${toDate2}`)
      const json = await response.json()
        if(response.ok){
            if(json.length > 0){ setTotalVariableCost2(json[0].totalVariableCoast)} else {
               setTotalVariableCost2(0)
            }
        } 
        if(!response.ok){
          openSnackbar('Failed to fetch cost of sales')
        }
    }
  } catch(error){
    openSnackbar('Failed to fetch expenses')
   }
  }
   
 const listTotalFixedCost2 = async () => {
    try{
      if(branchId === null){
      } else {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SECOND_PERIOD_LIST_FIXED_COST&id=${branchId}&fromDate2=${fromDate2}&toDate2=${toDate2}`)
        const json = await response.json()
          if(response.ok){
              if(json.length > 0){ setTotalFixedCost2(json[0].totalFixedCoast)} else {
                setTotalFixedCost2(0)
              }
          } 
          if(!response.ok){
            openSnackbar('Failed to fetch cost of sales')
          }
      }
    } catch(error){
      openSnackbar('Failed to fetch expenses')
     }
  } 

 const listTotalExpenseAccounts2 = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SECOND_PERIOD_LIST_TOTAL_EXPENSE_ACCOUNTS&id=${branchId}&fromDate2=${fromDate2}&toDate2=${toDate2}`)
        const json = await response.json()
        if(response.ok) {
         if(json.length > 0) {  
           setTotalExpenseAccounts2(json[0].amount)
        } else {
            setTotalExpenseAccounts2(0)
           }
        }
        if(!response.ok){
          openSnackbar(json.message)
        }
      } catch (e) {
        openSnackbar(e)
    }
 }

 const getCompanyInfo = async()=>{
  try{
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_USER_BY_COMPANY_ID&id=${companyId}`)
    const json = await response.json()
    if(response.ok){
      printProfitAndLoss(json[0])
    }
    if(!response.ok){
      openSnackbar(json.message)
    }
  }catch(error){openSnackbar("Error getting company")}
}

const printProfitAndLoss =async(companyInfo)=>{
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=PRINT_PROFIT_AND_LOSS&id=${branchId}&branch=${branch}&fromDate=${fromDate}&toDate=${toDate}`,{
      method: 'POST',
      body: JSON.stringify({ 
        companyInfo:companyInfo,
        totalRepayments:totalRepayments,
        totalIncomeAccounts:totalIncomeAccounts,
        totalVariableCost:totalVariableCost,
        firstPeriodGrossProfitState:firstPeriodGrossProfitState,
        totalFixedCost:totalFixedCost,
        totalExpenseAccounts:totalExpenseAccounts
      }),
      headers: {'Content-Type': 'application/json'}
    })

    if(response.ok){
      const file = await response.blob()
      const createdFile =  await window.URL.createObjectURL(new Blob([file]), { type: 'application/pdf'})
      const link = document.createElement('a');
      link.href = createdFile;
      link.download =`Profit and Loss/Income statement from${fromDate}=>${toDate}.pdf`
      link.click();
    }
  
    if(!response.ok){
      openSnackbar("Error creating pdf")
    }
  } catch(e){
    console.log(e)
     openSnackbar('Error during printing ledger')
  }
}

useEffect(() => {
  secondPeriodRepayments()
  listTotalIncomeAccounts2()
  listTotalVariableCost2()
  listTotalFixedCost2()
  listTotalExpenseAccounts2()
},[branchId,fromDate2,toDate2])

//obtain  first period  data
useEffect(() => {
  listTransactionsFn()
  loansReleased()
  repayments()
  listTotalFixedCost()
  listTotalVariableCost()
  listTotalIncomeAccounts()
  listTotalExpenseAccounts()
},[branchId,fromDate,toDate])

//calculate movement btn first and second periods
useEffect(() => {
  movementInGrossProfit()
},[totalRepayments,totalIncomeAccounts,totalVariableCost,totalRepayments,totalIncomeAccounts,totalVariableCost])

const movementInGrossProfit = () => {
  let firstPeriodGrossprofit = (Number(totalRepayments)+Number(totalIncomeAccounts)) - Number(totalVariableCost)
  let secondPeriodGrossprofit = (Number(totalRepayments2)+Number(totalIncomeAccounts2)) - Number(totalVariableCost2)
  let difference = Number(firstPeriodGrossprofit) - Number(secondPeriodGrossprofit)
  setDifferenceGrossProfit(difference)
  setFirstPeriodGrossProfitState(firstPeriodGrossprofit)
  setSecondPeriodGrossProfitState(secondPeriodGrossprofit)
}

  return (
    <div className="ledger-wrapper">
      <div className="ledger-bar">
        <h2>Profit and Loss</h2>
        <div className="ledger-far">
        <Popover content=
         {
            <Menu>
                <p className='container-p'>Filter options </p>
                <div className="date-picker">
                  <div className="from" style={{marginLeft:15}}>
                    <p>From</p>
                    <input type="date" value={fromDate} placeholder="Date" onChange={(e)=>setFromDate(e.target.value)} />
                  </div> <br />
                  <div className="from" style={{marginLeft:15}}>
                    <p>To</p> 
                    <input type="date" value={toDate} placeholder="Date" onChange={(e)=>setToDateFn(e.target.value)}  />
                  </div>
                  <button className="just-button" style={{margin:15}} onClick={()=> getCompanyInfo()} id="touchable">Export PDF</button>
              </div>
            </Menu>
            
           } position={Position.BOTTOM_BOTTOM}>
          <div className="ledger-far" id="touchable">
          <Icon icon="filter-list" color="#666" size={24} />
        </div> 
        </Popover>   
        </div>
      </div>
      <div className="ledger-info">
          <h3>{format(new Date(fromDate),'dd/MMMM/yyyy')} - {format(new Date(toDate),'dd/MMMM/yyyy')}</h3>
        </div>
        <select onChange={(e)=>setComparativePeriod(e.target.value)} className="form-control-select">
          <option value="range">Range</option>
          <option value="comparison">Comparison</option>
        </select>
        {comparativePeriod === 'comparison' ? 
        <div className='periods-wrapper'>
          <div className='first-period'>
            <p>First period</p>
            <input type="date"  value={fromDate} placeholder="Date" onChange={(e)=>setFromDate(e.target.value)}/> =&gt;
            <input type="date"  value={toDate} placeholder="Date" onChange={(e)=>setToDateFn(e.target.value)}/>
          </div>
          <div className='first-period'>
            <p>Second period</p>
            <input type="date"  value={fromDate2} placeholder="Date" onChange={(e)=>setFromDate2(e.target.value)}/> =&gt;
            <input type="date"  value={toDate2} placeholder="Date" onChange={(e)=>setToDateFn2(e.target.value)}/>
          </div>
        </div>: <div />}


        <div className="account-box">
            <div className="accountbox-bar"><h3>Revenue</h3> <p></p></div>
            {comparativePeriod === 'comparison' ?            
             <div className="transaction-table">
              <div className="transaction-table-row">
              <div className="transaction-table-row-title">Name</div>
              <div className="transaction-table-row-title">First period</div>
              <div className="transaction-table-row-title">Second period</div>
              </div>

                     
          <div className="transaction-table-wrapper">
          {totalRepayments <= 0 ? <div /> : <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell">Loans repayments&emsp;</div>
                  <div className="transaction-table-row-cell">{totalRepayments.toLocaleString()}<span className='currency'>{currency}</span></div>
                  <div className="transaction-table-row-cell">{totalRepayments2.toLocaleString()} <span className='currency'>{currency}</span></div>
                  {/*<div className="transaction-table-row-cell">{( Number(totalRepayments) - Number(totalRepayments2)).toLocaleString()} <span className='currency'>{currency}</span></div>*/}
                </div>}

          {totalIncomeAccounts <= 0 ? <div /> : <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell">Income accounts&emsp;</div>
                  <div className="transaction-table-row-cell">{totalIncomeAccounts.toLocaleString()}<span className='currency'>{currency}</span></div>
                  <div className="transaction-table-row-cell">{totalIncomeAccounts2.toLocaleString()}<span className='currency'>{currency}</span></div>
                  {/*<div className="transaction-table-row-cell">{
                    (Number(totalIncomeAccounts) - Number(totalIncomeAccounts2)).toLocaleString()
                  }<span className='currency'>{currency}</span></div>*/}
                </div>} 

 
          {totalVariableCost <= 0 ? <div /> : <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{marginLeft:10}}>Cost of sale/variable costs&emsp;</div>
                  <div className="transaction-table-row-cell">{totalVariableCost.toLocaleString()} <span className='currency'>{currency}</span></div>
                  <div className="transaction-table-row-cell">{totalVariableCost2.toLocaleString()} <span className='currency'>{currency}</span></div>
                {/*      <div className="transaction-table-row-cell">{
                    ( Number(totalVariableCost) - Number(totalVariableCost2)).toLocaleString()
                  }<span className='currency'>{currency}</span></div> */}
                </div>}

                <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{color:'#222'}}>GROSS PROFIT/LOSS&emsp;</div>
                  <div className="transaction-table-row-cell" style={{color:firstPeriodGrossProfitState < 0 ? '#B44C4C': '#666'}}>{
                      firstPeriodGrossProfitState.toLocaleString() }<span className='currency'>{currency}</span></div>
                  
                  <div className="transaction-table-row-cell" style={{color:secondPeriodGrossProfitState < 0 ? '#B44C4C': '#666'}}>{
                    secondPeriodGrossProfitState.toLocaleString()}<span className='currency'>{currency}</span></div>
                 {/**  <div className="transaction-table-row-cell">{DifferenceGrossProfit.toLocaleString()}<span className='currency'>{currency}</span></div> */}
                </div>

                {totalFixedCost <= 0 ? <div /> : <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{marginLeft:14}}>Operating expenses&emsp;</div>
                  <div className="transaction-table-row-cell">{totalFixedCost.toLocaleString()} <span className='currency'>{currency}</span></div>
                  <div className="transaction-table-row-cell">{totalFixedCost2.toLocaleString()} <span className='currency'>{currency}</span></div>
                 {/**  <div className="transaction-table-row-cell">{
                    (Number(totalFixedCost) - Number(totalFixedCost2)).toLocaleString()
                  }<span className='currency'>{currency}</span></div> */}
                </div>}

                {totalExpenseAccounts <= 0 ? <div /> : <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{marginLeft:14}}>Other expenses accounts&emsp;</div>
                  <div className="transaction-table-row-cell">{totalExpenseAccounts.toLocaleString()} <span className='currency'>{currency}</span></div>
                  <div className="transaction-table-row-cell">{totalExpenseAccounts2.toLocaleString()}<span className='currency'>{currency}</span></div>
                  {/**<div className="transaction-table-row-cell">{
                     (Number(totalExpenseAccounts) - Number(totalExpenseAccounts2)).toLocaleString()
                  }<span className='currency'>{currency}</span></div> */}
                </div>}

                <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{color:'#222'}}>OPERATING PROFIT/LOSS&emsp;</div>
                  <div className="transaction-table-row-cell">
                    {
                      //Gross profit/loss                                                                       //Total overhead cost
                     ( ((Number(totalRepayments) + Number(totalIncomeAccounts)) - Number(totalVariableCost)) -  (Number(totalFixedCost) + Number(totalExpenseAccounts))  ).toLocaleString()
                    }
                    <span className='currency'>{currency}</span></div>

                    <div className="transaction-table-row-cell">
                    {
                      //Gross profit/loss                                                                       //Total overhead cost
                     ( ((Number(totalRepayments2) + Number(totalIncomeAccounts2)) - Number(totalVariableCost2)) -  (Number(totalFixedCost2) + Number(totalExpenseAccounts2))  ).toLocaleString()
                    }
                    <span className='currency'>{currency}</span></div>
                  {/**
                  <div className="transaction-table-row-cell">{
                                    ((Number(totalRepayments) + Number(totalIncomeAccounts)) - Number(totalVariableCost)) -  (Number(totalFixedCost) + Number(totalExpenseAccounts))        
                  } <span className='currency'>{currency}</span></div> */}
                </div>

                <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{color:'#222'}}>GROSS PROFIT MARGIN&emsp;</div>
                  <div className="transaction-table-row-cell">           
                    { 
                      //total product sales                                   //cost of goods sold            //total product sales
                   ( ((Number(totalRepayments) + Number(totalIncomeAccounts)) - Number(totalVariableCost)) /  (Number(totalRepayments) + Number(totalIncomeAccounts)) ).toFixed(2)
                    } %
                  </div>

                  <div className="transaction-table-row-cell">           
                    { 
                      //total product sales                                     //cost of goods sold            //total product sales
                   ( ((Number(totalRepayments2) + Number(totalIncomeAccounts2)) - Number(totalVariableCost2)) /  (Number(totalRepayments2) + Number(totalIncomeAccounts2)) ).toFixed(2)
                    } %
                  </div>
                 
                </div>

                
                <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{color:'#222'}}>NET PROFIT/LOSS&emsp;</div>
                  <div className="transaction-table-row-cell" style={{color:
                  ( (Number(totalRepayments) + Number(totalIncomeAccounts)) - Number(totalVariableCost) - (Number(totalVariableCost) + Number(totalFixedCost) + Number(totalExpenseAccounts)) ) < 0 ? '#B44C4C' :'#222'
                       }}>
                  { 
                    //Gross profit                                                                         //TOTAL EXPENSES 
                 ( (Number(totalRepayments) + Number(totalIncomeAccounts)) - Number(totalVariableCost) - (Number(totalVariableCost) + Number(totalFixedCost) + Number(totalExpenseAccounts)) ).toLocaleString()
                     } <span className='currency'>{currency}</span>
                  </div>

                  <div className="transaction-table-row-cell" style={{color:
                  ( (Number(totalRepayments2) + Number(totalIncomeAccounts2)) - Number(totalVariableCost2) - (Number(totalVariableCost2) + Number(totalFixedCost2) + Number(totalExpenseAccounts2)) ) < 0 ? '#B44C4C' :'#222'
                       }}>
                  { 
                    //Gross profit                                                                         //TOTAL EXPENSES 
                 ( (Number(totalRepayments2) + Number(totalIncomeAccounts2)) - Number(totalVariableCost2) - (Number(totalVariableCost2) + Number(totalFixedCost2) + Number(totalExpenseAccounts2)) ).toLocaleString()
                     } <span className='currency'>{currency}</span>
                  </div>
                </div>
            </div>


            
              </div> : comparativePeriod === 'range' ?  
              <div className="transaction-table">
            <div className="transaction-table-row">
              <div className="transaction-table-row-title">Name</div>
              <div className="transaction-table-row-title"></div>
              <div className="transaction-table-row-title"></div>
              <div className="transaction-table-row-title">Amount </div>
              </div>

                     
          <div className="transaction-table-wrapper">
            {/* RANGE PROFIT AND LOSS*/}  
                {totalRepayments <= 0 ? <div /> : <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell">Loans repayments&emsp;</div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell">{totalRepayments.toLocaleString()}<span className='currency'>{currency}</span></div>
                </div>}

                {totalIncomeAccounts <= 0 ? <div /> : <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell">Income accounts&emsp;</div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell">{totalIncomeAccounts.toLocaleString()}<span className='currency'>{currency}</span></div>
                </div>}  


                {totalVariableCost <= 0 ? <div /> : <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{marginLeft:30}}>Cost of sale/variable costs&emsp;</div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell">{totalVariableCost.toLocaleString()} <span className='currency'>{currency}</span></div>
                </div>}

                <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{color:'#222'}}>GROSS PROFIT/LOSS&emsp;</div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell" style={{color:Number(totalRepayments) - Number(totalVariableCost) < 0 ? '#B44C4C': '#666'}}>{
                      ((Number(totalRepayments)+Number(totalIncomeAccounts)) - Number(totalVariableCost)).toLocaleString() }<span className='currency'>{currency}</span></div>
                </div>

                {totalFixedCost <= 0 ? <div /> : <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{marginLeft:30}}>Operating expenses&emsp;</div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell">{totalFixedCost.toLocaleString()} <span className='currency'>{currency}</span></div>
                </div>}
                {totalExpenseAccounts <= 0 ? <div /> : <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{marginLeft:30}}>Other expenses accounts&emsp;</div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell">{totalExpenseAccounts.toLocaleString()} <span className='currency'>{currency}</span></div>
                </div>}

                <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{color:'#222'}}>OPERATING PROFIT/LOSS&emsp;</div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell">
                    {
                      //Gross profit/loss                                                                       //Total overhead cost
                     ( ((Number(totalRepayments) + Number(totalIncomeAccounts)) - Number(totalVariableCost)) -  (Number(totalFixedCost) + Number(totalExpenseAccounts))  ).toLocaleString()
                    }
                    <span className='currency'>{currency}</span></div>
                </div>
                <div className="transaction-table-cell-row" style={{border:0}}>
                <div className="transaction-table-row-cell"  style={{border:0}}></div>
                  <div className="transaction-table-row-cell" style={{border:0}}></div>
                  <div className="transaction-table-row-cell" style={{border:0}}></div>
                  <div className="transaction-table-row-cell" style={{border:0}}></div>
                </div>
                <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{color:'#222'}}>GROSS PROFIT MARGIN&emsp;</div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell">
                     
                    { 
                      //total product sales                                   //cost of goods sold            //total product sales
                   ( ((Number(totalRepayments) + Number(totalIncomeAccounts)) - Number(totalVariableCost)) /  (Number(totalRepayments) + Number(totalIncomeAccounts)) ).toFixed(2)
                    } %
                  </div>
                </div>

                <div className="transaction-table-cell-row">
                  <div className="transaction-table-row-cell" style={{color:'#222'}}>NET PROFIT/LOSS&emsp;</div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell" style={{color:
                  ( (Number(totalRepayments) + Number(totalIncomeAccounts)) - Number(totalVariableCost) - (Number(totalVariableCost) + Number(totalFixedCost) + Number(totalExpenseAccounts)) ) < 0 ? '#B44C4C' :'#222'
                       }}>

                    { 
                    //Gross profit                                                                         //TOTAL EXPENSES 
        ( (Number(totalRepayments) + Number(totalIncomeAccounts)) - Number(totalVariableCost) - (Number(totalVariableCost) + Number(totalFixedCost) + Number(totalExpenseAccounts)) ).toLocaleString()
                     } <span className='currency'>{currency}</span>
                  </div>
                </div>
            </div>
              </div>: <div>Choose period type</div>            
              
               }
        </div>
    </div>
  )
}

export default ProfitAndLoss