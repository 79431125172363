import React,{useState,useEffect,useContext} from 'react'
import Empty  from '../components/Empty'
import {ThemeContext} from '../App'
import { useNavigate } from 'react-router-dom';
import {useSnackbar} from 'react-simple-snackbar'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {Icon,Popover,Menu,MenuItem,Drawer,Position,DrawerSize} from '@blueprintjs/core'

function GroupBorrowers({branch,branchId}) {
  const [openSnackbar] = useSnackbar()
  const [isDrawerOpen,setIsDrawerOpen] = useState(false)
  const [openedGroup,setOpenedGroup] = useState(null)
  const { borrowersGroups,dispatch,currentUser} = useGlobalContextHook()
  const {permissions} = currentUser
  const {borrowers: borrowersPermissions} = permissions
  const {lightgrey} = useContext(ThemeContext)
  const navigate = useNavigate()

  const searchGroup = async(keyword)=>{
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SEARCH_BORROWERS_GROUP&id=${branchId}&keyword=${keyword}`)
      const json = await response.json()
      if(response.ok){
        dispatch({type:'SET_BORROWERS_GROUPS',payload:json})
      }
      if(!response.ok){
        openSnackbar('Failed to find any groups')
      }
    } catch(err){
      openSnackbar(`Error during searching borrowers,refresh your page!`)
    }
  }

  const listBorrowersGroups = async()=>{
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_GROUPS&id=${branchId}`)
      const json = await response.json()
      if(response.ok){
        dispatch({type:'SET_BORROWERS_GROUPS',payload:json})
      }
      if(!response.ok){
        openSnackbar('Failed to list groups')
      }
    } catch(err){
      openSnackbar(`Error during list borrowers,refresh your page!`)
    }
  }

  const editGroup = (group)=>{
    navigate("/edit-group",{state:group})
  }

  const removeGroup = async(clickedMember)=>{
    try{
      const {_id,name} = clickedMember
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/remove?action=DELETE_GROUP&id=${_id}`,{
        method: 'DELETE'
      })
      if(response.ok){
        openSnackbar(`${name} removed successfully!`)
        listBorrowersGroups()
      }
      if(!response.ok){
         openSnackbar('Failed to remove group!')
      }

    } catch(err){
      openSnackbar('Failed to remove group!')
    }
  }

  const openGroup = (group)=>{
    setOpenedGroup(group)
    setIsDrawerOpen(true)
  }

  useEffect(() => {
    listBorrowersGroups()
  },[branchId])

  return (
    <div className="borrowers-wrapper">
        <h3>Selected branch:{branch}</h3>
    <div className="borrowers-wapper">
      <div className='search-bar' style={{marginLeft:'1%',marginTop:'0px'}}>
      <div className="search-input" style={{display:'inline-flex'}}>
        <input type="text"  placeholder="Search group's name"  style={{width:'60%',marginRight:15}}  onChange={(e)=>searchGroup(e.target.value)}/>
        {borrowersPermissions[0].isGranted ? <div className="add-new-group" onClick={()=>navigate('/add-group')}> + Create group</div> : <div /> } 
        </div> 
        </div>

        <div className="table1">
        <div className="table1-header">
          <div className="header-title">Name</div>
          <div className="header-title">Residence</div>
          <div className="header-title">Members</div>
          <div className="header-title">Branch</div>
          <div className="header-title">Menu</div>
        </div>

        <div className="table1-row-wrapper">
        {borrowersGroups.length <= 0 ? <Empty icon="people" name="Currently,No any groups" color="#666" /> : 
          borrowersGroups.map(group =>(
          <div className="table1-cell-holder">
          <div className="table1-cell" onClick={()=>openGroup(group)}>{group.name}</div>
          <div className="table1-cell">{group.residence}</div>   
          <div className="table1-cell">{group.members.length}</div>   
          <div className="table1-cell">{group.branch}</div>    
           <div className="table1-cell">
           <Popover content={
               <Menu>
                   <p className='container-p'>Options </p>
                  <MenuItem    icon='eye-open' text="View" onClick={()=>openGroup(group)} />
                  {borrowersPermissions[2].isGranted ?  <MenuItem    icon='edit' text="Edit" onClick={()=>editGroup(group)} />: <div />}
                 {borrowersPermissions[3].isGranted ?  <MenuItem    icon='trash' text="Remove" onClick={()=>removeGroup(group)} /> : <div />}
               </Menu>
               
              } position={Position.BOTTOM_BOTTOM}>
                <div className="table-menu" id="touchable">
               <Icon icon="more"  color="#666"  size={22}/></div> 
        </Popover>
              </div>
          </div>))
        }
        </div>

        <div className="table-footer">
             <div className="footer-title">Showing <strong> 1-{borrowersGroups.length}</strong>  </div>
              <div className="footer-items">
                rows per page 
                <select>
                <option value="20">20</option>
                <option value="20">50</option>
                <option value="20">100</option>
                <option value="20">200</option>
                </select>
              </div>
            <div className="footer-far-right">
            <div className="footer-next">
                   <div onClick={()=>{}} id="touchable">Prev</div>
               </div>  
               <div className="footer-next">
                   <div onClick={()=>{}} id="touchable">Next</div>
            </div></div>                       
             </div>
        </div>

        <Drawer className="borrower-sider-wrapper"
        isOpen={isDrawerOpen} 
        title="Group details"
        titleTextColor="#fff"
        position={Position.RIGHT}
        size={window.innerWidth > 1000 ? DrawerSize.STANDARD :  DrawerSize.LARGE} 
        canClickOutsideToClose={true} onClose={()=>setIsDrawerOpen(prev=>!prev)}>
      {openedGroup === null ? <div/> :
        <div>
              <div className="info-rail"  style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Group name</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedGroup.name}</div>
             </div>

             <div className="info-rail"  style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Residence</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedGroup.residence}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Description</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedGroup.description}</div>
             </div>

             
             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Branch</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedGroup.branch}</div>
             </div>

             <div className="info-rail" style={{borderWidth:0}}>
                <Icon icon="home" color={'#666'}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Members</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{openedGroup.members.length}</div>
             </div>

             {openedGroup.members.length <= 0 ? <div /> : openedGroup.members.map(member=>(
                    <div className="borrower-bar" id="touchable" key={member._id}>
                        <img src={member.passportImage}  alt="img" className=""/>
                            <div className="borrower-titles">
                            <h5>{member.fullName}</h5>
                            <h6>{member.phoneNumber}</h6>
                            </div>
                    </div>
                ))}
          </div>}
      </Drawer>
    </div>
   </div> 
  )
}

export default GroupBorrowers