import React,{useContext,useState,useEffect} from 'react'
import {ThemeContext} from '../App'
import { Icon,Collapse,Dialog} from '@blueprintjs/core'
import Attachbox  from '../components/Attachbox'
import { deleteDoc,doc } from 'firebase/firestore'
import db  from '../firebase/config'
import {storage} from '../firebase/config'
import {ref,deleteObject,getStorage} from 'firebase/storage'
import { useSnackbar } from 'react-simple-snackbar'
import {useNavigate} from  'react-router-dom'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import format from 'date-fns/format'


function OtherTab({loan}) {
  const {  lightgrey,card,primary} = useContext(ThemeContext)
  const {currentUser} = useGlobalContextHook()
  const {username,company} = currentUser
  const {permissions} = currentUser
  const {loans:loansPermissions,borrowers:borrowersPermissions} = permissions
  const {settings} = currentUser
  const {currency} = settings
  const [open, close] = useState(false)
  const {attachments,docId,releasedDate,fullName,phoneNumber} = loan
  const  [sms,setSms] = useState(`Habari ${fullName}, ${company} tunakukumbushwa kufikisha marejesho ya mkopo wako uliokopa ${format(new Date(releasedDate),'dd/MMMM/yyyy')} Ahsante`)
  const formattedReleasedDate = releasedDate
  const [view,setView] = useState(false)
  const [disabled,setDisabled] = useState(true)
  const [openSnackbar] = useSnackbar()
  const navigate = useNavigate()

  
  const downloadAttachment = (url) => {
    window.open(url, '_blank')
    
    // This can be downloaded directly:
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      const blob = xhr.response;
    };
    xhr.open('GET', url);
    xhr.send();
  }

  useEffect(() => {
    if(sms === ""){
       setDisabled(true)
    } else { setDisabled(false)}
},[sms])

  const deleteAttachments = async() => {
          try {
              if(attachments.length > 0) {
                  attachments.forEach(attach => {
                      let fileRefFromUrl = ref(storage,attach.url);
                         deleteObject(fileRefFromUrl);
                         openSnackbar("Attachment deleted")

                  })
              } else {
                openSnackbar("No attachments to delete")
              }
          } catch (error) {
             console.log(error)
          }
  }

 const editLoan = async() => {
    try {
      await navigate(`/Editloan`,{state:{...loan,releasedDateObj:formattedReleasedDate}})
    } catch (error) {
       console.log(error)
   }
}
const downloadFormLoan = async(companyOwner)=>{
try {
  const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=PRINT_LOAN_FORM&logo=${companyOwner.photoURL}&email=${companyOwner.email}&currency=${currency}&phone=${companyOwner.phoneNumber}&username=${username}`,{
    method: 'POST',
    body: JSON.stringify({...loan,logo:companyOwner.photoURL}),
    headers: {'Content-Type': 'application/json'}
  })
  if(response.ok){
    const file = await response.blob()
    const createdFile =  await window.URL.createObjectURL(new Blob([file]), { type: 'application/pdf'});
    const link = document.createElement('a');
    link.href = createdFile;
    link.download = fullName+" form.pdf";
    link.click();

  }

  if(!response.ok){
    openSnackbar("Error creating pdf")
  }
} catch (error) {
    console.log(error)
  }
}

const getCompanyInfo = async()=>{
  try{
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_USER_BY_COMPANY_ID&id=${loan.companyId}`)
    const json = await response.json()
    if(response.ok){
      downloadFormLoan(json[0])
    }
    if(!response.ok){
      openSnackbar(json.message)
    }
  }catch(error){openSnackbar("Error getting company")}
}

const sendSMS = async() => {
  try{
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=SEND_SMS&phone=${phoneNumber}`,{
        method:'POST',
        body: JSON.stringify({message:sms}),
        headers:{'Content-Type': 'application/json'}
    })
    const json = await response.json()
    if(response.ok) {
      openSnackbar("SMS sent to the client")
      setView(false)}
    if(!response.ok){console.log(response)}
}catch(e){
    openSnackbar("Failed to send sms")
    console.log(e)
}
}

  return (
    <div>
          <div className="triad-menu">
          {loansPermissions[1].isGranted ? <div class="first-triad"id="triad" style={{backgroundColor:card}} onClick={()=>close(prev=>!prev)}>
                        <Icon icon="download" className="triad-icon" size={24} color={lightgrey} />
                              <h3>Loan attachment files</h3>
                              
                        </div>: <div />}
                      {borrowersPermissions[1].isGranted ?  <div class="second-triad" id="triad"style={{backgroundColor:card}} onClick={()=>setView(prev=>!prev)}>
                            <Icon icon="chat" className="triad-icon" size={24}  color={lightgrey}/>
                                   <h3>Send SMS reminder</h3>
                        </div>: <div />}
                        

                        {loansPermissions[1].isGranted ?   <div class="third-triad"id="triad" style={{backgroundColor:card}} onClick={()=>getCompanyInfo()}>
                            <Icon icon="print" className="triad-icon" size={24} color={lightgrey} />
                              <h3>Print form document</h3>
                        </div>  : <div />}

                        {/*<div class="third-triad" style={{backgroundColor:card}} onClick={()=>deleteLoan()}>
                            <Icon icon="trash" className="triad-icon" size={24} color={lightgrey} />
                              <h3>Delete loan</h3>
                      </div>*/}
                   </div> 
                   <Collapse isOpen={open} canOutsideClickClose={true} onClose={()=>close(prev=>!prev)}>
                                <div>
                                {attachments.length <= 0 ? <div style={{textAlign: 'left',fontWeight:'bold'}}>This loan have no any attachments</div> :
                                  <div>
                                     {attachments.map((attachment, index) =>(
                                         <Attachbox key={index} info={attachment} removeAttachment={downloadAttachment} icon="download" name={"Download"} />  
                                       ))}
                                  </div>}
                                </div>
                                </Collapse>

                   <Dialog isOpen={view} canOutsideClickClose={true} onClose={()=>setView(prev=>!prev)}>  
                   <h3 style={{marginLeft:15}}>SMS </h3>
                   <textarea className="sms-input" value={sms} onChange={(e)=>setSms(e.target.value)}>
                   </textarea>
                   <button  className="sms-send-btn"style={{ backgroundColor:disabled ? "#ccc" :primary }} onClick={()=>sendSMS()} >Send sms</button>
                   </Dialog>           
    </div>
  )
}

export default OtherTab