import React,{useState,useEffect} from 'react'
import { useSnackbar } from 'react-simple-snackbar'
import Empty from '../components/Empty'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {Icon,Dialog,MenuItem,Menu,Collapse,Spinner,SpinnerSize,Popover,Position} from '@blueprintjs/core'

function AccountsList({branchId,branch}) {
  const [isDialogOpen,setIsDialogOpen] = useState(false)
  const [isDialogOpen2,setIsDialogOpen2] = useState(false)
  const [isEditDialogOpen,setIsEditDialogOpen] = useState(false)
  const {currentUser,accounts,dispatch} = useGlobalContextHook()
  const {id,company,companyId,settings} = currentUser
  const {currency} = settings
  const [openSnackbar] = useSnackbar()
  const [isCollapsed,setIsCollapsed] = useState(false)
  const [isSortCollapsed,setIsSortCollapsed] = useState(false)
  const [selectedAccountType,setSelectedAccountType] = useState({id:"asset","name":"Asset"})
  const [isDisabled,setIsDisabled] = useState(true)
  const [isEditDisabled,setIsEditDisabled] = useState(true)
  const [isSpinning,setIsSpinning] = useState(false)
  const [accountName,setAccountName] = useState("")
  const [accountNumber,setAccountNumber] = useState("")
  const [description,setDescription] = useState("")

  const [newSelectedAccountType,setNewSelectedAccountType] = useState(null)
  const [newAccountName,setNewAccountName] = useState("")
  const [newAccountNumber,setNewAccountNumber] = useState("")
  const [newDescription,setNewDescription] = useState("")
  const [pickedAccount,setPickedAccount] = useState(null)
  const [keyword,setKeyword] = useState("")
  const [sortBy,setSortBy] = useState("all")
  const [accountTypes,setAccountTypes] = useState([
    {id:"asset",name:"Asset"},
    {id:"liability",name:"Liability"},
    {id:"equity",name:"Equity"},
    {id:"income",name:"Income"},
    {id:"expense",name:"Expense"}
  ])
  const [sortOptions] = useState([
    {id:"asset"},
    {id:"liability"},
    {id:"equity"},
    {id:"income"},
    {id:"expense"}
  ])
  const [selectedSortOption,setSelectedSortOption] = useState({id:'all'}) 

  //close the dropdown list of account
  useEffect(() => {
    setIsCollapsed(false)
  },[selectedAccountType,newSelectedAccountType])


  //validate new account
  useEffect(() => {
    if(accountName === "" || accountNumber === "" || description === "" ){
       setIsDisabled(true)
    } else setIsDisabled(false)
  },[accountName,accountNumber,description])

  //list accounts
  useEffect(() => {
    listAccounts()
  },[branchId])

 //close dropdwown for sort Options
 useEffect(()=>{
   setIsSortCollapsed(false)
 },[selectedSortOption])

//sort fetching options
useEffect(()=>{
  if(selectedSortOption.id === "all") { listAccounts()} else {
    sortAndSearchFn()
  }
},[selectedSortOption,keyword])

  //validate new edit account
useEffect(() => {
    if(newAccountName === "" || newAccountNumber === "" || newDescription === "" ){
       setIsEditDisabled(true)
    } else setIsEditDisabled(false)
  },[newAccountName,newAccountNumber,newDescription])
 
  const addAccount =async()=>{
    setIsSpinning(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=ADD_ACCOUNT`,{
        method: 'POST',
        body: JSON.stringify({
          name:accountName,
          accountNumber:accountNumber,
          description:description,
          accountType:selectedAccountType.id,
          createdBy:id,
          company:company,
          companyId:companyId,
          branch:branch,
          branchId:branchId,
          status:"active"
        }),
        headers: {'Content-Type': 'application/json'}
      })
      const json = await response.json()
      if(response.ok){
        openSnackbar(`${accountName} is added succcessfully`)
        setAccountName(""); setAccountNumber(""); setDescription("");
        setIsDialogOpen(false); setIsSpinning(false); listAccounts(); setSelectedSortOption({id:"all"})
      }
      if(!response.ok){
        openSnackbar(`Failed to add account!`)
        setIsSpinning(false)
      }
    } catch(e) {
      openSnackbar(`Error during adding account`)
    }
  } 
  
  const listAccounts = async()=>{
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_ACCOUNTS&keyword=${keyword}&id=${branchId}`)
      const json = await response.json()
      if(response.ok){
        dispatch({type:'SET_ACCOUNTS',payload:json})
      }
      if(!response.ok){
        openSnackbar(`Failed to list branches`)
      }
    } catch(e){
      openSnackbar(`Error during listing accounts, Check your internet connection`)
    }
  }

  const sortAndSearchFn = async()=>{
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_ACCOUNTS_SORT&accountType=${selectedSortOption.id}&keyword=${keyword}&id=${branchId}`)
      const json = await response.json()
      if(response.ok){
        dispatch({type:'SET_ACCOUNTS',payload:json})
      }
      if(!response.ok){
        openSnackbar(`Failed to list branches`)
      }
    } catch(e){
      openSnackbar(`Error during listing accounts, Check your internet connection`)
    }
  } 
  const openAccount=()=>{}

  const editAccount=(account)=>{
    setPickedAccount(account)
    setNewAccountName(account.name)
    setNewAccountNumber(account.accountNumber)
    setNewDescription(account.description)
    setNewSelectedAccountType({id:account.accountType})
    setIsEditDialogOpen(true)
  }

  const removeAccount=async(account)=>{
    setPickedAccount(account)
    setIsDialogOpen2(true)
  }

  const saveAccountDetails=async()=>{
     setIsSpinning(true)
     try {
      const {name} = pickedAccount
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_ACCOUNT&id=${pickedAccount._id}`,{
        method: 'PATCH',
        body: JSON.stringify({
          name:newAccountName,
          accountNumber:newAccountNumber,
          description:newDescription,
          accountType:newSelectedAccountType.id,
          createdBy:id,
          company:company,
          companyId:companyId,
          branch:branch,
          branchId:branchId,
          status:"active"
        }),
        headers: {'Content-Type': 'application/json'}
      })
      const json = await response.json()
      if(response.ok){
        listAccounts(); setSelectedSortOption({id:"all"})
        setIsSpinning(false)
        setIsEditDialogOpen(false)
        openSnackbar(`${name} updated successfully`)
      }
      if(!response.ok){
        openSnackbar("Failed to update the account")
        console.log(json.message)
      }
     } catch(e){
       openSnackbar(`Error during saving account details`)
       console.log(e)
     }
  }

  const archieveAccount = async()=>{
    try {
      const {name} = pickedAccount
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=ARCHIEVE_ACCOUNT&id=${pickedAccount._id}`,{
        method: 'PATCH'
      })
      const json = await response.json()
      if(response.ok){
        openSnackbar(`${name} Archieved`)
        setIsDialogOpen2(false); listAccounts(); setSelectedSortOption({id:"all"})
      }
      if(!response.ok){
        openSnackbar("Failed to achieve the account")
        console.log(json.message)
      }
     } catch(e){
       openSnackbar(`Error during achieving account details`)
       console.log(e)
     }
  }
  return (
    <div className="charts-wrapper">
      <div className="location-bar">
        <h1 className="clinic-h1" style={{paddingLeft:10}}>Charts of Accounts</h1>
        <div className="clinic-far" id="touchable-primary" onClick={()=>setIsDialogOpen(prev=>!prev)}>
          <Icon icon="plus"  color="#fff" /> 
          {window.innerWidth > 1000 ? "Add New Account": "New Account"}
          </div>
        </div>
        <div className="clinic-options">
        <input type="text" placeholder="Search" onChange={(e)=>setKeyword(e.target.value)} /> 
        <div className="clinic-middle-section">
        <div>
        <Popover content={          
          <div>
            <Menu style={{marginLeft:10}}>
            <MenuItem text="All accounts" className="link2" id="link2"  onClick={()=>setSelectedSortOption({id:"all"})}/>
            {sortOptions.length <= 0 ? <div /> : sortOptions.map(option =>(
                <MenuItem text={option.id.replace(/^./, str => str.toUpperCase())} className="link2" id="link2" 
                    onClick={() =>setSelectedSortOption(option)} 
                    key={option.id} />
            ))}
            </Menu>
        </div>
              } position={Position.BOTTOM_BOTTOM}>
              <div className="middle-action" id="touchable">
            <Icon icon="sort"  color="#666"  size={20}  className="middle-icon"/>
              Sort by {selectedSortOption.id.charAt(0).toUpperCase()}{selectedSortOption.id.slice(1)}
             <Icon icon={isSortCollapsed ? "chevron-up":"chevron-down"}  color="#666"  size={20} className="middle-icon"/>
          </div>
        </Popover>
        </div>
        </div>
       </div>
        <div className="table1" id="table-1">
        <div className="table1-header">
          <div className="header-title">Name</div>
          <div className="header-title">Account type</div>
          <div className="header-title">Account number</div>
          <div className="header-title">Menu</div>
        </div>

        <div className="table1-row-wrapper">
        {accounts.length <= 0 ? <Empty icon="bank-account" name="Currently,No any accounts" color="#666" /> : 
          accounts.map(account =>(
          <div className="table1-cell-holder" key={account._id}>
          <div className="table1-cell" >{account.name}</div>  
          <div className="table1-cell">{account.accountType.charAt(0).toUpperCase()}{account.accountType.slice(1)}</div>   
          <div className="table1-cell">{account.accountNumber}</div>    
           <div className="table1-cell">
           <Popover content={
               <Menu>
                   <p className='container-p'>Options </p>
                 <div>{/** <MenuItem    icon='edit' text="Edit" onClick={()=>editAccount(account)} /> */}</div>
               </Menu>
               
              } position={Position.BOTTOM_BOTTOM}>
                <div className="table-menu" id="touchable">
               <Icon icon="more"  color="#666"  size={22}/></div> 
        </Popover>
              </div>
          </div>))
        }
        </div>
        </div>

        <Dialog isOpen={isDialogOpen}
            title=""
            style={{width:window.innerWidth > 1000 ? "50%": '90%',overflow:'auto',backgroundColor:'#fff'}}
            canOutsideClickClose={true} onClose={()=>setIsDialogOpen(prev=>!prev)}>
            <h1 className="new-acc-h1">Add New Account</h1>
            <div className="tab-2" id="tab-2">
            <div className="ln-box" id="ln-box">
                <p>Account type</p>
                <Menu id="menu">
                <MenuItem text={selectedAccountType.name} className="link2" onClick={() =>setIsCollapsed(prev=>!prev)} 
                  labelElement={<Icon icon={isCollapsed ? "chevron-up":"chevron-down"} size={20} style={{paddingRight:15}}/>}
                  />
                </Menu>
                <Collapse isOpen={isCollapsed} canOutsideClickClose={true} onClose={()=>setIsCollapsed(prev=>!prev)}>
                <Menu id="menu">
                  {accountTypes.length <= 0 ? <div /> : accountTypes.map(account=>(
                      <MenuItem text={account.name} className="link2" id="link2" 
                         onClick={() =>setSelectedAccountType(account)} 
                         key={account.id}
                      />
                  ))}
                  </Menu>
                </Collapse>
                </div>
                
              <div className="ln-box" id="ln-box">
                <p>Account Name</p>
                 <input type="text" placeholder="Enter a name" onChange={(e)=>setAccountName(e.target.value)} />
              </div>

              <div className="ln-box" id="ln-box">
                <p>Account Number</p>
                 <input type="text" placeholder="Enter a number" onChange={(e)=>setAccountNumber(e.target.value)} />
              </div>

              <div className="ln-box" id="ln-box" style={{marginTop:0}}>
                 <input type="text" placeholder="Add description" onChange={(e)=>setDescription(e.target.value)} />
              </div>

            </div>  
            <div className="just-button"  
              onClick={() =>addAccount()}
              id={isDisabled ? "disabled-button": "not-disabled"} style={{marginLeft:'7%'}}>
                      {isSpinning ? 'Adding....': 'Add Account'}
                      {isSpinning ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
                  </div>
        </Dialog>

        <Dialog isOpen={isEditDialogOpen}
            title=""
            style={{width:window.innerWidth > 1000 ? "50%": '90%',overflow:'auto',backgroundColor:'#fff'}}
            canOutsideClickClose={true} onClose={()=>setIsEditDialogOpen(prev=>!prev)}>
            <h1 className="new-acc-h1"><Icon icon="edit" color="#666" />&emsp;Edit Account Details</h1>
            {pickedAccount === null ? <div /> : <div className="tab-2" id="tab-2">
            <div className="ln-box" id="ln-box">
                <p>Account type</p>
                <Menu id="menu">
                <MenuItem text={newSelectedAccountType.id} className="link2" onClick={() =>setIsCollapsed(prev=>!prev)} 
                  labelElement={<Icon icon={isCollapsed ? "chevron-up":"chevron-down"} size={20} style={{paddingRight:15}}/>}
                  />
                </Menu>
                <Collapse isOpen={isCollapsed} canOutsideClickClose={true} onClose={()=>setIsCollapsed(prev=>!prev)}>
                <Menu id="menu">
                  {accountTypes.length <= 0 ? <div /> : accountTypes.map(account=>(
                      <MenuItem text={account.name} className="link2" id="link2" 
                         onClick={() =>setNewSelectedAccountType(account)} 
                         key={account.id}
                      />
                  ))}
                  </Menu>
                </Collapse>
                </div>
                
              <div className="ln-box" id="ln-box">
                <p>Account Name</p>
                 <input type="text" value={newAccountName} placeholder="Enter a name" onChange={(e)=>setNewAccountName(e.target.value)} />
              </div>

              <div className="ln-box" id="ln-box">
                <p>Account Number</p>
                 <input type="text" value={newAccountNumber} placeholder="Enter a number" onChange={(e)=>setNewAccountNumber(e.target.value)} />
              </div>

              <div className="ln-box" id="ln-box" style={{marginTop:0}}>
                 <input type="text" value={newDescription} placeholder="Add description" onChange={(e)=>setNewDescription(e.target.value)} />
              </div>

            </div>  }
            <div className="just-button"  
              onClick={() =>saveAccountDetails()}
              id={isEditDisabled ? "disabled-button": "not-disabled"} style={{marginLeft:'7%'}}>
                      {isSpinning ? 'Saving....': 'Save changes'}
                      {isSpinning ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
                  </div>
        </Dialog>

        <Dialog isOpen={isDialogOpen2}
            title="Archieve Account"
            style={{width:window.innerWidth > 1000 ? "50%": '90%',overflow:'auto',backgroundColor:'#fff'}}
            canOutsideClickClose={true} onClose={()=>setIsDialogOpen2(prev=>!prev)}>
            {pickedAccount === null ? <div /> : 
              <div className="alert-wrapper">
                <p>Do you want to  Archive <strong>{pickedAccount.name}</strong>  ?Just remember the account will still  appear on reports but this account will no longer appear in the Chart of Accounts</p>
              <div className="two-buttons" style={{display: 'inline-flex',marginLeft:'0%'}}>
              <div className="just-button"  
                  onClick={() =>setIsDialogOpen2(false)}
                  id="disabled-button">Cancel   
                  </div>
                  <div className="just-button"  
                  onClick={() =>archieveAccount()}
                  id="not-disabled">Archieve  
                  </div>
              </div>
                </div>}
        </Dialog>
    </div>
  )
}

export default AccountsList