import React,{useState,useEffect} from 'react'
import {Icon,Spinner,SpinnerSize,Popover,Position,Menu,MenuItem} from '@blueprintjs/core'
import { useSnackbar } from 'react-simple-snackbar'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import format from 'date-fns/format'
import Empty from '../components/Empty'
import Falling from '../components/Falling'
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import addDays from 'date-fns/addDays'
import AccessDenied from '../components/AccessDenied'
const uniqueId = require('react-unique-nums')
var moment = require('moment'); // require
moment().format();

function FinancesTab({branchId,branch}) {
  const {currentUser,dispatch,expenses,activeBranch,lastTenDaysGraph} = useGlobalContextHook()
  const {companyId,company,username,id,photoURL,settings} = currentUser
  const {permissions} = currentUser
  const {expenses:expensesPermissions} = permissions
  const {currency} = settings
  const [expenseName,setName] = useState("")
  const [lastTenDays] =  useState(addDays(new Date(),-10).toJSON().slice(0,10))
  const [sum,setSum] = useState(0)
  const [expenseAmount,setAmount] = useState(0)
  const [disabled,setDisabled] = useState(true)
  const [openSnackbar] = useSnackbar()
  const [spin,stop] = useState(false)
  const [oneBranch,setOneBranch] = useState([])
	const [errorMessage,showErrorMessage] = useState(null)
  const [datePicked,setDatePicked] = useState(new Date(0).toJSON().slice(0, 10))
  const [Limit,setLimit] = useState(15)
  const [amountGraphData,setAmountgraphData] = useState([])
  const [dateGraphData,setdategraphData] = useState([])
  const [Color,setColor] = useState("#07608B")
  const [expenseCategory,setExpenseCategory] = useState('cost_of_sale')

  useEffect(() => { 
        if (Number(expenseAmount) <= 0 || expenseAmount === "" || expenseName === "") {
             setDisabled(true); 
        } else setDisabled(false)},[expenseName,expenseAmount])

  const addExpense = async() =>{
         try{
            stop(true)
             const expObj = {
              branch:branch,
              branchId:branchId,
              company:company,
              companyId:companyId,
              expenseAmount:Number(expenseAmount),
              expenseName:expenseName,
              loanOfficer:id,
              loanOfficerName:username,
              photoURL:photoURL,
              color:Color,   
              category:expenseCategory           
             }
             const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=ADD_EXPENSE`,{
              method: 'POST',
              body: JSON.stringify(expObj),
              headers: {'Content-Type': 'application/json'}
             })
             const json = await response.json()
             if(response.ok){
              stop(false); setName(""); setAmount(0); setDisabled(true)
                 await incrementExpense(json)
             }

             if(!response.ok){
              console.log(response,'error on adding expense')
             }
         } catch (error) {
           console.log(error,'on adding expense')
           showErrorMessage("Error on adding new expense")
         }
    }   
   
  const incrementExpense = async()=>{
     try{
     const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=INCREMENT_EXPENSE&id=${branchId}&amount=${Number(expenseAmount)}`,{
      method:'PATCH'
     })
     const json = await response.json()
     if(response.ok){
         openSnackbar("New expense added")
         setLimit(prev=>prev + 1)
     }
     if(!response.ok){
       showErrorMessage("ERROR: Failed to update expense details")
     }
     } catch(error){
     showErrorMessage('on incrementing expensens')
     }
  }  

   useEffect(() => {
    listExpenses()
   },[datePicked,Limit,branchId]);

  const deleteExpense = async(exp) =>{
  try{
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/remove?action=REMOVE_EXPENSE&id=${exp._id}`,{
      method:'DELETE'
    })

    const json = await response.json()
    if(response.ok){
      await  deductExpenses(exp)
    }

    if(!response.ok){
      openSnackbar(`failed to remove expense`)
    }
  }catch(error){
      openSnackbar('error on deleting expense')
  }
  }

  const listExpenses = async () => {
  try{
    if(branchId === null){
    } else {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_EXPENSES&id=${branchId}&date=${datePicked}`)
      const json = await response.json()
        if(response.ok){
           dispatch({type:'SET_EXPENSES',payload:json})
        } else {
          openSnackbar(`Could not fetch expense`)
        }
    }
  } catch(error){
    openSnackbar('Failed to fetch expenses')
  }
  }

  const approveExpense =async(expense)=>{
  try {
    const {_id} = expense
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=APPROVE_EXPENSE&id=${_id}`,{
      method: 'PATCH'
    })
    if(response.ok){
      openSnackbar(`Expense approved successfully`)
      listExpenses()
    }
    if(!response.ok) {
      openSnackbar(`Failed to approve expense`)
    }
  } catch(error){
    openSnackbar('error while approving expense')
  }
 }

 const disApproveExpense =async(expense)=>{
  try {
    const {_id} = expense
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=DIS_APPROVE_EXPENSE&id=${_id}`,{
      method: 'PATCH'
    })
    if(response.ok){
      openSnackbar(`Expense dis-approved successfully`)
      listExpenses()
    }
    if(!response.ok) {
      openSnackbar(`Failed to dis-approve expense`)
    }
  } catch(error){
    openSnackbar('error while dis-approving expense')
  }
 }

const deductExpenses= async(exp)=>{
  try{
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=DECREMENT_EXPENSE&id=${branchId}&amount=${Number(exp.expenseAmount)}`,{
     method:'PATCH'
    })
    const json = await response.json()
    if(response.ok){
        openSnackbar("Expense removed")
        setLimit(prev=>prev + 1)
    }
    if(!response.ok){
     openSnackbar("Failed to add expense, incorrect inputs")
    }
    } catch(error){
    showErrorMessage('on decrementing expensens')
    }
}
useEffect(()=>{
  if(expenses.length <= 0 ) {} else {
     const newArry = []
     const datesNewArry = []
        expenses.map(exp=>{
          newArry.push(exp.expenseAmount)
          datesNewArry.push(format(new Date(exp.createdAt),'dd MMM'))
          })
      
     setAmountgraphData(newArry.length > 10 ? newArry.slice(newArry.length - 1,10) : newArry)
     setdategraphData(datesNewArry.length > 10 ? datesNewArry.slice(newArry.length - 1,10) : datesNewArry)
  }
},[expenses])
 
useEffect(()=>{
  if(expenses === undefined) {} else { if(expenses.length <= 0){} else {
     let sum = expenses.reduce((accumulator,object)=>{
       return accumulator + object.expenseAmount 
     },0); setSum(sum)
  
  }}
},[expenses]);

useEffect(() => {
const unsubscribe = async () => {
  try{
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LAST_TEN_DAYS_EXPENSES&id=${branchId}&date=${lastTenDays}`)
    const json = await response.json()
    if(response.ok){
      dispatch({type:'SET_LAST_TEN_DAYS',payload:json})
    } else {
      openSnackbar(json.message)
    }
  } catch(error){
    openSnackbar(error)
  }
}
unsubscribe()
},[activeBranch,expenses])

  return (
   <div>
    {expensesPermissions[1].isGranted ? 
        <div className="-ps-wr">
        <div className="stat-box">
           <div className="stat-h3" style={{ backgroundColor:"#7570B3"}}><Icon icon="export" style={{marginTop:-8}} color={"#fff"} size={30} className="exp-icon-icon"/></div>
               <div className="stat-para"><h3 className="stat-h4">EXPENSES</h3><p>{branch}</p></div>
               <div><h3>{sum <= 0 ? <Falling /> : sum.toLocaleString()}<span className="currency">{currency}</span></h3></div>
                     </div>
 
 <div className="e-main">
     <div className="e-section">
       <div className="e-t-bar">
           <h2>Recent</h2>
           <input type="date" placeholder="" value={datePicked} onChange={(e)=>setDatePicked(e.target.value)} /> 
       </div>
           {expenses.length <= 0 ? <Empty  name="No expenses available " icon="export" color="#666" /> : 
             expenses.map(exp =>(
               <div className="e-bar" key={exp._id}>
               <div className="e-img">
                 <Icon icon ="tag" size={20} color={exp.color === undefined ? "#07608B": exp.color} className="img"/>
                 </div>
                 
           <div className="e-title">
               <h3>{exp.expenseName}</h3>
                <p>{format(new Date(exp.createdAt),'dd MMM yyyy')}&emsp;&emsp;
                {exp.status === undefined ? "" : exp.status === 'unverified' ? <span className="unverified">Not-Approved</span> : 
                exp.status === 'verified' ? <span className="verified">Approved</span> : ""
                }
                <span>&emsp;{exp.category === 'cost_of_sale' ? 'Cost of sale/variable cost': 'Operating expense/fixed cost'}</span>
                 </p>
           </div>
       
               <div className="e-price">{exp.expenseAmount.toLocaleString()}<span className="currency">{currency}</span></div>
               <Popover content={
                <Menu>
                    <p className='container-p'>Options </p>
                  {expensesPermissions[2].isGranted ?  <MenuItem    icon='tick' text="Approve expense" onClick={()=>approveExpense(exp)} />: <div />}
                  {expensesPermissions[2].isGranted ?  <MenuItem    icon='disable' text="Disapprove expense" onClick={()=>disApproveExpense(exp)} />: <div />}
                  {expensesPermissions[3].isGranted ?  <MenuItem    icon='trash' text="Delete expense" onClick={()=>deleteExpense(exp)} /> : <div />}
                </Menu>
                
               } position={Position.BOTTOM_BOTTOM}>
                 <div className="table-menu" id="touchable" style={{marginTop:20,marginLeft:'auto'}}>
                <Icon icon="more"  color="#666"  size={22}/></div> 
           </Popover>
           </div>
             ))
             }
                   <div className="table-footer" style={{marginBottom:"auto"}}>
                           <div className="footer-title">Showing <strong> {expenses.length} </strong> expenses </div>
                             <div className="footer-next">
                                 <div onClick={()=>setLimit(prev=> prev <= 15 ? 15 : prev -15)}>Prev</div>
                             </div>  
                             <div className="footer-next">
                                 <div onClick={()=>setLimit(prev=> prev + 15)}>Next</div>
                             </div>                        
                           </div>
       </div>
 
       <div className="e-sider">
         {expensesPermissions[0].isGranted ? 
         <div>
                 <div className="e-t-bar">
           <h3 style={{color:'#444',paddingLeft:20}}>
              <Icon icon="add" color="#07608B" />&emsp;
              Add new expense</h3> 
       </div>
       {errorMessage === null ? <div/> : 
          <div className="input-bar" style={{borderWidth:0}}>
             <Icon icon="error" color={"#b41313"}  size={20} className='icon-auth'/>
              <p   style={{color:"#b41313"}}>{errorMessage}</p>
             </div> 
             }
         <input type="text" className="" placeholder="Name" value={expenseName} onChange={(e)=>setName(e.target.value)} />
         <input type="text" className="" placeholder="Amount" value={expenseAmount} onChange={(e)=>setAmount(e.target.value)}  style={{marginTop:20}}/>
         
         <p className="expense-type">Expense category</p>
         <select className="form-control-select" onChange={(e)=>setExpenseCategory(e.target.value)} style={{marginLeft:20,marginTop:0,width:'90%'}}>
           <option value="cost_of_sale">Cost of sale/variable cost</option>
           <option value="operating_expense">Operating Expense/fixed cost</option>
         </select> <br />

         <p className="expense-type">Label color</p>
         <input type="color" placeholder="tag color" className="round-color-input" onChange={(e)=>setColor(e.target.value)} value={Color}/>
         
         <button className="button"
              onClick={()=>addExpense()}
              disabled={disabled}
              style={{ backgroundColor:disabled ? "#ddd" :"#07608B",color:disabled ?"#ccc":"#fff"}}>
             {spin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
             Add expense</button>
         </div> : <div />}
         
             <h3 style={{color:'#444',paddingLeft:20}}>
              <Icon icon="chart" color="#07608B" />&emsp;
             Last 10 days</h3> 
             {lastTenDaysGraph.length <= 0 && lastTenDaysGraph.length <= 0 ? <div /> : 
           <Line
               style={{margin:20}}
               datasetIdKey='id'
               data={{
                   labels:lastTenDaysGraph[0].dates,
                   datasets:[{
                           id: 1,
                           label:'expenses',
                           data:lastTenDaysGraph[0].amount,
                           borderColor: '#7570B3',
                           tension: 0.1
                     },
                   ],
               }}
                />
                }
       </div>
     </div>
    </div>
    : <AccessDenied />}
   </div>
  )
}

export default FinancesTab