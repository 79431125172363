import React,{useContext,useState,useEffect,useRef} from 'react'
import {ThemeContext} from '../App'
import {Icon,Popover,Position,Menu,MenuItem,Button,Checkbox,Collapse} from '@blueprintjs/core'
import {useNavigate} from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import Loading from '../components/Loading'
import format from 'date-fns/format'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import SelectBranch from '../components/SelectBranch'
var moment = require('moment'); // require
moment().format();


function CollectionSheet() {
    const date = moment.utc();
    const { nusery,card,lighttext,body,primary} = useContext(ThemeContext) 
    const {currentUser,loans,dispatch,activeBranch} = useGlobalContextHook()
    const {role,companyId,username,settings} = currentUser
    const {currency} = settings
    const branchId = activeBranch === null ? currentUser.branchId : currentUser.role === "worker" ? currentUser.branchId :  activeBranch._id
    const branch = activeBranch === null ? currentUser.branch : currentUser.role === "worker" ? currentUser.branch :  activeBranch.name
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [xlsArry, setExportXls] = useState([])
    const [keyword,setKeyword] = useState("")
    const [Limit,setLimit] = useState(20)
    const [openSnackbar] = useSnackbar()
    const [selectedLoansArry,SetselectedLoans] = useState([])
    const [fromDate,setFromDate] = useState(new Date().toJSON().slice(0, 10))
    const [toDate,setToDate] = useState(date.clone().add(60,"days").format("YYYY-MM-DD"))
    const [loading,setLoading] = useState(false)

    const setToDateFn =(date)=>{
      let newDate = moment.utc(date)
      setToDate(newDate.clone().add(1,"days").format("YYYY-MM-DD"))     
    }

    const getCompanyInfo = async()=>{
      try{
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_USER_BY_COMPANY_ID&id=${companyId}`)
        const json = await response.json()
        if(response.ok){
          downloadFormLoan(json[0])
        }
        if(!response.ok){
          openSnackbar(json.message)
        }
      }catch(error){openSnackbar("Error getting company")}
    }
    
    const downloadFormLoan = async(companyOwner)=>{
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=PRINT_SHEET&currency=${currency}&branch=${branch}&company=${companyOwner.company}&fromDate=${fromDate}&toDate=${toDate}&email=${companyOwner.email}&phone=${companyOwner.phoneNumber}&username=${username}`,{
          method: 'POST',
          body: JSON.stringify({loans,logo:companyOwner.photoURL}),
          headers: {'Content-Type': 'application/json'}
        })
        if(response.ok){
          const file = await response.blob()
          const createdFile =  await window.URL.createObjectURL(new Blob([file]), { type: 'application/pdf'});
          const link = document.createElement('a');
          link.href = createdFile;
          link.download = `Collection sheet from${fromDate}=>${toDate}.pdf`;
          link.click();    
        }
      
        if(!response.ok){
          openSnackbar("Error creating pdf")
        }
      } catch (error) {
          console.log(error)
        }
      }

    useEffect(() => {
      const unSubscribe = async () => {
         try {
            if(branchId === null){
               //get all loans from all branches
                console.log(branchId,'branchId')
            } else {
                  setLoading(true)
                  const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_BRANCH_MATURITY_LOANS&id=${branchId}&limit=${Limit}&fromDate=${fromDate}&toDate=${toDate}&keyword=${keyword}`)
                  const json = await response.json()
                  if(response.ok){
                      dispatch({type:'SET_BRANCH_LOANS',payload:json})
                      setLoading(false)
                  } 
                  if(!response.ok){
                      console.log("error fetching loans",response)
                      setLoading(false)
                  }
             }
         } catch (error) {
             console.log(error)
                setLoading(false)
            } 
               
      }
      unSubscribe();
      return () =>unSubscribe();
 },[fromDate,toDate,keyword,Limit,branchId])


const viewLoan =(loanId)=>{
   navigate("/Loans/View",{state:`${loanId}`})
}
 
const selectLoan =(loanSelected)=>{
      dispatch({type:'SELECT_LOAN',payload:loanSelected})  
      const newArry = []
      selectedLoansArry.forEach(loan=>{
         if(loan._id === loanSelected._id){
             
         }
      })   
}

const openLoan =(loanId)=>{
 navigate('/Loans/View',{state:`${loanId}`})
}
 
  return (
    <div className="main-wapper" >
         <TopNav /> 
 <div className="content-wrapper">
 <div className="sider" >
   <SiderContent  /></div>

<div className="section" style={{backgroundColor:body}}> 
{branchId === null && role === "company_admin" ? <SelectBranch /> : 
<div className="-ps-wr" style={{backgroundColor:body}}>
<div className="stat-box">
   <div className="stat-h3" style={{ backgroundColor:"#c6b401"}}><Icon icon="clipboard" style={{marginTop:-8}} color={lighttext} size={30} className="exp-icon-icon"/></div>
      <div className="stat-para"><h3 className="stat-h4">COLLECTION SHEET</h3><p>{branch}</p></div><div></div></div>                   
      <div className='search-bar' style={{marginTop:-20}}>
    <div className="search-input" >
        <input type="text" onChange={(e)=>setKeyword(e.target.value)}  placeholder="Search loan"/>
    </div>
 <div className="search-option" >
    <input  className="date-cl"type="date" placeholder="Date" onChange={(e)=>setFromDate(e.target.value)} />
    <Icon icon="arrow-right" color={"#666"} className="icon-arrow-right"/>
    <input className="date-cl" type="date" placeholder="Date" onChange={(e)=>setToDateFn(e.target.value)} />

<Popover content={
          <Menu>
           <MenuItem onClick={()=>getCompanyInfo()}  icon='document'text="PDF" />    
        </Menu>} position={Position.BOTTOM_BOTTOM}>
  <button className='sort-btn'  style={{margin:"20px 10px 10px 10px",width:100}}>Export <Icon icon="chevron-down" color={primary} /></button>
</Popover>
</div>  

<div className="far-menu-search" onClick={()=>setOpen(prev=>!prev)}>
      <Icon icon="filter-list" color={primary} size={20} className="search-icon-iconx" /></div>
        </div>

<Collapse isOpen={open} canOutsideClickClose={true} onClose={()=>setOpen(prev=>!prev)}>
    <div className='filter-collapse'>
    <input  className="date-cl"type="date" value={fromDate} placeholder="Date" onChange={(e)=>setFromDate(e.target.value)} />
    <Icon icon="arrow-right" color={"#666"} className="icon-arrow-right"/>
    <input className="date-cl" type="date" value={toDate} placeholder="Date" onChange={(e)=>setToDateFn(e.target.value)} />

<Popover content={
          <Menu>
            <MenuItem    icon='panel-table' text="CSV"/>
           <MenuItem   icon='document'text="PDF" />    
        </Menu>} position={Position.BOTTOM_BOTTOM}>
  <button className='sort-btn' onClick={()=>getCompanyInfo()}  style={{margin:15}}>Export <Icon icon="chevron-down" color={primary} /></button>
</Popover>
    </div>
  </Collapse>
      <div className="m-table">
          <div className="m-table-header">
             <div className='m-header-title' id="m-number">No</div>
             <div className='m-header-title' id="m-name">Name</div>
             <div className='m-header-title' id="m-cash">Cash</div>
             <div className='m-header-title' id="m-interest">Interest</div>
             <div className='m-header-title' id="m-final">Final Amount</div>
             <div className='m-header-title' id="m-released">Released date</div>
             <div className='m-header-title' id="m-maturity">Maturity date</div>
             <div className='m-header-title' id="m-status">Status</div>
             <div className='m-header-title' id="m-menu">menu</div>
    
          </div>

          <div className="m-table-row-wrapper">
          {loans.length <= 0 ? <div /> :
           loans.map((loan,i)=>(
            <div className="m-loan-cell-wrapper"  key={i}>
            <div className='m-loan-cell' id="m-number">{i+1}</div>
            <div className='m-loan-cell' id="m-name" onClick={()=>openLoan(loan._id)}>{loan.fullName}</div>
            <div className='m-loan-cell'  id="m-cash">{loan.cash.toLocaleString()}<span className="currency">{currency}</span></div>
            <div className='m-loan-cell'  id="m-interest">{loan.interest}%</div>
            <div className='m-loan-cell'  id="m-final">{loan.final_amount.toLocaleString()}<span className="currency">{currency}</span></div>
            <div className='m-loan-cell' id="m-released">{format(new Date(loan.releasedDate),'dd/MMMM/yyyy')}</div>
            <div className='m-loan-cell' id="m-maturity">{format(new Date(loan.maturityDate),'dd/MMMM/yyyy')}</div>
            <div className='m-loan-cell'  id="m-status"style={{display: 'inline-flex',
                                              color:loan.status === "opened" ? "#F59E0B": 
                                              loan.status === "fullypaid" ? nusery : 
                                              loan.status === "overdue" ? "#FF3366":
                                              loan.status === "ongoing" ? primary:
                                              "#ccc"
               
                  }}>
                    
                     <Icon icon="dot" className="dot-status" />
                    {loan.status}</div>
             <div className='m-loan-cell' id="m-menu">
             <Popover content=
{
 <Menu>
     <p className='container-p'>menu</p>  
    <MenuItem    icon='eye-open' onClick={()=>openLoan(loan._id)} text="View"/>
 </Menu>
 
} position={Position.BOTTOM_BOTTOM}>
        <button className="btn-hidden"><Icon icon="more" /></button>
      </Popover>  
              </div>        
           
            </div>
         ))}
          </div>
          {loading ? <Loading /> : <div />}
          <div className="table-footer">
             <div className="footer-title">Showing <strong> {loans.length} </strong> loans </div>
               <div className="footer-next">
                   <div onClick={()=>setLimit(prev=> prev <= 15 ? 15 : prev -15)}>Prev</div>
               </div>  
               <div className="footer-next">
                   <div onClick={()=>setLimit(prev=> prev + 15)}>Next</div>
               </div>                        
             </div>
        </div>       
         </div>
     }
           </div>
 </div>
    </div>
  )
}

export default CollectionSheet