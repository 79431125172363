import React,{useState,useEffect} from 'react'
import logo from '../img/logo.png'
import {useNavigate} from 'react-router-dom'
import {Icon,Collapse} from '@blueprintjs/core'

function Plan() {
    const [open, close] = useState(true)
    const navigate = useNavigate()
    const [disabled,setDisabled] = useState(true)
    const [toggle,setToggle] = useState(false)
    const [selectedPlan,setSelectedPlan]  = useState(null)
    const [plans,setPlans] = useState([
               {
                   code:"A",
                   title:'Monthly Subscription',
                   price:"80000",
                   color:'#04293A',
                   days:30,
                   selected:false
                },

                {
                    code:"B",
                    title:'3-Months Subscription',
                    price:"220000",
                    color:'#249E61',
                    days:90,
                    selected:false
                },

                {
                    code:"C",
                    title:'6-Months Subscription',
                    price:"420000",
                    color:'#BF1E2E',
                    days:180,
                    selected:false,
                },


    ])

    const selectSubscription =(code,title,price,color,days)=>{
        setSelectedPlan({code:code,title:title,price:price,color:color,days:days})
           
           plans.map(plan=>{
                  plan.selected = false
          })
          let filterPlan =  plans.filter(plan =>plan.code != code)
            setPlans([...filterPlan,{
                code:code,
                title:title,
                price:price,
                color:color,
                days:days,
                selected:true
                 }])
    }    
    
    const confirm = ()=>{
          navigate("/Payment", {state:selectedPlan})
    }

    useEffect(() => {
        if(selectedPlan === null){
            setDisabled(true)
        } else {setDisabled(false)}
    },[selectedPlan])
  return (
    <div>
        <div className="auth-nav">
            <img src={logo} />
            <h2 style={{color:"#fff"}}>Kopaleo</h2>
        </div>  

        <div className="auth-container">

                <h2>Subscription</h2>
           
             {plans.map((plan)=>(
                  <div key={plan.code} className="input-bar" style={{flexDirection: 'column',backgroundColor:plan.selected ? "#f2f2f2" : "#fff"}} onClick={()=>close(prev=>!prev)} >
                    <div className="comp-bar" style={{margin:10}} >
                         <div className="round-comp" style={{ backgroundColor:plan.color,marginTop:30}}>{plan.code}</div> 
                          <div className="round-name" style={{width:"70%"}}>
                              <h3>{plan.title}</h3>
                              <p style={{marginTop:-10}}>{plan.price.toLocaleString()}<span className="currency">TZS</span></p>
                              </div> 
                          <div className="round-tick" onClick={()=>selectSubscription(plan.code,plan.title,plan.price,plan.color,plan.days)}>
                              <Icon icon={plan.selected ? "tick-circle":"circle"}  className='loan-icon' color={"#249E61"} size={20} style={{marginTop:30}}/></div>
                     </div>
                 </div>
             ))}

<button style={{ backgroundColor:disabled ? "#ccc" :"#07608B" }} onClick={()=>confirm()}>
    
    Confirm subscription</button>
   
           
             
        <Collapse isOpen={open} canOutsideClickClose={true} onClose={()=>close(prev=>!prev)}>
        <div className="companies">
                
             </div>
        </Collapse>
            
          

            

        </div>
    </div>
  )
}

export default Plan