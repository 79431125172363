import React,{useContext} from 'react'
import {ThemeContext} from '../App'

function BranchCard({name,location,color}) {
    const {lighttext} = useContext(ThemeContext)

  return (
    <div className="b-card-wrapper" >
         <div className="b-card-abb" style={{backgroundColor:color,color:lighttext,fontSize:23,paddingTop:16}}>{name.charAt(0)+name.charAt(1).toUpperCase()}</div>
         <div className="b-card-names">
              <h2>{name} </h2>
              <p>{location}</p>
         </div>
    </div>
  )
}

export default BranchCard