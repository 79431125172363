import React,{useContext,useState,useEffect} from 'react'
import {ThemeContext} from '../App'
import {Spinner,SpinnerSize} from '@blueprintjs/core'
import { useSnackbar } from 'react-simple-snackbar'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'

function EditBranch(props) {
  const {dispatch,currentUser} = useGlobalContextHook()
  const {name,color,_id,location} = props.info
  const closeFn = props.closeFn
  const {  primary} = useContext(ThemeContext)
  const [openSnackbar] = useSnackbar()
  const [branch_name,setName] = useState(name)
  const [Location,setLocation] = useState(location)
  const [disabled,setDisabled] = useState(true)
  const [spin,stop] = useState(false)
  const [Color,setColor] = useState(color)
  const [limit,setLimit] = useState(40)
  const [criteria, setCriteria] = useState("active")


  const editbranch = async()=>{
         try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_BRANCH&id=${_id}`,{
                method: 'PATCH',
                body: JSON.stringify({
                       name:branch_name,
                       location:Location,
                       color:Color,
                }),
                headers:{
                  'Content-Type': 'application/json'
                }
          })
          const json = await response.json()
          if(response.ok){
            dispatch({type:'UPDATE_BRANCH',payload:json})
            unSubscribe()
            openSnackbar(`Branch details updated`)
          }
          if(!response.ok){
            await openSnackbar(`update details failed`)
          }

          await closeFn()
           
         } catch (error) {
               let err = error.toString();
               openSnackbar(err)
         }  
  }


  useEffect(() => {
    if(branch_name === "" || Location === ""){
       setDisabled(true)
    } else { setDisabled(false)}
},[branch_name,Location])



  const unSubscribe = async () => {
       try {
         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_BRANCHES&id=${currentUser.companyId}&limit=${limit}&criteria=${criteria}`)
         const json = await response.json()
          if(response.ok){
             dispatch({type:'SET_BRANCHES',payload:json})
          }
       } catch (error) {
          console.log(error,'error on fetching branches')
       }
  }


  return (
    <div className='new-branch-wrapper'>
        <h2>Edit Branch details</h2>
     
         <input type="text"  onChange={(e)=>setName(e.target.value)} value={branch_name} />
         <input type="text"  onChange={(e)=>setLocation(e.target.value)} value={Location} />
         <div className="color-bar">
             <p>Change color </p>
             <input type="color" className="round-color-input"
                  onChange={(e)=>setColor(e.target.value)}
                  value={Color}/>
         </div>

         
         <button style={{ backgroundColor:disabled ? "#ccc" :primary }} onClick={()=>editbranch()}>Save</button>
         {spin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
    </div>
  )
}

export default EditBranch