import React,{useContext,useState,useEffect} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {ThemeContext} from '../App'
import { Icon,Drawer,DrawerSize,Popover,Position,Menu,MenuItem,Spinner,SpinnerSize, Dialog,Collapse} from '@blueprintjs/core'
import Empty from '../components/Empty'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {useNavigate} from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import Loading from '../components/Loading'
import AccessDenied from '../components/AccessDenied'


function Workers() {

    const {dispatch,currentUser,workers,branches} = useGlobalContextHook()
    const {company,photoURL:adminphotoURL} = currentUser
    const {permissions} = currentUser
    const {workers:workersPermissions} = permissions
    const [spin,stop] = useState(false)
    const [spin2,stop2] = useState(false)
    const [open,setOpen] = useState(false)
    const [disabled,setDisabled] = useState(true)
    const { primary,card,lighttext,body,nusery} = useContext(ThemeContext)
    const [Limit, setLimit] = useState(30)
    const [criteria,setCriteria] = useState("all")
    const [branch_criteria,setBranchCriteria] = useState("active")
    const [openSnackbar] = useSnackbar()
    const [loading,setLoading] = useState(false)
    const [countryCode,setCountryCode] = useState(255)
    const navigate = useNavigate()
    const [sider,showSider] = useState(false)
    const [username,setUsername] = useState("")
    const [pwd,setPwd] = useState("")
    const [email,setEmail] = useState("")
    const [phone,setPhone] = useState("")
    const [branchCollapse,setBranchCollapse] =useState(true)
    const [workerBranch,setBranchWorker] = useState(null)
  
    const [photo,setPhoto] = useState("https://kopaleo.com/api/img/logos/siba.png")
    const [photoURL,setPhotoURL] = useState("https://kopaleo.com/api/img/logos/siba.png")
    const [ten,setTen] = useState(['1','2','3','4','5','6','7','8','9','10','11','12','1','2','3','4','5','6','7','8','9','10','11','12','1','2','3','4','5','6','7','8','9','10','11','12','1','2','3','4','5','6','7','8','9','10','11','12'])

    const setCriteriaFn =(cr)=>{
      setCriteria(cr)
    }
    const selectImage = (img) => {
      setPhoto(img); setOpen(false);
      openSnackbar("Photo selected")
    }
    
   useEffect(() => {
    const unSubscribe = async () => {
         setLoading(true);
         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_WORKERS&worker_criteria=${criteria}&limit=${Limit}&id=${currentUser.companyId}`)
         const json = await response.json()
         if(response.ok){
           dispatch({type:'SET_WORKERS',payload:json})
           setLoading(false)
         }
         if(!response.ok){
          console.log('error on fetching workers')
          setLoading(false)
         }
    }   
     unSubscribe()
   },[criteria,Limit]) 
    
   const unSubscribe = async () => {
    setLoading(true);
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_WORKERS&worker_criteria=${criteria}&limit=${Limit}&id=${currentUser.companyId}`)
    const json = await response.json()
    if(response.ok){
      dispatch({type:'SET_WORKERS',payload:json})
      setLoading(false)
    }
    if(!response.ok){
     console.log('error on fetching workers')
     setLoading(false)
    }
}

const createNotification=async(worker)=>{
    try {
      const notificationObject = {
        title:`Your request to continue as worker for the ${worker.branch} has been approved by company administrator ${currentUser.username}`,
        owner:worker.id,
        companyId:currentUser.companyId,
        company:currentUser.company,
        worker:worker.username,
        branch:worker.branch,
        icon:"endorsed",
        read:false,
        headline:"Worker Approved"
      }
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=CREATE_NOTIFICATION`,{
        method: 'POST',
        body: JSON.stringify(notificationObject),
        headers: {'Content-Type': 'application/json'}
      })
      const json = await response.json()
      if(response.ok){
        
      }
      if(!response.ok){
        console.log(response,'itself')
      }
    } catch (error) {
      console.log(error);
    }
}

const createNewNotification=async(worker)=>{
  try {
    const notificationObject = {
      title:`You created new user with email ${worker.email} using the password ${pwd}`,
      owner:currentUser.id,
      companyId:currentUser.companyId,
      company:currentUser.company,
      worker:worker.username,
      branch:worker.branch,
      icon:"inherited-group",
      read:false,
      headline:"Created new user"
    }
  
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=CREATE_NOTIFICATION`,{
      method: 'POST',
      body: JSON.stringify(notificationObject),
      headers: {'Content-Type': 'application/json'}
    })
    const json = await response.json()
    if(response.ok){
      
    }
    if(!response.ok){
      console.log(response,'itself')
    }
  } catch (error) {
    console.log(error);
  }
}
const blockUser =async(worker)=>{
  try {
    stop2(true)  
    const userObj = {worker_status:"blocked"}
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_USER_PROFILE&id=${worker.id}`,{
        method: 'PATCH',
        body: JSON.stringify(userObj),
        headers: {'Content-Type': 'application/json'}
    })
    const json = await response.json()
    if(response.ok) {
        openSnackbar('Acccount Blocked');
        setCriteriaFn("all"); stop2(false); unSubscribe()
    }
    if(!response.ok){
        openSnackbar('!Ooops! failed to update user profile');stop2(false)
    }

  } catch (error) {
      console.log(error);stop2(false)
  }
}

const approveWorker = async(worker)=>{
  try {
      
   stop2(true) 
    const userObj = {worker_status:"active",completed:"completed"}
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_USER_PROFILE&id=${worker.id}`,{
        method: 'PATCH',
        body: JSON.stringify(userObj),
        headers: {'Content-Type': 'application/json'}
    })
    const json = await response.json()
    if(response.ok) {
        createNotification(worker); stop2(false); setCriteriaFn("all"); unSubscribe()
    }
    if(!response.ok){
        openSnackbar('!Ooops! failed to update user profile')
    }

  } catch (error) {
      console.log(error);
  }
}

const searchWorker =async(keyword)=>{
if(keyword.length <= 0 ) {} else {
  setLoading(true)
  const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SEARCH_WORKERS&keyword=${keyword}&worker_criteria=${criteria}&limit=${Limit}&id=${currentUser.companyId}`)
  const json = await response.json()
  if(response.ok){
    dispatch({type:'SET_WORKERS',payload:json})
    setLoading(false)
  }
  if(!response.ok){
    openSnackbar(json.message)
   setLoading(false)
      }
   } 
}

const generatePassword =()=>{
  function generatePassword() {
    var buf = new Uint8Array(10);
    window.crypto.getRandomValues(buf);
    return btoa(String.fromCharCode.apply(null, buf));
}

setPwd(generatePassword)
}

useEffect(() => {
  const unSubscribe = async () => {
       try {
         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_BRANCHES&id=${currentUser.companyId}&limit=${Limit}&criteria=active`)
         const json = await response.json()
          if(response.ok){
             dispatch({type:'SET_BRANCHES',payload:json})
             
          }
       } catch (error) {
          console.log(error,'error on fetching branches')
       }
  }
  unSubscribe();
  return () =>unSubscribe();
},[branch_criteria,sider])

useEffect(() =>{
  if(workerBranch === null || username === " " || pwd === "" || email === "" || phone === ""){
    setDisabled(true)
  } else { setDisabled(false)}
},[workerBranch,username,pwd,email,phone])

const addWorker = async()=>{
  try{
    setDisabled(true); stop(true)
    const cleanNumber = parseInt(phone,10);
    const userBody = {
      username:username,
      email:email,
      phoneNumber:countryCode+""+cleanNumber,
      photoURL:photo,
      pwd:pwd,
      branch:workerBranch.name,
      branchId:workerBranch._id,
      company:currentUser.company,
      companyId:currentUser.companyId,
      completed:"completed",
      subscription:"unset",
      started:"unset",
      ending:"unset",
      accountStatus:"active",
      worker_status:"active",
      lastTimeLoggedIn:"unset",
      role:"worker",
      settings:{
        notifications:{
          loan:false,
          repayment:false,
          mature:false
        },
        language:"English",
        currency:"TZS"
      },
      permissions:{
        loans:[
          {id:"1",name:"Create loan",isGranted:false},
          {id:"2",name:"List loans",isGranted:false},
          {id:"3",name:"Update loan details",isGranted:false},
          {id:"4",name:"Delete loan",isGranted:false}
        ],
        repayments:[
          {id:"1",name:"Add repayment",isGranted:false},
          {id:"2",name:"List repayments",isGranted:false},
          {id:"3",name:"Update repayment details",isGranted:false},
          {id:"4",name:"Delete repayment details",isGranted:false}
        ],
        borrowers:[
          {id:"1",name:"Add borrower",isGranted:false},
          {id:"2",name:"List borrowers",isGranted:false},
          {id:"3",name:"Update borrower's details",isGranted:false},
          {id:"4",name:"Delete borrower",isGranted:false},
        ],
        expenses:[
          {id:"1",name:"Add expense",isGranted:false},
          {id:"2",name:"List expenses",isGranted:false},
          {id:"3",name:"Update expense",isGranted:false},
          {id:"4",name:"Delete expense",isGranted:false},
        ],
        workers:[
          {id:"1",name:"Add worker",isGranted:false},
          {id:"2",name:"List worker",isGranted:false},
          {id:"3",name:"Update worker",isGranted:false},
          {id:"4",name:"Delete worker",isGranted:false},
        ],
        branches:[
          {id:"1",name:"Create branch",isGranted:false},
          {id:"2",name:"List branches",isGranted:false},
          {id:"3",name:"Update branch",isGranted:false},
          {id:"4",name:"Delete",isGranted:false},
        ],
        reports:[
          {id:"1",name:"Create report",isGranted:false},
          {id:"2",name:"List report",isGranted:false}
        ],
        accounting:[
          {id:"1",name:"Create account",isGranted:false},
          {id:"2",name:"List accounts",isGranted:false},
          {id:"3",name:"Update account",isGranted:false},
          {id:"4",name:"Delete account",isGranted:false},
        ],

      }
    }
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=ADD_NEW_WORKER`,{
      method:"POST",
      body:JSON.stringify(userBody),
      headers:{'Content-Type':'application/json'}
    })
   const json = await response.json()
   if(response.ok) {
      stop(false); openSnackbar('New worker added successfully')
      setCriteria("active"); showSider(false);
      setUsername("")
      setPwd("")
      setEmail("")
      setPhone("")
      createNewNotification(json)

   } else {
    openSnackbar(json.message)
     stop(false)
   }
  }catch(error){
    console.log(error); openSnackbar(error)
  }
}

const editWorker = (worker)=>{
  navigate('/edit-user', {state:worker})
}
  return (
<div className="main-wapper" ><TopNav />
    <div className="content-wrapper"><div className="sider" ><SiderContent /></div>
       {workersPermissions[1].isGranted ?  <div className="section" style={{backgroundColor:body}}>
        <Drawer 
              isOpen={sider} 
              title="Add new worker"
              titleTextColor="#333"
              position={Position.RIGHT}
              size={window.innerWidth > 1000 ? DrawerSize.STANDARD :  DrawerSize.LARGE} 
              canClickOutsideToClose={true} onClose={()=>showSider(prev=>!prev)}>
               <div className="form-new-user" style={{overflow:'auto'}}>
               <div className="station-input">
                 <h4 onClick={()=>setBranchWorker(null)}>{workerBranch === null ? 'Select branch':workerBranch.name} &emsp; <Icon onClick={()=>setBranchWorker(null)} icon="chevron-down" color="#666" /></h4>
               <Collapse isOpen={workerBranch === null ? true: false} canClickOutsideToClose={true} onClose={()=>setBranchWorker(null)}>
               {branches.length <= 0 ? <Empty name="No braches found" icon="git-branch" color="#666"/> : branches.map(branch=>(
                    <div key={branch._id} onClick={()=>setBranchWorker(branch)} className="sel-branch" id="triad">{branch.name}</div>
                ))}
               </Collapse>
              </div>  
              <div className="station-input">
                 <h4>User name</h4>
                 <input type="text"  value={username}className="id" disabled={false}  
                  style={{width:'98%'}} onChange={(e)=>setUsername(e.target.value)}/>
              </div>

             <div className="station-input">
                 <h4>E-mail</h4>
                 <input type="text" value={email} className="id" disabled={false}  
                 style={{width:'98%'}} onChange={(e)=>setEmail(e.target.value)}/>
             </div> 

             <div className="input-bar">
                <Icon icon="flag" color={"#5F6B7C"}  size={20} className='icon-auth'/>
                <select style={{outline:'none',borderWidth:0,width:'96%'}} name="countryCode" id="country-code"
                  onChange={(e)=>setCountryCode(e.target.value)}
                  defaultValue={countryCode}
                  >
		<option countrycode="DZ" value="213">Algeria (+213)</option>
		<option countrycode="AD" value="376">Andorra (+376)</option>
		<option countrycode="AO" value="244">Angola (+244)</option>
		<option countrycode="AI" value="1264">Anguilla (+1264)</option>
		<option countrycode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
		<option countrycode="AR" value="54">Argentina (+54)</option>
		<option countrycode="AM" value="374">Armenia (+374)</option>
		<option countrycode="AW" value="297">Aruba (+297)</option>
		<option countrycode="AU" value="61">Australia (+61)</option>
		<option countrycode="AT" value="43">Austria (+43)</option>
		<option countrycode="AZ" value="994">Azerbaijan (+994)</option>
		<option countrycode="BS" value="1242">Bahamas (+1242)</option>
		<option countrycode="BH" value="973">Bahrain (+973)</option>
		<option countrycode="BD" value="880">Bangladesh (+880)</option>
		<option countrycode="BB" value="1246">Barbados (+1246)</option>
		<option countrycode="BY" value="375">Belarus (+375)</option>
		<option countrycode="BE" value="32">Belgium (+32)</option>
		<option countrycode="BZ" value="501">Belize (+501)</option>
		<option countrycode="BJ" value="229">Benin (+229)</option>
		<option countrycode="BM" value="1441">Bermuda (+1441)</option>
		<option countrycode="BT" value="975">Bhutan (+975)</option>
		<option countrycode="BO" value="591">Bolivia (+591)</option>
		<option countrycode="BA" value="387">Bosnia Herzegovina (+387)</option>
		<option countrycode="BW" value="267">Botswana (+267)</option>
		<option countrycode="BR" value="55">Brazil (+55)</option>
		<option countrycode="BN" value="673">Brunei (+673)</option>
		<option countrycode="BG" value="359">Bulgaria (+359)</option>
		<option countrycode="BF" value="226">Burkina Faso (+226)</option>
		<option countrycode="BI" value="257">Burundi (+257)</option>
		<option countrycode="KH" value="855">Cambodia (+855)</option>
		<option countrycode="CM" value="237">Cameroon (+237)</option>
		<option countrycode="CA" value="1">Canada (+1)</option>
		<option countrycode="CV" value="238">Cape Verde Islands (+238)</option>
		<option countrycode="KY" value="1345">Cayman Islands (+1345)</option>
		<option countrycode="CF" value="236">Central African Republic (+236)</option>
		<option countrycode="CL" value="56">Chile (+56)</option>
		<option countrycode="CN" value="86">China (+86)</option>
		<option countrycode="CO" value="57">Colombia (+57)</option>
		<option countrycode="KM" value="269">Comoros (+269)</option>
		<option countrycode="CG" value="242">Congo (+242)</option>
		<option countrycode="CK" value="682">Cook Islands (+682)</option>
		<option countrycode="CR" value="506">Costa Rica (+506)</option>
		<option countrycode="HR" value="385">Croatia (+385)</option>
		<option countrycode="CU" value="53">Cuba (+53)</option>
		<option countrycode="CY" value="90392">Cyprus North (+90392)</option>
		<option countrycode="CY" value="357">Cyprus South (+357)</option>
		<option countrycode="CZ" value="42">Czech Republic (+42)</option>
		<option countrycode="DK" value="45">Denmark (+45)</option>
		<option countrycode="DJ" value="253">Djibouti (+253)</option>
		<option countrycode="DM" value="1809">Dominica (+1809)</option>
		<option countrycode="DO" value="1809">Dominican Republic (+1809)</option>
		<option countrycode="EC" value="593">Ecuador (+593)</option>
		<option countrycode="EG" value="20">Egypt (+20)</option>
		<option countrycode="SV" value="503">El Salvador (+503)</option>
		<option countrycode="GQ" value="240">Equatorial Guinea (+240)</option>
		<option countrycode="ER" value="291">Eritrea (+291)</option>
		<option countrycode="EE" value="372">Estonia (+372)</option>
		<option countrycode="ET" value="251">Ethiopia (+251)</option>
		<option countrycode="FK" value="500">Falkland Islands (+500)</option>
		<option countrycode="FO" value="298">Faroe Islands (+298)</option>
		<option countrycode="FJ" value="679">Fiji (+679)</option>
		<option countrycode="FI" value="358">Finland (+358)</option>
		<option countrycode="FR" value="33">France (+33)</option>
		<option countrycode="GF" value="594">French Guiana (+594)</option>
		<option countrycode="PF" value="689">French Polynesia (+689)</option>
		<option countrycode="GA" value="241">Gabon (+241)</option>
		<option countrycode="GM" value="220">Gambia (+220)</option>
		<option countrycode="GE" value="7880">Georgia (+7880)</option>
		<option countrycode="DE" value="49">Germany (+49)</option>
		<option countrycode="GH" value="233">Ghana (+233)</option>
		<option countrycode="GI" value="350">Gibraltar (+350)</option>
		<option countrycode="GR" value="30">Greece (+30)</option>
		<option countrycode="GL" value="299">Greenland (+299)</option>
		<option countrycode="GD" value="1473">Grenada (+1473)</option>
		<option countrycode="GP" value="590">Guadeloupe (+590)</option>
		<option countrycode="GU" value="671">Guam (+671)</option>
		<option countrycode="GT" value="502">Guatemala (+502)</option>
		<option countrycode="GN" value="224">Guinea (+224)</option>
		<option countrycode="GW" value="245">Guinea - Bissau (+245)</option>
		<option countrycode="GY" value="592">Guyana (+592)</option>
		<option countrycode="HT" value="509">Haiti (+509)</option>
		<option countrycode="HN" value="504">Honduras (+504)</option>
		<option countrycode="HK" value="852">Hong Kong (+852)</option>
		<option countrycode="HU" value="36">Hungary (+36)</option>
		<option countrycode="IS" value="354">Iceland (+354)</option>
		<option countrycode="IN" value="91">India (+91)</option>
		<option countrycode="ID" value="62">Indonesia (+62)</option>
		<option countrycode="IR" value="98">Iran (+98)</option>
		<option countrycode="IQ" value="964">Iraq (+964)</option>
		<option countrycode="IE" value="353">Ireland (+353)</option>
		<option countrycode="IL" value="972">Israel (+972)</option>
		<option countrycode="IT" value="39">Italy (+39)</option>
		<option countrycode="JM" value="1876">Jamaica (+1876)</option>
		<option countrycode="JP" value="81">Japan (+81)</option>
		<option countrycode="JO" value="962">Jordan (+962)</option>
		<option countrycode="KZ" value="7">Kazakhstan (+7)</option>
		<option countrycode="KE" value="254">Kenya (+254)</option>
		<option countrycode="KI" value="686">Kiribati (+686)</option>
		<option countrycode="KP" value="850">Korea North (+850)</option>
		<option countrycode="KR" value="82">Korea South (+82)</option>
		<option countrycode="KW" value="965">Kuwait (+965)</option>
		<option countrycode="KG" value="996">Kyrgyzstan (+996)</option>
		<option countrycode="LA" value="856">Laos (+856)</option>
		<option countrycode="LV" value="371">Latvia (+371)</option>
		<option countrycode="LB" value="961">Lebanon (+961)</option>
		<option countrycode="LS" value="266">Lesotho (+266)</option>
		<option countrycode="LR" value="231">Liberia (+231)</option>
		<option countrycode="LY" value="218">Libya (+218)</option>
		<option countrycode="LI" value="417">Liechtenstein (+417)</option>
		<option countrycode="LT" value="370">Lithuania (+370)</option>
		<option countrycode="LU" value="352">Luxembourg (+352)</option>
		<option countrycode="MO" value="853">Macao (+853)</option>
		<option countrycode="MK" value="389">Macedonia (+389)</option>
		<option countrycode="MG" value="261">Madagascar (+261)</option>
		<option countrycode="MW" value="265">Malawi (+265)</option>
		<option countrycode="MY" value="60">Malaysia (+60)</option>
		<option countrycode="MV" value="960">Maldives (+960)</option>
		<option countrycode="ML" value="223">Mali (+223)</option>
		<option countrycode="MT" value="356">Malta (+356)</option>
		<option countrycode="MH" value="692">Marshall Islands (+692)</option>
		<option countrycode="MQ" value="596">Martinique (+596)</option>
		<option countrycode="MR" value="222">Mauritania (+222)</option>
		<option countrycode="YT" value="269">Mayotte (+269)</option>
		<option countrycode="MX" value="52">Mexico (+52)</option>
		<option countrycode="FM" value="691">Micronesia (+691)</option>
		<option countrycode="MD" value="373">Moldova (+373)</option>
		<option countrycode="MC" value="377">Monaco (+377)</option>
		<option countrycode="MN" value="976">Mongolia (+976)</option>
		<option countrycode="MS" value="1664">Montserrat (+1664)</option>
		<option countrycode="MA" value="212">Morocco (+212)</option>
		<option countrycode="MZ" value="258">Mozambique (+258)</option>
		<option countrycode="MN" value="95">Myanmar (+95)</option>
		<option countrycode="NA" value="264">Namibia (+264)</option>
		<option countrycode="NR" value="674">Nauru (+674)</option>
		<option countrycode="NP" value="977">Nepal (+977)</option>
		<option countrycode="NL" value="31">Netherlands (+31)</option>
		<option countrycode="NC" value="687">New Caledonia (+687)</option>
		<option countrycode="NZ" value="64">New Zealand (+64)</option>
		<option countrycode="NI" value="505">Nicaragua (+505)</option>
		<option countrycode="NE" value="227">Niger (+227)</option>
		<option countrycode="NG" value="234">Nigeria (+234)</option>
		<option countrycode="NU" value="683">Niue (+683)</option>
		<option countrycode="NF" value="672">Norfolk Islands (+672)</option>
		<option countrycode="NP" value="670">Northern Marianas (+670)</option>
		<option countrycode="NO" value="47">Norway (+47)</option>
		<option countrycode="OM" value="968">Oman (+968)</option>
		<option countrycode="PW" value="680">Palau (+680)</option>
		<option countrycode="PA" value="507">Panama (+507)</option>
		<option countrycode="PG" value="675">Papua New Guinea (+675)</option>
		<option countrycode="PY" value="595">Paraguay (+595)</option>
		<option countrycode="PE" value="51">Peru (+51)</option>
		<option countrycode="PH" value="63">Philippines (+63)</option>
		<option countrycode="PL" value="48">Poland (+48)</option>
		<option countrycode="PT" value="351">Portugal (+351)</option>
		<option countrycode="PR" value="1787">Puerto Rico (+1787)</option>
		<option countrycode="QA" value="974">Qatar (+974)</option>
		<option countrycode="RE" value="262">Reunion (+262)</option>
		<option countrycode="RO" value="40">Romania (+40)</option>
		<option countrycode="RU" value="7">Russia (+7)</option>
		<option countrycode="RW" value="250">Rwanda (+250)</option>
		<option countrycode="SM" value="378">San Marino (+378)</option>
		<option countrycode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
		<option countrycode="SA" value="966">Saudi Arabia (+966)</option>
		<option countrycode="SN" value="221">Senegal (+221)</option>
		<option countrycode="CS" value="381">Serbia (+381)</option>
		<option countrycode="SC" value="248">Seychelles (+248)</option>
		<option countrycode="SL" value="232">Sierra Leone (+232)</option>
		<option countrycode="SG" value="65">Singapore (+65)</option>
		<option countrycode="SK" value="421">Slovak Republic (+421)</option>
		<option countrycode="SI" value="386">Slovenia (+386)</option>
		<option countrycode="SB" value="677">Solomon Islands (+677)</option>
		<option countrycode="SO" value="252">Somalia (+252)</option>
		<option countrycode="ZA" value="27">South Africa (+27)</option>
		<option countrycode="ES" value="34">Spain (+34)</option>
		<option countrycode="LK" value="94">Sri Lanka (+94)</option>
		<option countrycode="SH" value="290">St. Helena (+290)</option>
		<option countrycode="KN" value="1869">St. Kitts (+1869)</option>
		<option countrycode="SC" value="1758">St. Lucia (+1758)</option>
		<option countrycode="SD" value="249">Sudan (+249)</option>
		<option countrycode="SR" value="597">Suriname (+597)</option>
		<option countrycode="SZ" value="268">Swaziland (+268)</option>
		<option countrycode="SE" value="46">Sweden (+46)</option>
		<option countrycode="CH" value="41">Switzerland (+41)</option>
		<option countrycode="SI" value="963">Syria (+963)</option>
		<option countrycode="TW" value="886">Taiwan (+886)</option>
		<option countrycode="TJ" value="7">Tajikstan (+7)</option>
		<option countrycode="TZ" value="255">Tanzania (+255)</option>
		<option countrycode="TH" value="66">Thailand (+66)</option>
		<option countrycode="TG" value="228">Togo (+228)</option>
		<option countrycode="TO" value="676">Tonga (+676)</option>
		<option countrycode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
		<option countrycode="TN" value="216">Tunisia (+216)</option>
		<option countrycode="TR" value="90">Turkey (+90)</option>
		<option countrycode="TM" value="7">Turkmenistan (+7)</option>
		<option countrycode="TM" value="993">Turkmenistan (+993)</option>
		<option countrycode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
		<option countrycode="TV" value="688">Tuvalu (+688)</option>
		<option countrycode="UG" value="256">Uganda (+256)</option>
		<option countrycode="GB" value="44">UK (+44)</option>
		<option countrycode="UA" value="380">Ukraine (+380)</option>
		<option countrycode="AE" value="971">United Arab Emirates (+971)</option>
		<option countrycode="UY" value="598">Uruguay (+598)</option>
		<option countrycode="US" value="1">USA (+1)</option> 
		<option countrycode="UZ" value="7">Uzbekistan (+7)</option>
		<option countrycode="VU" value="678">Vanuatu (+678)</option>
		<option countrycode="VA" value="379">Vatican City (+379)</option>
		<option countrycode="VE" value="58">Venezuela (+58)</option>
		<option countrycode="VN" value="84">Vietnam (+84)</option>
		<option countrycode="VG" value="84">Virgin Islands - British (+1284)</option>
		<option countrycode="VI" value="84">Virgin Islands - US (+1340)</option>
		<option countrycode="WF" value="681">Wallis &amp; Futuna (+681)</option>
		<option countrycode="YE" value="969">Yemen (North)(+969)</option>
		<option countrycode="YE" value="967">Yemen (South)(+967)</option>
		<option countrycode="ZM" value="260">Zambia (+260)</option>
		<option countrycode="ZW" value="263">Zimbabwe (+263)</option>
                    </select>
            </div>
             <div className="station-input">
                 <h4>Phone number</h4>
                 <input type="text" value={phone} className="id" disabled={false}  
                 style={{width:'98%'}} onChange={(e)=>setPhone(e.target.value)}/>
             </div>

             <div className="station-input">
                 <h4>Password</h4>
                 <input type="text" value={pwd} className="id" disabled={false} 
                 style={{width:'98%'}} onChange={(e)=>setPwd(e.target.value)}/>
                 <button className="btn-generate" onClick={()=>generatePassword()}>
                  <Icon icon="lock" color={pwd.length <=1 ? "#666": "#107E39"} />&emsp;
                   Generate password</button>
             </div>

             <div className="station-input">
              <h4 style={{margin:"0px 4px 8px 4px"}}>Select profile picture</h4>
                  <div className="logo" onClick={()=>setOpen(prev=>!prev)} style={{marginTop:0}}>
                          <img src={photo} alt="logo" />
                    </div>
            </div>

             <div className="station-action">
               <button disabled={disabled} onClick={() =>addWorker()}
               style={{backgroundColor:disabled ? "#ddd": "#07608B",color:disabled ? "#444": "#fff" }}>
                 {spin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
                Add user</button>
               <button className='b' onClick={()=>showSider(prev=>!prev)}>Cancel</button>
             </div>
  
               </div>
             </Drawer>
        <div className="stat-box" style={{width:'98%',marginLeft:'1%'}}>
          <div className="stat-h3" style={{ backgroundColor:primary}}><Icon icon="inherited-group" style={{marginTop:-8}} color={lighttext} size={30} className="exp-icon-icon"/></div>
              <div className="stat-para"><h3 className="stat-h4">WORKERS</h3><p>{company}</p></div>
                    <div></div>
                   {workersPermissions[0].isGranted ?   <div class="add-user" onClick={()=>showSider(prev=>!prev)}>+ Add new worker</div> : <div />}
                    </div>
        <div className='search-bar' style={{marginLeft:'1%',marginTop:'0px'}}>
      <div className="search-input">
        <input type="text"  placeholder="Search worker"  onChange={(e)=>searchWorker(e.target.value)}/></div> 
<div className="filter-div" style={{marginTop:20}}>
          <Popover content=
   {
    <Menu>
        <p className='container-p'>Criteria</p>            
        <MenuItem    icon='inherited-group' text="All workers" onClick={()=>setCriteriaFn("all")} />
        <MenuItem    icon='inherited-group' text="Active worker" onClick={()=>setCriteriaFn("active")} />
        <MenuItem     icon='disable' text="Inactive worker"  onClick={()=>setCriteriaFn("inactive")}  />
        <MenuItem     icon='cross' text="Wainting approval" onClick={()=>setCriteriaFn("waiting approval")}  />
        <MenuItem     icon='blocked-person' text="Blocked"onClick={()=>setCriteriaFn("blocked")}  />               
    </Menu>
    } position={Position.BOTTOM_BOTTOM}>
  <button className='filter' style={{backgroundColor:card}}>Sort by&emsp; {criteria}<Icon icon='chevron-down'  color={primary}/>
</button>
</Popover></div>    
        </div>      
 <div className="m-table" style={{margin:"1%",width:'98%'}}>
          <div className="m-table-header">
             <div className='m-header-title' id="m-name">Name</div>
             <div className='m-header-title' id="m-cash">Phone</div>
             <div className='m-header-title' id="m-final">E-mail</div>
             <div className='m-header-title' id="m-final">Branch</div>
             <div className='m-header-title' id="m-released">{spin2 ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : "Status"}</div>
             <div className='m-header-title' id="m-menu">Menu</div>
    
          </div>
          <div className="m-table-row-wrapper">
          {workers.length <= 0 ? <div /> :
           workers.map((worker,i)=>(
            <div className="m-loan-cell-wrapper"  key={i}>
            <div className='m-loan-cell' id="m-name"  onClick={()=>editWorker(worker)} >{worker.username}</div>
            <div className='m-loan-cell'  id="m-cash">{worker.phoneNumber}</div>
            <div className='m-loan-cell'  id="m-final">{worker.email}</div>
            <div className='m-loan-cell'  id="m-final">{worker.branch}</div>
            <div className='m-loan-cell' id="m-released"     style={{ 
                        color:worker.worker_status === "active" ? nusery :
                              worker.worker_status === "inactive" ? "#dd3549" :
                              worker.worker_status === 'waiting approval' ? "#a53edd" :
                              worker.worker_status === 'blocked' ? "#FF1B2D" :nusery
                        }}>{worker.worker_status}</div>
             <div className='m-loan-cell' id="m-menu">
             <Popover content=
              {
                 <Menu>
                     <p className='container-p'>Worker options</p>
                    {workersPermissions[2].isGranted ? <MenuItem    onClick={()=>editWorker(worker)} icon='edit' text="Edit user" /> : <div />}
                    {workersPermissions[2].isGranted ? <MenuItem    onClick={()=>approveWorker(worker)} icon='tick' text="Approve worker" /> : <div />}
                    {workersPermissions[2].isGranted ? <MenuItem    onClick={()=>blockUser(worker)} icon='blocked-person' text="Block worker" /> : <div />}
                 </Menu>
                } position={Position.BOTTOM_BOTTOM}>
                      <button className="btn-hidden"><Icon icon="more" /></button>
                      </Popover>
              </div>        
           
            </div>
         ))}
          </div>
         {loading ? <Loading /> : <div />}
          <div className="table-footer">
             <div className="footer-title">Showing <strong> {workers.length} </strong> workers </div>
               <div className="footer-next">
                   <div onClick={()=>setLimit(prev=> prev <= 15 ? 15 : prev -15)}>Prev</div>
               </div>  
               <div className="footer-next">
                   <div onClick={()=>setLimit(prev=> prev + 15)}>Next</div>
               </div>                        
             </div>
        </div>       
        </div>: <AccessDenied />}
        <Dialog isOpen={open} canClickOutsideToClose={true} onClose={()=>setOpen(prev=>!prev)}>
          <h1 style={{marginLeft:20,marginBottom:0}}>Select photo</h1>
          <div className="photos">
              {ten.map((n,i)=>(
              <div className="frame" key={i} onClick={() =>selectImage(`https://picsum.photos/id/10${i}/100/100`)}>
                  <img src={`https://picsum.photos/id/10${i}/100/100`} />
              </div>
               ))}
           
          </div>
          </Dialog>
         </div>
    </div>
  )
}

export default Workers