import React,{ useState, useEffect} from 'react'
import LandingNavbar from '../components/LandingNavbar'
import { Icon,Spinner,SpinnerSize} from '@blueprintjs/core'
import macowigo from '../img/macowigo.png'
import { useNavigate,Link} from 'react-router-dom'
import { getAuth,signInWithEmailAndPassword} from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth';

const auth = getAuth()

function Pricing() {
  const navigate = useNavigate()
  const [user]= useAuthState(auth)
  const [isSpin,setIsSpin] = useState(false)
  const [currencies,setCurrencies] = useState([])
  const [price,setPrice] = useState(50000)
  const [currency,setCurrency] = useState('TZS')
  const [isLoading,setIsLoading] = useState(false)


  const LoginFn = async()=>{
    try {
        setIsSpin(true);
        const userCredential = await signInWithEmailAndPassword(auth,'josephowigo10@gmail.com', '1234567')    
         await setIsSpin(false);
         navigate('/') 
    } catch (error) {
       setIsSpin(false)
    }
}


  const getCurrenies = async ()=>{
    try {
      var myHeaders = new Headers();
      myHeaders.append("apikey", "uCzHo7W8jxQjV0uhxBz6SOHUisVkATzJ");
      
      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
      }

      fetch("https://api.apilayer.com/fixer/symbols",requestOptions)
        .then(response => response.text())
        .then(result =>{
          setIsLoading(false)
          const jsonToObject = JSON.parse(result)
          const arrayOfCurrencies = [] 
          const keys =  Object.keys(jsonToObject.symbols)  
          const values = Object.values(jsonToObject.symbols) 
           
          for (let i = 0;  i <= 1; i++){
              keys.reduce((acc, elem, i) =>{
              arrayOfCurrencies.push({...acc,
                   id:elem,
                   name:values[i]
                  })
            },{})
          }
          
          setCurrencies(arrayOfCurrencies)
         })
        .catch(error => console.log('error', error));

    } catch (error){
       console.log(error,'error')
    }
  }

 const convertPrice=async(currencyId)=>{
  setIsLoading(true)
  setCurrency(currencyId.toUpperCase())
    try {
      var myHeaders = new Headers();
      myHeaders.append("apikey", "uCzHo7W8jxQjV0uhxBz6SOHUisVkATzJ")

      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
      }
      
      fetch(`https://api.apilayer.com/fixer/convert?to=${currencyId}&from=TZS&amount=50000`, requestOptions)
        .then(response => response.text())
        .then((result) => {
          setIsLoading(false)
           const resToObject =  JSON.parse(result)
            setPrice(resToObject.result)
        })
        .catch(error => {
          setIsLoading(false)
          console.log('error', error)});
    } catch (error){
      console.log('error', error)
    }
 }
  useEffect(()=>{
    getCurrenies()
  },[])

  return (
    <div className="main-wapper" >
        <LandingNavbar /> 
        <div className="pricing-main">
            <h1 className="pricing-main-h1"><span style={{color: '#07608B'}}>Simple,</span>straight forward pricing.</h1>
            <div className="pricing-symbols">
              <h2>Currency</h2>
              <select className="pricing-currencies" 
                  onChange={(e)=>convertPrice(e.target.value)}>
                 {currencies.length <= 0 ? <div /> : currencies.map(item=>(
                  <option value={item.id}>{item.name}</option>
                 ))}
              </select>
            </div>
            <div className="pricing-wrapper">
        <div className="pricing-box">
          <div className='pricing-box-container'>
          <h1>Monthly</h1>
            <p className='pricing-box-p'>Expore kopaleo for a whole month</p>
            <h2 className='pricing-box-h2'>{isLoading ? <Spinner size={SpinnerSize.SMALL} intent="warning"/>: (price).toLocaleString()}<span className="currency">{currency}</span></h2>
            <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Unlimited branches.</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Unlimited workers.</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Full customization.</p>
           </div>

           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>SMS notifications.</p>
           </div>

           <button  onClick={()=>navigate('/Signup')} className='pricing-box-btn-monthly'>Get started</button>
          </div>
        </div>

        <div className="pricing-box">
        <div className='pricing-box-container'>
            <h1> 3 Months</h1>
            <p className='pricing-box-p'>Get more done with less</p>
            <h2 className='pricing-box-h2'>{isLoading ? <Spinner size={SpinnerSize.SMALL} intent="warning"/>: (price * 3).toLocaleString()}<span className="currency">{currency}</span></h2>
            <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Unlimited branches.</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Unlimited workers.</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Full customization.</p>
           </div>

           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>SMS notifications.</p>
           </div>

           <button  onClick={()=>navigate('/Signup')} id='pricing-box-btn'>Get started</button>
           </div>
        </div>

        <div className="pricing-box">
        <div className='pricing-box-container'>
            <h1> 6 Months</h1>
            <p className='pricing-box-p'>Exploit all features</p>
            <h2 className='pricing-box-h2'>{isLoading ? <Spinner size={SpinnerSize.SMALL} intent="warning"/>: (price * 6).toLocaleString()}<span className="currency">{currency}</span></h2>
            <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Unlimited branches.</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Unlimited workers.</p>
           </div>
           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>Full customization.</p>
           </div>

           <div className="event-bar">
            <Icon icon='tick' color="#7d2e4e" size={25}  className="event-icon"/>
            <p>SMS notifications.</p>
           </div>
           <button onClick={()=>navigate('/Signup')} id='pricing-box-btn'>Get started</button>
           </div>
        </div>
        </div><br/>
        </div>
        <div className="landing-page-footer">
          <div className="landing-page-footer-box">
            <ul>
            <li onClick={()=>navigate('/Landing')}>Features</li>
              <li onClick={()=>navigate('/Pricing')}>Pricing</li>
                <div onClick={()=>LoginFn()}><li>{isSpin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : "Demo"}</li></div>
                <a href="https://affiliate-kopaleo.web.app/"><li>Affiliate</li></a>
                <a href="mailto:support@macowigotech.com"><li>Contacts</li></a>
            </ul>
          </div>

          <div className="landing-page-footer-box-second">
          <ul>
                <a href="mailto:support@macowigotech.com"><li>Support</li></a>
                <a href="mailto:support@macowigotech.com"><li>Request feature</li></a>
                <a href="https://macowigotech.com/"><li>About us</li></a>
                <li onClick={()=>navigate('/Signup')}>Sign up</li>
            </ul>
          </div>

          <div className="landing-page-footer-box-third">
          <div className="more-card" >
            <img src={macowigo} alt="macowigo_logo" className="more-card-icon"  />
             <div className="more-card-title">
              <h2 style={{color:"#666"}}>MACOWIGO</h2>
               <p style={{color:"#666"}}>We are the technology.</p>
              </div> 
           </div>
          </div>
        </div>
      </div>
  )
}

export default Pricing