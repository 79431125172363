import React,{useState,useEffect} from 'react'
import logo from '../img/logo.png'
import {Link} from 'react-router-dom'
import {Icon} from '@blueprintjs/core'
import {getAuth,sendPasswordResetEmail} from 'firebase/auth'
import { useSnackbar } from 'react-simple-snackbar'

const auth = getAuth()

function Recovery() {
  const [email,setEmail] = useState(null)
  const [spin,stop] = useState(false)
	const [errorMessage,showErrorMessage] = useState(null)
  const [disabled,setDisabled] = useState(true)
  const [validEmail,show_email] = useState(null)
  const [openSnackbar] = useSnackbar()
   
  const recoveryAccount = async ()=>{

      try {
        const result = await sendPasswordResetEmail(auth,email)
        await openSnackbar( `Open your email inbox or spam folder/junk folder to recover your email`)
      } catch (error) {
          console.log(error,'on sending reovering password reset') 
          showErrorMessage(error)
      }
  }

  const validateEmail = (email) =>{
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(!email.match(validRegex)){
        show_email(true);
        setEmail(email)
    } else {
        show_email(false)
        setEmail(email)
    }

}
useEffect(() => {
  if(validEmail === null) {setDisabled(true)} else {setDisabled(false)}
},[email])

  return (
    <div>
        <div className="auth-nav">
            <img src={logo} />
                <h2 style={{color:"#fff"}}>Kopaleo</h2>
        </div>

        <div className="auth-container">
            <h2>Recover account</h2>
            <div className="input-bar">
                <Icon icon="envelope" color={"#5F6B7C"}  size={20} className='icon-auth'/>
                <input type="text" placeholder="E-mail" onChange={(e)=>validateEmail(e.target.value)} />
                {validEmail === null || validEmail === " " || email.length <= 0 ? <di/> :
               <Icon icon={validEmail ? "cross" :"tick-circle"} color={validEmail ? "#b41313": "#249E61" }  size={20} className='icon-auth'/>}
            </div>

            {errorMessage === null ? <div/> : 
			        <div className="input-bar" style={{borderWidth:0}}>
			        <Icon icon="error" color={"#b41313"}  size={20} className='icon-auth'/>
			        <p   style={{color:"#b41313"}}>{errorMessage.toString()}</p>
				      </div> 
			       }
            
            <button onClick={()=>recoveryAccount()} style={{ backgroundColor:disabled ? "#ccc" :"#07608B" }}>Send recovery link</button>
             <Link to="/Signup" style={{marginLeft:20}}>Don't have an account? Signup here</Link> <br /><br />


        </div>
    </div>
  )
}

export default Recovery