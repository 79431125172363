import React,{useContext,useState,useEffect} from 'react'
import {ThemeContext} from '../App'
import {Icon,Popover,Position,Menu,MenuItem,Collapse} from '@blueprintjs/core'
import Fab from '../components/Fab'
import {Link,useNavigate} from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import Loading from '../components/Loading'
import format from 'date-fns/format'
import Falling from '../components/Falling'
import Empty from '../components/Empty'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import AccessDenied from '../components/AccessDenied'
var moment = require('moment'); // require
moment().format();


function LoanTab({branchId,branch}) {
  const { nusery,card,lighttext,primary} = useContext(ThemeContext)
  const {loans,dispatch,loanReport,currentUser, pendingLoans} = useGlobalContextHook()
  const {permissions} = currentUser
  const {loans:loansPermissions} = permissions 
  const navigate = useNavigate()
  const [Limit,setLimit] = useState(10000)
  const [open,setOpen] = useState(false)
  const [openSnackbar, closeSnackbar] = useSnackbar()
  const [loanStatus, setloanStatus] = useState("all")
  const [datePicked,setDatePicked] = useState(new Date(0).toJSON().slice(0, 10))
  const [loading,setLoading] = useState(false)
  const {settings} = currentUser
  const {currency} = settings

useEffect(() => {
  const unSubscribe = async () => {
    try {
      if(branchId === null){
         //get all loans from all branches
         // console.log(branchId,'branchId')
      } else {
            setLoading(true)
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_BRANCH_LOANS&id=${branchId}&limit=${Limit}&date=${datePicked}&criteria=${loanStatus}`)
            const json = await response.json()
            if(response.ok){
                dispatch({type:'SET_BRANCH_LOANS',payload:json})
                setLoading(false)
            } 
            if(!response.ok){
                console.log("error fetching loans",response)
                setLoading(false)
            }
       }
   } catch (error) {
       console.log(error)
          setLoading(false)
      }            
  }
  unSubscribe();
  },[datePicked,Limit,loanStatus,branchId])

useEffect(() => {
  const unSubscribe = async () => {
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LOAN_REPORT&id=${branchId}`)
      const json = await response.json()
      if(response.ok){
        dispatch({type:'SET_LOAN_REPORT',payload:json})
      }
      if(!response.ok){openSnackbar(json.message)}
    } catch (error) {
      openSnackbar(error)
    }
  }
  unSubscribe()
},[loans,branchId]) 


useEffect(()=> {
 pendingPayments()
},[loans,branchId ])


const pendingPayments = async () => {
     try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LOAN_PENDING&id=${branchId}`)
      const json = await response.json()
      if(response.ok){
        //console.log(json,"loan pending")
        dispatch({type:'SET_PENDING_LOANS',payload:json})
      }
      if(!response.ok){openSnackbar(json.message)}
    } catch (error) {
      openSnackbar(error)
    }
}

 const searchLoan=async(keyword)=>{
    if(keyword.length <= 0){

    } else {
       try{
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SEARCH_BRANCH_LOAN&keyword=${keyword}&id=${branchId}&limit=${Limit}&criteria=${loanStatus}&date=${datePicked}`)
            const json = await response.json()
            if(response.ok){
                dispatch({type:'SET_BRANCH_LOANS',payload:json})
                setLoading(false)
                if(json.length <= 0){
                   openSnackbar('No results found')
                }
            } 
            if(!response.ok){
                console.log("error fetching loans",response)
                setLoading(false)
            }

         }catch(err){
          console.log(err)
         }
     }
 }
  
 const deleteLoan = async(id)=>{
     try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/remove?action=DELETE_LOAN&id=${id}`,{
        method: 'DELETE',
      })
      const json = await response.json()
      if(response.ok){
         dispatch({type:'REMOVE_LOAN', payload:json})
         openSnackbar("loan deleted")

      }
     }catch(err){
      console.log("error deleting loan",)
     }
 }

 const openLoan =(loanId)=>{
    navigate('/Loans/View',{state:`${loanId}`})
 }

 const showAll = ()=>{
  setDatePicked(new Date(0));
  setloanStatus('all')
 }

  return (
  <div>{loansPermissions[1].isGranted ?   <div style={{marginBottom:60}}>
  <div className="stat-box">
     <div className="stat-h3" style={{ backgroundColor:"#1B9E77"}}><Icon icon="dollar" style={{marginTop:-8}} color={lighttext} size={30} className="exp-icon-icon"/></div>
         <div className="stat-para"><h3 className="stat-h4">LOANS</h3><p>{branch}</p></div>
               <div></div>
               </div>
   <div className='loan-bar' style={{backgroundColor:card}}>
       <div className='loan-box'>
            <div className='loan-icon-box' style={{backgroundColor:primary}}><Icon icon="folder-open"  className='loan-icon' color={lighttext} size={20}/></div>
            <div className='loan-box-title'><h4>Total Loans released</h4>
               <p>
                 {loanReport.length <= 0 ? <Falling /> : loanReport[0].released.toLocaleString()}<span className="currency">{currency}</span>
                </p></div>
       </div>

       <div className='loan-box'>
            <div className='loan-icon-box' style={{backgroundColor:nusery}}><Icon icon="confirm"  className='loan-icon' color={lighttext} size={20}/></div>
            <div className='loan-box-title'><h4>Fully paid Loans</h4>
                 <p>
                 {loanReport.length <= 0 ? <Falling /> : loanReport[0].fullypaid.toLocaleString()}<span className="currency">{currency}</span>
                   </p></div>
       </div>

              <div className='loan-box'>
            <div className='loan-icon-box' style={{backgroundColor:"#FFC508"}}><Icon icon="confirm"  className='loan-icon' color={lighttext} size={20}/></div>
            <div className='loan-box-title'><h4>Pending Loans</h4>
                 <p>
                 {pendingLoans.length <= 0 ? <Falling /> : pendingLoans[0].totalRemainingAmount.toLocaleString()}<span className="currency">{currency}</span>
                   </p></div>
       </div>

       <div className='loan-box'>
            <div className='loan-icon-box' style={{backgroundColor:"#dd3549"}}><Icon icon="ban-circle"  className='loan-icon' color={lighttext} size={20}/></div>
            <div className='loan-box-title'><h4>Overdue Loans</h4>
                 <p>
                 {loanReport.length <= 0 ? <Falling /> : loanReport[0].overdue.toLocaleString()}<span className="currency">{currency}</span>
                   </p></div>
       </div>
   </div>

<div className='search-bar'>
 <div className="search-input">
   <input type="text" onChange={(e)=>searchLoan(e.target.value)}  placeholder="Search loan"/></div>
     <div className="search-option"><input type="date"  placeholder="Date" onChange={(e)=>setDatePicked(e.target.value)} />
         <Popover content=
         {
            <Menu>
                <p className='container-p'>sort by </p>
               <MenuItem    icon='expand-all' onClick={()=>showAll()} text="all"/>
               <MenuItem    icon='dollar' onClick={()=>setloanStatus("opened")} text="opened"/>
               <MenuItem    icon='path' onClick={()=>setloanStatus("ongoing")} text="ongoing"/>
               <MenuItem    icon='confirm' onClick={()=>setloanStatus("fullypaid")} text="fullypaid"/>
               <MenuItem     icon='ban-circle'onClick={()=>setloanStatus("overdue")}text="overdue loans" />
            </Menu>
            
           } position={Position.BOTTOM_BOTTOM}>
                    <button className='sort-btn' style={{marginTop:18}}>sorty by {loanStatus}<Icon icon="chevron-down" color={primary} /></button>
                 </Popover>
         </div>     
   <div className="far-menu-search" onClick={()=>setOpen(prev=>!prev)}>
     <Icon icon="filter-list" color={primary} size={20} className="search-icon-iconx" />
   </div>
   </div>
 
     <Collapse isOpen={open} canOutsideClickClose={true} onClose={()=>setOpen(prev=>!prev)}>
     <div className='filter-collapse'>
     <input type="date" placeholder="Date" value={datePicked} onChange={(e)=>setDatePicked(e.target.value)} style={{margin:5}} />
         <Popover content=
         {
            <Menu>
                <p className='container-p'>sort by </p>
               <MenuItem    icon='expand-all' onClick={()=>showAll()} text="all"/>
               <MenuItem    icon='dollar' onClick={()=>setloanStatus("opened")} text="opened"/>
               <MenuItem    icon='path' onClick={()=>setloanStatus("ongoing")} text="ongoing"/>
               <MenuItem    icon='confirm' onClick={()=>setloanStatus("fullypaid")} text="fullypaid"/>
               <MenuItem     icon='ban-circle'onClick={()=>setloanStatus("overdue")}text="overdue loans" />
            </Menu>
            
           } position={Position.BOTTOM_BOTTOM}>
                    <button className='sort-btn' style={{margin:5,border:0}} >sorty by {loanStatus}<Icon icon="chevron-down" color={primary} /></button>
                 </Popover>
     </div>
     </Collapse>
   <Link to="/Newloan" id="link">
        {loansPermissions[0].isGranted ?  <Fab  icon="add" text="New loan" action="NEW_LOAN"  />: <div />}
    </Link>
    <div className="table1" id="table-1">
   <div className="table1-header">
     <div className="header-title">Name</div>
     <div className="header-title">Cash</div>
     <div className="header-title">Interest</div>
     <div className="header-title">Final amount</div>
     <div className="header-title">Amount paid</div>
     <div className="header-title">Amount remained</div>
     <div className="header-title">Released date</div>
     <div className="header-title">Status</div>
     <div className="header-title">Menu</div>
   </div>

   <div className="table1-row-wrapper">
   {loans.length <= 0 ? <Empty icon="dollar" name="Currently,No any loan" color="#666" /> : 
     loans.map(loan =>(
     <div className="table1-cell-holder">
     <div className="table1-cell" onClick={()=>openLoan(loan._id)} >{loan.fullName === null ? 'not-added': loan.fullName}</div>  
     <div className="table1-cell">{loan.cash.toLocaleString()}</div>   
     <div className="table1-cell">{loan.interest}%</div>    
     <div className="table1-cell">{loan.final_amount.toLocaleString()}</div>    
     <div className="table1-cell">{loan.amountPaid.toLocaleString()}</div>    
     <div className="table1-cell">{(loan.final_amount - loan.amountPaid).toLocaleString()}</div>    
     <div className="table1-cell">{format(new Date(loan.releasedDate),'dd/MMMM/yyyy')}</div>       
     <div className='table1-cell' style={{display: 'inline-flex',
             color:loan.status === "opened" ? "#F59E0B": 
             loan.status === "fullypaid" ? nusery : 
             loan.status === "overdue" ? "#FF3366":
             loan.status === "ongoing" ? primary:
             "#ccc"
       }}>
       {loan.status}</div>       
      <div className="table1-cell">
      <Popover content={
          <Menu>
              <p className='container-p'>Options </p>
             <MenuItem    icon='eye-open' text="View" onClick={()=>openLoan(loan._id)} />
             {loansPermissions[3].isGranted ?  <MenuItem    icon='trash' text="Delete loan" onClick={()=>deleteLoan(loan._id)} />: <div />}
          </Menu>
          
         } position={Position.BOTTOM_BOTTOM}>
           <div className="table-menu" id="touchable">
          <Icon icon="more"  color="#666"  size={22}/></div> 
   </Popover>
         </div>
     </div>))
   }
   </div>
      </div> 
</div> : <AccessDenied />}</div>
  )
}

export default LoanTab