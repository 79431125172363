import React,{useContext,useState} from 'react'
import {ThemeContext} from '../App'
import {useNavigate,useLocation} from 'react-router-dom'
import format from 'date-fns/format'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {Switch,Alignment,Icon,Spinner,SpinnerSize} from '@blueprintjs/core'
import SelectBranch from '../components/SelectBranch'
import { useSnackbar } from 'react-simple-snackbar'
import MainLoader from '../components/MainLoader'
var moment = require('moment'); // require
moment().format();

function EditUser() {
    const [openSnackbar] = useSnackbar()
    const {state} = useLocation()
    const {permissions} = state
    const [isSpinning,setIsSpinning] = useState(false)
    const [loansPermissions,setLoansPermissions] =useState(permissions.loans)
    const [repaymentPermissions,setRepaymentPermissions] =useState(permissions.repayments)
    const [borrowerPermissions,setBorrowerPermissions] =useState(permissions.borrowers)
    const [expensesPermissions,setExpensesPermissions] =useState(permissions.expenses)
    const [workersPermissions,setWorkersPermissions] =useState(permissions.workers)
    const [branchesPermissions,setBranchesPermissions] =useState(permissions.branches)
    const [reportsPermissions,setReportsPermissions] =useState(permissions.reports)
    const [userName,setUserName] = useState(state === null ? "" : state.username)
    const [phone,setPhone] = useState(state === null ? "" : state.phoneNumber)
    const [userEmail,setUserEmail] = useState(state === null ? "" : state.email)
    const { body, nusery} = useContext(ThemeContext) 
    const {currentUser,dispatch,activeBranch} = useGlobalContextHook()
    const {role,settings} = currentUser
    const branchId = activeBranch === null ? null : currentUser.role === "worker" ? currentUser.branchId :  activeBranch._id
    const branch = activeBranch === null ? currentUser.branch : currentUser.role === "worker" ? currentUser.branch :  activeBranch.name
    const navigate = useNavigate()

    const toggleLoansPermissions =(selectedPermission)=>{
        let newLoanPermissions = loansPermissions.map(permission => {
             if(permission.id === selectedPermission.id ){
                 return {
                    ...permission,
                    id:selectedPermission.id,
                    name:selectedPermission.name,
                    isGranted: permission.isGranted ? false : true
                 }
             }
             return permission
        })
        setLoansPermissions(newLoanPermissions)
    }

    const toggleRepaymentPermissions =(selectedPermission)=>{
        let newrepaymentPermissions = repaymentPermissions.map(permission => {
             if(permission.id === selectedPermission.id ){
                 return {
                    ...permission,
                    id:selectedPermission.id,
                    name:selectedPermission.name,
                    isGranted: permission.isGranted ? false : true
                 }
             }
             return permission
        })
        setRepaymentPermissions(newrepaymentPermissions)
    }

    const toggleBorrowerPermissions =(selectedPermission)=>{
        let newborrowerPermissions = borrowerPermissions.map(permission => {
             if(permission.id === selectedPermission.id ){
                 return {
                    ...permission,
                    id:selectedPermission.id,
                    name:selectedPermission.name,
                    isGranted: permission.isGranted ? false : true
                 }
             }
             return permission
        })
        setBorrowerPermissions(newborrowerPermissions)
    }
    
    const toggleExpensesPermissions =(selectedPermission)=>{
        let newexpensesPermissions = expensesPermissions.map(permission => {
             if(permission.id === selectedPermission.id ){
                 return {
                    ...permission,
                    id:selectedPermission.id,
                    name:selectedPermission.name,
                    isGranted: permission.isGranted ? false : true
                 }
             }
             return permission
        })
        setExpensesPermissions(newexpensesPermissions)
    }
    
    const toggleWorkersPermissions =(selectedPermission)=>{
        let newworkersPermissions = workersPermissions.map(permission => {
             if(permission.id === selectedPermission.id ){
                 return {
                    ...permission,
                    id:selectedPermission.id,
                    name:selectedPermission.name,
                    isGranted: permission.isGranted ? false : true
                 }
             }
             return permission
        })
        setWorkersPermissions(newworkersPermissions)
    }

    const toggleBranchesPermissions =(selectedPermission)=>{
        let newbranchesPermissions = branchesPermissions.map(permission => {
             if(permission.id === selectedPermission.id ){
                 return {
                    ...permission,
                    id:selectedPermission.id,
                    name:selectedPermission.name,
                    isGranted: permission.isGranted ? false : true
                 }
             }
             return permission
        })
        setBranchesPermissions(newbranchesPermissions)
    }

    const toggleReportsPermissions =(selectedPermission)=>{
      let newreportsPermissions = reportsPermissions.map(permission => {
           if(permission.id === selectedPermission.id ){
               return {
                  ...permission,
                  id:selectedPermission.id,
                  name:selectedPermission.name,
                  isGranted: permission.isGranted ? false : true
               }
           }
           return permission
      })
      setReportsPermissions(newreportsPermissions)
  }  
    const savePermissionChanges = async() => {
        try {
            setIsSpinning(true)
            const {id} = state
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_USER_PERMISSIONS&id=${id}`,{
                method: 'PATCH',
                body: JSON.stringify({
                     loansPermissions:loansPermissions,
                     repaymentPermissions:repaymentPermissions,
                     borrowerPermissions:borrowerPermissions,
                     expensesPermissions:expensesPermissions,
                     workersPermissions:workersPermissions,
                     branchesPermissions:branchesPermissions,
                     reportsPermissions:reportsPermissions,
                }),
                headers: {'Content-Type': 'application/json'}
            })
          if(response.ok) {
            openSnackbar('Permission changes saved successfully!')
            setIsSpinning(false)
          }  
          if(!response.ok) {
            openSnackbar('Failed to save permissions')
            setIsSpinning(false)
          }
        } catch (err) {
            openSnackbar('Error durring saving changes')
        }
    }
    
    const saveProfileChanges =()=>{}

  return (
    <div className="main-wapper" >
         <TopNav /> 
        <div className="content-wrapper">
        <div className="sider" ><SiderContent  /></div>
        <div className="section" style={{backgroundColor:body}}> 
            {branchId === null && role === "company_admin" ? <SelectBranch /> : 
             <div>
            {state === null ? <MainLoader /> : 
            <div className="charts-wrapper">
             <div className="location-bar">
           {/**    <h1 className="clinic-h1" style={{paddingLeft:10}}>Edit worker's details</h1> */}
             </div>
             <h2 style={{marginLeft:10}}>Worker's details</h2>
            <div className="permission-container">
               <div className="container-input">
              <div className="station-input" style={{marginLeft:15}}>
                 <h4 style={{margin:"10px 4px 8px 4px"}}>Username</h4>
                  <input type="text" value={userName} onChange={(e)=>setUserName(e.target.value)}  disabled={true}/>
              </div>
           <div className="station-input">
               <h4 style={{margin:"10px 4px 8px 4px"}}>Phone number</h4>
             <input type="text"  value={phone}  onChange={(e)=>setPhone(e.target.value)} disabled={true} />
             </div>
          </div>
 
          <div className="station-input" style={{marginBottom:20,marginLeft:15}}>
                 <h4 style={{margin:"15px 4px 8px 4px"}}>E-mail</h4>
                  <input type="text" className="id" onChange={(e)=>setUserEmail(e.target.value)}   value={userEmail} disabled={true} />
              </div>
              <div>{/**<div className="just-button" id="not-disabled" onClick={()=>saveProfileChanges()}>
                Save changes
             </div> */}</div>
            </div> 
             <h2 style={{marginLeft:10}}>Persmissions</h2>
             <div className="permission-container">
                <h3>Loans</h3>
              {loansPermissions.length <= 0 ? <div /> : loansPermissions.map(permission=>(
                  <div className="permission-box" key={permission.name}><Switch checked={permission.isGranted} onChange={()=>toggleLoansPermissions(permission)} alignIndicator={Alignment.RIGHT} label={permission.name}  className="permission-switch" large={true} labelElement={permission.isGranted ? <Icon icon="tick-circle" color={nusery} style={{marginLeft:10}}/> : <div />}  /></div>
                ))}

                <h3>Repayments</h3>
                {repaymentPermissions.length <= 0 ? <div /> : repaymentPermissions.map(permission=>(
                  <div className="permission-box" key={permission.name} ><Switch checked={permission.isGranted} onChange={()=>toggleRepaymentPermissions(permission)} alignIndicator={Alignment.RIGHT} label={permission.name}  className="permission-switch" large={true} labelElement={permission.isGranted ? <Icon icon="tick-circle" color={nusery} style={{marginLeft:10}}/> : <div />}  /></div>
                 ))}

                <h3>Borrowers</h3>
                {borrowerPermissions.length <= 0 ? <div /> : borrowerPermissions.map(permission=>(
                  <div className="permission-box"  key={permission.name}><Switch checked={permission.isGranted} onChange={()=>toggleBorrowerPermissions(permission)} alignIndicator={Alignment.RIGHT} label={permission.name}  className="permission-switch" large={true} labelElement={permission.isGranted ? <Icon icon="tick-circle" color={nusery} style={{marginLeft:10}}/> : <div />}  /></div>
                 ))}

                <h3>Expenses</h3>
                {expensesPermissions.length <= 0 ? <div /> : expensesPermissions.map(permission=>(
                  <div className="permission-box"  key={permission.name}><Switch checked={permission.isGranted} onChange={()=>toggleExpensesPermissions(permission)} alignIndicator={Alignment.RIGHT} label={permission.name}  className="permission-switch" large={true} labelElement={permission.isGranted ? <Icon icon="tick-circle" color={nusery} style={{marginLeft:10}}/> : <div />}  /></div>
                 ))}

                <h3>Workers</h3>
                {workersPermissions.length <= 0 ? <div /> : workersPermissions.map(permission=>(
                  <div className="permission-box" key={permission.name}><Switch checked={permission.isGranted} onChange={()=>toggleWorkersPermissions(permission)} alignIndicator={Alignment.RIGHT} label={permission.name}  className="permission-switch" large={true} labelElement={permission.isGranted ? <Icon icon="tick-circle" color={nusery} style={{marginLeft:10}}/> : <div />}  /></div>
                 ))}

                <h3>Branches</h3>
                {branchesPermissions.length <= 0 ? <div /> : branchesPermissions.map(permission=>(
                  <div className="permission-box" key={permission.name}><Switch checked={permission.isGranted} onChange={()=>toggleBranchesPermissions(permission)} alignIndicator={Alignment.RIGHT} label={permission.name}  className="permission-switch" large={true} labelElement={permission.isGranted ? <Icon icon="tick-circle" color={nusery} style={{marginLeft:10}}/> : <div />}  /></div>
                 ))}

              <h3>Reports</h3>
                {reportsPermissions.length <= 0 ? <div /> : reportsPermissions.map(permission=>(
                  <div className="permission-box" key={permission.name}><Switch checked={permission.isGranted} onChange={()=>toggleReportsPermissions(permission)} alignIndicator={Alignment.RIGHT} label={permission.name}  className="permission-switch" large={true} labelElement={permission.isGranted ? <Icon icon="tick-circle" color={nusery} style={{marginLeft:10}}/> : <div />}  /></div>
                 ))}
             </div>
             <div className="just-button" id="not-disabled" onClick={()=>savePermissionChanges()}>
                Save changes
                {isSpinning ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
             </div>
           </div>}
             </div>
             }
        </div>
    </div>
    </div>
  )
}

export default EditUser