import React,{useContext,useState} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {ThemeContext} from '../App'
import ReportsTab from '../tabs/ReportsTab'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import SelectBranch from '../components/SelectBranch'
import AccessDenied from '../components/AccessDenied'

function Report() {
    const { body} = useContext(ThemeContext)
    const {activeBranch,currentUser} = useGlobalContextHook()
    const {role,branch,branchId,permissions} = currentUser
    const {reports} = permissions
 
  return (
    <div className="main-wapper" >
         <TopNav />
<div className="content-wrapper">
<div className="sider" >
            <SiderContent />
        </div>
   {reports[1].isGranted ?        <div className="section" style={{backgroundColor:body}}> 
        <div className="-ps-wr"><br />
        {activeBranch === null && role === "company_admin" ? <SelectBranch /> : 
         activeBranch !== null && role === "worker"  ?   <ReportsTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> :
           <ReportsTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> }
        </div>
           </div> : <AccessDenied />}
</div>
    </div>
  )
}

export default Report