import React,{useState} from 'react'
import  logo from '../img/logo.png'
import {Icon,Drawer,Position,DrawerSize,Spinner,SpinnerSize} from '@blueprintjs/core'
import {useNavigate,Link} from 'react-router-dom'
import {getAuth,signInWithEmailAndPassword} from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth';

const auth = getAuth()

function Lnavbar() {
  const [user]= useAuthState(auth)
  const [isSpin,setIsSpin] = useState(false)
  const [isDrawerOpen,setIsDrawerOpen] = useState(false)
  const navigate = useNavigate()

  const LoginFn = async()=>{
    try {
        setIsSpin(true);
        await signInWithEmailAndPassword(auth,'demo@mail.com', '1234567')        
         await setIsSpin(false);
         navigate('/') 
    } catch (error) {
       setIsSpin(false)
    }
}
  return (
    <div className='landig-page-navbar'>
        <img src={logo} alt={"logo"} />
        <h1>Kopaleo</h1>
        <div className='landing-page-nav-li'>
            <ul className='landing-page-nav-li'>
                <Link to="/Landing"><li>Features</li></Link>
                <Link to="/Pricing"><li>Pricing</li></Link>
                <div onClick={()=>LoginFn()}><li>{isSpin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : "Demo"}</li></div>
                <a href="https://affiliate-kopaleo.web.app/"><li>Affiliate</li></a>
                <a href="mailto:support@macowigotech.com"><li>Contacts</li></a>
            </ul>
        </div>
        <div class="landing-page-nav-far-div">
            <button onClick={()=>navigate('/Signup')}>Sign up</button>
            <div class="landing-nav-menu" onClick={()=>setIsDrawerOpen(prev=>!prev)} id="triad"><Icon className="icon-landing-menu" icon="menu" color="#07608b" size={22} /></div>
        </div>
        <Drawer 
                isOpen={isDrawerOpen} 
                position={Position.LEFT}
                size={DrawerSize.LARGE} 
                title="Menu"
                canClickOutsideToClose={true} onClose={()=>setIsDrawerOpen(prev=>!prev)}>
            <div className= 'landing-page-drawer'>
            <ul>
               <Link to="/Landing"><li>Features</li></Link>
                <Link to="/Pricing"><li>Pricing</li></Link>
                <div onClick={()=>LoginFn()}><li>{isSpin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : "Demo"}</li></div>
                <a href="https://affiliate-kopaleo.web.app/"><li>Affiliate</li></a>
                <a href="mailto:support@macowigotech.com"><li>Contacts</li></a>
            </ul>
            <button onClick={()=>navigate('/Signup')}>Sign up</button>
            </div>
            </Drawer>    
    </div>
  )
}

export default Lnavbar