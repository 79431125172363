import React,{useContext,useState} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {ThemeContext,UserContext} from '../App'
import {Dialog} from '@blueprintjs/core'
import Newbranch from '../components/Newbranch'
import RepaymentTab from '../tabs/RepaymentTab'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import SelectBranch from '../components/SelectBranch'
import AccessDenied from '../components/AccessDenied'


function Repayments() {
    const { body} = useContext(ThemeContext)
    const {currentUser,activeBranch} = useGlobalContextHook()
    const {role,branchId,branch} = currentUser
    const {permissions} = currentUser
    const {repayments} = permissions
    const [open, close] = useState(false);
  
  return (
    <div className="main-wapper" >
         <TopNav />
    <div className="content-wrapper">
    <div className="sider" >
            <SiderContent />
        </div>
        <div className="section" style={{backgroundColor:body}}> 
        {!repayments[1].isGranted ?  <AccessDenied /> : <div className="-ps-wr">
       {activeBranch === null && role === "company_admin" ? <SelectBranch /> : 
         activeBranch !== null && role === "worker"  ?   <RepaymentTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> :
           <RepaymentTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> }
         </div>}
           </div>

        <Dialog isOpen={open} canOutsideClickClose={true} onClose={()=>close(prev=>!prev)}>
            <Newbranch />
        </Dialog>
    </div>
    </div>
  )
}

export default Repayments