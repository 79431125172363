import React,{useState,useEffect} from 'react'
import {Icon,Popover,Position,Menu} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import { useSnackbar } from 'react-simple-snackbar'
import Empty  from '../components/Empty'
import format from 'date-fns/format'
var moment = require('moment'); // require
moment().format();

function TrialBalance({branchId, branch}) {
  const { currentUser,trialBalance,dispatch,expenses} = useGlobalContextHook()
  const { settings, companyId} = currentUser
  const { currency} = settings
  const [openSnackbar] = useSnackbar()
  const [trTotal,setTrTotal] = useState([])
  const [totalExpenses,setTotalExpense] = useState(0)
  const date = moment.utc();
  const [fromDate,setFromDate] = useState(date.clone().add(-10,"days").format("YYYY-MM-DD"))
  const [toDate,setToDate] = useState(date.clone().add(1,"days").format("YYYY-MM-DD"))

  const setToDateFn =(date)=>{
    let newDate = moment.utc(date)
    setToDate(newDate.clone().add(1,"days").format("YYYY-MM-DD"))     
}

const createTrialBalance =async()=>{
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=TRIAL_BALANCE&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
    const json = await response.json()
      if(response.ok) {
        dispatch({type:'SET_TRIAL_BALANCE',payload:json})
      }
      if(!response.ok) {
        openSnackbar('Failed to generate trial balance')
      }

  } catch (e) {
    openSnackbar('Error while getting transaction list')
  }
}

const getTrialBalanceTotals =async()=>{
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=TRIAL_BALANCE_TOTALS&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
    const json = await response.json()
      if(response.ok) {
       setTrTotal(json)
      }
      if(!response.ok) {
        openSnackbar('Failed to generate trial balance')
      }

  } catch (e) {
    openSnackbar('Error while getting transaction list')
  }
}

const listExpenses = async () => {
  try{
    if(branchId === null){
    } else {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_APPROVED_EXPENSES&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
      const json = await response.json()
        if(response.ok){
           dispatch({type:'SET_EXPENSES',payload:json})
        } else {
          openSnackbar(`Could not fetch expense`)
        }
    }
  } catch(error){
    openSnackbar('Failed to fetch expenses')
   }
  }

  const sumExpenses =()=>{
     if(expenses.length > 0) { 
       const summedTotalExpenses = expenses.reduce((accumulator,expense)=>{
         return accumulator + Number(expense.expenseAmount)
       },0)
       setTotalExpense(summedTotalExpenses)
     } else {}
  }

  const getCompanyInfo = async()=>{
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_USER_BY_COMPANY_ID&id=${companyId}`)
      const json = await response.json()
      if(response.ok){
        printTrialBalance(json[0])
      }
      if(!response.ok){
        openSnackbar(json.message)
      }
    }catch(error){openSnackbar("Error getting company")}
  }


  const printTrialBalance =async(companyInfo)=>{
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=PRINT_TRIAL_BALANCE&id=${branchId}&branch=${branch}&fromDate=${fromDate}&toDate=${toDate}`,{
        method: 'POST',
        body: JSON.stringify({ 
          trialBalance:trialBalance,
          expenses: expenses,
          trTotal: trTotal,
          companyInfo:companyInfo
        }),
        headers: {'Content-Type': 'application/json'}
      })

      if(response.ok){
        const file = await response.blob()
        const createdFile =  await window.URL.createObjectURL(new Blob([file]), { type: 'application/pdf'})
        const link = document.createElement('a');
        link.href = createdFile;
        link.download =`Trial balance from${fromDate}=>${toDate}.pdf`
        link.click();
      }
    
      if(!response.ok){
        openSnackbar("Error creating pdf")
      }
    } catch(e){
      console.log(e)
       openSnackbar('Error during printing ledger')
    }
  }
  
useEffect(() => {
  createTrialBalance()
  getTrialBalanceTotals()
  listExpenses()
  sumExpenses()
},[branchId,fromDate,toDate])

  return (
    <div className="ledger-wrapper">
      <div className="ledger-bar">
        <h2>Trial Balance</h2>
        <div className="ledger-far">
        <Popover content=
         {
            <Menu>
                <p className='container-p'>Filter options </p>
                <div className="date-picker">
                  <div className="from" style={{marginLeft:15}}>
                    <p>From</p>
                    <input type="date" value={fromDate} placeholder="Date" onChange={(e)=>setFromDate(e.target.value)} />
                  </div> <br />
                  <div className="from" style={{marginLeft:15}}>
                    <p>To</p> 
                    <input type="date" value={toDate} placeholder="Date" onChange={(e)=>setToDateFn(e.target.value)}  />
                  </div>
                  <button className="just-button" onClick={()=>getCompanyInfo()} style={{margin:15}} id="touchable">Export PDF</button>
              </div>
            </Menu>
            
           } position={Position.BOTTOM_BOTTOM}>
          <div className="ledger-far" id="touchable">
          <Icon icon="filter-list" color="#666" size={24} />
        </div> 
        </Popover>   
        </div>
      </div>
      <div className="ledger-info">
          <h3>{format(new Date(fromDate),'dd/MMMM/yyyy')} - {format(new Date(toDate),'dd/MMMM/yyyy')}</h3>
        </div>
         <div className="account-box">
            <div className="accountbox-bar"><h3>Summary of accounts</h3> <p></p></div>
              <div className="transaction-table">
            <div className="transaction-table-row">
              <div className="transaction-table-row-title">Account name </div>
              <div className="transaction-table-row-title">Type</div>
              <div className="transaction-table-row-title">Debit </div>
              <div className="transaction-table-row-title">Credit </div>
              </div>

                     
              <div className="transaction-table-wrapper">
              {trialBalance.length <= 0 ? <div /> : trialBalance.map(tr=>(
                <div className="transaction-table-cell-row" key={tr._id}>
                <div className="transaction-table-row-cell">{tr._accountName}&emsp;</div>
                  <div className="transaction-table-row-cell">{tr._accountType}</div>
                  <div className="transaction-table-row-cell">{tr.debit.toLocaleString()}<span className='currency'>{currency}</span></div>
                  <div className="transaction-table-row-cell">{tr.credit.toLocaleString()} <span className='currency'>{currency}</span></div>
              </div>
              ))} 
              <div className="transaction-table-cell-row">
                <div className="transaction-table-row-cell">Branch Expenses&emsp;</div>
                  <div className="transaction-table-row-cell">asset</div>
                  <div className="transaction-table-row-cell">{totalExpenses.toLocaleString()}<span className='currency'>{currency}</span></div>
                  <div className="transaction-table-row-cell"> 0<span className='currency'>{currency}</span></div>
              </div>
              <div className="transaction-table-cell-row">
                <div className="transaction-table-row-cell">Branch Petty cash&emsp;</div>
                  <div className="transaction-table-row-cell">asset</div>
                  <div className="transaction-table-row-cell">0<span className='currency'>{currency}</span></div>
                  <div className="transaction-table-row-cell">{totalExpenses.toLocaleString()}<span className='currency'>{currency}</span></div>
              </div>
                   </div>
              </div>
              <div className='net-movement'>
              <div className="transaction-table-cell-row" style={{display:'inline-flex',marginTop:12}}>
                <div className="transaction-table-row-cell" style={{color:'#444'}}>Total&emsp;</div>
                  <div className="transaction-table-row-cell"></div>
                  <div className="transaction-table-row-cell">{trTotal.length <=0 ? 0 :(Number(trTotal[0].totalDebit) + Number(totalExpenses)).toLocaleString()}<span className='currency'>{currency}</span></div>
                  <div className="transaction-table-row-cell">{trTotal.length <=0 ? 0 :(Number(trTotal[0].totalCredit) + Number(totalExpenses)).toLocaleString()}<span className='currency'>{currency}</span></div>
              </div>
                  </div>
         </div>
    </div>
  )
}

export default TrialBalance