import React from 'react'
import logo from '../img/logo.png'
import {Link} from 'react-router-dom'

function NotFound() {
  return (
    <div>
         <div className="auth-nav">
            <img src={logo} />
                <h2 style={{color:"#fff"}}>Kopaleo</h2>
        </div>
        <div id="auth-container">
            <h1 >Ooops!</h1>
                <h3>404-Page not found</h3>
                <p>Go to the login page
                </p>
              <Link to="/login" >
              <button className="btn-go-to-login">Go to Login</button></Link>  
        </div>
    </div>
  )
}

export default NotFound