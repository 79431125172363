import React,{useContext,useState} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {ThemeContext} from '../App'
import {Dialog} from '@blueprintjs/core'
import Newbranch from '../components/Newbranch'
import LoanTab from '../tabs/LoanTab'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import SelectBranch  from '../components/SelectBranch'
import AccessDenied from '../components/AccessDenied'


function LoanScreen() {
    const {currentUser,activeBranch} = useGlobalContextHook()
    const { body} = useContext(ThemeContext)
    const [open, close] = useState(false);
    const {role,branchId,branch,permissions} = currentUser   
    const {loans:loansPermissions} = permissions 
  
  return (
    <div className="main-wapper" >
         <TopNav /> 
    <div className="content-wrapper">
    <div className="sider" >
            <SiderContent />
        </div>
        <div className="section" style={{backgroundColor:body}}> 
       {!loansPermissions[1].isGranted ? <AccessDenied /> :  <div className="-ps-wr" style={{backgroundColor:body}}> <br />
         {activeBranch === null && role === "company_admin" ? <SelectBranch /> : 
         activeBranch !== null && role === "worker"  ?   <LoanTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> :
           <LoanTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> }
           </div> }

        <Dialog isOpen={open} canOutsideClickClose={true} onClose={()=>close(prev=>!prev)}>
            <Newbranch />
        </Dialog>
    </div>
    </div> </div>
  )
}

export default LoanScreen