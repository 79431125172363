import React,{useContext,useState} from 'react'
import SiderContent from '../components/SiderContent'
import TopNav from '../components/TopNav'
import {ThemeContext} from '../App'
import BorrowersList from '../Borrowertabs/BorrowersList'
import GroupBorrowers from '../Borrowertabs/GroupBorrowers'
import BulkySms from '../Borrowertabs/BulkySms'
import { Tab, Tabs, Icon} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import { useNavigate } from 'react-router-dom'
import SelectBranch  from '../components/SelectBranch'
import AccessDenied from '../components/AccessDenied'


function Borrowers() {
    const {currentUser,activeBranch} = useGlobalContextHook()
    const {role,company,companyId} = currentUser
    const {permissions} = currentUser
    const {borrowers} = permissions
    const branchId = activeBranch === null ? currentUser.branchId : currentUser.role === "worker" ? currentUser.branchId :  activeBranch._id
    const branch = activeBranch === null ? currentUser.branch : currentUser.role === "worker" ? currentUser.branch :  activeBranch.name
    const {body,lighttext} = useContext(ThemeContext)
    const [selectedTabId,setSelectedTab] = useState("1")
    const navigate = useNavigate()
    const [tabs,setTabs] = useState([
        {id:"1",title:"Borrowers list"},
        {id:"2",title:"Borrowers groups"},
        {id:"3",title:"SMS borrowers"}
         ])
    
    const handleTabChange =(id)=>{
        setSelectedTab(id)
     }
   
  return (
    <div className="main-wapper" >
        <TopNav />
        <div className="content-wrapper">
            <div className="sider" >
             <SiderContent />
         </div>
        {!borrowers[1].isGranted ?  <AccessDenied /> :
                 <div className="section" style={{backgroundColor:body}}>
                 <div className="stat-box" style={{width:'96%',marginLeft:'1%'}}>
                  <div className="stat-h3" style={{ backgroundColor:'#8E44AD'}}><Icon icon="inherited-group" style={{marginTop:-8}} color={lighttext} size={30} className="exp-icon-icon"/></div>
                      <div className="stat-para"><h3 className="stat-h4">BORROWERS</h3><p>{company}</p></div>
                            <div></div>
                            </div>
                
                {activeBranch === null && role === "company_admin" ? <SelectBranch /> : 
                 activeBranch !== null && role === "worker" ?  
                 <Tabs className="Tabs" onChange={(id)=>handleTabChange(id)}  animate={true} selectedTabId={selectedTabId}  > 
                 {tabs.map((tab, i)=>(
                    <Tab key={i} id={tab.id} title={tab.title}   style={{outline: "none",fontWeight: "bold"}} 
                         panel={
                            <div>
                                { tab.id === "1" ? <BorrowersList branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> : 
                                  tab.id === "2" ? <GroupBorrowers branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id }/> : <div />
                                 }
                            </div>
                              }
                         />
                         ))}  
                         </Tabs> : 
                           <Tabs className="Tabs" onChange={(id)=>handleTabChange(id)}  animate={true} selectedTabId={selectedTabId}  > 
                           {tabs.map((tab, i)=>(
                              <Tab key={i} id={tab.id} title={tab.title}   style={{outline: "none",fontWeight: "bold"}} 
                                   panel={
                                      <div>
                                          { tab.id === "1" ? <BorrowersList branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> :
                                             tab.id === "2" ? <GroupBorrowers branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId: activeBranch._id } /> :
                                             tab.id === "3" ? <BulkySms branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId: activeBranch._id } /> :
                                           <div />
                                           }
                                      </div>
                                        }
                                   />
                                   ))}  
                                   </Tabs>       
                         }
                    </div>
        }
        </div>
    </div>
  )
}

export default Borrowers