import React,{useContext,useState,useEffect,useRef} from 'react'
import {ThemeContext} from '../App'
import {useNavigate,useLocation} from 'react-router-dom'
import Loading from '../components/Loading'
import format from 'date-fns/format'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {Icon,MenuItem,Menu,Popover,Position} from '@blueprintjs/core'
import SelectBranch from '../components/SelectBranch'
import { useSnackbar } from 'react-simple-snackbar'
import MainLoader from '../components/MainLoader'
import Empty from '../components/Empty'

function ViewGroupLoan() {
    const {state} = useLocation()
    const [openSnackbar] = useSnackbar()
    const navigate = useNavigate()
    const {body,primary,lighttext,card,nusery} = useContext(ThemeContext) 
    const {currentUser,activeBranch,membersLoans,dispatch} = useGlobalContextHook()
    const [loanStatus, setloanStatus] = useState("all")
    const [datePicked,setDatePicked] = useState(new Date(0).toJSON().slice(0, 10))
    const [Limit, setLimit] = useState(100000)
    const {role,settings} = currentUser
    const {currency} = settings
    const branchId = activeBranch === null ? null : currentUser.role === "worker" ? currentUser.branchId :  activeBranch._id
    const branch = activeBranch === null ? currentUser.branch : currentUser.role === "worker" ? currentUser.branch :  activeBranch.name

    const listLoans =async()=>{
        try {
            const {_id} = state || "undefinedid"
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_BRANCH_GROUP_LOANS&id=${_id}&limit=${Limit}&date=${datePicked}&criteria=${loanStatus}`)
            const json = await response.json()
            if(response.ok) { 
                console.log(json)
                dispatch({type: 'SET_MEMBERS_LOANS',payload:json})
            }
            if(!response.ok) {
                openSnackbar('Failed to list loans')
            }
        } catch (e) {
            openSnackbar(`Error during listing loans`)
        }
    }

    const openLoan =(loanId)=>{
        navigate('/Loans/View',{state:`${loanId}`})
      }
    const editLoan =(loanId)=>{}
    const removeLoan =async(loanId)=>{
        try{
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/remove?action=DELETE_LOAN&id=${loanId}`,{
              method: 'DELETE',
            })
            const json = await response.json()
            if(response.ok){
               dispatch({type:'REMOVE_MEMBER_LOAN', payload:json})
               openSnackbar("loan deleted")
      
            }
           }catch(err){
            console.log("error deleting loan",)
           }
    }
    //list related group loan
    useEffect(() => {
        if(state === null) {} else {
            listLoans()
        }
    },[branchId,datePicked,loanStatus,state])
  return (
    <div className="main-wapper" >
         <TopNav /> 
        <div className="content-wrapper">
        <div className="sider" ><SiderContent  /></div>
        <div className="section" style={{backgroundColor:body}}> 
            {branchId === null && role === "company_admin" ? <SelectBranch /> : 
             state === null ? <MainLoader /> : 
             <div className="charts-wrapper">
             <div className='loan-bar' style={{backgroundColor:card}}>
             <div className='loan-box'>
                  <div className='loan-icon-box' style={{backgroundColor:primary}}><Icon icon="inherited-group"  className='loan-icon' color={lighttext} size={20}/></div>
                  <div className='loan-box-title'><h4> Group loan details</h4><p>{state.name}</p></div>
             </div>
              </div>
            <div className='loan-bar' style={{backgroundColor:card,marginTop:20,width:'99%'}}>
            <div className='loan-box'>
                 <div className='loan-icon-box' style={{backgroundColor:primary}}><Icon icon="folder-open"  className='loan-icon' color={lighttext} size={20}/></div>
                 <div className='loan-box-title'><h4>Final Amount</h4>
                    <p>
                    {state.finalAmount.toLocaleString()}<span className="currency">{currency}</span>
                     </p></div>
            </div>

            <div className='loan-box'>
                 <div className='loan-icon-box' style={{backgroundColor:nusery}}><Icon icon="confirm"  className='loan-icon' color={lighttext} size={20}/></div>
                 <div className='loan-box-title'><h4>Amount Paid</h4>
                      <p>
                      {state.amountPaid.toLocaleString()}<span className="currency">{currency}</span>
                        </p></div>
            </div>

            <div className='loan-box'>
                 <div className='loan-icon-box' style={{backgroundColor:"#dd3549"}}><Icon icon="ban-circle"  className='loan-icon' color={lighttext} size={20}/></div>
                 <div className='loan-box-title'><h4>Amount Remained</h4>
                      <p>
                      {state.amountRemained.toLocaleString()}<span className="currency">{currency}</span>
                        </p></div>
            </div>
           </div>
              <h2 style={{marginLeft:20}}>Members </h2>
              <div className="table1" id="table-1">
        <div className="table1-header">
          <div className="header-title">Name</div>
          <div className="header-title">Cash</div>
          <div className="header-title">Interest</div>
          <div className="header-title">Final amount</div>
          <div className="header-title">Amount paid</div>
          <div className="header-title">Amount remained</div>
          <div className="header-title">Released date</div>
          <div className="header-title">Menu</div>
        </div>

        <div className="table1-row-wrapper">
        {membersLoans.length <= 0 ? <Empty icon="dollar" name="Currently,No any loan" color="#666" /> : 
          membersLoans.map(loan =>(
          <div className="table1-cell-holder">
          <div className="table1-cell" onClick={()=>openLoan(loan._id)} >{loan.fullName === null ? 'not-added': loan.fullName}</div>  
          <div className="table1-cell">{loan.cash.toLocaleString()}</div>   
          <div className="table1-cell">{loan.interest}%</div>    
          <div className="table1-cell">{loan.final_amount.toLocaleString()}</div>    
          <div className="table1-cell">{loan.amountPaid.toLocaleString()}</div>    
          <div className="table1-cell">{(loan.final_amount - loan.amountPaid).toLocaleString()}</div>    
          <div className="table1-cell">{format(new Date(loan.releasedDate),'dd/MMMM/yyyy')}</div>       
           <div className="table1-cell">
           <Popover content={
               <Menu>
                   <p className='container-p'>Options </p>
                  <MenuItem    icon='eye-open' text="View" onClick={()=>openLoan(loan._id)} />
                  <MenuItem    icon='trash' text="Delete loan" onClick={()=>removeLoan(loan._id)} />
               </Menu>
               
              } position={Position.BOTTOM_BOTTOM}>
                <div className="table-menu" id="touchable">
               <Icon icon="more"  color="#666"  size={22}/></div> 
        </Popover>
              </div>
          </div>))
        }
        </div>
           </div> 
             </div>
             }
        </div>
    </div>
    </div>
  )
}

export default ViewGroupLoan