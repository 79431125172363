import React,{useContext,useState,useEffect} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {ThemeContext} from '../App'
import {Tab,Tabs, Icon} from '@blueprintjs/core'
import PersonalTab from '../loantab/PersonalTab'
import LoanTab from '../loantab/LoanTab'
import OtherTab from '../loantab/OtherTab'
import {useLocation} from 'react-router-dom'
import Falling from '../components/Falling'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'

function ViewLoan() {
    const {  primary,lighttext,card,body} = useContext(ThemeContext)
    const {state} = useLocation()
    const {currentUser} = useGlobalContextHook()
    const {currency} = currentUser

    const [loan,setLoan] = useState(null)
    const [selectedTabId,setSelectedTab] = useState("1")
    const [tabs,setTabs] = useState([
      {id:"1",title:"Personal details",},{id:"2",title:"Loan details"},{id:"3",title:"Options"}
       ])

       
    const handleTabChange =(id)=>{
        setSelectedTab(id)
      }
    
      useEffect(() => {
          const unSubscribe = async () => {
               const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=GET_ONE_LOAN&id=${state}`)
               const json = await response.json()
               if(response.ok){
                setLoan(json)
               }
               if(!response.ok){
                console.log("Error",response)
               }
          }
          unSubscribe()
          return ()=>unSubscribe();
      },[state])



  return (
    <div className="main-wapper" >
         <TopNav />
    <div className="content-wrapper">
    <div className="sider" >
            <SiderContent />
        </div>
        <div className="section" style={{backgroundColor:body}}><br />
            <div className="-ps-wr">
            <div className='loan-bar' style={{backgroundColor:card}}>
            <div className='loan-box'>
                 <div className='loan-icon-box' style={{backgroundColor:primary}}><Icon icon="dollar"  className='loan-icon' color={lighttext} size={20}/></div>
                 <div className='loan-box-title'><h4>Loan details</h4><p>{loan === null ? "" :loan.fullName}</p></div>
            </div>

           
        </div>     
                {loan === null ? <Falling /> : 
                
                <Tabs className="Tabs" onChange={(id)=>handleTabChange(id)}  animate={true} selectedTabId={selectedTabId}  > 
                       {tabs.map((tab, i)=>(
                          <Tab key={i} id={tab.id} title={tab.title}  
                               panel={<div> {
                                  tab.id === "1" ? <PersonalTab  loan={loan}/> :
                                    tab.id === "2" ? <LoanTab  loan={loan} /> : 
                                    tab.id === "3" ? <OtherTab loan={loan} /> : 
                                          <LoanTab />
                                      }
                                  </div>
                               } style={{outline: "none",fontWeight: "bold"}} />
                       ))} 
                  </Tabs>
                    }
            </div>
           </div>
    </div>
    </div>
  )
}

export default ViewLoan