import React,{useState,useContext,useEffect} from 'react'
import SiderContent from '../components/SiderContent'
import TopNav from '../components/TopNav'
import {ThemeContext} from '../App'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {Icon,Spinner,SpinnerSize} from '@blueprintjs/core'
import {useSnackbar} from 'react-simple-snackbar'
import SelectBranch from '../components/SelectBranch'
import {useNavigate} from 'react-router-dom'

function Addborrower() {
    const navigate = useNavigate()
    const [openSnackbar] = useSnackbar()
    const {currentUser,activeBranch} = useGlobalContextHook()
    const {role,company,companyId} = currentUser
    const branchId = activeBranch === null ? currentUser.branchId : currentUser.role === "worker" ? currentUser.branchId :  activeBranch._id
    const branch = activeBranch === null ? currentUser.branch : currentUser.role === "worker" ? currentUser.branch :  activeBranch.name
    const {body} = useContext(ThemeContext)
    const [fullname,setFullName] = useState("")
    const [gender,setGender] = useState("male")
    const [residence,setResidence] = useState("")
    const [phone,setPhone] = useState("")
    const [age,setAge] = useState("")
    const [idNumber,setIdNumber] = useState("")
    const [idNumber2,setIdNumber2] = useState("")
    const [occupation,setOccupation] = useState("")
    const [bankName,setBankName] = useState("NMB")
    const [accountNumber,setAccountNumber] = useState("")
    const [accountStatus,setAccountStatus] = useState(false)
    const [countryCode,setCountryCode] = useState(255)
    const [isSpinning,setIsSpinning] = useState(false)
    const [isSpinning2,setIsSpinning2] = useState(false)
    const [photo,setPhoto] = useState("https://kopaleo.com/api/img/logos/siba.png")
    const [selectedImage,setSelectedImage] = useState(null)
    const [isDisabled,setIsDisabled] = useState(true)
	const [idType,setIdType] = useState("national_id");
	const [idType2,setIdType2] = useState("national_id");

    const uploadPassportSize = async()=>{
        if(selectedImage === null){
          openSnackbar(`Select first photo to upload!`)
        } else {
          try{
            setIsSpinning(true)
            const formData = new FormData();
            formData.append(`myFile`,selectedImage)
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=UPLOAD_PASSPORT`,{
             method: 'POST',
             body: formData
            })
            const json = await response.json()
            if(response.ok){
             openSnackbar('upload success')
             setPhoto(`${process.env.REACT_APP_SITE_URL}/${json.message}`)
             setIsSpinning(false); setSelectedImage(null)
           } else {openSnackbar("Failed to upload"); console.log(json.message)}
          } catch(e){
            openSnackbar("Error during uploading passport size image")
          }
        }
    }

    const handleImagePicker =(file)=>{
        let image = URL.createObjectURL(file)
        setSelectedImage(file)
        setPhoto(image)
    }

    const Addborrower = async()=>{
      setIsSpinning2(true)
      try{
		const cleanNumber = parseInt(phone,10);
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=ADD_BORROWER`,{
          method: 'POST',
          body: JSON.stringify({
            age:Number(age),
            fullName:fullname,
            occupation:occupation,
            phoneNumber:countryCode+""+cleanNumber,
            sex:gender,
			idType:idType,
			idType2:idType2,
            idNumber1:idNumber,
            idNumber2:idNumber2,
            passportImage:photo,
            branch:branch,
            branchId:branchId,
            company:company,
            companyId:companyId,
            bankName:bankName,
			residence:residence,
            accountNumber:accountNumber,
            accountStatus:accountStatus
          }),
          headers: {'Content-Type': 'application/json'}
        })
        const json = await response.json()
        if(response.ok){
           navigate('/borrowers/list')
           openSnackbar("Borrower added successfully!")
        }
        if(!response.ok){
          openSnackbar("Failed to add new borrower!")
          setIsSpinning2(false)
          setIsDisabled(false)
        }
      } catch(e){
        openSnackbar("Error during saving borrower's details")
      }
    }  
    //validate borrower form
    useEffect(() => {
      if(fullname === "" || residence === "" || phone === "" || 
         age === "" || age <= 17 || occupation === "" || idNumber === "") {
          setIsDisabled(true)
         } else {
          setIsDisabled(false)
         }
    },[fullname,residence,phone,age,occupation,idNumber])

  return (
    <div className="main-wapper" >
        <TopNav />
        <div className="content-wrapper">
            <div className="sider" >
             <SiderContent />
         </div>
         <div className="section" style={{backgroundColor:body}}>
         {branchId === null && role === "company_admin" ? <SelectBranch /> :  <div className="new-user-wrapper">
              <h1 style={{padding:10}}>Personal details</h1>
              <div className="new-user-bar">
                <img src={photo} alt="borrower_img" />
                <div classNamee="new-titles">
                    <h3>Passport size</h3>
                    <input type="file" onChange={(e)=>handleImagePicker(e.target.files[0])} />
                    <h4 onClick={()=>uploadPassportSize()}>
                    {isSpinning ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
                      {isSpinning ? 'Uploading....': 'Upload passport size'}
                      </h4>
                </div>
              </div>

              <div className="tab-2">
                       <div className="ln-box">
                       <p>Name</p> 
                       <input type="text" placeholder="Full name"
                           onChange={(e)=>setFullName(e.target.value)}
                           />
                       </div>
                       <div className="ln-box">
                       <p>Sex</p>
                        <select onChange={(e)=>setGender(e.target.value)}>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                       </div>

                       <div className="ln-box"><input type="text" onChange={(e)=>setResidence(e.target.value)} placeholder="Residence" /></div>
                        <div className="ln-box">
                <select style={{outline:'none',borderWidth:1}} name="countryCode" id="country-code"
                  onChange={(e)=>setCountryCode(e.target.value)}
                  defaultValue={countryCode}
                  >
		<option countrycode="DZ" value="213">Algeria (+213)</option>
		<option countrycode="AD" value="376">Andorra (+376)</option>
		<option countrycode="AO" value="244">Angola (+244)</option>
		<option countrycode="AI" value="1264">Anguilla (+1264)</option>
		<option countrycode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
		<option countrycode="AR" value="54">Argentina (+54)</option>
		<option countrycode="AM" value="374">Armenia (+374)</option>
		<option countrycode="AW" value="297">Aruba (+297)</option>
		<option countrycode="AU" value="61">Australia (+61)</option>
		<option countrycode="AT" value="43">Austria (+43)</option>
		<option countrycode="AZ" value="994">Azerbaijan (+994)</option>
		<option countrycode="BS" value="1242">Bahamas (+1242)</option>
		<option countrycode="BH" value="973">Bahrain (+973)</option>
		<option countrycode="BD" value="880">Bangladesh (+880)</option>
		<option countrycode="BB" value="1246">Barbados (+1246)</option>
		<option countrycode="BY" value="375">Belarus (+375)</option>
		<option countrycode="BE" value="32">Belgium (+32)</option>
		<option countrycode="BZ" value="501">Belize (+501)</option>
		<option countrycode="BJ" value="229">Benin (+229)</option>
		<option countrycode="BM" value="1441">Bermuda (+1441)</option>
		<option countrycode="BT" value="975">Bhutan (+975)</option>
		<option countrycode="BO" value="591">Bolivia (+591)</option>
		<option countrycode="BA" value="387">Bosnia Herzegovina (+387)</option>
		<option countrycode="BW" value="267">Botswana (+267)</option>
		<option countrycode="BR" value="55">Brazil (+55)</option>
		<option countrycode="BN" value="673">Brunei (+673)</option>
		<option countrycode="BG" value="359">Bulgaria (+359)</option>
		<option countrycode="BF" value="226">Burkina Faso (+226)</option>
		<option countrycode="BI" value="257">Burundi (+257)</option>
		<option countrycode="KH" value="855">Cambodia (+855)</option>
		<option countrycode="CM" value="237">Cameroon (+237)</option>
		<option countrycode="CA" value="1">Canada (+1)</option>
		<option countrycode="CV" value="238">Cape Verde Islands (+238)</option>
		<option countrycode="KY" value="1345">Cayman Islands (+1345)</option>
		<option countrycode="CF" value="236">Central African Republic (+236)</option>
		<option countrycode="CL" value="56">Chile (+56)</option>
		<option countrycode="CN" value="86">China (+86)</option>
		<option countrycode="CO" value="57">Colombia (+57)</option>
		<option countrycode="KM" value="269">Comoros (+269)</option>
		<option countrycode="CG" value="242">Congo (+242)</option>
		<option countrycode="CK" value="682">Cook Islands (+682)</option>
		<option countrycode="CR" value="506">Costa Rica (+506)</option>
		<option countrycode="HR" value="385">Croatia (+385)</option>
		<option countrycode="CU" value="53">Cuba (+53)</option>
		<option countrycode="CY" value="90392">Cyprus North (+90392)</option>
		<option countrycode="CY" value="357">Cyprus South (+357)</option>
		<option countrycode="CZ" value="42">Czech Republic (+42)</option>
		<option countrycode="DK" value="45">Denmark (+45)</option>
		<option countrycode="DJ" value="253">Djibouti (+253)</option>
		<option countrycode="DM" value="1809">Dominica (+1809)</option>
		<option countrycode="DO" value="1809">Dominican Republic (+1809)</option>
		<option countrycode="EC" value="593">Ecuador (+593)</option>
		<option countrycode="EG" value="20">Egypt (+20)</option>
		<option countrycode="SV" value="503">El Salvador (+503)</option>
		<option countrycode="GQ" value="240">Equatorial Guinea (+240)</option>
		<option countrycode="ER" value="291">Eritrea (+291)</option>
		<option countrycode="EE" value="372">Estonia (+372)</option>
		<option countrycode="ET" value="251">Ethiopia (+251)</option>
		<option countrycode="FK" value="500">Falkland Islands (+500)</option>
		<option countrycode="FO" value="298">Faroe Islands (+298)</option>
		<option countrycode="FJ" value="679">Fiji (+679)</option>
		<option countrycode="FI" value="358">Finland (+358)</option>
		<option countrycode="FR" value="33">France (+33)</option>
		<option countrycode="GF" value="594">French Guiana (+594)</option>
		<option countrycode="PF" value="689">French Polynesia (+689)</option>
		<option countrycode="GA" value="241">Gabon (+241)</option>
		<option countrycode="GM" value="220">Gambia (+220)</option>
		<option countrycode="GE" value="7880">Georgia (+7880)</option>
		<option countrycode="DE" value="49">Germany (+49)</option>
		<option countrycode="GH" value="233">Ghana (+233)</option>
		<option countrycode="GI" value="350">Gibraltar (+350)</option>
		<option countrycode="GR" value="30">Greece (+30)</option>
		<option countrycode="GL" value="299">Greenland (+299)</option>
		<option countrycode="GD" value="1473">Grenada (+1473)</option>
		<option countrycode="GP" value="590">Guadeloupe (+590)</option>
		<option countrycode="GU" value="671">Guam (+671)</option>
		<option countrycode="GT" value="502">Guatemala (+502)</option>
		<option countrycode="GN" value="224">Guinea (+224)</option>
		<option countrycode="GW" value="245">Guinea - Bissau (+245)</option>
		<option countrycode="GY" value="592">Guyana (+592)</option>
		<option countrycode="HT" value="509">Haiti (+509)</option>
		<option countrycode="HN" value="504">Honduras (+504)</option>
		<option countrycode="HK" value="852">Hong Kong (+852)</option>
		<option countrycode="HU" value="36">Hungary (+36)</option>
		<option countrycode="IS" value="354">Iceland (+354)</option>
		<option countrycode="IN" value="91">India (+91)</option>
		<option countrycode="ID" value="62">Indonesia (+62)</option>
		<option countrycode="IR" value="98">Iran (+98)</option>
		<option countrycode="IQ" value="964">Iraq (+964)</option>
		<option countrycode="IE" value="353">Ireland (+353)</option>
		<option countrycode="IL" value="972">Israel (+972)</option>
		<option countrycode="IT" value="39">Italy (+39)</option>
		<option countrycode="JM" value="1876">Jamaica (+1876)</option>
		<option countrycode="JP" value="81">Japan (+81)</option>
		<option countrycode="JO" value="962">Jordan (+962)</option>
		<option countrycode="KZ" value="7">Kazakhstan (+7)</option>
		<option countrycode="KE" value="254">Kenya (+254)</option>
		<option countrycode="KI" value="686">Kiribati (+686)</option>
		<option countrycode="KP" value="850">Korea North (+850)</option>
		<option countrycode="KR" value="82">Korea South (+82)</option>
		<option countrycode="KW" value="965">Kuwait (+965)</option>
		<option countrycode="KG" value="996">Kyrgyzstan (+996)</option>
		<option countrycode="LA" value="856">Laos (+856)</option>
		<option countrycode="LV" value="371">Latvia (+371)</option>
		<option countrycode="LB" value="961">Lebanon (+961)</option>
		<option countrycode="LS" value="266">Lesotho (+266)</option>
		<option countrycode="LR" value="231">Liberia (+231)</option>
		<option countrycode="LY" value="218">Libya (+218)</option>
		<option countrycode="LI" value="417">Liechtenstein (+417)</option>
		<option countrycode="LT" value="370">Lithuania (+370)</option>
		<option countrycode="LU" value="352">Luxembourg (+352)</option>
		<option countrycode="MO" value="853">Macao (+853)</option>
		<option countrycode="MK" value="389">Macedonia (+389)</option>
		<option countrycode="MG" value="261">Madagascar (+261)</option>
		<option countrycode="MW" value="265">Malawi (+265)</option>
		<option countrycode="MY" value="60">Malaysia (+60)</option>
		<option countrycode="MV" value="960">Maldives (+960)</option>
		<option countrycode="ML" value="223">Mali (+223)</option>
		<option countrycode="MT" value="356">Malta (+356)</option>
		<option countrycode="MH" value="692">Marshall Islands (+692)</option>
		<option countrycode="MQ" value="596">Martinique (+596)</option>
		<option countrycode="MR" value="222">Mauritania (+222)</option>
		<option countrycode="YT" value="269">Mayotte (+269)</option>
		<option countrycode="MX" value="52">Mexico (+52)</option>
		<option countrycode="FM" value="691">Micronesia (+691)</option>
		<option countrycode="MD" value="373">Moldova (+373)</option>
		<option countrycode="MC" value="377">Monaco (+377)</option>
		<option countrycode="MN" value="976">Mongolia (+976)</option>
		<option countrycode="MS" value="1664">Montserrat (+1664)</option>
		<option countrycode="MA" value="212">Morocco (+212)</option>
		<option countrycode="MZ" value="258">Mozambique (+258)</option>
		<option countrycode="MN" value="95">Myanmar (+95)</option>
		<option countrycode="NA" value="264">Namibia (+264)</option>
		<option countrycode="NR" value="674">Nauru (+674)</option>
		<option countrycode="NP" value="977">Nepal (+977)</option>
		<option countrycode="NL" value="31">Netherlands (+31)</option>
		<option countrycode="NC" value="687">New Caledonia (+687)</option>
		<option countrycode="NZ" value="64">New Zealand (+64)</option>
		<option countrycode="NI" value="505">Nicaragua (+505)</option>
		<option countrycode="NE" value="227">Niger (+227)</option>
		<option countrycode="NG" value="234">Nigeria (+234)</option>
		<option countrycode="NU" value="683">Niue (+683)</option>
		<option countrycode="NF" value="672">Norfolk Islands (+672)</option>
		<option countrycode="NP" value="670">Northern Marianas (+670)</option>
		<option countrycode="NO" value="47">Norway (+47)</option>
		<option countrycode="OM" value="968">Oman (+968)</option>
		<option countrycode="PW" value="680">Palau (+680)</option>
		<option countrycode="PA" value="507">Panama (+507)</option>
		<option countrycode="PG" value="675">Papua New Guinea (+675)</option>
		<option countrycode="PY" value="595">Paraguay (+595)</option>
		<option countrycode="PE" value="51">Peru (+51)</option>
		<option countrycode="PH" value="63">Philippines (+63)</option>
		<option countrycode="PL" value="48">Poland (+48)</option>
		<option countrycode="PT" value="351">Portugal (+351)</option>
		<option countrycode="PR" value="1787">Puerto Rico (+1787)</option>
		<option countrycode="QA" value="974">Qatar (+974)</option>
		<option countrycode="RE" value="262">Reunion (+262)</option>
		<option countrycode="RO" value="40">Romania (+40)</option>
		<option countrycode="RU" value="7">Russia (+7)</option>
		<option countrycode="RW" value="250">Rwanda (+250)</option>
		<option countrycode="SM" value="378">San Marino (+378)</option>
		<option countrycode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
		<option countrycode="SA" value="966">Saudi Arabia (+966)</option>
		<option countrycode="SN" value="221">Senegal (+221)</option>
		<option countrycode="CS" value="381">Serbia (+381)</option>
		<option countrycode="SC" value="248">Seychelles (+248)</option>
		<option countrycode="SL" value="232">Sierra Leone (+232)</option>
		<option countrycode="SG" value="65">Singapore (+65)</option>
		<option countrycode="SK" value="421">Slovak Republic (+421)</option>
		<option countrycode="SI" value="386">Slovenia (+386)</option>
		<option countrycode="SB" value="677">Solomon Islands (+677)</option>
		<option countrycode="SO" value="252">Somalia (+252)</option>
		<option countrycode="ZA" value="27">South Africa (+27)</option>
		<option countrycode="ES" value="34">Spain (+34)</option>
		<option countrycode="LK" value="94">Sri Lanka (+94)</option>
		<option countrycode="SH" value="290">St. Helena (+290)</option>
		<option countrycode="KN" value="1869">St. Kitts (+1869)</option>
		<option countrycode="SC" value="1758">St. Lucia (+1758)</option>
		<option countrycode="SD" value="249">Sudan (+249)</option>
		<option countrycode="SR" value="597">Suriname (+597)</option>
		<option countrycode="SZ" value="268">Swaziland (+268)</option>
		<option countrycode="SE" value="46">Sweden (+46)</option>
		<option countrycode="CH" value="41">Switzerland (+41)</option>
		<option countrycode="SI" value="963">Syria (+963)</option>
		<option countrycode="TW" value="886">Taiwan (+886)</option>
		<option countrycode="TJ" value="7">Tajikstan (+7)</option>
		<option countrycode="TZ" value="255">Tanzania (+255)</option>
		<option countrycode="TH" value="66">Thailand (+66)</option>
		<option countrycode="TG" value="228">Togo (+228)</option>
		<option countrycode="TO" value="676">Tonga (+676)</option>
		<option countrycode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
		<option countrycode="TN" value="216">Tunisia (+216)</option>
		<option countrycode="TR" value="90">Turkey (+90)</option>
		<option countrycode="TM" value="7">Turkmenistan (+7)</option>
		<option countrycode="TM" value="993">Turkmenistan (+993)</option>
		<option countrycode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
		<option countrycode="TV" value="688">Tuvalu (+688)</option>
		<option countrycode="UG" value="256">Uganda (+256)</option>
		<option countrycode="GB" value="44">UK (+44)</option>
		<option countrycode="UA" value="380">Ukraine (+380)</option>
		<option countrycode="AE" value="971">United Arab Emirates (+971)</option>
		<option countrycode="UY" value="598">Uruguay (+598)</option>
		<option countrycode="US" value="1">USA (+1)</option> 
		<option countrycode="UZ" value="7">Uzbekistan (+7)</option>
		<option countrycode="VU" value="678">Vanuatu (+678)</option>
		<option countrycode="VA" value="379">Vatican City (+379)</option>
		<option countrycode="VE" value="58">Venezuela (+58)</option>
		<option countrycode="VN" value="84">Vietnam (+84)</option>
		<option countrycode="VG" value="84">Virgin Islands - British (+1284)</option>
		<option countrycode="VI" value="84">Virgin Islands - US (+1340)</option>
		<option countrycode="WF" value="681">Wallis &amp; Futuna (+681)</option>
		<option countrycode="YE" value="969">Yemen (North)(+969)</option>
		<option countrycode="YE" value="967">Yemen (South)(+967)</option>
		<option countrycode="ZM" value="260">Zambia (+260)</option>
		<option countrycode="ZW" value="263">Zimbabwe (+263)</option>
                    </select>
            </div>
                        <div className="ln-box"><input type="text" onChange={(e)=>setPhone(e.target.value)} placeholder="Phone number" /></div>
                        <div className="ln-box"><input type="text" onChange={(e)=>setAge(e.target.value)} placeholder="Age" /></div>
                        <div className="ln-box"><input type="text" onChange={(e)=>setOccupation(e.target.value)} placeholder="Occupation" /></div>
						<div className="ln-box">
                       <p>Identification type</p>
                        <select onChange={(e)=>setIdType(e.target.value)}>
                            <option value="national_id">National identification</option>
                            <option value="voting_id">Voting card identification</option>
                            <option value="driving_id">Driving Licence</option>
                            <option value="passport">Passport</option>
                        </select>
                       </div>
                        <div className="ln-box"><input type="text" onChange={(e)=>setIdNumber(e.target.value)} placeholder="ID number" /></div>
					   <div className="ln-box"><input type="text" onChange={(e)=>setIdNumber2(e.target.value)} placeholder="another ID number" /></div>
					   <div className="ln-box">
                       <p>Extra Identification type</p>
                        <select onChange={(e)=>setIdType2(e.target.value)}>
                            <option value="national_id">National identification</option>
                            <option value="voting_id">Voting card identification</option>
                            <option value="driving_id">Driving Licence</option>
                            <option value="passport">Passport</option>
                        </select>
                       </div>
           

              {/*  <h1 style={{padding:10}}>Bank account details</h1>*/} 
                {/*<div className="ln-box">
                       <p>Bank name</p>
                        <select onChange={(e)=>setBankName(e.target.value)}>
                            <option value="NMB">NMB</option>
                        </select>
  						</div> */}

                     {/* <div className="ln-box">  
                       <p>Account number</p> 
                       <input type="text" placeholder=""
                           onChange={(e)=>setAccountNumber(e.target.value)}
                           />
                       </div>*/}
                  </div> 
                 { /*<div className="validate-card">Validate account</div>*/}
                {/* <div className="validation-status">
                    <div className="valid-bar">
                      <Icon icon={accountStatus ? "tick-circle":"cross"} color={accountStatus ? "#3A8E5C":"#B71C1C"}  size={22} className="valid-icon" />
                      <h3>{accountStatus ? "Account is active": "Account is inactive"}</h3>
                    </div>

                    <div className="valid-bar">
                      <Icon icon='user' color="#666" size={22} className="valid-icon"/>
                       <h3>JOSEPH ALFRED OWIGO</h3>
                    </div>

                    <div className="valid-bar">
                      <Icon icon='credit-card' color="#666" size={22} className="valid-icon"/>
                       <h3>222 82839 232</h3>
                    </div>

                    <div className="valid-bar">
                      <Icon icon='dollar' color="#666" size={22} className="valid-icon"/>
                       <h3>12,000<span className="currency">TZS</span></h3>
                    </div>

                  </div>*/}

				  
                  <div className="just-button" onClick={()=>Addborrower()} id={isDisabled ? "disabled-button": "not-disabled"}>
                      {isSpinning2 ? 'Saving....': 'Add borrower'}
                      {isSpinning2 ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
                  </div>
             </div>}
    
            </div>
        </div>
    </div>
  )
}

export default Addborrower