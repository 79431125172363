import React,{useContext,useState,useEffect} from 'react'
import {ThemeContext} from '../App'
import { Icon,Dialog,Spinner,SpinnerSize} from '@blueprintjs/core'
import { useSnackbar } from 'react-simple-snackbar'
import format from 'date-fns/format'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {useNavigate} from 'react-router-dom'
var moment = require('moment'); // require
moment().format();

function LoanTab({loan}) {
 // console.log(loan.phoneNumber,"phone number","and laon",loan)
  const {currentUser,dispatch} = useGlobalContextHook()
  const {settings} = currentUser
  const {permissions} = currentUser
  const {repayments: repaymentsPermissions} = permissions
  const {currency} = settings
  const [open, close] = useState(false) 
  const [isPenalDialogOpen,setIsPenalDialogOpen] = useState(false) 
  const [disabled,setDisabled] = useState(true)
  const [isPenaltyBtnDisabled,setIsPenaltyBtnDisabled] = useState(true)
  const [openSnackbar] = useSnackbar()
  const [spin,stop] = useState(false)
  const [errorMessage,showErrorMessage] = useState(null)
  const [amount,setAmount] = useState(0)
  const [remainedAmount,setRemainedAmount] = useState(0)
  const [repaymentId,setRepaymentId] = useState("")
  const [name,setName] = useState(null)
  const navigate = useNavigate()
  const [penaltyAmountToBePaid,setPenaltyAmountToBePaid] = useState(0)
  const [penaltyAmountRemained,setPenaltyAmountRemained] = useState(0)
  const [StaticRepaymentAmount,setStaticRepaymentAmount] = useState(0)
  const [repaymentSelectedState,setRepaymentSelectedState] = useState(null)


  const {  primary,lightgrey,card,grey,nusery} = useContext(ThemeContext)
  const {
    fullName,branchId,branch,company,
    repaymentDates,cash,companyId,
    interest,final_amount,
    maturityDate,releasedDate,
    duration,duration_type,
    repayment_cycle,
    repayment_amount,
    number_of_repayments,amountPaid,penalty,
    loanOfficerName,_id} = loan
  const amountRemained = Number(final_amount) - Number(amountPaid)  
  const releasedDateFormatted = releasedDate
  const maturityDateFormatted = maturityDate

  const addRepayment=(repaymentSelected)=>{

         close(true); setRepaymentId(repaymentSelected.id); 
         setAmount(repaymentSelected.amountPaid <= 0 ? repaymentSelected.amount :
                   Number(repaymentSelected.amount) - Number(repaymentSelected.amountPaid)
                );
         setName(repaymentSelected.name);
         setStaticRepaymentAmount(repaymentSelected.amount);
         setRepaymentSelectedState(repaymentSelected)
        
   }
  
  const confrimRepayment = ()=>{
         setDisabled(true)
         stop(true)       
        let newArry = repaymentDates.filter(repayment=>repayment.id !== repaymentSelectedState.id)
        let repayedAt = moment.utc().format("YYYY-MM-DD");
        let updatedArry;
         newArry.push({
                            date:repaymentSelectedState.date,
                            id:repaymentSelectedState.id,
                            name:repaymentSelectedState.name,
                            status:(Number(repaymentSelectedState.amountPaid)+ Number(amount))-Number(repaymentSelectedState.amount) === 0 ? "paid" :"ongoing",
                            amount:repaymentSelectedState.amount,
                            amountPaid:Number(repaymentSelectedState.amountPaid)+ Number(amount),
                            remained:remainedAmount,
                            cycle_createdAt:repaymentSelectedState.cycle_createdAt,
                            repayedAt:repayedAt,
        });
        updatedArry = newArry
        //console.log(updatedArry)
        addRepaymentDetails(updatedArry)
  }

  const addRepaymentDetails =async(updatedArry)=>{
    //update loan 
    try {
     stop(false);
     const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_REPAYMENT&id=${_id}&amount=${Number(repaymentSelectedState.amountPaid)+ Number(amount)}`,{
        method: 'PATCH',
        body: JSON.stringify(updatedArry),
        headers: {'Content-Type': 'application/json'}
     })
     const json = await response.json()
     if(response.ok){
        addRepaymentToDb(updatedArry)
     }
     if(!response.ok){
        console.log(response,'Error updating')
     }
        
       } catch (error) {
          openSnackbar("Failed to save loan details")
          showErrorMessage(error)
       }

  }

  const addRepaymentToDb = async() => {
          try {
              const repaymentObj = {
                    loanOfficer:currentUser.id,
                    loanOfficerName:currentUser.username,
                    company:company,
                    companyId:companyId,
                    branch:branch,
                    branchId:branchId,
                    amountPaid:Number(amount),
                    fullName:fullName,
                    loanId:_id,
                    totalLoan:final_amount,
                    title:repaymentSelectedState.id
               }
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=ADD_REPAYMENT`,{
                  method: 'POST',
                  body: JSON.stringify(repaymentObj),
                  headers: {'Content-Type': 'application/json'}
            })
            const json = await response.json()
            if(response.ok){
                 //update the amount on branch
                 getCompanyInfo(json)
                 navigate('/Receipt',{state:json})
                 updateLoanStatus()
                

            }
            if(!response.ok){
                console.log('error on fetching',response)
            }
          } catch (error) {
            console.log(error);
          }
  }


const updateLoanStatus = async()=>{
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_LOAN_STATUS&id=${_id}&branchId=${branchId}&amount=${final_amount}`,{
            method:'PATCH'
        })
        const json = await response.json()
        if(response.ok){
            stop(false)
            //refreshFn()
        }
        if(!response.ok){
            console.log('error on fetching',response,json)
        }
      } catch (error) {
        console.log(error,'updating')
      }
}
  useEffect(() => {
       if(amount === null || amount <= 0 || remainedAmount === NaN){
          setDisabled(true)
       } else { setDisabled(false)}
         
       let AmountRemain;
      if(repaymentSelectedState === null || repaymentSelectedState.amountPaid === undefined){
        AmountRemain = Number(StaticRepaymentAmount) - Number(amount)
        setRemainedAmount(Number(AmountRemain))
      } else {
        AmountRemain = Number(repaymentSelectedState.amountPaid) + Number(amount) - Number(StaticRepaymentAmount)
        setRemainedAmount(Number(AmountRemain))
      }
  },[amount,StaticRepaymentAmount])

  const sendSMS= async(owner,newRepay)=>{
    try{
        let modifiedPhone = loan.phoneNumber
        const message = `Loan repayment recieved from ${newRepay.fullName} at branch ${newRepay.branch}. Amount paid ${newRepay.amountPaid.toLocaleString()} ${currency}. Total loan:${newRepay.totalLoan.toLocaleString()} ${currency}. Received by ${newRepay.loanOfficerName}, date:${format(new Date(newRepay.createdAt),'dd/MMMM/yyyy')}`
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=SEND_SMS&phone=${modifiedPhone}`,{
            method:'POST',
            body: JSON.stringify({message:message}),
            headers:{'Content-Type': 'application/json'}
        })
        if(response.ok) {console.log('sent sms')}
        if(!response.ok){console.log(response)}
    }catch(e){
        openSnackbar("Failed to send sms")
        console.log(e)
    }
 }

 const getCompanyInfo = async(newRepay)=>{
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_USER_BY_COMPANY_ID&id=${companyId}`)
      const json = await response.json()
      if(response.ok){
        if(json[0].settings.notifications.repayment){
            sendSMS(json[0],newRepay)
            console.log(loan)
        } else {
         // console.log("Admin opted out sms for new loans",json[0].settings.notifications)
        }
      }
      if(!response.ok){
        openSnackbar(json.message)
      }
    }catch(error){openSnackbar("Error getting company")}
 }

 const penaltyRepayment =()=>{
    if(penalty === undefined) {} else { 
      setIsPenalDialogOpen(true)
    }
 }

 //deduct penalty repayment
 useEffect(() => {
     setPenaltyAmountRemained((Number(penalty.amountPaid) + Number(penaltyAmountToBePaid)) -Number(penalty.penaltyAmount) )
 },[penaltyAmountToBePaid,penaltyAmountRemained])


 //validate penalty amount
 useEffect(() => {
  if(penaltyAmountToBePaid <= 0 || penaltyAmountToBePaid === NaN ||  penaltyAmountRemained === NaN ){
    setIsPenaltyBtnDisabled(true)
  } else setIsPenaltyBtnDisabled(false)
 },[penaltyAmountToBePaid,penaltyAmountRemained])


const confrimPenaltyRepayment = async()=>{
  try{
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_PENALTY_PAYMENT&id=${_id}`,{
      method: 'PATCH',
      body:JSON.stringify({
        amountPaid:Number( penalty.amountPaid ) + Number(penaltyAmountToBePaid),
        amountRemained:Number(penalty.penaltyAmount) - (Number(penalty.amountPaid ) + Number(penaltyAmountToBePaid)) 
      }),
      headers: {'Content-Type': 'application/json'}
    })
    const json = await response.json()
    if(response.ok){
       //navigate to receipt 
       openSnackbar("penalty repayment added")
       setIsPenalDialogOpen(false)
       addPenaltyRepaymentToDb()
    }
    if(!response.ok){
      openSnackbar(`Failed to add penalty repayment`)
    }
  } catch(error){
    openSnackbar('Error during adding repayment penalty')
    console.log(error)
  }
} 

const addPenaltyRepaymentToDb = async() => {
  try {
      const repaymentObj = {
            loanOfficer:currentUser.id,
            loanOfficerName:currentUser.username,
            company:company,
            companyId:companyId,
            branch:branch,
            branchId:branchId,
            amountPaid:Number(penaltyAmountToBePaid),
            fullName:fullName,
            loanId:_id,
            totalLoan:final_amount,
            title:'Penalty repayment'
       }
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=ADD_REPAYMENT`,{
          method: 'POST',
          body: JSON.stringify(repaymentObj),
          headers: {'Content-Type': 'application/json'}
    })
    const json = await response.json()
    if(response.ok){
         //update the amount on branch
         getCompanyInfo(json)
         navigate('/Receipt',{state:json})
         updateLoanStatus()
    }
    if(!response.ok){
        console.log('error on fetching',response)
    }
  } catch (error) {
    console.log(error);
  }
}

function removeFirstThreeChars(text) {
    return text.substring(3);
}

  return (
      <div>
          <div className="info-box" style={{ backgroundColor:card}}>
             <div className="info-rail">
                <Icon icon="dollar" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Cash</div>
                     <div className="info-title"  id="info-title" style={{color:lightgrey}}>{cash.toLocaleString()}<span className="currency">{currency}</span></div>
             </div>

             <div className="info-rail">
                <Icon icon="percentage" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Interest rate</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{interest}%</div>
             </div>

             <div className="info-rail">
                <Icon icon="dollar" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Final amount</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{final_amount.toLocaleString()}<span className="currency">{currency}</span></div>
             </div>

             
             <div className="info-rail">
                <Icon icon="calendar" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Released date</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{format(new Date(releasedDateFormatted),'dd/MMMM/yyyy')}</div>
             </div>

                         
             <div className="info-rail">
                <Icon icon="time" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Duration </div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{duration} {duration_type}</div>
             </div>

             <div className="info-rail">
                <Icon icon="dollar" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title" id="info-title" >Repayment cylce</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{repayment_cycle}</div>
             </div>

             <div className="info-rail">
                <Icon icon="dollar" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Repayment amount</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{repayment_amount.toLocaleString()}<span className="currency">{currency}</span></div>
             </div>

             <div className="info-rail">
                <Icon icon="dollar" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Number of Repayments</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{number_of_repayments}</div>
            </div>

            <div className="info-rail">
                <Icon icon="calendar" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Maturity date</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{format(new Date(maturityDateFormatted),'dd/MMMM/yyyy')}</div>
             </div>

             <div className="info-rail">
                <Icon icon="calendar" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Amount paid</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{amountPaid.toLocaleString()}<span className="currency">{currency}</span></div>
             </div>

             <div className="info-rail">
                <Icon icon="calendar" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Amount remained</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{amountRemained.toLocaleString()}<span className="currency">{currency}</span></div>
             </div>

             <div className="info-rail">
                <Icon icon="calendar" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title"  id="info-title">Penalty</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{penalty === undefined ? 0 : penalty.penaltyAmount.toLocaleString()}<span className="currency">{currency}</span></div>
             </div>

            <div className="info-rail">
                <Icon icon="user" color={grey}  size={0} className='info-icon'/>
                    <div className="info-title" id="info-title" >Loan officer/Worker</div>
                     <div className="info-title" id="info-title" style={{color:lightgrey}}>{loanOfficerName}</div>
            </div>         
          </div>
          {repaymentsPermissions[0].isGranted ? <div>
            <h2>Repayments table</h2>
          <div className="info-box" style={{ backgroundColor:card}}>
          <div className="m-table">
          <div className="m-table-header">
             <div className='m-header-title' id="m-name">Number of Repayments  [ {number_of_repayments} ]</div>
             <div className='m-header-title' id="m-cash">Status</div>
             <div className='m-header-title' id="m-interest">Repayment amount</div>
             <div className='m-header-title' id="m-final">Amount paid</div>
             <div className='m-header-title' id="m-released">Date</div>
             <div className='m-header-title' id="m-maturity">Remained</div>
             <div className='m-header-title' id="m-maturity">Add repayment</div>
          </div>

          <div className="m-table-row-wrapper">
          {repaymentDates.length <= 0 ? <div /> : 
                repaymentDates.map((repayment,i) => (
                  <div className="m-loan-cell-wrapper"  key={i}>
                  <div className='m-loan-cell' id="m-name" >{repayment.id} Repayment</div>
                  <div className='m-loan-cell'  id="m-cash" style={{
                            color:repayment.status === "pending" ? "#FCAB53" : 
                                  repayment.status === "paid" ? nusery : 
                                  repayment.status === "overdue" ? "#FF3366":
                                  repayment.status === "ongoing" ? primary:
                                  "#ccc"}}>{repayment.status === "paid" ?<Icon icon="tick-circle" color={nusery}  className="titi"/>: null}
                                           {repayment.status.charAt(0).toUpperCase() + repayment.status.slice(1)}</div>
                  <div className='m-loan-cell'  id="m-interest">{repayment.amount.toLocaleString()}<span className="currency">{currency}</span></div>
                  <div className='m-loan-cell'  id="m-final">{repayment.amountPaid === undefined ? 0 : repayment.amountPaid.toLocaleString()}<span className="currency">{currency}</span></div>
                  <div className='m-loan-cell' id="m-released">{repayment.name}</div>
                  <div className='m-loan-cell' id="m-maturity">{repayment.remained === undefined ? 0 : repayment.remained}</div>
                   <div className='m-loan-cell' id="m-maturity">
                       {repayment.status === "paid" ? <div /> : <button  onClick={()=>addRepayment(repayment)}style={{backgroundColor:'inherit'}}><Icon  icon="add" color={lightgrey} /></button>}
                    </div>        
                 
                  </div>
                        ))}
          </div>
         </div> 

             
             <Dialog isOpen={open} canOutsideClickClose={true} onClose={()=>close(prev=>!prev)}>
                <div className='new-branch-wrapper'>
                    <h2>Add  the {repaymentId} Repayment </h2>
                    <p style={{marginLeft:10}}>Date: <em>{name}</em></p>
                      
                     <input type="text" 
                      onChange={(e)=>setAmount(e.target.value)}
                      value={amount} placeholder="" style={{width:"90%"}} />
                       <p style={{marginLeft:10}}>Remained amount on {repaymentId} repayment is: {remainedAmount.toLocaleString()}<span className="currency">{currency}</span></p>

                       {errorMessage === null ? <div/> : 
			                <div className="input-bar" style={{borderWidth:0}}>
			                <Icon icon="error" color={"#b41313"}  size={20} className='icon-auth'/>
			                <p   style={{color:"#b41313"}}>{errorMessage.toString()}<span className="currency">{currency}</span></p>
				                </div> 
			                }

                       <button type="button"  onClick={()=>confrimRepayment()}
                                style={{ backgroundColor:disabled ? "#ccc" :"#07608B" }}>
                       {spin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
                           Add repayment</button>
                </div>
               </Dialog> 
          </div>


          <h2>Penalty repayment</h2>
          <div className="info-box" style={{ backgroundColor:card}}>
          <div className="m-table">
          <div className="m-table-header">
             <div className='m-header-title' id="m-interest">Penalty amount</div>
             <div className='m-header-title' id="m-final">Amount paid</div>
             <div className='m-header-title' id="m-released">Last time penalty added</div>
             <div className='m-header-title' id="m-maturity">Remained</div>
             <div className='m-header-title' id="m-maturity">Add repayment</div>
          </div>

          <div className="m-table-row-wrapper">
          {penalty === undefined ? <div /> : 
                  <div className="m-loan-cell-wrapper">
                  <div className='m-loan-cell'  id="m-interest">{penalty.penaltyAmount.toLocaleString()}<span className="currency">{currency}</span></div>
                  <div className='m-loan-cell'  id="m-final">{penalty.amountPaid === undefined ? 0 : penalty.amountPaid.toLocaleString()}<span className="currency">{currency}</span></div>
                  <div className='m-loan-cell' id="m-released">{format(new Date(penalty.lastTimeIncremented),'dd/MMMM/yyyy')}</div>
                  <div className='m-loan-cell' id="m-maturity">{penalty.amountRemained === undefined ? 0 : penalty.amountRemained.toLocaleString()}</div>
                   <div className='m-loan-cell' id="m-maturity">
                     {penalty.penaltyAmount <= 0  ? <div /> :  <button  onClick={()=>penaltyRepayment()}style={{backgroundColor:'inherit'}}><Icon  icon="add" color={lightgrey} /></button>}
                    </div>        
                 
                  </div>
                  }
          </div>
         </div> 

             
             <Dialog isOpen={isPenalDialogOpen} canOutsideClickClose={true} onClose={()=>setIsPenalDialogOpen(prev=>!prev)}>
                <div className='new-branch-wrapper'>
                    <h2>Add  Penalty Repayment </h2>
                    <p style={{marginLeft:10}}>Date: <em>{name}</em></p>
                      
                     <input type="text" 
                      onChange={(e)=>setPenaltyAmountToBePaid(e.target.value)}
                      value={penaltyAmountToBePaid} placeholder="" style={{width:"90%"}} />
                       <p style={{marginLeft:10}}>Remained amount on penalty repayment is: {penaltyAmountRemained.toLocaleString()}<span className="currency">{currency}</span></p>

                       {errorMessage === null ? <div/> : 
			                <div className="input-bar" style={{borderWidth:0}}>
			                <Icon icon="error" color={"#b41313"}  size={20} className='icon-auth'/>
			                <p   style={{color:"#b41313"}}>{errorMessage.toString()}<span className="currency">{currency}</span></p>
				                </div> 
			                }

                       <button type="button"  onClick={()=>confrimPenaltyRepayment()}
                                style={{ backgroundColor:isPenaltyBtnDisabled ? "#ccc" :"#07608B" }}>
                       {spin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
                           Add repayment</button>
                </div>
               </Dialog> 
          </div>
          </div> : <div />}
    
      </div>
  )
}

export default LoanTab