import React, {useContext, useState, useEffect} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {useNavigate} from 'react-router-dom'
import {ThemeContext} from '../App'
import {Menu, MenuItem, ProgressBar, Spinner, SpinnerSize, Icon, Collapse} from '@blueprintjs/core'
import Attachbox from '../components/Attachbox'
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'
import {storage} from '../firebase/config'
import {useSnackbar} from 'react-simple-snackbar'
import {useGlobalContextHook} from '../hook/useGlobalContextHook'
import format from 'date-fns/format'
import SelectBranch from '../components/SelectBranch'
import {v4 as uuidv4} from 'uuid';

var moment = require('moment'); // require
const uniqueId = require('react-unique-nums')

function Newloan() {

    const {currentUser, dispatch, activeBranch, borrowers, borrowersGroups} = useGlobalContextHook()
    const [countryCode, setCountryCode] = useState(255)
    const {role, company, companyId, settings} = currentUser
    const {currency} = settings
    const branchId = activeBranch === null ? currentUser.branchId : currentUser.role === "worker" ? currentUser.branchId : activeBranch._id
    const branch = activeBranch === null ? currentUser.branch : currentUser.role === "worker" ? currentUser.branch : activeBranch.name
    const navigate = useNavigate()
    const {body, card, primary, lighttext, lightgrey} = useContext(ThemeContext)
    const [active, setActive] = useState("1")
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [isCollapsed2, setIsCollapsed2] = useState(false)
    const [openSnackbar, closeSnackbar] = useSnackbar()
    const [spin, stop] = useState(false)
    const [errorMessage, showErrorMessage] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [selectedBorrower, setSelectedBorrower] = useState(null)
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [fullname, setFullName] = useState("")
    const [gender, setGender] = useState("male")
    const [residence, setResidence] = useState("")
    const [phone, setPhone] = useState("")
    const [age, setAge] = useState("")
    const [idNumber, setIdNumber] = useState("")
    const [idNumber2, setIdNumber2] = useState("")
    const [occupation, setOccupation] = useState("")
    const [borrowerId, setBorrowerId] = useState("")
    const [borrowerPassportImage, setBorrowPassportImage] = useState("")

    const [groupCash, setGroupCash] = useState(0)
    const [cash, setCash] = useState(0)
    const [interest, setInterest] = useState(5)
    const [finalAmount, setFinalAmount] = useState(0)
    const [duration, setDuration] = useState(1)
    const [repayment_circle, setRepaymentCircle] = useState("days")
    const [repayment_amount, setRepaymentAmount] = useState(0)
    const [numberOfRepayments, setNumberOfRepayments] = useState(1)
    const [profit, setProfit] = useState(0)
    const [percentage_state, setPercentage] = useState(0)
    const [maturity, setMaturity] = useState(null)
    const [maturityDate, setMaturityDate] = useState(null)
    const [basis, setBasis] = useState("days")
    const [collateral, setCollateral] = useState("")
    const [collateralValue, setCollateralValue] = useState(0)
    const [show, hide] = useState(false)

    const [selectedAttachment, setSelectedAttachment] = useState(null)
    const [attachmentTitle, setAttachmentTitle] = useState("")
    const [attachmentsList, setAttachmentList] = useState([])
    const [repaymentDates, setRepaymentDates] = useState([])
    const [datesArry, setDatesArry] = useState([])
    const [idType1, setIdType1] = useState("")
    const [idType2, setIdType2] = useState("")
    const [isItGroupLoan, setIsItGroupLoan] = useState(false)
    const [ loanReleasedDate, setLoanReleasedDate] = useState(new Date())


    useEffect(() => {
        let interest_rate = Number(interest) / 100
        let interest_amount = Number(interest_rate) * Number(cash)
        let finalAmount = Number(cash) + Number(interest_amount)
        setFinalAmount(finalAmount)
        setProfit(interest_amount)

        let repayment_amount_var = Number(finalAmount) / Number(numberOfRepayments)
        setRepaymentAmount(repayment_amount_var)


    }, [cash, interest, numberOfRepayments, repayment_amount])

    const repaymentFn = (amount) => {
        setRepaymentAmount(amount);
        let repayment_amount_circle = Number(finalAmount) / Number(amount)
        setNumberOfRepayments(repayment_amount_circle.toFixed(2))
    }

    const uploadAttachment = () => {
        if (selectedAttachment === null) {
            openSnackbar("Select first attachment to upload")
        } else {
            if (attachmentTitle === "") {
                openSnackbar("Please write attachment's name ")
            } else {
                //upload it
                hide(true)
                let attachmentId = uniqueId().toString()
                let attachmentRef = ref(storage, `/attachments/${currentUser.company}/${branch}/${attachmentId}`)
                let uploadTask = uploadBytesResumable(attachmentRef, selectedAttachment);

                uploadTask.on("state_changed",
                    function progress(snapshot) {
                        var percentage = (snapshot.bytesTransferred /
                            snapshot.totalBytes)
                        //console.log(percentage);
                        setPercentage(percentage);
                    },
                    function error(error) {
                        let err = error.toString();
                        openSnackbar("Error on uploading", err)
                        console.log(error)
                    },
                    function complete() {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            //console.log('File available at', downloadURL);
                            attachmentsList.push({
                                title: attachmentTitle,
                                url: downloadURL,
                                type: selectedAttachment.type

                            });
                            hide(false)
                        });
                    })
            }

        }
    }

    const removeAttachment = (url) => {
        let newArry = attachmentsList.filter(attachment => attachment.url !== url);
        setAttachmentList(newArry);
    }

    const saveLoanDetail = async () => {
        if (isItGroupLoan) {
            saveGroupLoan()
        } else {
            try {
                stop(true)
                //let releasedDate = moment.utc();
                let createdAt = moment.utc().format("YYYY-MM-DD");
                let loanId = uniqueId().toString()
                let repayment_circle_modified;

                if (repayment_circle === "months") {
                    repayment_circle_modified = "monthly"
                } else {
                    if (repayment_circle === "weeks") {
                        repayment_circle_modified = "weekly"
                    } else {
                        if (repayment_circle === "days") {
                            repayment_circle_modified = "daily"
                        } else {
                            if (repayment_circle === "years") {
                                repayment_circle_modified = "yearly"
                            } else {
                                repayment_circle_modified = "monthly"
                            }
                        }
                    }
                }
                const cleanNumber = parseInt(phone, 10);
                const loanObject = {
                    attachments: attachmentsList,
                    fullName: fullname,
                    sex: gender,
                    residence: residence,
                    phoneNumber: cleanNumber,
                    age: Number(age),
                    occupation: occupation,
                    idNumber1: idNumber,
                    idNumber2: idNumber2,
                    idType1: idType1,
                    idType2: idType2,
                    borrowerId: borrowerId,
                    passportImage: borrowerPassportImage,

                    cash: Number(cash),
                    interest: Number(interest),
                    final_amount: Number(finalAmount),
                    duration: Number(duration),
                    duration_type: basis,
                    repayment_cycle: repayment_circle_modified,
                    number_of_repayments: Number(numberOfRepayments),
                    repayment_amount: Number(repayment_amount),
                    profit: Number(profit),
                    penalty: {
                        penaltyAmount: 0,
                        amountPaid: 0,
                        amountRemained: 0,
                        lastTimeIncremented: maturityDate,
                    },
                   // releasedDate: releasedDate._d,
                    releasedDate: loanReleasedDate,
                    maturity: maturity,
                    maturityDate: maturityDate,
                    collateral: collateral,
                    collateralValue: Number(collateralValue),
                    repaymentDates: repaymentDates,
                    status: "opened",
                    amountPaid: 0,
                    company: currentUser.company,
                    companyId: currentUser.companyId,
                    branch: currentUser.role === "worker" ? currentUser.branch : activeBranch.name,
                    branchId: `${branchId}`,
                    loanOfficer: currentUser.id,
                    loanOfficerName: currentUser.username
                }
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=CREATE_LOAN`, {
                    method: 'POST',
                    body: JSON.stringify(loanObject),
                    headers: {'Content-Type': 'application/json'}
                })

                const json = await response.json()
                if (response.ok) {
                    await navigate('/Loans/View', {state: `${json._id}`})
                    await stop(false)
                    await openSnackbar(`New loan released`);
                    await getCompanyInfo(json)
                }
                if (!response.ok) {
                    openSnackbar(`Fill all required fields`)
                    console.log(json)
                    stop(false)

                }


            } catch (error) {
                console.log(error);
                showErrorMessage(error)
                stop(false)
            }
        }
    }

    useEffect(() => {
        let real_duration = Number(duration)
        let realtime = moment.utc()
        let ending = realtime.clone().add(real_duration, basis);
        setMaturity(ending._d)
        setMaturityDate(ending.format("YYYY-MM-DD"))
    }, [basis, duration])

    useEffect(() => {

        let newArry = [];

        for (let i = 1; i <= numberOfRepayments; i++) {
            let date = moment.utc(loanReleasedDate);

            let newDate = date.clone().add(i, repayment_circle)

            let dateName = newDate.format("MMMM DD, YYYY")
            let createdAt = moment.utc().format("YYYY-MM-DD");
            let repayment_amount_var = Number(finalAmount) / Number(numberOfRepayments).toFixed(0)
            newArry.push({
                date: newDate._d,
                id: i,
                name: dateName,
                status: "pending",
                amount: repayment_amount_var,
                remained: 0,
                amountPaid: 0,
                cycle_createdAt: createdAt
            });
        }
        ;
        setRepaymentDates(newArry);

    }, [numberOfRepayments, repayment_circle, repayment_amount, loanReleasedDate])

    useEffect(() => {
        if (repaymentDates.length <= 0) {
        } else {
            let newDatesTmptArry = []
            repaymentDates.forEach(date => {
                newDatesTmptArry.push(date.cycle_createdAt)
            });
            setDatesArry(newDatesTmptArry);
        }
    }, [repaymentDates])

    const sendSMS = async (owner, newLoan) => {
        try {
            const message = `New loan released to ${newLoan.fullName}, Cash ${newLoan.cash.toLocaleString()} ${currency} with Interest rate:${newLoan.interest}%, and Final amount:${newLoan.final_amount.toLocaleString()} ${currency}. Duration:${newLoan.duration} ${newLoan.duration_type},  date:${format(new Date(newLoan.releasedDate), 'dd/MMMM/yyyy')}`
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=SEND_SMS&phone=${newLoan.phoneNumber}`, {
                method: 'POST',
                body: JSON.stringify({message: message}),
                headers: {'Content-Type': 'application/json'}
            })
            if (response.ok) {
                console.log('sent sms', response)
            }
            if (!response.ok) {
                console.log(response)

            }
        } catch (e) {
            openSnackbar("Failed to send sms")
        }
    }

    const getCompanyInfo = async (newLoan) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_USER_BY_COMPANY_ID&id=${companyId}`)
            const json = await response.json()
            if (response.ok) {
                if (json[0].settings.notifications.loan) {
                    sendSMS(json[0], newLoan)
                } else {
                    //console.log("Admin opted out sms for new loans",json[0].settings.notifications)
                }
            }
            if (!response.ok) {
                openSnackbar(json.message)
            }
        } catch (error) {
            openSnackbar("Error getting company")
        }
    }

    const searchBorower = async (keyword) => {
        try {
            setIsCollapsed(true)
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SEARCH_BORROWERS&keyword=${keyword}&id=${branchId}`)
            const json = await response.json()
            if (response.ok) {
                dispatch({type: 'SET_BORROWERS', payload: json})
            }
            if (!response.ok) {
                openSnackbar('Failed to search borrowers!')
            }
        } catch (err) {
            openSnackbar('Error during searching borrowers')
            console.log(err)
        }
    }

    const setBorrower = (borrower) => {
        setSelectedBorrower(borrower)
        setIsCollapsed(false)

        setFullName(borrower.fullName)
        setGender(borrower.sex)
        setAge(borrower.age)
        setPhone(borrower.phoneNumber)
        setOccupation(borrower.occupation)
        setResidence(borrower.residence)
        setIdNumber(borrower.idNumber1)
        setIdNumber2(borrower.idNumber2)
        setIdType1(borrower.idType)
        setIdType2(borrower.idType2)
        setBorrowerId(borrower._id)
        setBorrowPassportImage(borrower.passportImage)
    }

    const searchGroup = async (keyword) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SEARCH_BORROWERS_GROUP&id=${branchId}&keyword=${keyword}`)
            const json = await response.json()
            if (response.ok) {
                dispatch({type: 'SET_BORROWERS_GROUPS', payload: json})
                setIsCollapsed2(true)
            }
            if (!response.ok) {
                openSnackbar('Failed to find any groups')
            }
        } catch (err) {
            openSnackbar(`Error during searching borrowers,refresh your page!`)
        }
    }

    const selectGroup = (group) => {
        setIsCollapsed2(false)
        setSelectedGroup(group)
    }

    const saveGroupLoan = async () => {
        if (selectedGroup === null) {
            openSnackbar(`There is no any selected group`)
        } else {
            const groupLoanId = await uuidv4();
            selectedGroup.members.forEach(member => {
                saveMemberLoan(member, groupLoanId, selectedGroup._id, selectedGroup.name)
            })
        }
    }

    const saveMemberLoan = async (member, groupLoanId, groupId, groupName) => {
        try {
            stop(true)
            //let releasedDate = moment.utc();
            let repayment_circle_modified;

            if (repayment_circle === "months") {
                repayment_circle_modified = "monthly"
            } else {
                if (repayment_circle === "weeks") {
                    repayment_circle_modified = "weekly"
                } else {
                    if (repayment_circle === "days") {
                        repayment_circle_modified = "daily"
                    } else {
                        if (repayment_circle === "years") {
                            repayment_circle_modified = "yearly"
                        } else {
                            repayment_circle_modified = "monthly"
                        }
                    }
                }
            }
            const loanObject = {
                attachments: attachmentsList,
                fullName: member.fullName,
                sex: member.sex,
                residence: member.residence,
                phoneNumber: Number(member.phoneNumber),
                age: Number(member.age),
                occupation: member.occupation,
                idNumber1: member.idNumber1,
                idNumber2: member.idNumber2,
                idType1: member.idType1,
                idType2: member.idType2,
                borrowerId: member.borrowerId,
                passportImage: member.borrowerPassportImage,

                isItGroupLoan: true,
                groupId: groupId,
                groupLoanId: groupLoanId,
                groupName: groupName,
                groupTotalAmount: groupCash,
                cash: Number(cash),
                interest: Number(interest),
                final_amount: Number(finalAmount),
                duration: Number(duration),
                duration_type: basis,
                repayment_cycle: repayment_circle_modified,
                number_of_repayments: Number(numberOfRepayments),
                repayment_amount: Number(repayment_amount),
                profit: Number(profit),
                penalty: {
                    penaltyAmount: 0,
                    amountPaid: 0,
                    amountRemained: 0,
                    lastTimeIncremented: maturityDate,
                },
                //releasedDate: releasedDate._d,
                releasedDate: loanReleasedDate,
                maturity: maturity,
                maturityDate: maturityDate,
                collateral: collateral,
                collateralValue: Number(collateralValue),
                repaymentDates: repaymentDates,
                status: "opened",
                amountPaid: 0,
                company: currentUser.company,
                companyId: currentUser.companyId,
                branch: currentUser.role === "worker" ? currentUser.branch : activeBranch.name,
                branchId: `${branchId}`,
                loanOfficer: currentUser.id,
                loanOfficerName: currentUser.username
            }
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=CREATE_LOAN`, {
                method: 'POST',
                body: JSON.stringify(loanObject),
                headers: {'Content-Type': 'application/json'}
            })

            const json = await response.json()
            if (response.ok) {
                await navigate('/Loans/View', {state: `${json._id}`})
                await stop(false)
                await openSnackbar(`New loan released`);
                await getCompanyInfo(json)
            }
            if (!response.ok) {
                openSnackbar(`Fill all required fields`)
                console.log(json)
                stop(false)

            }


        } catch (error) {
            console.log(error);
            showErrorMessage(error)
            stop(false)
        }
    }

//find total amount to each member
    useEffect(() => {
        if (selectedGroup === null) {
        } else {
            let amountForEachMember = Number(groupCash) / selectedGroup.members.length
            setCash(amountForEachMember)
        }
    }, [groupCash])
    return (
        <div className="main-wapper">
            <TopNav/>
            <div className="content-wrapper">
                <div className="sider">
                    <SiderContent/>
                </div>
                <div className="section" style={{backgroundColor: body}}>
                    <div className="-ps-wr">
                        {branchId === null && role === "company_admin" ? <SelectBranch/> :
                            <div className="new-loan-wrapper" style={{backgroundColor: card}}>
                                <h2>New loan</h2>
                                <Menu> <MenuItem className="my-tab" style={{
                                    borderBottomWidth: active === "1" ? 2 : 0,
                                    borderColor: active === "1" ? primary : lightgrey
                                }}
                                                 icon='user' text="Personal details"
                                                 onClick={() => setActive("1")}/></Menu>

                                <div className="tab-2">
                                    {isItGroupLoan ? <div className="ln-box">
                                            <p>Group details</p>
                                            {selectedGroup === null ? <div/> :
                                                <div className="borrower-bar">
                                                    <div className="borrower-titles">
                                                        <h5>{selectedGroup.name}</h5>
                                                        <h6>{selectedGroup.residence} |
                                                            Members {selectedGroup.members.length}</h6>
                                                    </div>
                                                    <div className="borrower-far" id="touchable"
                                                         onClick={() => setSelectedGroup(null)}>
                                                        <Icon icon="trash" color="#666" size={22}/>
                                                    </div>
                                                </div>}
                                            <input type="text" placeholder="Search group"
                                                   onChange={(e) => searchGroup(e.target.value)}/>
                                            <Collapse isOpen={isCollapsed2} canOutsideClickClose={true}
                                                      onClose={() => setIsCollapsed2(prev => !prev)}>
                                                {borrowersGroups.length <= 0 ? <div/> : borrowersGroups.map(group => (
                                                    <div className="borrower-bar" id="touchable"
                                                         onClick={() => selectGroup(group)}>
                                                        <div className="borrower-titles">
                                                            <h5>{group.name}</h5>
                                                            <h6>{group.residence} | Members {group.members.length}</h6>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Collapse>
                                        </div> :
                                        <div className="ln-box">
                                            <p>Borrowers details</p>
                                            {selectedBorrower === null ? <div/> :
                                                <div className="borrower-bar">
                                                    <img src={selectedBorrower.passportImage} alt="img" className=""/>
                                                    <div className="borrower-titles">
                                                        <h5>{selectedBorrower.fullName}</h5>
                                                        <h6>{selectedBorrower.phoneNumber}</h6>
                                                    </div>
                                                    <div className="borrower-far" id="touchable"
                                                         onClick={() => setSelectedBorrower(null)}>
                                                        <Icon icon="trash" color="#666" size={22}/>
                                                    </div>
                                                </div>}
                                            <input type="text" placeholder="Search borrower"
                                                   onChange={(e) => searchBorower(e.target.value)}/>
                                            <Collapse isOpen={isCollapsed} canOutsideClickClose={true}
                                                      onClose={() => setIsCollapsed(prev => !prev)}>
                                                {borrowers.length <= 0 ? <div/> : borrowers.map(borrower => (
                                                    <div className="borrower-bar" id="touchable"
                                                         onClick={() => setBorrower(borrower)}>
                                                        <img src={borrower.passportImage} alt="img" className=""/>
                                                        <div className="borrower-titles">
                                                            <h5>{borrower.fullName}</h5>
                                                            <h6>{borrower.phoneNumber}</h6>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Collapse>
                                        </div>
                                    }
                                    <div className="ln-box">
                                        <button class="switch-to-group-loan" id="touchable"
                                                onClick={() => setIsItGroupLoan(prev => !prev)}>
                                            {isItGroupLoan ? "Switch to Individual loan" : "Switch to Group loan"}
                                        </button>
                                    </div>
                                </div>

                                <Menu><MenuItem className="my-tab" style={{
                                    borderBottomWidth: active === "2" ? 2 : 0,
                                    borderColor: active === "2" ? primary : lightgrey
                                }}
                                                icon='dollar' text="Loan details"/></Menu>
                                <div className="tab-2">
                                    {isItGroupLoan ? <div className="ln-box">
                                        <p>Group Cash/Principal Amount</p>
                                        <input type="text" value={groupCash.toLocaleString()}
                                               onChange={(e) => setGroupCash(e.target.value)} placeholder="Cash"/>
                                    </div> : null}

                                    <div className="ln-box">
                                        {isItGroupLoan ? "Amount for each Member" : <p>Cash/Principal Amount</p>}
                                        <input type="text" value={cash.toLocaleString()}
                                               onChange={(e) => setCash(e.target.value)} placeholder="Cash"/></div>
                                    <div className="ln-box">
                                        <p>Interest rate:</p>
                                        <select onChange={(e) => setInterest(e.target.value)}>
                                            <option value="5">5%</option>
                                            <option value="10">10%</option>
                                            <option value="15">15%</option>
                                            <option value="20">20%</option>
                                            <option value="25">25%</option>
                                            <option value="30">30%</option>
                                            <option value="35">35%</option>
                                            <option value="40">40%</option>
                                            <option value="45">45%</option>
                                            <option value="50">50%</option>
                                        </select>
                                        <input type="text" placeholder='Custom interest rate.'
                                               onChange={(e) => setInterest(e.target.value)}/>
                                    </div>

                                    <div class="ln-box">
                                        <p>Final Amount</p>
                                        <input type="text" disabled={true} value={finalAmount}/>
                                    </div>

                                    <div class="ln-box">
                                        <p>Duration:</p>
                                        <select name="" onChange={(e) => setDuration(e.target.value)}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                        </select>

                                        <select onChange={(e) => setBasis(e.target.value)}>
                                            <option value="days">days</option>
                                            <option value="weeks">weeks</option>
                                            <option value="months">months</option>
                                            <option value="years">years</option>
                                        </select>
                                    </div>

                                    <div className="ln-box">
                                        <p>Repayment cycle </p>
                                        <select onChange={(e) => setRepaymentCircle(e.target.value)}>
                                            <option value="days">Daily</option>
                                            <option value="weeks">Weekly</option>
                                            <option value="months">Monthly</option>
                                            <option value="years">Yearly</option>
                                        </select>
                                    </div>


                                    <div className="ln-box">
                                        <p>Number of repayments</p>
                                        <input type="text" onChange={(e) => setNumberOfRepayments(e.target.value)}
                                               placeholder={` ${numberOfRepayments}`}/>
                                    </div>

                                    <div className="ln-box">
                                        <p>Loan released date </p>
                                       <input type={"date"}
                                              onChange={(e) => setLoanReleasedDate(e.target.value)}/>
                                    </div>

                                    <div className="ln-box">
                                        <p>Rapayment dates</p>
                                        <div className="ln-box-wrapper">
                                            {repaymentDates <= 0 ? <div/> :
                                                <div>
                                                    {repaymentDates.map((date, i) => (
                                                        <div className="ln-chart" key={i}>
                                                            <p>{date.id} repayment date {date.name} <em
                                                                className="em">[{date.amount.toLocaleString()}]</em></p>

                                                        </div>
                                                    ))}
                                                </div>}
                                        </div>
                                    </div>

                                    <div className="ln-box">
                                        <p>Repayment amount </p>
                                        <input type="text" onChange={(e) => repaymentFn(e.target.value)}
                                               value={repayment_amount}
                                        />
                                    </div>

                                    <div className="ln-box">
                                        <p>Profit</p>
                                        <input type="text" value={profit.toLocaleString()} disabled={true}/>
                                    </div>


                                    <div className="ln-box">
                                        <p>Maturity</p>
                                        <input type="text" value={maturity === null ? " " : maturity.toDateString()}
                                               disabled={true}/>
                                    </div>

                                    <div className="ln-box">
                                        <p>Collateral </p>
                                        <input type="text" onChange={(e) => setCollateral(e.target.value)}
                                               placeholder="e.g Tv,Land"/>
                                    </div>

                                    <div className="ln-box">
                                        <p>Collateral value </p>
                                        <input type="text" onChange={(e) => setCollateralValue(e.target.value)}
                                               placeholder="4000"/>
                                    </div>
                                </div>

                                <Menu><MenuItem className="my-tab" style={{
                                    borderBottomWidth: active === "3" ? 2 : 0,
                                    borderColor: active === "3" ? primary : lightgrey
                                }}
                                                icon='document' text="Loan attachments files"
                                                onClick={() => setActive("3")}/></Menu>
                                <div className="tab-3">
                                    <div className="filled"><b>Add attachment</b> <br/>
                                        <input type="text" placeholder="name of attachment"
                                               onChange={(e) => setAttachmentTitle(e.target.value)} id="title"/>
                                        <input type="file" placeholder=""
                                               onChange={(e) => setSelectedAttachment(e.target.files[0])}/>
                                        <button className="btn-save-upload" onClick={(e) => uploadAttachment()}>Upload
                                        </button>
                                    </div>

                                    {!show ? <div/> :
                                        <div>
                                            <div className="progress">
                                                <p>Uploading attachment {(percentage_state * 100).toFixed(0)} %</p>
                                                <ProgressBar value={percentage_state} stripes={true}/>
                                            </div>
                                        </div>
                                    }
                                    <p style={{marginLeft: 10, fontWeight: 'bold'}}>Uploaded</p>

                                    {attachmentsList.length <= 0 ? <div/> :
                                        <div>
                                            {attachmentsList.map((attachment, index) => (
                                                <Attachbox key={index} info={attachment} icon="trash" name="Remove"
                                                           removeAttachment={removeAttachment}/>
                                            ))}
                                        </div>}
                                </div>
                                <div>
                                    {errorMessage === null ? <div/> :
                                        <div className="input-bar" style={{borderWidth: 0}}>
                                            <Icon icon="error" color={"#b41313"} size={20} className='icon-auth'/>
                                            <p style={{color: "#b41313"}}>{errorMessage.toString()}</p>
                                        </div>
                                    }
                                    <button onClick={() => saveLoanDetail()}
                                            style={{backgroundColor: primary, color: lighttext}} className="save-loan">
                                        {spin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div/>}
                                        Save loan details
                                    </button>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newloan