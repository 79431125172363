import React,{ useContext, useState, useEffect, useRef} from 'react'
import TopNav from '../../components/TopNav'
import SiderContent from '../../components/SiderContent'
import { useGlobalContextHook } from '../../hook/useGlobalContextHook'
import { Icon, Tag, Spinner, SpinnerSize, Popover, Position, Menu, MenuItem, Drawer,  DrawerSize } from '@blueprintjs/core'
import { ThemeContext } from '../../App'
import EmojiPicker from 'emoji-picker-react'
import { useSnackbar } from 'react-simple-snackbar'
import { Dialog } from '@blueprintjs/core'
import format from 'date-fns/format'
import Empty from '../../components/Empty'
import outgoingSound from '../../assets/sounds/outgoing.mp3'
import incomingSound from '../../assets/sounds/incoming.wav'
import ChatBuble from '../../components/chatbuble/ChatBuble'
import useSound from 'use-sound'
import { io } from 'socket.io-client'
import { filter }  from 'smart-array-filter'
import './chat.css'
const lazyDefault = 'https://firebasestorage.googleapis.com/v0/b/micro-credit-ms.appspot.com/o/profile_photos%2Fhd-wallpaper-3765172_1920.jpg?alt=media&token=ec604c86-cadc-4d8f-a618-a57eafd2e855'
const lazyImg = 'https://firebasestorage.googleapis.com/v0/b/micro-credit-ms.appspot.com/o/3350441-removebg-preview.png?alt=media&token=a2318ad9-fdd5-4499-9d12-ce7c5c58b31a'

function Chat() {
    const { body } = useContext(ThemeContext)
    const { currentUser, dispatch, workers} = useGlobalContextHook()
    const { photoURL, _id } = currentUser
    const [isResizableActive,setIsResizableActive] = useState("resizable-active")
    const [isSearchShown,setIsSearchShown] = useState(false)
    const [isEmojiBoardOpen, setIsEmojiBoardOpen] = useState(false)
    const [sms, setSms] = useState('')
    const [isSendDisabled, setIsSendDisabled] = useState(true)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [wallpaperDialogOpen, setWallpaperDialogOpen] = useState(false)
    const [Limit, setLimit] = useState(30)
    const [criteria,setCriteria] = useState("all")
    const [openSnackbar] = useSnackbar()
    const [loading,setLoading] = useState(false)
    const [chatId, setChatId] = useState(null)
    const [chattingWith, setChattingWith] = useState(null)
    const [chats, setChats] = useState([])
    const [chatIds, setChatIds] = useState([])
    const [onlineUsers, setOnlineUsers] = useState([])
    const [receiverOnline, setReceiverOnline] = useState(false)
    const [playSound] = useSound(incomingSound)
    const [playIncomingSound] = useSound(incomingSound)
    const [isUploadingFile, setIsUploadingFile] = useState(false)
    const [isTyping, setIsTyping] = useState(false)
    //const socket = useRef(io("http://172.104.159.6:4600"))
    const socket = useRef(io(`${process.env.REACT_APP_SITE_URL}`))
    const [wallpaper, setWallpaper] = useState('https://firebasestorage.googleapis.com/v0/b/micro-credit-ms.appspot.com/o/profile_photos%2Fhd-wallpaper-3765172_1920.jpg?alt=media&token=ec604c86-cadc-4d8f-a618-a57eafd2e855')
    const [randomWallpaper, setRandomWallpaper] = useState([])
    const [imgQuery, setImageQuery] = useState('wallpaper')
    const [typingTimeOut, setTypingTimeOut] = useState(null)
    const [isChatsShown, setIsChatsShown] = useState(false)

    //connect to socket

    //set socket
    useEffect(() => {
        try {
            const { _id: currentUserId } = currentUser
            socket.current.emit("addUser", currentUserId )
            socket.current.on("listOnlineUsers", (list) => {
                setOnlineUsers(list)
            }) 
    
            getDefaullWallpaper()
            fetchrandomWallpaper()
        } catch (err) {
            console.log(err)
        }
    },[currentUser])

    //Search wallpaper 
    useEffect(() => {
        fetchrandomWallpaper()
    },[imgQuery])

    const fetchrandomWallpaper = async () => {
        try {
            const response = await fetch(` https://api.pexels.com/v1/search?query=${imgQuery}&per_page=40`,{
                method: "GET",
                headers: {'Authorization': '563492ad6f91700001000001f5cc1648405d4a5fbdbe0c5dc0ae9b59'}
            })
            const json = await response.json()
            if(response.ok) {
               setRandomWallpaper(json.photos)
            }
            if(!response.ok) {
                openSnackbar('Failed to fetch wallpaper')
            }
        } catch (err) {
            openSnackbar("Failed to fetch wallpapers")
            console.log(err)
        }
    }

    const getDefaullWallpaper = async() => {
        try {
            const defaultWallpaper = await localStorage.getItem("defaultWallpaper")
            if (defaultWallpaper === null) {
                 setWallpaper(wallpaper)
            } 

            if(defaultWallpaper !== null) {
                setWallpaper(defaultWallpaper)
            }
        } catch (err) {
            openSnackbar('Failed to get default wallpaper')
            console.log(err)
        }
    }

    const selectWallpaper = (image) =>{
        try {
            localStorage.setItem("defaultWallpaper", image)
            setWallpaper(image)
            setWallpaperDialogOpen(false)
        } catch (err) {
            openSnackbar('Failed to set wallpaper')
        }
    }

    useEffect(() => {
        //get messages
        socket?.current.on("getMessage", (message) => {
            setChatId(message.chatId)
             playSound()
             listChats(); listSms()      
        })

        //receive is typing
        socket?.current.on("receiveTyping", (message) => {
            setIsTyping(true)
        })

      //receive stopped typing
       socket?.current.on("receive-stoppedTyping", (message) => {
          setIsTyping(false)
      })
        


    },[socket])



    //check online status
    useEffect(() => {
        if(chattingWith !== null){
            const { _id: worker_id } = chattingWith
            const isUserOnline = onlineUsers.map(user=>user.id).indexOf(worker_id) 
            if(isUserOnline !== -1) {
                setReceiverOnline(true)
            } else { setReceiverOnline(false) }
        }
    },[onlineUsers, chatId])
    
    const typing =(text)=> {
        setSms(text)
    }

    const toggleSearch =()=> {
        setIsSearchShown(prev=>!prev)
        if(isSearchShown) { 
            listSms()
        }
    }

    const searchWorker =async(keyword)=>{
        if(keyword.length <= 0 ) {} else {
          setLoading(true)
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SEARCH_WORKERS&keyword=${keyword}&worker_criteria=${criteria}&limit=${Limit}&id=${currentUser.companyId}`)
          const json = await response.json()
          if(response.ok){
            dispatch({type:'SET_WORKERS',payload:json})
            setLoading(false)
          }
          if(!response.ok){
            openSnackbar(json.message)
            setLoading(false)
              }
           } 
    }
    
    const checkChatHistory= async(worker)=> {
        try {
            setChattingWith(worker)
            const { _id: worker_id } = worker
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=CHECK_CHAT_HISTORY&id=${worker_id}&receiver=${worker_id}&userId=${_id}`)
            const json = await response.json()
            if(response.ok) {
              setChatId(json)
              setIsDialogOpen(false)
            }
            if(!response.ok) {
                openSnackbar(json.message)
            }
        } catch (error){
            openSnackbar(error.message)
        }
    }
    
    const sendChatSms = async()=>{
        try {
            const { id } = chatId
            const { _id: worker_id } = chattingWith

            socket.current.emit("sendMessage", {
                senderId:_id,
                receiverId:worker_id,
                text:sms,
                chatId:chatId
            })
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=SEND_CHAT_SMS`,{
                method:'POST',
                body: JSON.stringify({
                    chatId:id,
                    text:sms,
                    senderId:_id,
                    receiverId:worker_id,
                    isItMedia:false,
                    sender:currentUser,
                    receiver:chattingWith
                }),
                headers: {'Content-Type': 'application/json'}
            })
            const json = await response.json()
            if(response.ok) { 
                setSms(''); listSms(); listChats(); setIsEmojiBoardOpen(false) 
                //playSound()
            }
            if(!response.ok) {
                openSnackbar(json.message) 
            }
        } catch (error){
            openSnackbar('Failed to send chat message')
        }
    }

    const listSms = async() => {
        if(chatId === null) {} else {
            try {
                const { id } = chatId
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_CHAT_SMS&id=${id}`)
                const json = await response.json()
                if(response.ok) {
                    setChats(json)
                }
    
                if(!response.ok) {
                    openSnackbar(json.message)
                }
            } catch (error){
                //openSnackbar('Failed to send list message')
                console.log(error)
            }
        }
    }


    const listChats = async()=>{
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_CHATS&id=${_id}`)
            const json = await response.json()
            if(response.ok) {
                setChatIds(json)
            }

            if(!response.ok) {
                openSnackbar(json.message)
            }
        } catch (error){
            openSnackbar('Failed to list chats')
            //console.log(error)
        }
    }

    const markAllSmsAsRead = async(chatIdParam)=>{
        try {
            const { _id: currentUserId } = currentUser
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=MARK_SMS_READ&id=${chatIdParam}&userId=${currentUserId}`,{
                method: "PATCH"
            })
            const json = await response.json()
            if(response.ok) {
               
            }

            if(!response.ok) {
                openSnackbar(json.message)
            }
        } catch (error){
            openSnackbar('Failed to clear read sms')
            console.log(error)
        }
    }

    const openChat =(chat)=>{
       const { _id } = chat
       const { _id: currentUserId } = currentUser
       const worker = chat._senderId === currentUserId ? chat._receiver : chat._sender
       checkChatHistory(worker)
       markAllSmsAsRead(_id)
       setIsChatsShown(false)
    }

    const sendFile = async(file)=>{
        try {
            //upload file first
            setIsUploadingFile(true)
            const formData = new FormData()
            formData.append(`myFile`, file)

            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=SEND_FILE`,{
             method: 'POST',
             body: formData
            })
            const json = await response.json()

            if(response.ok){
             setIsUploadingFile(false)
             saveFileText(json, file)     
           } 
           
           if(!response.ok) {
            openSnackbar('Sending file error')
            setIsUploadingFile(false)
           }
           
        } catch(err) {
            openSnackbar("Failed to send file")
        }
    }

    const  saveFileText = async(fileText, fileSelected)=> {
        try {
            const { id } = chatId
            const { _id: worker_id } = chattingWith
            const { name, size } = fileSelected
            const url = fileText.message
            const media = {
                name:name,
                size:size,
                url:url,
                fileExt: name.split('.').pop()
            }

            socket.current.emit("sendMessage", {
                senderId:_id,
                receiverId:worker_id,
                text:sms,
                chatId:chatId
            })
            
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=SEND_CHAT_SMS`,{
                method:'POST',
                body: JSON.stringify({
                    chatId:id,
                    text:'media',
                    senderId:_id,
                    receiverId:worker_id,
                    isItMedia:true,
                    media:media,
                    sender:currentUser,
                    receiver:chattingWith
                }),
                headers: {'Content-Type': 'application/json'}
            })
            const json = await response.json()
            if(response.ok) { 
                listSms(); listChats();
                //playSound()
            }
            if(!response.ok) {
                openSnackbar(json.message) 
            }
        } catch(err) {
            openSnackbar('Sending file error')
        }
    }

    const downloadFile = (url) => {
        const filePath = `${process.env.REACT_APP_SITE_URL}${url}`
        window.open(filePath, '_blank')
        
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send();
    }


    useEffect(()=>{
        if(sms === ''){
            setIsSendDisabled(true)
        } else {
             setIsSendDisabled(false)
             //send typing
             const { _id: worker_id } = chattingWith
             socket.current.emit("sendTyping",{
                senderId:_id,
                text:sms,
                chatId:chatId,
                receiverId:worker_id
            })

            //stopped typing   
            if(typingTimeOut)  clearTimeout(typingTimeOut)
            setTypingTimeOut(
              setTimeout(() => {
                socket.current.emit("stoppedTyping",{
                    senderId:_id,
                    text:sms,
                    chatId:chatId,
                    receiverId:worker_id
                })
              },1000)
            )
            
            }
    },[sms])

    useEffect(()=>{
        listChats()
       if(chatId === null) { } else {
           listSms()
       }
    },[chatId])

    //auto scroll to the bottom 
    useEffect(()=>{
        const chatHolder = document.getElementById('chat-holder')
         if(chatHolder) {
            chatHolder.scrollIntoView({behavior: "smooth"})
         }
    },[chats])


    const searchChatIds =(keyword)=> {
        if(keyword.length > 0) {
            const filteredData = filter(chatIds, {
                limit: 1,
                keywords: keyword, 
                caseSensitive: false
              });
              setChatIds(filteredData)
        } else {
            listChats()
        }
          
    }

    const searchSms = async(keyword) => {
        if(chatId === null) {} else {
            try {
                const { id } = chatId
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SEARCH_CHAT_SMS&id=${id}&keyword=${keyword}`)
                const json = await response.json()
                if(response.ok) {
                    setChats(json)
                }
    
                if(!response.ok) {
                    openSnackbar(json.message)
                }
            } catch (error){
                //openSnackbar('Failed to send list message')
                console.log(error)
            }
        }
    }

    const deleteConversation = async() => {
        if(chatId === null) {} else {
            try {
                const { id } = chatId
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/remove?action=DELETE_CHAT&id=${id}&`,{
                    method: "DELETE"
                })
                const json = await response.json()
                if(response.ok) {
                    setChattingWith(null)
                    listChats()
                    openSnackbar('Chats deleted successfully')
                }
    
                if(!response.ok) {
                    openSnackbar(json.message)
                }
            } catch (error){
                //openSnackbar('Failed to send list message')
                console.log(error)
            }
        }  
    }

  return (
    <div className="main-wapper" ><TopNav /> 
        <div className="content-wrapper">
            <div className="sider" ><SiderContent /></div>
            <div className="section" style={{backgroundColor:body}}>
            <div className='chat-wrapper'>
                <div className='chat-left-sider'> 
                  <input type="text" placeholder="Search user" onChange={(e)=>searchChatIds(e.target.value)} /> 
                    {chatIds.length <= 0 ? <Empty icon="chat" name="Currently,No any chat" color="#666" /> : chatIds.map(chat=>(
                        <div className='user-chat-bar' id='touchable' onClick={() =>openChat(chat)}>
                        <img src={chat._sender.photoURL === photoURL ? chat._receiver.photoURL: chat._sender.photoURL} alt='some image' />
                        <div className='user-chat-title'>
                            <h3>{chat._senderId === _id ? chat._receiver.username: chat._sender.username}</h3> 
                             <p>{ chat._lastText}</p>
                        </div>
                        <div className='chat-far'>
                            <p>{format(new Date(chat._lastTextTime), "h:mm a")}</p>
                           {chat._unReadSms <= 0 ? null :  <div className='chat-badge'>{chat._unReadSms}</div>}
                        </div>
                    </div>  
                    ))}
                    <div className='bottom-new-chat'><button id='touchable-primary' onClick={(e)=>setIsDialogOpen(prev=>!prev)}>New chat</button></div>
                    </div>

                <div className='chat-section' id={`${isResizableActive}`} 
                    style={{backgroundImage:`url(${wallpaper})`}}
                    >
                    {chattingWith === null  ? 
                    <div className='chat-lazy-load'>
                        <div className='chat-lazy-img-box'>
                        <img src={lazyImg} alt='alt' />
                        </div>
                        <h2>You haven't opened any messages yet, Please select message</h2>
                        <div className='bottom-new-chat'><button class='button' id='touchable-primary' onClick={(e)=>setIsDialogOpen(prev=>!prev)}>New chat</button></div>
                        <div className='small-btn-inline'>
                            <button id='touchable'  onClick={(e)=>setIsChatsShown(prev=>!prev)}>Chats</button>
                            <button id='touchable-primary' 
                                onClick={(e)=>setIsDialogOpen(prev=>!prev)}
                                style={{backgroundColor:'#07608B', color:'#fff'}}>New chat</button>
                        </div>
                    </div> :
                     <div className='chat-bar'>
                  <div className='chat-back' id='touchable'  onClick={() => setIsChatsShown(true)}><Icon icon='arrow-left' color='#666' size={18} /></div>
                    <img src={chattingWith.photoURL} alt='some image' />
                    {receiverOnline ?  <Icon icon='symbol-circle' color='#43a50e' size={20}  className='icon-online'/> : null}
                        <div className='user-chat-title'>
                            <h3>{chattingWith.username}</h3> 
                            <p style={{color: receiverOnline ? '#43a50e':'#333'}}>{ receiverOnline ? 'online': chattingWith.email}</p>
                        </div>
                        {isSearchShown ? <input type='search' placeholder='Search messages'  onChange={(e)=>searchSms(e.target.value)} /> :  null}
                        <div className='chat-far'>
                            <div className='chat-inline-far'>
                            <Popover  
                                content={
                                    <Menu>
                                        <MenuItem text="Delete chat" onClick={() => deleteConversation()}  icon='trash'/>
                                        <MenuItem text="Wallpaper" onClick={() => setWallpaperDialogOpen(true)}  icon='media'/>
                                    </Menu>
                                    } position={Position.BOTTOM_BOTTOM}>
                                    <div className='chat-inline-button' id='touchable'><Icon icon='more' color='#666' size={18} /></div>
                               </Popover>
                                <div className='chat-inline-button' id='touchable'  onClick={() =>toggleSearch()}><Icon icon={isSearchShown ? 'cross':'search'} color='#666' size={18} /></div>                               {/** <div className='chat-inline-button' id='touchable'  onClick={()=>resizeSider()}> 
                                    <Icon icon={isChevronRight ? "drawer-left-filled": "drawer-right-filled"}   color='#666' size={18} /></div>*/}
                            </div> 
                        </div>
                    </div>}
 
                    <input type='file' id="file-upload" onChange={(e)=>sendFile(e.target.files[0])} />
                   {chats.length <= 0  || chattingWith === null? null : 
                   <div className='chat-holder' >
                     {chats.length <= 0 ? null : chats.map(chat => (
                        <div className='date-group' key={chat._id}><div className='date-tag'>
                             <Tag  multiline={true}   style={{margin:5}} round={true}>{format(new Date(chat._id), "dd/MMM/yyyy")}</Tag>
                            </div>
                            
                         {chat.chats.length <= 0  ? null : chat.chats.map((singleChat, i) => (
                            <div>
                            {singleChat.senderId === _id ?  <div className='normal-chat-buble-right' key={i}>
                                { singleChat.isItMedia ?  <div className='chat-me' style={{borderRadius:'1rem'}}>
                                <div class="attach-bar-x">
                                    <div class="attach-icon-x"><Icon icon='document' /></div>
                                        <div class="attach-title-x"><h4>{singleChat.media.name}</h4><p>{(Number(singleChat.media.size) / 1000000).toFixed(2)}Mb</p></div>
                                            <div class="attach-far-x"><div id='touchable-primary' onClick={() =>downloadFile(singleChat.media.url)}><Icon icon='download' size={25} /></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    : <div className='chat-me'>{singleChat.text} 
                                <Icon icon='small-tick'  color={singleChat.messageRead ? '#43a50e':'#07608B'}/>
                                </div>}
                                <br /> <br /> <br />
                                    <div className='chat-time-me'>{format(new Date(singleChat.createdAt), "h:mm a")}</div>
                            </div>: 
                        
                        <div className='normal-chat-buble-left'>
                            { singleChat.isItMedia ?  <div className='chat-text' style={{borderRadius:'1rem'}}>
                                <div class="attach-bar-x">
                                    <div class="attach-icon-x"><Icon icon='document' /></div>
                                        <div class="attach-title-x"><h4>{singleChat.media.name}</h4><p>{(Number(singleChat.media.size) / 1000000).toFixed(2)}Mb</p></div>
                                            <div class="attach-far-x"><div id='touchable-primary' onClick={() =>downloadFile(singleChat.media.url)}><Icon icon='download' size={25} /></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    : <div className='chat-text'>{singleChat.text} 
                                </div>}
                            
                            <br /> <br /> <br />
                                <div className='chat-time'>{format(new Date(singleChat.createdAt), "h:mm a")}</div>
                            </div>}
                        </div>
                    ))}

                        </div>
                     ))}
                      <div id='chat-holder'></div>
                   </div>}

                {isUploadingFile ?   <div className='normal-chat-buble-right'>
                    <div className='chat-me' style={{borderRadius:'1rem'}}>
                        <div class="attach-bar-x">
                        <div class="attach-icon-x"><Icon icon='document' /></div>
                            <div class="attach-title-x"><h4>Job Offer description and tasks.doc</h4><p style={{color:'#fff'}}>Uploading...</p></div>
                                <div class="attach-far-x"><div id='touchable'> <Spinner size={SpinnerSize.SMALL} intent="warning"/></div>
                        </div>
                        </div>
                    </div> 
                    </div> : <div />  }

                { isTyping ? <div className='normal-chat-buble-left'>
                    <div className='chat-text'>
                        <div className="typing-bar">
                        <h4>{chattingWith.username}</h4><p>is typing</p>
                        <div className="fb-chat">
                        <div className="fb-chat--bubbles">
                                <span></span><span></span><span></span>
                                 </div>
                            </div>
                        </div>
                        </div>                    
                    </div>: null}
                   
                <div className='emoji-board' id={isEmojiBoardOpen ? 'show-emoji-board':'hide-emoji-board'}> 
                 <EmojiPicker width='100%' onEmojiClick={(emoji)=>setSms(sms.concat(emoji.emoji))}/></div>
                 {chattingWith === null ? null : <div className='chat-input-bar'>
                    <div className='chat-min-bar'>
                        <input type="text" placeholder="Write your message" value={sms}  onChange={(e)=>typing(e.target.value)}/>
                        <div className='chat-inline-far'>
                                <div className='chat-inline-button' id='touchable' onClick={()=>setIsEmojiBoardOpen(prev=>!prev)}><Icon icon='emoji' color='#666' size={18} /></div>
                                <label for="file-upload"><div className='chat-inline-button' id='touchable'><Icon icon='paperclip' color='#666' size={18} /></div></label>
                                <div className='chat-inline-button' id='touchable' onClick={() => sendChatSms()}>
                                    <Icon icon='send-message'   color={isSendDisabled ? '#666': '#07608B'} size={18} /></div>
                            </div>
                    </div>
                    </div> }                   
                </div>
               
            </div>
            <Dialog isOpen={isDialogOpen} canOutsideClickClose={true}
                style={{width:'50%',height:'90vh'}} 
                title='New chat'
                onClose={(e)=>setIsDialogOpen(prev=>!prev)}>
             <div className='new-chat-wrapper'>
                <input type='search' placeholder='Search user' onChange={(e)=>searchWorker(e.target.value)}/>

                { workers.length <= 0 ? <div /> : workers.map(worker=>(
                    <div className='user-chat-bar' id='touchable' onClick={()=>checkChatHistory(worker)}
                    style={{borderBottom: '1px solid #ccc',backgroundColor:'#fff'}}>
                    <img src={worker.photoURL} alt='some image' />
                        <div className='user-chat-title'>
                            <h3>{worker.username}</h3> <p>{worker.email}</p>
                        </div>
                    </div>
                ))}
             </div>
            </Dialog>
            
            <Dialog isOpen={wallpaperDialogOpen} canOutsideClickClose={true}
                style={{width:'50%',height:'90vh'}} 
                title='Chat wallpaper'
                onClose={() => setWallpaperDialogOpen(prev=>!prev)}>
                    <div className='new-chat-wrapper'>
                      <input type='search' placeholder='Search photo' onChange={(e)=>setImageQuery(e.target.value)}/>
                  </div>
             <div className="photos">
             <div className="frame"  onClick={() =>selectWallpaper(lazyDefault)}>
                  <img src={lazyDefault} />
              </div>
              {randomWallpaper.length <= 0 ? null : randomWallpaper.map((img,i)=>(
              <div className="frame" key={i} onClick={() =>selectWallpaper(img.src.original)}>
                  <img src={img.src.small} />
              </div>
               ))}
           
          </div>
            </Dialog>

            <Drawer isOpen={isChatsShown} 
              title="Chats"
              titleTextColor="#333"
              position={Position.LEFT}
              size={ DrawerSize.LARGE } 
              canClickOutsideToClose={ true } onClose={()=>setIsChatsShown(prev=>!prev)}>

             <div className='chat-left-sider-small'> 
                  <input type="text" placeholder="Search user" onChange={(e)=>searchChatIds(e.target.value)} /> 
                    {chatIds.length <= 0 ? <Empty icon="chat" name="Currently,No any chat" color="#666" /> : chatIds.map(chat=>(
                        <div className='user-chat-bar' id='touchable' onClick={() =>openChat(chat)}>
                        <img src={chat._sender.photoURL === photoURL ? chat._receiver.photoURL: chat._sender.photoURL} alt='some image' />
                        <div className='user-chat-title'>
                            <h3>{chat._senderId === _id ? chat._receiver.username: chat._sender.username}</h3> 
                             <p>{ chat._lastText}</p>
                        </div>
                        <div className='chat-far'>
                            <p>{format(new Date(chat._lastTextTime), "h:mm a")}</p>
                           {chat._unReadSms <= 0 ? null :  <div className='chat-badge'>{chat._unReadSms}</div>}
                        </div>
                    </div>  
                    ))}
                    <div className='bottom-new-chat'><button id='touchable-primary' onClick={(e)=>setIsDialogOpen(prev=>!prev)}>New chat</button></div>
                    </div>
            </Drawer>
        </div>
     </div>
    </div>
  )
}

export default Chat