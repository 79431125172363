import React,{useState,useEffect} from 'react'
import {Icon,Collapse,Menu,MenuItem,Position,Popover as BlueprintPopover} from '@blueprintjs/core'
import 'react-day-picker/dist/style.css';
import addDays from 'date-fns/addDays'
import { useSnackbar } from 'react-simple-snackbar'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import Empty from '../components/Empty'
import Falling from '../components/Falling'
import { Defs, linearGradientDef } from '@nivo/core'
import { ResponsiveLine } from '@nivo/line'
import AccessDenied from '../components/AccessDenied';
var moment = require('moment'); 


function ReportsTab({branchId,branch}) {
  const {report,dispatch,financeReport,branchLoanGraph,branchRepaymentGraph,branchExpenseGraph,currentUser} = useGlobalContextHook()
  const {companyId,username,settings} = currentUser
  const {permissions} = currentUser
  const {reports:reportPermissions} = permissions
  const {currency} = settings
  const [openSnackbar] = useSnackbar()
  const [datePicked,setDatePicked] = useState(new Date().toJSON().slice(0,10))
  const [open3,setOpen3] = useState(false)
  const [fromDate,setFromDate] = useState(addDays(new Date(),-7).toJSON().slice(0,10))
  const [toDate,setToDate] = useState(addDays(new Date(),1).toJSON().slice(0,10))
  const [data,setData] = useState([])
  const [Limit,setLimit] = useState(20)
  const [keyword,setKeyword] = useState("")
  const [repaymentsReport,setRepaymentReport] = useState([])

 const FirstDateSelector = async (date) => {
  let date_picked =await  moment.utc(date);
  setFromDate(date_picked.format("yyyy-MM-DD"))
}

const SecondDate = async (date) => {  
  let date_picked =await  moment.utc(date);  
  let plusAddedTime =  date_picked.clone().add(1,"days");
  setToDate(plusAddedTime.format("yyyy-MM-DD"))
}

const setDailyDates = (date)=>{
  let date_picked =  moment.utc(date);
  setFromDate(date_picked.format("yyyy-MM-DD"))

  let plusAddedTime =  date_picked.clone().add(1,"days");
  setToDate(plusAddedTime.format("yyyy-MM-DD"))

}

useEffect(() => {
  const unsubscribe = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=REPORT&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
      const json = await response.json()
      if(response.ok) {
        dispatch({type:'SET_REPORT',payload:json})
        
      }
      if(!response.ok){
        openSnackbar(json.message)
      }
    } catch (e) {
      openSnackbar(e)
    }
  }
  unsubscribe();
},[fromDate, toDate,branchId])


useEffect(() => {
  const unsubscribe = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=REPAYMENTS_REPORT&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
      const json = await response.json()
      if(response.ok) {
        setRepaymentReport(json)
        
      }
      if(!response.ok){
        openSnackbar(json.message)
      }
    } catch (e) {
      openSnackbar(e)
    }
  }
  unsubscribe();
},[fromDate, toDate,branchId])

useEffect(()=>{
  const unsubscribe = async () => {
    try{
      const  response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=EXPENSE_REPORT&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
      const json = await response.json()
      if(response.ok) {
        dispatch({type:'SET_FINANCE_REPORT',payload:json})
      }
      if(!response.ok) {
        openSnackbar(json.message)
      }
    }catch(error){openSnackbar(error)}
  }
  unsubscribe()
},[fromDate,toDate,branchId])

//loan graph
useEffect(()=>{
const unsubscribe = async () => {
  try{
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=BRANCH_LOAN_GRAPH&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
    const json = await response.json()
    if(response.ok){
       dispatch({type:'BRANCH_LOAN_GRAPH',payload:json})
    } 
    if(!response.ok){
      openSnackbar(json.message)
    }
  } catch(error){openSnackbar(error)}
}
unsubscribe()
},[fromDate,toDate,branchId])

//repayment graph
useEffect(()=>{
  const unsubscribe = async () => {
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=BRANCH_REPAYMENT_GRAPH&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
      const json = await response.json()
      if(response.ok){
         dispatch({type:'BRANCH_REPAYMENT_GRAPH',payload:json})
      } 
      if(!response.ok){
        openSnackbar(json.message)
      }
    } catch(error){openSnackbar(error)}
  }
  unsubscribe()
  },[fromDate,toDate,branchId])

//expense graph  
useEffect(()=>{
  const unsubscribe = async () => {
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=BRANCH_EXPENSE_GRAPH&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}`)
      const json = await response.json()
      if(response.ok){
         dispatch({type:'BRANCH_EXPENSE_GRAPH',payload:json})
      } 
      if(!response.ok){
        openSnackbar(json.message)
      }
    } catch(error){openSnackbar(error)}
  }
  unsubscribe()
  },[fromDate,toDate,branchId])

//aggregate loan graphs  
useEffect(() => {
  setData([
    branchLoanGraph[0]|| {id:"Loans",data:[{x:"0",y:"0"}]},
    branchRepaymentGraph [0] || {id:"Repayments",data:[{x:"0",y:"0"}]},
    branchExpenseGraph[0] || {id:"Expenses",data:[{x:"0",y:"0"}]}
  ])
},[branchLoanGraph,branchRepaymentGraph,branchExpenseGraph])

const getCompanyInfo = async()=>{
  try{
    if(report[0] === undefined  && financeReport.length <= 0) {openSnackbar('There no enough information to print report')} else {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_USER_BY_COMPANY_ID&id=${companyId}`)
      const json = await response.json()
      if(response.ok){
        downloadFormLoan(json[0])
      }
      if(!response.ok){
        openSnackbar(json.message)
      }
    }
  }catch(error){openSnackbar("Error getting company")}
}

const downloadFormLoan = async(companyOwner)=>{
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=PRINT_REPORT&branch=${branch}&currency=${currency}&company=${companyOwner.company}&title=Report&fromDate=${fromDate}&toDate=${toDate}&email=${companyOwner.email}&phone=${companyOwner.phoneNumber}&username=${username}`,{
      method: 'POST',
      body: JSON.stringify({
        loans:report.length <= 0 ? 0: report[0].loans,
        repayments:repaymentsReport.length <= 0 ? 0: repaymentsReport[0].repayments,
        finance:financeReport.length <= 0 ? 0 :financeReport[0].expense,
        logo:companyOwner.photoURL
      }),
      headers: {'Content-Type': 'application/json'}
    })
    if(response.ok){
      const file = await response.blob()
      const createdFile =  await window.URL.createObjectURL(new Blob([file]), { type: 'application/pdf'});
      const link = document.createElement('a');
      link.href = createdFile;
      link.download = `Report ${branch} from ${fromDate}=>${toDate}.pdf`;
      link.click(); 
    }
  
    if(!response.ok){
      openSnackbar("Error creating pdf")
    }
  } catch (error) {
   
    }
  }


  return (
 <div>
  {reportPermissions[1].isGranted ? 
    <div>
    <div className="stat-box">
    <div className="stat-h3" style={{ backgroundColor:"#F59E0B"}}><Icon icon="projects" style={{marginTop:0}} color={"#fff"} size={30} className="exp-icon-icon"/></div>
    <div className="stat-para" ><h3 className="stat-h4">{branch.toUpperCase()}</h3><p>Reports</p></div>
    <div className="hide-para">
    <div className="date-from">
        <div className="" style={{color:"#444"}}>From</div>
        <input type="date" onChange={(e) =>FirstDateSelector(e.target.value)} />
        </div>
    <div className="date-from">
        <div className="" style={{color:"#444"}}>To</div>
         <input type="date" onChange={(e)=>SecondDate(e.target.value)} />
     </div>
      
    {reportPermissions[0].isGranted ?      <div className="select-date" style={{border:0}} id="triad" onClick={() =>getCompanyInfo()}>
        Expor PDF&emsp;
          <Icon icon="chevron-down" />
     </div>: <div />}
    </div>
    <div className="far-menu-search" onClick={()=>setOpen3(prev=>!prev)}>
          <Icon icon="filter-list" color={"#07608B"} size={20} className="search-icon-iconx" />
        </div>
   </div>
   <Collapse isOpen={open3} canOutsideClickClose={true} onClose={()=>setOpen3(prev=>!prev)}>
   <div className='filter-collapse'>
    <input  className="date-cl" type="date" value={fromDate} placeholder="Date" onChange={(e)=>FirstDateSelector(e.target.value)} />
    <Icon icon="arrow-right" color={"#666"} className="icon-arrow-right"/>
    <input className="date-cl" type="date" value={toDate} placeholder="Date" onChange={(e)=>SecondDate(e.target.value)} />

<BlueprintPopover content={
          <Menu>
           <MenuItem   icon='document'text="PDF" onClick={()=>getCompanyInfo()}/>    
        </Menu>} position={Position.BOTTOM_BOTTOM}>
 {reportPermissions[0].isGranted ?  <button className='sort-btn'  style={{margin:15}}>Export <Icon icon="chevron-down" color={"#07608B"} /></button>: <div />}
</BlueprintPopover>
    </div>
          </Collapse>

   <div className="e-main">
      <div className="e-section" style={{width:window.innerWidth > 1000 ?'65%':'90vh',height:window.innerWidth > 1000 ?'45vh':'40vh',marginBottom:window.innerWidth > 1000 ? 0 : 20}}>
      <div className="e-bar" >
            <div className="e-img" id="e-img" style={{backgroundColor:"#1B9E77"}}>
               <Icon icon ="dollar" size={20} color= "#fff" id="img"/>
                </div>
                
          <div className="e-title">
              <h3 style={{marginTop:30}}>Loans</h3>
               <p></p>
          </div>
              <div className="e-price">{report.length <= 0 ?"0": report[0].loans.toLocaleString()}<span className="currency">{currency}</span></div>
          </div>


          <div className="e-bar" >
            <div className="e-img" id="e-img" style={{backgroundColor:"#D95F02"}}>
               <Icon icon ="import" size={18} color= "#fff" id="img"/>
                </div>
                
          <div className="e-title">
              <h3 style={{marginTop:30}}>Repayments</h3>
               <p></p>
          </div>
              <div className="e-price">{repaymentsReport.length <= 0 ? 0 : repaymentsReport[0].repayments.toLocaleString()}<span className="currency">{currency}</span></div>
          </div>
         
          <div className="e-bar" style={{borderWidth:0}}>
            <div className="e-img" id="e-img" style={{backgroundColor:"#7570B3"}}>
               <Icon icon ="export" size={18} color= "#fff" id="img"/>
                </div>
                
          <div className="e-title">
              <h3 style={{marginTop:30}}>Expenses</h3>
               <p></p>
          </div>
              <div className="e-price">{financeReport === undefined ? <div>Loading</div> :
               financeReport.length <= 0 ? <Falling /> : financeReport[0].expense.toLocaleString()}<span className="currency">{currency}</span></div>
          </div>
        
      </div>

      <div className="e-sider" style={{width:window.innerWidth > 1000 ?'35%':'90vh',height:window.innerWidth > 1000 ?'45vh':'38vh',marginBottom:window.innerWidth > 1000 ? 0 : 30}}>
      <div className="e-div-div">
      <h3 style={{color:'#444',paddingLeft:20}}>
             <Icon icon="calendar" color="#07608B" />&emsp;
             Daily report</h3>
             <input type="date" value={fromDate} onChange={(e)=>setDailyDates(e.target.value)} />
      </div>
      </div>
    </div>
<div className="e-chart">
{
branchLoanGraph === undefined  ||
branchRepaymentGraph === undefined ? <h3>Laoding...</h3> : 
branchLoanGraph.length <= 0 || branchRepaymentGraph.length <= 0 ? <Empty icon="chart" name="No Enough data available to generate charts" color="#666" /> :
<ResponsiveLine
data={data}
margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
defs={[
  linearGradientDef('gradientA', [
    { offset: 0, color: 'inherit' },
{ offset: 70, color: 'inherit', opacity: 0.1 },
]),
]}
fill={[{ match: '*', id: 'gradientA' }]}
curve="monotoneX"
xScale={{ type:'point'}}
yScale={{
    type: 'linear',
    min: 0,
    max: 'auto',
    stacked: true,
    reverse: false
}}

axisTop={null}
axisRight={null}

colors={{ scheme: 'dark2' }}
pointSize={0}
pointColor={{ theme: 'background' }}
pointBorderWidth={2}
pointBorderColor={{ from: 'serieColor' }}
pointLabelYOffset={-12}
useMesh={true}
areaOpacity={0.05}
enableArea = {true}
legends={[
  {
      anchor: 'bottom-right',
      direction: 'column',
      justify: false,
      translateX: 100,
      translateY: 0,
      itemsSpacing: 0,
      itemDirection: 'left-to-right',
      itemWidth: 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: 'circle',
      symbolBorderColor: 'rgba(0, 0, 0, .5)',
      effects: [
          {
              on: 'hover',
              style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
              }
          }
      ]
  }
]}

/>
      }
    </div>
    </div>
   : <AccessDenied />}
 </div>
  )
}

export default ReportsTab