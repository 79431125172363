import React,{useContext,useState} from 'react'
import {ThemeContext} from '../App'
import {Drawer,Position,DrawerSize,Icon} from '@blueprintjs/core'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import  BalanceSheet from '../accountingtabs/BalanceSheet'
import CashFlow from '../accountingtabs/CashFlow'
import Ledger from '../accountingtabs/Ledger'
import ProfitAndLoss from '../accountingtabs/ProfitAndLoss'
import AccountsList from '../accountingtabs/AccountsList'
import TrialBalance from '../accountingtabs/TrialBalance'
import JournalEntry from '../accountingtabs/JournalEntry'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import SelectBranch from '../components/SelectBranch'

function Accounting() {
    const { body} = useContext(ThemeContext)
    const { currentUser,activeBranch,selectedTabId,dispatch} = useGlobalContextHook()
    const { role,company,companyId} = currentUser
    const branchId = activeBranch === null ? null : currentUser.role === "worker" ? currentUser.branchId :  activeBranch._id
    const branch = activeBranch === null ? currentUser.branch : currentUser.role === "worker" ? currentUser.branch :  activeBranch.name
    const [isDrawerOpen,setIsDrawerOpen] = useState(false)
    const [isResizableActive,setIsResizableActive] = useState("not-resizable-active")
    const [isResizableSider,setIsResizableSider] = useState("not-resizable-sider")
    const [isChevronRight,setIsChevronRight] = useState(true)
    const [tabs,setTabs] = useState([
      {id:"1",title:"Chart of Accounts"},
      {id:"2",title:"Journal Entry"},
      {id:"3",title:"General Ledger"},
      {id:"4",title:"Trial Balance"},
      {id:"5",title:"Balance Sheet"},
      {id:"6",title:"Profit and Loss"},
     /* {id:"7",title:"Cashflow"},*/
       ])
  
     const handleSelectedTab =(id)=>{
      dispatch({type:'SET_SELECTED_TAB',payload:id})
        setIsDrawerOpen(false)
     }
     const resizeSider = ()=>{
       if(isResizableActive === "not-resizable-active" && isResizableSider === "not-resizable-sider"){
        setIsResizableActive("resizable-active")
        setIsResizableSider("resizable-sider")
        setIsChevronRight(false)
  
       } else { 
        if(isResizableActive === "resizable-active" && isResizableSider === "resizable-sider"){
          setIsResizableActive("not-resizable-active")
          setIsResizableSider("not-resizable-sider")
          setIsChevronRight(true)
         }
       }     
     } 
  return (
    <div className="main-wapper" >
    <TopNav />
    <div className="content-wrapper">
        <div className="sider" >
         <SiderContent />
     </div>
     <div className="section" style={{backgroundColor:body}}>
     {activeBranch === null && role === "company_admin" ? <SelectBranch /> :<div>
<div className="page-bar">
       <h1>Accounting</h1>
       <div className="options" id="touchable" onClick={()=>setIsDrawerOpen(prev=>!prev)}>Options</div>
     </div>
<div className="setting-container">
        <div className="setting-active"  id={`${isResizableActive}`}>
         {selectedTabId === "1" ? <AccountsList branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> :
          selectedTabId === "2" ? <JournalEntry branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> :
          selectedTabId === "3" ? <Ledger branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id }/> :
          selectedTabId === "4" ? <TrialBalance branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id }/> :
          selectedTabId === "5" ? <BalanceSheet branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id }/> :
          selectedTabId === "6" ? <ProfitAndLoss branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> :
         <div />
         }
        </div>
        <div className="setting-sider" id={`${isResizableSider}`} >
         <h2>Options</h2>
         {tabs.length <= 0 ? <div /> : tabs.map(tab=>(
           <li id="touchable"  
           className={selectedTabId === tab.id ? "selected-tab":"unselected-tab"}
           key={tab.id} onClick={() =>dispatch({type:'SET_SELECTED_TAB',payload:tab.id})}>{tab.title}</li>
         ))}
           <div className="resize-fab" id="touchable" onClick={()=>resizeSider()}>
             <Icon icon={isChevronRight ? "chevron-right": "chevron-left"} color="#666"  size={22}/>
           </div>
        </div>
     </div>

     <Drawer 
           className="sider-drawer"
           isOpen={isDrawerOpen} 
           title="Accounting options"
           titleTextColor="#fff"
           position={Position.RIGHT}
           size={window.innerWidth > 1000 ? DrawerSize.STANDARD :  DrawerSize.LARGE} 
           canClickOutsideToClose={true} onClose={()=>setIsDrawerOpen(prev=>!prev)}>

       <div className="setting-small-sider">
       {tabs.length <= 0 ? <div /> : tabs.map(tab=>(
           <li id="touchable"  
           className={selectedTabId === tab.id ? "selected-tab":"unselected-tab"}
           key={tab.id} onClick={() =>handleSelectedTab(tab.id)}>{tab.title}</li>
         ))}
       </div>
 
      </Drawer>
</div> }
     </div>
    </div>
   </div>  
  )
}

export default Accounting