import React,{createContext,useEffect,useState} from 'react'
import {lightTheme } from './Theme/ThemeFile'
import MainNavigation from './navigation/MainNavigation'
import {BrowserRouter as Router} from 'react-router-dom'
import db from './firebase/config'
import { useAuthState } from 'react-firebase-hooks/auth';
import {getAuth} from 'firebase/auth'
import './css/main.css'
import AuthNavigation from './navigation/AuthNavigation'
import SnackbarProvider from 'react-simple-snackbar'
import MainLoader from './components/MainLoader'
import { useGlobalContextHook } from './hook/useGlobalContextHook'
//import socketIO from 'socket.io-client';
//const socket = socketIO.connect('http://localhost:5000');

export const ThemeContext = createContext()
export const UserContext = createContext()
       const auth = getAuth()

export default function App() {   
    const {dispatch,currentUser,accountSetupStatus,blocked,subscription} = useGlobalContextHook()
    const [user]= useAuthState(auth)
    const [userContextState] = useState(null)
    const [theme,] = useState(lightTheme)
  
useEffect(() => {
  const unSubscribe = async () => {
    if(user === null) { console.log('firebase auth logged in =',user) } else {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_USER&id=${user.uid}`)
        const json = await response.json()
        if(response.statusText === "OK"){
           dispatch({type:'SET_IS_LOADING', payload:false})
            dispatch({type:'SET_CURRENT_USER',payload:json})
            if(json.completed  === "completed") {dispatch({type:'ACCOUNT_STATUS',payload:true})} else {
              dispatch({type:'ACCOUNT_STATUS',payload:false})}

            if(json.accountStatus  === "expired") {dispatch({type:'SET_SUBSCRIPTION',payload:"expired"})} else {
                dispatch({type:'SET_SUBSCRIPTION',payload:"active"})}  
            if(json.worker_status === "blocked") {
              dispatch({type:'BLOCK_ACCOUNT',payload:true})} else {
                dispatch({type:'BLOCK_ACCOUNT',payload:false})}
        }
        if(response.statusText ==="Not Found"){
           console.log(json.message)
        }
     } catch (error) {
        console.log(error)   
     }
    }
  }
  unSubscribe()

},[user]);

  return (
      <UserContext.Provider value={userContextState} >
        <ThemeContext.Provider  value={theme === null ? lightTheme : theme}>
          <SnackbarProvider>           
            <Router>
            {user === null  && currentUser === undefined   ? <MainLoader/> :
             user === null && currentUser === null  ? <AuthNavigation  screename="landing"/>:
             !accountSetupStatus  && !blocked ? <AuthNavigation  screename="setup"/> : 
             blocked ? <AuthNavigation screename="blocked" /> :
             subscription === "expired" ? <AuthNavigation screename="expired" /> :
             user !== null && accountSetupStatus  && !blocked ? <MainNavigation /> :
            <div />}
              </Router> 
             </SnackbarProvider>        
       </ThemeContext.Provider>
       </UserContext.Provider>  
      
  )
}


