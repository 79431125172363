import React,{useState} from 'react'
import {Icon} from '@blueprintjs/core'
import {useNavigate} from 'react-router-dom'
import {getAuth,} from 'firebase/auth'
import { useSnackbar } from 'react-simple-snackbar'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'

const auth = getAuth()

function Combar(props) {
    const navigate = useNavigate()
    const {name,brandColor,_id} = props.info
    const {dispatch,currentUser} = useGlobalContextHook()
    const [check,checked] = useState(false)
    const [openSnackbar] = useSnackbar()

    const selectedCompany = async()=>{
        checked(prev=>!prev);
        navigate('/Proceed',{state:props.info})
        modifyuserprofile()
    }


    const modifyuserprofile = async()=>{
        try {
            
            const userObj = {companyId:_id,company:name, role:"worker",worker_status:"unset"}
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update?action=UPDATE_USER_PROFILE&id=${currentUser.id}`,{
                method: 'PATCH',
                body: JSON.stringify(userObj),
                headers: {'Content-Type': 'application/json'}
            })
            const json = await response.json()
            if(response.ok) {
                 //update user context
                 dispatch({type:'SET_CURRENT_USER',payload:json})
            }
            if(!response.ok){
                openSnackbar('!Ooops! failed to update user profile')
            }

          } catch (error) {
              console.log(error);
          }
  }
  return (
    <div className="comp-bar">
            <div className="round-comp" style={{ backgroundColor:brandColor}}>{name.charAt(0)+name.charAt(1)}</div> 
            <div className="round-name">{name}</div> 
             <div className="round-tick" onClick={()=>selectedCompany()}>
                 <Icon icon={check ? "tick-circle" : "circle"}  className='loan-icon' 
                  style={{marginTop:0}}color={"#249E61"} size={20}/></div>
    </div>
  )
}

export default Combar