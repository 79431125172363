import React from 'react'
import logo from '../img/logo.png'
import {Link} from 'react-router-dom'

function Waiting() {
  return (
    <div>
                <div className="auth-nav">
            <img src={logo} />
                <h2 style={{color:"#fff"}}>Kopaleo</h2>
        </div>

        <div className="auth-container">

                <h2>Account waiting verification from by your company</h2>
                <div className="input-bar">
                <p style={{textAlign: 'center',padding:12}}>Contact your company administrator to approve your account</p>
                </div> 
               
                <Link to="/" >
              <button className="btn-go-to-login">Check verification status</button></Link>  
                </div>
    </div>
  )
}

export default Waiting