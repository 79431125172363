import React, { useContext, useState,useEffect } from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {useNavigate,useLocation} from 'react-router-dom'
import { ThemeContext } from '../App'
import { Menu, MenuItem,ProgressBar,Spinner,SpinnerSize,Icon} from '@blueprintjs/core'
import Attachbox from '../components/Attachbox'
import db  from '../firebase/config'
import { useAuthState } from 'react-firebase-hooks/auth';
import {setDoc,doc,collection,query,onSnapshot,where,updateDoc,Timestamp} from 'firebase/firestore'
import { ref,uploadBytesResumable,getDownloadURL} from 'firebase/storage'
import {storage} from '../firebase/config'
import { UserContext } from '../App'
import { useSnackbar } from 'react-simple-snackbar'
var moment = require('moment'); // require
moment().format();
const uniqueId = require('react-unique-nums')

function Editloan() {
    const currentUserContext = useContext(UserContext)
    const navigate = useNavigate()
    const {state} = useLocation()

    const  {
        loanId,docId,fullName,sex,residence:user_residence,phoneNumber,age:user_age,occupation:user_occupation,idNumber2:user_idNumber2,idNumber1,
        profit:loan_profit,repaymentDates:loan_repaymentDates,cash:loan_cash,interest:loan_interest,final_amount,maturity:loan_maturity,releasedDate,duration:loan_duration,duration_type,repayment_cycle,repayment_amount:loan_repayment_amount,number_of_repayments,loanOfficerName,
        collateral:loan_collateral,collateralValue:loan_collateralValue,attachments,releasedDateObj,createdAt
       } = state
    
   
        let loanMaturityDateFormatted = loan_maturity//.toDate().toDateString()
        //let  releasedDateFormatted = releasedDate.toDate().toDateString()
    const { body, card, primary, lighttext,lightgrey } = useContext(ThemeContext)
    const [active,setActive] = useState("1")
    const [openSnackbar, closeSnackbar] = useSnackbar()
    const [spin,stop] = useState(false)
	const [errorMessage,showErrorMessage] = useState(null)

    const [fullname,setFullName] = useState(fullName)
    const [gender,setGender] = useState(sex)
    const [residence,setResidence] = useState(user_residence)
    const [phone,setPhone] = useState(phoneNumber)
    const [age,setAge] = useState(user_age)
    const [idNumber,setIdNumber] = useState(idNumber1)
    const [idNumber2,setIdNumber2] = useState(user_idNumber2)
    const [occupation,setOccupation] = useState(user_occupation)

    const [cash,setCash] = useState(loan_cash)
    const [interest,setInterest ] = useState(loan_interest)
    const [finalAmount,setFinalAmount] = useState(final_amount)
    const [duration,setDuration] =useState(loan_duration)
    const [repayment_circle,setRepaymentCircle] = useState(duration_type)
    const [repayment_amount,setRepaymentAmount] = useState(loan_repayment_amount)
    const [numberOfRepayments,setNumberOfRepayments] = useState(number_of_repayments)
    const [profit,setProfit] = useState(loan_profit)
    const [percentage_state,setPercentage] = useState(0)
    const [maturity,setMaturity] = useState(loanMaturityDateFormatted)
    const [maturityDate,setMaturityDate] = useState(null)
    const [basis,setBasis] = useState(duration_type)
    const [collateral,setCollateral] = useState(loan_collateral)
    const [collateralValue,setCollateralValue] = useState(loan_collateralValue)
    const [show, hide] = useState(false)

    const [selectedAttachment,setSelectedAttachment] = useState(null)
    const [attachmentTitle,setAttachmentTitle] = useState("")
    const [attachmentsList,setAttachmentList] = useState(attachments)
    const [repaymentDates,setRepaymentDates] = useState(loan_repaymentDates)
    const [datesArry,setDatesArry] = useState([])

    
    
    useEffect(() => {
          let interest_rate = Number(interest) / 100
          let interest_amount = Number(interest_rate) * Number(cash)
          let finalAmount = Number(cash)+Number(interest_amount)
         setFinalAmount(finalAmount)
         setProfit(interest_amount)
         
         let repayment_amount_var = Number(finalAmount) / Number(numberOfRepayments)
         setRepaymentAmount(repayment_amount_var)



    },[cash,interest,numberOfRepayments,repayment_amount])

    const repaymentFn=(amount)=>{
         setRepaymentAmount(amount);
         let repayment_amount_circle = Number(finalAmount) / Number(amount)
         setNumberOfRepayments(repayment_amount_circle.toFixed(2))
    }
  
    const uploadAttachment = () =>{
         if(selectedAttachment === null){
             openSnackbar("Select first attachment to upload")
         } else {
            if(attachmentTitle === ""){
                openSnackbar("Please write attachment's name ")
            } else {
                //upload it
                hide(true)
                let attachmentId = uniqueId().toString()
                let  attachmentRef = ref(storage,`/attachments/${currentUserContext.company}/${currentUserContext.branch}/${attachmentId}`)
                let uploadTask = uploadBytesResumable(attachmentRef,selectedAttachment);
  
                uploadTask.on("state_changed", 
                       function progress(snapshot){
                          var percentage = (snapshot.bytesTransferred / 
                          snapshot.totalBytes) 
                          //console.log(percentage);
                          setPercentage(percentage);
                       },
                       function error(error){
                          let err = error.toString();
                          openSnackbar("Error on uploading",err)
                          console.log(error)
                       },
                       function complete(){
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            //console.log('File available at', downloadURL);
                            attachmentsList.push({
                                  title:attachmentTitle,
                                  url:downloadURL,
                                  type:selectedAttachment.type

                            });
                            hide(false)
                          });
                       })
            }
        
         }
    }

  const removeAttachment = (url)=>{
        let newArry = attachmentsList.filter(attachment=>attachment.url !== url);
        setAttachmentList(newArry);
  }
  const saveLoanDetail = async()=>{
            try {
                stop(true)
                showErrorMessage(null)
               
                let updateRef = doc(db,"loans",docId)
                let repayment_circle_modified;

                if(repayment_circle === "months"){
                    repayment_circle_modified = "monthly"
                } else {
                    if(repayment_circle === "weeks"){
                        repayment_circle_modified = "weekly"
                    } else {
                        if(repayment_circle === "days"){
                            repayment_circle_modified = "daily"
                        } else {
                            if(repayment_circle === "years"){
                                repayment_circle_modified = "yearly"
                            } else {
                                repayment_circle_modified = "monthly"
                            }
                        }
                    }
                }
                await updateDoc(updateRef,{
                       loanId:loanId,
                       attachments:attachmentsList,
                       fullName:fullname,
                       sex:gender,
                       residence:residence,
                       phoneNumber:Number(phone),
                       age:Number(age),
                       occupation:occupation,
                       idNumber1:idNumber,
                       idNumber2:idNumber2,
                       
                       cash:Number(cash),
                       interest:Number(interest),
                       final_amount:Number(finalAmount),
                       duration:Number(duration),
                       duration_type:basis,
                       repayment_cycle:repayment_circle_modified,
                       number_of_repayments:Number(numberOfRepayments),
                       repayment_amount:Number(repayment_amount),
                       profit:Number(profit),
                       maturity:maturity,
                       maturityDate:maturityDate,
                       collateral:collateral,
                       collateralValue:Number(collateralValue),
                       repaymentDates:repaymentDates,
                       datesArry:datesArry,


                       company:currentUserContext.company,
                       companyId:currentUserContext.companyId,
                       branch:currentUserContext.branch,
                       branchId:`${currentUserContext.branchId}`,
                       loanOfficer:currentUserContext.email,
                       loanOfficerName:currentUserContext.username
                });

                await openSnackbar(`Loan  edited successfully`)
                await navigate('/Loans/View',{state:`${loanId}`})
                await stop(false)
            } catch (error) {
                console.log(error);
                showErrorMessage(error)
            }
  }

  useEffect(()=>{
    if(repaymentDates.length <= 0){

    } else {
        let d = repaymentDates[repaymentDates.length - 1]
                setMaturity(d.date)
                 setMaturityDate(d.name)
    }


  },[basis,duration])

  useEffect(() => {
      
    let newArry = [];
    //
      for(let i=1;i<=numberOfRepayments;i++){
        let date = moment(createdAt)
        let repayment_amount_var = Number(finalAmount) / Number(numberOfRepayments)
        let newDate = date.clone().add(i,repayment_circle)
        let dateName = newDate.format("MMMM DD, YYYY")
        let cycle_createdAt = newDate.format("YYYY-MM-DD");
        newArry.push({
            date:newDate._d,
            id:i,
            name:dateName,
            status:"pending",
            amount:Number(repayment_amount_var),
            remained:0,
            amountPaid:0,
            cycle_createdAt:cycle_createdAt,
          });

          //newDatesTmptArry.push(createdAt)     
      };
      setRepaymentDates(newArry);
     // setDatesArry(newDatesTmptArry)
  },[numberOfRepayments,repayment_circle])

  useEffect(() => {
     if(repaymentDates.length <= 0 ) {} else{
             let newDatesTmptArry = []
            repaymentDates.forEach(date =>{
                newDatesTmptArry.push(date.cycle_createdAt) 
            });
            setDatesArry(newDatesTmptArry);
            
     }
  },[repaymentDates])

  useEffect(() => {
     if(state === null || state === undefined){
         navigate("/");
     }
  },[state])



    return (
        <div className="main-wapper" >
            <TopNav /> <br />
            <div className="sider" >
                <SiderContent />
            </div>
            <div className="section" style={{ backgroundColor: body }}>
                <div className="-ps-wr"  style={{ backgroundColor: body }}><br />
                    <div className="new-loan-wrapper" style={{ backgroundColor: card }}>
                        <h2>Edit loan details</h2>
                        <div className="menu-div">
                        <Menu><MenuItem
                                    className="my-tab"
                                    style={{borderBottomWidth:active === "1" ? 2:0,borderColor: active === "1" ? primary :lightgrey}}
                                    
                                    icon='user' text="Personal details" onClick={()=>setActive("1")}  /></Menu>
                        <Menu><MenuItem
                                     className="my-tab"
                                    style={{borderBottomWidth:active === "2" ? 2:0,borderColor: active === "2" ? primary :lightgrey}}

                                    icon='dollar' text="Loan details"  onClick={()=>setActive("2")}/></Menu>
                        <Menu><MenuItem 
                                     className="my-tab"
                                    style={{borderBottomWidth:active === "3" ? 2:0,borderColor: active === "3" ? primary :lightgrey}}

                                    icon='document' text="Loan attachments files" onClick={()=>setActive("3")} /></Menu>
                        </div>
                        {active === "1" ?                      
                         <div className="tab-2">
                         
                       <div class="ln-box">  
                          <p>Full name</p>
                                <input type="text" placeholder="Full name"
                                 onChange={(e)=>setFullName(e.target.value)}
                                value={fullname === undefined ? "" : fullname}
                           />

                         </div>

                     <div className="ln-box">
                        <p>Sex </p>
                         <select onChange={(e)=>setGender(e.target.value)}
                                 value={gender}>
                            <option value="male" selected={sex === "male" ? true : false}>Male</option>
                            <option value="female" selected={sex === "female" ? true : false}>Female</option>
                        </select>
                    </div>   
                        

                    <div className="ln-box">
                        <p>Residence</p>
                        <input type="text" onChange={(e)=>setResidence(e.target.value)} 
                                value={residence}
                                placeholder="Residence" />
                    </div>

                    <div className="ln-box">
                        <p>Phone number</p>
                        <input type="text" onChange={(e)=>setPhone(e.target.value)} 
                                value={phone}
                                placeholder="Phone number" />
                    </div>

                    <div className="ln-box">
                        <p>Age</p>
                        <input type="text" onChange={(e)=>setAge(e.target.value)} 
                                value={age}
                                placeholder="Age" />
                    </div>
                    
                    <div className="ln-box">
                        <p>Occupation</p>
                        <input type="text" onChange={(e)=>setOccupation(e.target.value)} 
                                value={occupation}
                                placeholder="Occupation" />
                    </div>

                    <div className="ln-box">
                        <p>ID number</p>
                        
                        <input type="text" onChange={(e)=>setIdNumber(e.target.value)} 
                                value={idNumber}
                                placeholder="ID number" />
                    </div>

                    <div className="ln-box">
                        <p>Another ID number</p>
                        <input type="text" onChange={(e)=>setIdNumber2(e.target.value)} 
                                value={idNumber2}
                                placeholder="another ID number" />
                    </div>

                    <div style={{flexDirection:"column",width:"100%"}}>
                            <div style={{display:"inline-flex"}}>
                            <div className="next" style={{backgroundColor:primary,margin:15}} onClick={()=>setActive("2")}>Next</div>      
                            </div>
                        </div>    

                     
                        </div> :
                        active === "2" ?                        
                        <div className="tab-2">
                       
                     <div className="ln-box">  
                        <p>Cash/Principal Amount </p> 
                        <input type="text" value={cash} onChange={(e)=>setCash(e.target.value)} 
                                placeholder="Cash" /></div>
                       <div className="ln-box">  
                       <p>Interest rate:</p>
                        <select onChange={(e)=>setInterest(e.target.value)}>
                            <option value="5"  selected={interest === 5 ? true :false}>5%</option>
                            <option value="10" selected={interest === 10 ? true :false}>10%</option>
                            <option value="15" selected={interest === 15 ? true :false}>15%</option>
                            <option value="20" selected={interest === 20 ? true :false}>20%</option>
                            <option value="25" selected={interest === 25 ? true :false}>25%</option>
                            <option value="30" selected={interest === 30 ? true :false}>30%</option>
                            <option value="35" selected={interest === 35 ? true :false}>35%</option>
                            <option value="40" selected={interest === 40 ? true :false}>40%</option>
                            <option value="45" selected={interest === 45 ? true :false}>45%</option>
                            <option value="50" selected={interest === 50 ? true :false}>50%</option>
                        </select></div>

                       <div class="ln-box"> 
                       <p>Final Amount</p>
                        <input type="text" disabled={true} value={finalAmount} />
                     </div>

                       <div class="ln-box">  
                       <p>Duration:</p>
                        <select name="" onChange={(e)=>setDuration(e.target.value)}>
                            <option value="1" selected={duration === 1 ?true:false}>1</option>
                            <option value="2" selected={duration === 2 ?true:false}>2 </option>
                            <option value="3" selected={duration === 3 ?true:false}>3 </option>
                            <option value="4" selected={duration === 4 ?true:false}>4 </option>
                            <option value="5" selected={duration === 5 ?true:false}>5 </option>
                            <option value="6" selected={duration === 6 ?true:false}>6 </option>
                            <option value="7" selected={duration === 7 ?true:false}>7 </option>
                            <option value="8" selected={duration === 8 ?true:false}>8 </option>
                            <option value="9" selected={duration === 9 ?true:false}>9 </option>
                            <option value="10" selected={duration === 10 ?true:false}>10 </option>
                            <option value="11" selected={duration === 11 ?true:false}>11 </option>
                            <option value="12" selected={duration === 12 ?true:false}>12 </option>
                            <option value="13" selected={duration === 13 ?true:false}>13 </option>
                            <option value="14" selected={duration === 14 ?true:false}>14 </option>
                            <option value="15" selected={duration === 15 ?true:false}>15 </option>
                            <option value="16" selected={duration === 16 ?true:false}>16 </option>
                            <option value="17" selected={duration === 17 ?true:false}>17 </option>
                            <option value="18" selected={duration === 18 ?true:false}>18 </option>
                            <option value="19" selected={duration === 19 ?true:false}>19 </option>
                            <option value="20" selected={duration === 20 ?true:false}>20 </option>
                            <option value="21" selected={duration === 21 ?true:false}>21 </option>
                            <option value="22" selected={duration === 22 ?true:false}>22 </option>
                            <option value="23" selected={duration === 23 ?true:false}>23 </option>
                            <option value="24" selected={duration === 24 ?true:false}>24 </option>
                            <option value="25" selected={duration === 25 ?true:false}>25</option>
                            <option value="26" selected={duration === 26 ?true:false}>26</option>
                            <option value="27" selected={duration === 27 ?true:false}>27</option>
                            <option value="28" selected={duration === 28 ?true:false}>28</option>
                            <option value="29" selected={duration === 29 ?true:false}>29</option>
                            <option value="30" selected={duration === 30 ?true:false}>30</option>
                        </select>

                        <select onChange={(e)=>setBasis(e.target.value)}>
                            <option value="days"  selected={basis === "days" ?true : false}>days</option>
                            <option value="weeks" selected={basis === "weeks" ?true : false}> weeks</option>
                            <option value="months" selected={basis === "months" ?true : false}>months</option>
                            <option value="years"  selected={basis === "years" ?true : false}>years </option>
                        </select>
                        </div>

                     <div className="ln-box">
                     <p>Repayment cycle  </p>
                        <select onChange={(e)=>setRepaymentCircle(e.target.value)}>
                            <option value="days" selected={repayment_circle === "days" ?true:false}>Daily</option>
                            <option value="weeks" selected={repayment_circle === "weeks" ?true:false}>Weekly</option>
                            <option value="months" selected={repayment_circle === "months" ?true:false}>Monthly</option>
                            <option value="years" selected={repayment_circle === "years" ?true:false}>Yearly</option>
                        </select>
                     </div>

                      
                        <div className="ln-box">
                            <p>Number of repayments</p>
                            <input type="text" onChange={(e)=>setNumberOfRepayments(e.target.value)} 
                              value={numberOfRepayments}
                              placeholder={` ${numberOfRepayments}`}/>
                        </div>

                        <div className="ln-box">
                            <p>Rapayment dates </p>
                             <div className="ln-box-wrapper">
                              {repaymentDates <= 0 ? 
                              <div/> :
                                 <div>
                                     {repaymentDates.map((date,i)=>(
                                        <div className="ln-chart" key={i}>
                                          <p>{date.id} repayment date {date.name} [ <em style={{color:lightgrey,fontSize:11}}>{date.amount.toLocaleString()}</em> ]</p>
                                           
                                          </div>
                                     ))}
                                     </div>}
                             </div>
                            </div>
                        
                        <div className="ln-box">
                        <p>Repayment amount </p>
                            <input type="text" onChange={(e)=>repaymentFn(e.target.value)} 
                             value={repayment_amount}
                            />
                        </div>

                        <div className="ln-box">
                            <p>Profit</p>
                            <input type="text" value={ profit.toLocaleString()} disabled={true} />
                        </div>

                        
                        <div className="ln-box">
                            <p>Maturity </p>
                            <input type="text" value={maturityDate === null ? " ": maturityDate} disabled={true} />
                        </div>

                        <div className="ln-box">
                        <p>Collateral  </p>
                            <input type="text" onChange={(e)=>setCollateral(e.target.value)} 
                             value={collateral}
                             placeholder="e.g Tv,Land"/>
                        </div>

                        <div className="ln-box">
                        <p>Collateral value </p>
                            <input type="text" onChange={(e)=>setCollateralValue(e.target.value)} 
                            value={collateralValue}
                             placeholder="400,000"/>
                        </div>
                        <div style={{flexDirection:"column",width:"100%"}}>
                            <div style={{display:"inline-flex"}}>
                             <div className="next" style={{backgroundColor:primary}} onClick={()=>setActive("1")}>Previous</div>  
                                  <div className="next" style={{backgroundColor:primary}} onClick={()=>setActive("3")}>Next</div>   
                                </div>
                        </div>

                        </div> :
                       <div className="tab-3">
                           
                       <Menu><MenuItem icon='document' text="Loan attachments" /></Menu>
               

                        <div className="filled"><b>Add attachment</b> <br />
                           <input type="text" placeholder="name of attachment" onChange={(e)=>setAttachmentTitle(e.target.value)} id="title" />
                           <input type="file" placeholder="" onChange={(e)=>setSelectedAttachment(e.target.files[0])} />
                          <button className="btn-save-upload" onClick={(e)=>uploadAttachment()}>Upload</button >
                          </div>

                          {!show ? <div /> : 
                              <div>
                                  <div className="progress">
                                     <p>Uploading attachment {(percentage_state * 100).toFixed(0)} %</p>   
                                        <ProgressBar value={percentage_state}  stripes={true}/>
                                    </div>
                              </div>
                            } 
                         <p style={{marginLeft:10,fontWeight:'bold'}}>Uploaded</p>
                       
                        {attachmentsList.length <= 0 ? <div/> :
                         <div>
                             {attachmentsList.map((attachment, index) =>(
                                  <Attachbox key={index} info={attachment}
                                     icon="trash" name="Remove"
                                     removeAttachment={removeAttachment} />  
                             ))}
                             </div>}

                       <div> 
                       {errorMessage === null ? <div/> : 
			                <div className="input-bar" style={{borderWidth:0}}>
			                <Icon icon="error" color={"#b41313"}  size={20} className='icon-auth'/>
			                <p   style={{color:"#b41313"}}>{errorMessage.toString()}<span className=" ">{ }</span></p>
				                </div> 
			                }
                         <button  onClick ={()=>saveLoanDetail()}style={{backgroundColor:primary,color:lighttext}}  className="save-loan">
                         {spin ? <Spinner size={SpinnerSize.SMALL} intent="warning"/> : <div />}
                            Save loan details </button></div>
                      </div>
                     }
                        
 <br />
                    </div>
                    <br /><br />
                </div>
            </div>
        </div>
    )
}

export default Editloan