import React from 'react'
import {Icon} from '@blueprintjs/core'

function Empty({name, icon, color}) {
  return (
    <div className="empty-center">
        <Icon icon={icon} size={50} color={color} className="empty-icon"  />
        <h3>{name}</h3>
    </div> 
  )
}

export default Empty