import React,{useContext,useState} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {ThemeContext} from '../App'
import {Tab,Tabs, Icon,Dialog} from '@blueprintjs/core'
import Newbranch from '../components/Newbranch'
import BranchCard  from '../components/BranchCard'
import LoanTab from '../tabs/LoanTab'
import RepaymentTab from '../tabs/RepaymentTab'
import FinancesTab from '../tabs/FinancesTab'
import ReportsTab from '../tabs/ReportsTab'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import SelectBranch  from '../components/SelectBranch'


function ViewBranch() {
    const { lightgrey,card,body} = useContext(ThemeContext)
    const {currentUser,activeBranch} = useGlobalContextHook()
    const {role,branchId,branch} = currentUser  
    const [open, close] = useState(false);
    const [selectedTabId,setSelectedTab] = useState("1")
    const [tabs,setTabs] = useState([
      {id:"1",title:"Loans"},{id:"2",title:"Repayments"},{id:"3",title:"Expenses"},{id:"4",title:"Reports"}
       ])

    const handleTabChange =(id)=>{
      setSelectedTab(id)
    }
    
  return (
    <div className="main-wapper" >
         <TopNav />
   <div className="content-wrapper"> <div className="sider" >
            <SiderContent />
        </div>
        <div className="section" style={{backgroundColor:body}}>       
      {activeBranch === null ? <div /> : 
      <div>
           <BranchCard color={activeBranch.color} name={activeBranch.name} location={activeBranch.location} consumer="viewbranch"/>  
        </div>} 
        {activeBranch === null && role === "company_admin" ? <SelectBranch /> : 
         activeBranch !== null && role === "worker"  ?   
         <Tabs className="Tabs" onChange={(id)=>handleTabChange(id)}  animate={true} selectedTabId={selectedTabId}  > 
         {tabs.map((tab, i)=>(
            <Tab key={i} id={tab.id} title={tab.title}  
                 panel={<div> {
                      tab.id === "1" ? <LoanTab  branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id }/> :
                      tab.id === "2" ? <RepaymentTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> : 
                      tab.id === "3" ? <FinancesTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> : 
                      tab.id === "4" ? <ReportsTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id }/> :
                            <LoanTab />
                        }
                    </div>
                 } style={{outline: "none",fontWeight: "bold"}} />
         ))} 
        </Tabs> :
                   <Tabs className="Tabs" onChange={(id)=>handleTabChange(id)}  animate={true} selectedTabId={selectedTabId}  > 
                   {tabs.map((tab, i)=>(
                      <Tab key={i} id={tab.id} title={tab.title}  
                           panel={<div> {
          
                                tab.id === "1" ? <LoanTab  branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> :
                                tab.id === "2" ? <RepaymentTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> : 
                                tab.id === "3" ? <FinancesTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> : 
                                tab.id === "4" ? <ReportsTab branch={role === "worker" ? branch : activeBranch.name } branchId={role === "worker" ? branchId : activeBranch._id } /> :
                                      <LoanTab />
                                  }
                              </div>
                           } style={{outline: "none",fontWeight: "bold"}} />
                   ))} 
                  </Tabs>}
       
           </div></div>

        <Dialog isOpen={open} canOutsideClickClose={true} onClose={()=>close(prev=>!prev)}>
            <Newbranch />
        </Dialog>
    </div>
  )
}

export default ViewBranch