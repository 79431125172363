import React,{useContext,useState} from 'react'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import {ThemeContext} from '../App'
import {Icon,Menu,MenuItem,Button} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {useNavigate} from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth';
import {getAuth,signOut} from 'firebase/auth'
import format from 'date-fns/format'


const auth = getAuth()

function Expenses() {  
    const {dispatch,currentUser} = useGlobalContextHook()
    const [user]= useAuthState(auth)
    const navigate = useNavigate()

    const {role,photoURL,username,email,company,worker_status,subscription,started,ending,branch,phoneNumber} = currentUser
    const { nusery,body} = useContext(ThemeContext)

  
    const logoutFn = async()=>{
        try {
            await signOut(auth);
            await  navigate('/');
            await window.location.reload();
        } catch (error) {
              console.log(error.message,'failed to sign out')
           }
    }

    //console.log(currentUserContext)
 
  return (
    <div className="main-wapper" >
         <TopNav />
      <div className="content-wrapper">
      <div className="sider" >
            <SiderContent />
        </div>
        <div className="section" style={{backgroundColor:body}}> <br />
          <div  className="info-box" style={{marginLeft:15,marginTop:0}}>

          <div className="banner-profile" style={{
             backgroundImage:"url('https://picsum.photos/id/168/1920/1280')"
          }}>
          <div className="headline">
                 <h3 style={{color:"#fff"}}>Profile</h3>
            </div> 

            <div style={{width:"100%",}}>
                    <div className="logo">
                        <img src={photoURL} alt="logo" />
                            <div>
                            <h3 style={{color:"#fff"}}>{username}</h3>
                         <p style={{color:"#fff"}}>{email}</p>
                    </div>
                 </div>
                </div> 
          </div>

                <div className="book-preview">
                     <div className="title-bar">
                         <h2>User infomation</h2>
                     </div>
                     <div className="span-bar"><div className="spanned-a">Company:</div>&emsp; <div className="spanned">{company}</div></div>
                     {role === "worker" ?                      <div className="span-bar"><div className="spanned-a">Branch:</div>&emsp; <div className="spanned">{branch}</div></div>:<div />}
                     <div className="span-bar"><div className="spanned-a">Role:</div>&emsp; <div className="spanned">{role}</div></div>
                     {role === "worker" ?  <div className="span-bar"><div className="spanned-a">Status:</div>&emsp; <div className="spanned">  
                        <Icon icon="dot"
                                          color={ 
                                                  worker_status === "active" ? nusery :
                                                  worker_status === "inactive" ? "#dd3549" :
                                                  worker_status === 'waiting approval' ? "#a53edd" :
                                                  worker_status === 'blocked' ? "#FF1B2D" :nusery
                                            }
                        />{worker_status}</div></div> : 
                      <div>
                            <div className="span-bar"><div className="spanned-a">Phone:</div>&emsp; <div className="spanned">{phoneNumber}</div></div>
                            <div className="span-bar"><div className="spanned-a">Subscription:</div>&emsp; <div className="spanned">{subscription}</div></div>
                            <div className="span-bar"><div className="spanned-a">Started:</div>&emsp; <div className="spanned" style={{color:"#7C3AED"}}> {format(new Date(started),'dd/MMMM/yyyy')}</div></div>
                            <div className="span-bar"><div className="spanned-a">Ending:</div>&emsp; <div className="spanned" style={{color:'#DB2777'}}> {format(new Date(ending),'dd/MMMM/yyyy')}</div></div>
                      </div>
                      } 
                      <div className="span-bar"><div className="spanned-a">Logout</div>&emsp; <div onClick={()=>logoutFn()} className="spanned" style={{color:'#DB2777'}}> <Icon icon="power" color="#DB2777" /></div></div> 
                </div>
          </div>
           </div>
      </div>
    </div>
  )
}

export default Expenses