import React,{useContext,useState,useEffect} from 'react'
import {ThemeContext} from '../App'
import {useNavigate,Link} from 'react-router-dom'
import Fab from '../components/Fab'
import format from 'date-fns/format'
import TopNav from '../components/TopNav'
import SiderContent from '../components/SiderContent'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import {Icon,MenuItem,Menu,Popover,Position} from '@blueprintjs/core'
import SelectBranch from '../components/SelectBranch'
import { useSnackbar } from 'react-simple-snackbar'
import Empty from '../components/Empty'
var moment = require('moment'); // require
moment().format();


function GroupLoans() {
    const [openSnackbar] = useSnackbar()
    const { body} = useContext(ThemeContext) 
    const {currentUser,dispatch,activeBranch,groupLoans} = useGlobalContextHook()
    const {role,settings} = currentUser
    const {currency} = settings
    const {permissions} = currentUser
    const {loans:loansPermissions} = permissions 
    const branchId = activeBranch === null ? currentUser.branchId : currentUser.role === "worker" ? currentUser.branchId :  activeBranch._id
    const branch = activeBranch === null ? currentUser.branch : currentUser.role === "worker" ? currentUser.branch :  activeBranch.name
    const navigate = useNavigate()
    const [isSortCollapsed,setIsSortCollapsed] = useState(false)
    const [sortOptions] = useState([
      {id:"opened"},
      {id:"ongoing"},
      {id:"fullypaid"},
      {id:"overdue"},
    ])
    const [selectedSortOption,setSelectedSortOption] = useState({id:'all'}) 
    
    const listGroupLoans =async()=>{
       try{
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_GROUP_LOANS&id=${branchId}`)
        const json = await response.json()
        if(response.ok){
         dispatch({type:'SET_GROUP_LOANS',payload:json})
        }
        if(!response.ok){
          openSnackbar(`Failed to list  group loans  check your internet connection`)
        }
       } catch(e){
        openSnackbar('Error during listing errors')
       }
    }

    const searchGroupLoans =async(keyword)=>{
    if(keyword.length <= 0) {
      listGroupLoans()
    } else {
      try{
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=SEARCH_GROUP_LOANS&keyword=${keyword}&id=${branchId}`)
        const json = await response.json()
        if(response.ok){
         dispatch({type:'SET_GROUP_LOANS',payload:json})
        }
        if(!response.ok){
          openSnackbar(`Failed to list  group loans  check your internet connection`)
        }
       } catch(e){
        openSnackbar('Error during listing errors')
       }
    }
   }

    const openLoan =(loan)=>{
      navigate('/view-group-loan',{state:loan})
    }
    
    const editLoan =(loanId)=>{}
    const removeLoan =async(loan)=>{
        try{
          const {_id} = loan
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/remove?action=REMOVE_GROUP_LOAN&id=${_id}`,{
            method: 'DELETE'
          })
          if(response.ok){
            openSnackbar(`Group loan removed successfully`)
            listGroupLoans()
          }
          if(!response.ok){
            openSnackbar(`Failed to remove group loan`)
          }
        } catch(e){
          openSnackbar(`Error during removing group loan`)
        }
    }
   
    //List group loans
    useEffect(() => {
      listGroupLoans()
    },[])

  return (
    <div className="main-wapper" >
         <TopNav /> 
        <div className="content-wrapper">
        <div className="sider" ><SiderContent  /></div>
        <div className="section" style={{backgroundColor:body}}> 
            {branchId === null && role === "company_admin" ? <SelectBranch /> : 
            <div className="charts-wrapper">
              <div className="location-bar">
                <h1 className="clinic-h1" style={{paddingLeft:10}}>Group Loans</h1>
              </div>
         <div className="clinic-options">
        <input type="text" placeholder="Search" style={{width:'80%'}} onChange={(e)=>searchGroupLoans(e.target.value)} /> 
      
        <div className="clinic-middle-section">
        </div>
            </div>
          <div className="table1" id="table-1">
        <div className="table1-header">
          <div className="header-title">Name</div>
          <div className="header-title">Cash</div>
          <div className="header-title">Interest</div>
          <div className="header-title">Final amount</div>
          <div className="header-title">Amount paid</div>
          <div className="header-title">Amount remained</div>
          <div className="header-title">Released date</div>
          <div className="header-title">Menu</div>
        </div>

        <div className="table1-row-wrapper">
        {groupLoans.length <= 0 ? <Empty icon="dollar" name="Currently,No any groupLoans" color="#666" /> : 
          groupLoans.map(loan =>(
          <div className="table1-cell-holder">
          <div className="table1-cell"onClick={()=>openLoan(loan)} >{loan.name === null ? 'not-added': loan.name}</div>  
          <div className="table1-cell">{loan.cash.toLocaleString()}</div>   
          <div className="table1-cell">{loan.interest}%</div>    
          <div className="table1-cell">{loan.finalAmount.toLocaleString()}</div>    
          <div className="table1-cell">{loan.amountPaid.toLocaleString()}</div>    
          <div className="table1-cell">{loan.amountRemained.toLocaleString()}</div>    
          <div className="table1-cell">{format(new Date(loan.releasedDate),'dd/MMMM/yyyy')}</div>       
           <div className="table1-cell">
           <Popover content={
               <Menu>
                   <p className='container-p'>Options </p>
                  <MenuItem    icon='eye-open' text="View" onClick={()=>openLoan(loan)} />
                  <MenuItem    icon='trash' text="Delete" onClick={()=>removeLoan(loan)} />
               </Menu>
               
              } position={Position.BOTTOM_BOTTOM}>
                <div className="table-menu" id="touchable">
               <Icon icon="more"  color="#666"  size={22}/></div> 
        </Popover>
              </div>
          </div>))
        }
        </div>
           </div> 
           <Link to="/Newloan" id="link">
            {loansPermissions[0].isGranted ?  <Fab  icon="add" text="New loan" action="NEW_LOAN"  />: <div />}
            </Link>
            </div>
             }
        </div>
    </div>
    </div>
  )
}

export default GroupLoans