import React,{useContext,useState,useEffect} from 'react'
import {ThemeContext} from '../App'
import {Icon,Popover,Position,Menu,MenuItem,Collapse} from '@blueprintjs/core'
import { useGlobalContextHook } from '../hook/useGlobalContextHook'
import Loading from '../components/Loading'
import format from 'date-fns/format'
import {useNavigate} from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import Falling from '../components/Falling'
import AccessDenied from '../components/AccessDenied'
var moment = require('moment'); // require
moment().format();

const ids = ['1']

function RepaymentTab({branchId,branch}) {
  const date = moment.utc();
  const { card,primary,nusery,lighttext} = useContext(ThemeContext)
  const {repayments,dispatch,currentUser} = useGlobalContextHook()
  const {username,companyId,settings} = currentUser
  const {permissions} = currentUser
  const {repayments: repaymentsPermissions} = permissions
  const {currency} = settings
  const [fromDate,setFromDate] = useState(date.clone().add(-10,"days").format("YYYY-MM-DD"))
  const [toDate,setToDate] = useState(date.clone().add(1,"days").format("YYYY-MM-DD"))
  const [loading,setLoading] = useState(false)
  const [Limit,setLimit] = useState(20)
  const [keyword,setKeyword] = useState("")
  const [openSnackbar] = useSnackbar()
  const [sum,setSum] = useState(0)
  const navigate = useNavigate()
  const [open,setOpen] = useState(false)

const viewRepayment =async(repayment)=>{
  navigate('/Receipt',{state:repayment})
}



const setToDateFn =(date)=>{
     let newDate = moment.utc(date)
     setToDate(newDate.clone().add(1,"days").format("YYYY-MM-DD"))     
}

const deleteRepayment= async(id)=>{
  try{
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/remove?action=DELETE_REPAYMENT&id=${id}`,{
      method: 'DELETE'
    })
    const json = await response.json()
    if(response.ok){
      dispatch({type:'REMOVE_REPAYMENT',payload:json})       
    } 
    if(!response.ok){
      console.log('ERROR',json.error)
      openSnackbar('Failed to delete repayment')
    }

  }catch(error){
    console.log(error,'on deleting repayment')
  }
}

useEffect(() => {
  const unsubscribe = async () => {
    try{
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=LIST_BRANCH_REPAYMENTS&id=${branchId}&fromDate=${fromDate}&toDate=${toDate}&keyword=${keyword}&limit=${Limit}`)
      const json = await response.json()
      if(response.ok){
        dispatch({type:'SET_REPAYMETS',payload:json})
      }
      if(!response.ok) {openSnackbar(json.message)}
    } catch(error){
      openSnackbar(error)
    }
  }
  unsubscribe()
},[fromDate, toDate, keyword,branchId])

useEffect(()=>{
  if(repayments === undefined) {} else { if(repayments.length <= 0){} else {
     let sum = repayments.reduce((accumulator,object)=>{
       return accumulator + object.amountPaid 
     },0); setSum(sum)
  
  }}
},[repayments,branchId])

const getCompanyInfo = async()=>{
  try{
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/read?action=READ_USER_BY_COMPANY_ID&id=${companyId}`)
    const json = await response.json()
    if(response.ok){
      downloadFormLoan(json[0])
    }
    if(!response.ok){
      openSnackbar(json.message)
    }
  }catch(error){openSnackbar("Error getting company")}
}

const downloadFormLoan = async(companyOwner)=>{
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create?action=PRINT_TABLE&currency=${currency}&total=${sum}&branch=${branch}&company=${companyOwner.company}&title=Repayments&fromDate=${fromDate}&toDate=${toDate}&email=${companyOwner.email}&phone=${companyOwner.phoneNumber}&username=${username}`,{
      method: 'POST',
      body: JSON.stringify({repayments,logo:companyOwner.photoURL}),
      headers: {'Content-Type': 'application/json'}
    })
    if(response.ok){
      const file = await response.blob()
      const createdFile =  await window.URL.createObjectURL(new Blob([file]), { type: 'application/pdf'});
      const link = document.createElement('a');
      link.href = createdFile;
      link.download = `Repayments from${fromDate}=>${toDate}.pdf`;
      link.click();
  
    }
  
    if(!response.ok){
      openSnackbar("Error creating pdf")
    }
  } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      {repaymentsPermissions[1].isGranted ?
       <div>
       <div className="stat-box">
           <div className="stat-h3" style={{ backgroundColor:"#D95F02"}}><Icon icon="import" style={{marginTop:-8}} color={lighttext} size={30} className="exp-icon-icon"/></div>
             <div className="stat-para"><h3 className="stat-h4">REPAYMENTS</h3><p>{branch}</p></div>
               <div><h3>{sum <= 0 ? <Falling /> : sum.toLocaleString()}<span className="currency">{currency}</span></h3></div></div>
     
     <div className='search-bar' style={{marginTop:-20}}>
         <div className="search-input" >
             <input type="text" onChange={(e)=>setKeyword(e.target.value)}  placeholder="Search repayment"/>
         </div>
      <div className="search-option" >
         <input  className="date-cl"type="date" placeholder="Date" onChange={(e)=>setFromDate(e.target.value)} />
         <Icon icon="arrow-right" color={"#666"} className="icon-arrow-right"/>
         <input className="date-cl" type="date" placeholder="Date" onChange={(e)=>setToDateFn(e.target.value)} />
     
     <Popover content={
               <Menu>
                <MenuItem onClick={()=>getCompanyInfo()}  icon='document'text="PDF" />    
             </Menu>} position={Position.BOTTOM_BOTTOM}>
      {repaymentsPermissions[0].isGranted ?  <button className='sort-btn'  style={{margin:"20px 10px 10px 10px",width:100}}>Export <Icon icon="chevron-down" color={primary} /></button> : <div />}
     </Popover>
     </div>  
     
     <div className="far-menu-search" onClick={()=>setOpen(prev=>!prev)}>
           <Icon icon="filter-list" color={primary} size={20} className="search-icon-iconx" /></div>
             </div>
     
     <Collapse isOpen={open} canOutsideClickClose={true} onClose={()=>setOpen(prev=>!prev)}>
         <div className='filter-collapse'>
         <input  className="date-cl"type="date"  value={fromDate} placeholder="Date" onChange={(e)=>setFromDate(e.target.value)} />
         <Icon icon="arrow-right" color={"#666"} className="icon-arrow-right"/>
         <input className="date-cl" type="date" value={toDate} placeholder="Date" onChange={(e)=>setToDateFn(e.target.value)} />
     
     <Popover content={
               <Menu>
                 <MenuItem    icon='panel-table' text="CSV"/>
                <MenuItem   icon='document'text="PDF" />    
             </Menu>} position={Position.BOTTOM_BOTTOM}>
     {repaymentsPermissions[0].isGranted ?   <button className='sort-btn' onClick={()=>getCompanyInfo()}  style={{margin:15}}>Export <Icon icon="chevron-down" color={primary} /></button>: <div />}
     </Popover>
         </div>
       </Collapse>     
     
             <div className="m-table">
               <div className="m-table-header">
                  <div className='m-header-title' id="m-number">No</div>
                  <div className='m-header-title' id="m-name">Name</div>
                  <div className='m-header-title' id="m-cash">Amount paid</div>
                  <div className='m-header-title' id="m-final">Total loan</div>
                  <div className='m-header-title' id="m-final">Collected by</div>
                  <div className='m-header-title' id="m-released">Collected date</div>
                  <div className='m-header-title' id="m-menu">menu</div>
         
               </div>
               <div className="m-table-row-wrapper">
               {repayments.length <= 0 ? <div /> :
                repayments.map((repayment,i)=>(
                 <div className="m-loan-cell-wrapper"  key={i}>
                 <div className='m-loan-cell' id="m-number">{i+1}</div>
                 <div className='m-loan-cell' id="m-name" onClick={()=>viewRepayment(repayment)}>{repayment.fullName}</div>
                 <div className='m-loan-cell'  id="m-cash">{repayment.amountPaid.toLocaleString()}<span className="currency">{currency}</span></div>
                 <div className='m-loan-cell'  id="m-final">{repayment.totalLoan.toLocaleString()}<span className="currency">{currency}</span></div>
                 <div className='m-loan-cell'  id="m-final">{repayment.loanOfficerName}</div>
                 <div className='m-loan-cell' id="m-released">{format(new Date(repayment.createdAt),'dd/MMMM/yyyy')}</div>
                  <div className='m-loan-cell' id="m-menu">
                  <Popover content=
     {
      <Menu>
          <p className='container-p'>menu</p>
         
         <MenuItem    icon='eye-open' onClick={()=>viewRepayment(repayment)} text="View"/>
        {repaymentsPermissions[3].isGranted ?  <MenuItem    icon='trash' onClick={()=>deleteRepayment(repayment._id)} text="Delete repayments"/> : <div />}
      </Menu>
      
     } position={Position.BOTTOM_BOTTOM}>
             <button className="btn-hidden"><Icon icon="more" /></button>
           </Popover>  
                   </div>        
                
                 </div>
              ))}
               </div>
               {loading ? <Loading /> : <div />}
               <div className="table-footer">
                  <div className="footer-title">Showing <strong> {repayments.length} </strong> repayments </div>
                    <div className="footer-next">
                        <div onClick={()=>setLimit(prev=> prev <= 15 ? 15 : prev -15)}>Prev</div>
                    </div>  
                    <div className="footer-next">
                        <div onClick={()=>setLimit(prev=> prev + 15)}>Next</div>
                    </div>                        
                  </div>
             </div>
         </div>
      : <AccessDenied />}
    </div>
  )
}

export default RepaymentTab