import React from 'react'
import logo from '../img/logo.png'
import {Link} from 'react-router-dom'

function Forbidden() {
  return (
    <div>
        <div className="auth-nav">
            <img src={logo} />
                <h2 style={{color:"#fff"}}>Kopaleo</h2>
        </div>

        <div id="auth-container">
            <h1 >Ooops!</h1>
                <h3>HTTP 403 Forbidden</h3>
                <p> Your access limit is forbidden to access  this page Go to the login page and try to sign in
                    contact us: 0766298542
                </p>
              <Link to="/" >
              <button className="btn-go-to-login">Go to home</button></Link>  
        </div>
    </div>
  )
}

export default Forbidden